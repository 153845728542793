import { supabase } from '../lib/supabaseClient';
import { Politician, PoliticalOffice } from '../types/database.types';

interface PoliticianOfficeJoin {
  political_office: PoliticalOffice;
  assigned_place: any;
}

class PoliticianService {
  async getPoliticians(partyId?: string): Promise<Politician[]> {
    let query = supabase
      .from('politicians')
      .select(`
        *,
        party:parties!politicians_party_id_fkey(*),
        birth_place:places(*),
        political_offices:politician_offices(
          political_office:political_offices(*),
          assigned_place:places(*)
        )
      `);

    if (partyId) {
      query = query.eq('party_id', partyId);
    }

    const { data, error } = await query;

    if (error) {
      console.error('Error fetching politicians:', error);
      throw new Error('Failed to fetch politicians');
    }

    // Transform the data to match the expected format
    const transformedData = data.map((politician: any) => ({
      ...politician,
      political_offices: politician.political_offices?.map((po: any) => ({
        id: po.political_office.id,
        title: po.political_office.title,
        description: po.political_office.description,
        assigned_place: po.assigned_place
      })) || []
    }));

    console.log('Transformed politicians data:', transformedData);
    return transformedData;
  }

  async getPoliticianById(id: string): Promise<Politician | null> {
    try {
      console.log('Fetching politician by ID:', id);
      const { data, error } = await supabase
        .from('politicians')
        .select(`
          *,
          party:parties!politicians_party_id_fkey(*),
          birth_place:places(*),
          politician_offices(
            id,
            political_office:political_offices(*),
            assigned_place:places(*)
          )
        `)
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching politician:', error);
        throw error;
      }

      if (!data) {
        return null;
      }

      console.log('Raw politician data:', data);

      // Transform the nested political_offices data
      const transformedData = {
        ...data,
        politician_offices: data.politician_offices?.map((po: any) => ({
          id: po.id,
          political_office: po.political_office,
          assigned_place: po.assigned_place
        })) || []
      };

      console.log('Transformed politician data:', transformedData);
      return transformedData;
    } catch (error) {
      console.error('Failed to fetch politician:', error);
      throw error;
    }
  }

  async getPoliticianVoteCount(id: string): Promise<number> {
    try {
      console.log('Fetching vote count for politician ID:', id);
      const { data, error } = await supabase
        .rpc('get_politician_vote_count', { politician_id_param: id });

      if (error) {
        console.error('Error fetching politician vote count:', error);
        throw error;
      }

      console.log('Vote count for politician:', data);
      return data || 0;
    } catch (error) {
      console.error('Failed to fetch politician vote count:', error);
      return 0;
    }
  }

  async createPolitician(politician: Omit<Politician, 'id' | 'created_at' | 'updated_at' | 'created_by'>): Promise<Politician> {
    try {
      // Get current user
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('No authenticated user found');
      }

      const now = new Date().toISOString();
      const { data, error } = await supabase
        .from('politicians')
        .insert([{
          ...politician,
          created_at: now,
          updated_at: now,
          created_by: user.id ?? null
        }])
        .select(`
          *,
          party:parties!politicians_party_id_fkey(*),
          birth_place:places(*),
          politician_offices(
            political_office:political_offices(*),
            assigned_place:places(*)
          )
        `)
        .single();

      if (error) {
        console.error('Failed to create politician:', error);
        throw error;
      }

      if (!data) {
        throw new Error('No data returned after creating politician');
      }

      // Transform the nested data
      const transformedData = {
        ...data,
        political_offices: data.political_offices?.map((po: any) => po.political_office) || []
      };

      return transformedData;
    } catch (error) {
      console.error('Failed to create politician:', error);
      throw error;
    }
  }

  async updatePolitician(id: string, politician: Partial<Politician>): Promise<void> {
    try {
      console.log('Starting politician update:', { id, politician });

      // Create update data object with all provided fields
      const updateData: Partial<Politician> = {};
      const updateableFields = [
        'first_name',
        'last_name',
        'description',
        'party_id',
        'birth_place_id',
        'birth_date',
        'image_url',
        'gender',
        'education_level',
        'twitter_url',
        'facebook_url',
        'instagram_url',
        'linkedin_url'
      ] as const;

      type UpdateableField = typeof updateableFields[number];

      updateableFields.forEach((field: UpdateableField) => {
        if (field in politician) {
          (updateData[field] as any) = politician[field];
        }
      });

      console.log('Update data:', updateData);

      const { error } = await supabase
        .from('politicians')
        .update(updateData)
        .eq('id', id);

      if (error) {
        console.error('Error updating politician:', error);
        throw error;
      }

      console.log('Successfully updated politician');
    } catch (error) {
      console.error('Failed to update politician:', error);
      throw error;
    }
  }

  async deletePolitician(id: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('politicians')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Error deleting politician:', error);
        throw error;
      }
    } catch (error) {
      console.error('Failed to delete politician:', error);
      throw error;
    }
  }
}

export const politicianService = new PoliticianService();
