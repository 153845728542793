import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  InputAdornment, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar, 
  Avatar, 
  Divider, 
  Paper, 
  IconButton,
  Tab,
  Tabs,
  CircularProgress,
  Button,
  useTheme
} from '@mui/material';
import { 
  Search as SearchIcon, 
  Clear as ClearIcon,
  Person as PersonIcon,
  Groups as GroupsIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { searchService, SearchResult } from '../../../services/searchService';
import { PoliticianCard } from '../shared/PoliticianCard';
import { PartyCard } from '../shared/PartyCard';
import { supabase } from '../../../lib/supabaseClient';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`search-tabpanel-${index}`}
      aria-labelledby={`search-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `search-tab-${index}`,
    'aria-controls': `search-tabpanel-${index}`,
  };
}

export const UserSearch: React.FC = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [selectedItem, setSelectedItem] = useState<{type: 'politician' | 'party', id: string, data: any} | null>(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const theme = useTheme();
  
  // Filter results based on current tab
  const filteredResults = searchResults.filter(result => {
    if (tabValue === 0) return true; // All results
    if (tabValue === 1) return result.type === 'politician'; // Only politicians
    if (tabValue === 2) return result.type === 'party'; // Only parties
    return true;
  });

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    
    // Reset selected item when search query changes
    if (selectedItem) {
      setSelectedItem(null);
    }
    
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }
    
    setIsLoading(true);
    try {
      const results = await searchService.search(query);
      setSearchResults(results);
    } catch (error) {
      console.error('Error during search:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = () => {
    setSearchQuery('');
    setSearchResults([]);
    setSelectedItem(null);
  };

  const handleItemClick = async (result: SearchResult) => {
    setLoadingDetails(true);
    
    try {
      if (result.type === 'politician') {
        // Fetch politician details
        const { data: politicianData, error } = await supabase
          .from('politicians')
          .select(`
            *,
            party:parties!party_id(*),
            birth_place:places!birth_place_id(*),
            politician_offices(
              political_office:political_offices(
                title,
                description
              )
            )
          `)
          .eq('id', result.id)
          .single();
          
        if (error) throw error;
        
        setSelectedItem({
          type: 'politician',
          id: result.id,
          data: politicianData
        });
      } else if (result.type === 'party') {
        // Fetch party details
        const { data: partyData, error } = await supabase
          .from('parties')
          .select('*')
          .eq('id', result.id)
          .single();
          
        if (error) throw error;
        
        setSelectedItem({
          type: 'party',
          id: result.id,
          data: partyData
        });
      }
    } catch (error) {
      console.error('Error fetching details:', error);
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  const handleBackToResults = () => {
    setSelectedItem(null);
  };
  
  // Function to determine role text for politician card
  const getPoliticianRole = (politician: any): string => {
    if (politician.politician_offices && politician.politician_offices.length > 0) {
      return politician.politician_offices
        .map((office: any) => office.political_office?.title)
        .filter((title: string | null) => title)
        .join(', ') || 'Politico';
    }
    return 'Politico';
  };

  return (
    <Box sx={{ 
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      height: '100%'
    }}>
      {/* Search Bar - Only show when not viewing details */}
      {!selectedItem && (
        <Box sx={{ 
          width: '100%', 
          backgroundColor: theme.palette.background.default,
          borderBottom: `1px solid ${theme.palette.divider}`,
          position: 'sticky',
          top: 0,
          zIndex: 10,
          padding: '8px 16px'
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            width: '100%',
            maxWidth: '600px',
            margin: '0 auto'
          }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="cerca politico o partito"
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: theme.palette.text.secondary, fontSize: '1.2rem' }} />
                  </InputAdornment>
                ),
                endAdornment: searchQuery ? (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={handleClear} size="small">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
                style: { 
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#f4f4f4',
                  borderRadius: '8px',
                  padding: '2px 6px',
                  height: '36px'
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.23)',
                    borderRadius: '8px',
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: theme.palette.primary.main,
                  },
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.text.primary,
                  '&::placeholder': {
                    color: theme.palette.text.secondary,
                    opacity: 1,
                    fontStyle: 'italic'
                  },
                },
              }}
            />
          </Box>
        </Box>
      )}

      {/* Content Area */}
      <Box sx={{ 
        flex: 1, 
        overflow: 'auto', 
        padding: selectedItem ? '0' : '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: selectedItem ? '100%' : 'auto'
      }}>
        {selectedItem ? (
          <Box sx={{ 
            width: '100vw', 
            height: 'calc(100vh - 56px)',
            display: 'flex', 
            flexDirection: 'column', 
            overflow: 'hidden',
            p: 0,
            m: 0,
            border: 'none', 
            borderRadius: 0,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            backgroundColor: '#2c3e2e'
          }}>
            <Box sx={{ 
              padding: '12px 16px', 
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 10
            }}>
              <Button 
                startIcon={<ArrowBackIcon />} 
                onClick={handleBackToResults}
                size="small"
                sx={{ 
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.08)'
                  }
                }}
              >
                Torna ai risultati
              </Button>
            </Box>
            
            {loadingDetails ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <CircularProgress size={32} />
              </Box>
            ) : selectedItem.type === 'politician' ? (
              <Box sx={{ 
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <PoliticianCard
                  politician={selectedItem.data}
                  loading={false}
                  error={null}
                  role={getPoliticianRole(selectedItem.data)}
                  colorIndex={0}
                  cardColors={['#c2185b', '#388e3c', '#d32f2f', '#7b1fa2', '#1976d2']}
                  onCardClick={() => {}}
                  enableCaricatureGeneration={true}
                  replaceImageOnly={true}
                  resetView={0}
                />
              </Box>
            ) : (
              <Box sx={{ 
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <PartyCard
                  party={selectedItem.data}
                  loading={false}
                  error={null}
                  colorIndex={0}
                  cardColors={['#c2185b', '#388e3c', '#d32f2f', '#7b1fa2', '#1976d2']}
                  onCardClick={() => {}}
                  resetView={0}
                />
              </Box>
            )}
          </Box>
        ) : searchQuery && (
          <Box sx={{ 
            width: '100%', 
            maxWidth: '600px',
            backgroundColor: theme.palette.background.default,
            borderRadius: 1
          }}>
            <ResultsList 
              results={filteredResults} 
              isLoading={isLoading} 
              onItemClick={handleItemClick} 
              searchQuery={searchQuery}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

interface ResultsListProps {
  results: SearchResult[];
  isLoading: boolean;
  onItemClick: (result: SearchResult) => void;
  searchQuery: string;
}

const ResultsList: React.FC<ResultsListProps> = ({ results, isLoading, onItemClick, searchQuery }) => {
  const theme = useTheme();
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress size={32} />
      </Box>
    );
  }

  if (searchQuery && results.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', p: 3 }}>
        <Typography variant="body1" color="text.secondary">
          Nessun risultato trovato per "{searchQuery}"
        </Typography>
      </Box>
    );
  }

  return (
    <List sx={{ 
      width: '100%', 
      bgcolor: theme.palette.background.default,
      borderRadius: 1,
      overflow: 'hidden'
    }}>
      {results.map((result, index) => (
        <React.Fragment key={`${result.type}-${result.id}`}>
          <ListItem 
            alignItems="flex-start" 
            button 
            onClick={() => onItemClick(result)}
            sx={{ 
              borderRadius: 1,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              }
            }}
          >
            <ListItemAvatar>
              {result.imageUrl ? (
                <Avatar 
                  src={result.imageUrl} 
                  alt={result.name}
                  sx={{ 
                    width: 50, 
                    height: 50,
                    mr: 1
                  }}
                />
              ) : (
                <Avatar 
                  sx={{ 
                    width: 50, 
                    height: 50,
                    mr: 1,
                    bgcolor: result.type === 'politician' ? 'primary.main' : 'secondary.main'
                  }}
                >
                  {result.type === 'politician' ? <PersonIcon /> : <GroupsIcon />}
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography 
                  variant="subtitle1" 
                  component="div" 
                  sx={{ 
                    fontWeight: 'medium',
                    color: theme.palette.text.primary
                  }}
                >
                  {result.name}
                </Typography>
              }
              secondary={
                <Typography
                  variant="body2"
                  color="text.secondary"
                  component="div"
                >
                  {result.type === 'politician' 
                    ? (result.position || 'Politico')
                    : 'Partito'}
                </Typography>
              }
            />
          </ListItem>
          {index < results.length - 1 && <Divider variant="inset" component="li" />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default UserSearch;
