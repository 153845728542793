import React from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { italianTranslations as t } from '../../../i18n/it';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';

export const StatisticsIntro: React.FC = () => {
  const navigate = useNavigate();
  const muiTheme = useTheme();

  const handleNavigate = (type: string) => {
    navigate(`/app/user/statistics?view=${type}`);
  };

  return (
    <Box 
      sx={{ 
        width: '100vw', 
        height: 'calc(100vh - 56px)',
        display: 'flex', 
        flexDirection: 'column', 
        overflow: 'hidden',
        p: 0,
        m: 0,
        border: 'none', 
        borderRadius: 0,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0
      }}
    >
      {/* Top half with landing page background */}
      <Box 
        sx={{
          width: '100%',
          height: '40%',
          backgroundImage: {
            xs: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("/BackgroundMobil.jpg")',
            sm: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("/BackgroundDesktop.jpg")'
          },
          backgroundSize: 'cover', 
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: '#ffffff',
            textAlign: 'center',
            textShadow: '0 2px 4px rgba(0,0,0,0.7)',
            px: 2
          }}
        >
          {t.statistics.intro.title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: '#ffffff',
            textAlign: 'center',
            textShadow: '0 2px 4px rgba(0,0,0,0.7)',
            mt: 1,
            px: 2
          }}
        >
          {t.statistics.intro.subtitle}
        </Typography>
      </Box>
      
      {/* Bottom half with category buttons */}
      <Box 
        sx={{
          width: '100%',
          height: '60%',
          bgcolor: muiTheme.palette.background.default,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          p: 3
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            mb: 4,
            color: muiTheme.palette.text.primary
          }}
        >
          {t.statistics.intro.selectCategory}
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={2} sx={{ maxWidth: 600 }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleNavigate('camera')}
                startIcon={<BarChartIcon />}
                sx={{
                  bgcolor: muiTheme.palette.primary.main,
                  color: '#ffffff',
                  py: 2,
                  textTransform: 'none',
                  fontWeight: 'normal',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiButton-startIcon': {
                    margin: 0,
                    fontSize: '2rem',
                    marginBottom: 1
                  },
                  '&:hover': {
                    bgcolor: muiTheme.palette.primary.dark,
                    boxShadow: '0 6px 12px rgba(0,0,0,0.3)'
                  }
                }}
              >
                {t.statistics.intro.camera}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => handleNavigate('senato')}
                startIcon={<PieChartIcon />}
                sx={{
                  bgcolor: '#f44336',
                  color: '#ffffff',
                  py: 2,
                  textTransform: 'none',
                  fontWeight: 'normal',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  '& .MuiButton-startIcon': {
                    margin: 0,
                    fontSize: '2rem',
                    marginBottom: 1
                  },
                  '&:hover': {
                    bgcolor: '#d32f2f',
                    boxShadow: '0 6px 12px rgba(0,0,0,0.3)'
                  }
                }}
              >
                {t.statistics.intro.senato}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
