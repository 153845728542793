import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HeroSection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box 
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: { xs: '2rem 1rem', sm: '2rem' },
        position: 'relative',
        backgroundColor: '#000000',
        backgroundImage: {
          xs: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("/BackgroundMobil.jpg")',
          sm: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("/BackgroundDesktop.jpg")'
        },
        backgroundSize: '100% 100%', 
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      {/* Logo Section */}
      <Box sx={{ 
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        mb: 4,
        mt: { xs: 4, sm: 2 }
      }}>
        <Box
          component="img"
          src="/logo.png"
          alt="Polinder Logo"
          sx={{
            width: { xs: '200px', sm: '240px' },
            height: 'auto',
            objectFit: 'contain'
          }}
        />
      </Box>

      {/* Main Content */}
      <Box sx={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
        maxWidth: '400px',
        mb: 'auto'
      }}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 'normal',
            fontSize: { xs: '2rem', sm: '3rem' },
            mb: 6,
            color: '#ffffff',
            textShadow: '0 2px 4px rgba(0,0,0,0.7)' 
          }}
        >
          io voto tu voti
          <br />
          egli vota
        </Typography>

        {/* Buttons */}
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2, px: 3 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => navigate('/auth?mode=signup')}
            sx={{
              bgcolor: '#FF4458',
              color: '#ffffff',
              py: 2,
              fontSize: '1.25rem',
              textTransform: 'none',
              fontWeight: 'normal',
              borderRadius: '2rem',
              boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
              '&:hover': {
                bgcolor: '#E00007',
                boxShadow: '0 6px 12px rgba(0,0,0,0.4)'
              }
            }}
          >
            Crea account
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => navigate('/auth?mode=signin')}
            sx={{
              borderColor: '#ffffff',
              borderWidth: 2,
              color: '#ffffff',
              py: 2,
              fontSize: '1.25rem',
              textTransform: 'none',
              fontWeight: 'normal',
              borderRadius: '2rem',
              backgroundColor: 'rgba(0, 0, 0, 0.4)', 
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              '&:hover': {
                borderColor: '#ffffff',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderWidth: 2,
                boxShadow: '0 6px 12px rgba(0,0,0,0.3)'
              }
            }}
          >
            Accedi
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HeroSection;
