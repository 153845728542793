import { Politician } from '../types/database.types';
import { politicianService } from './politicianService';
import { supabase, supabaseAdmin } from '../lib/supabaseClient';

class CaricatureService {
    async generatePoliticianCaricature(politician: Politician, userId: string): Promise<string> {
        // Check if caricature is already in progress
        const inProgress = await this.checkCaricatureInProgress(politician.id);
        if (inProgress) {
            throw new Error('Caricatura già in fase di sviluppo');
        }

        try {
            // Set caricature as in progress - but continue even if this fails
            try {
                await this.setCaricatureInProgress(politician.id, userId);
            } catch (error) {
                console.error('Error setting caricature in progress:', error);
            }

            console.log('Starting caricature generation for politician:', politician.first_name, politician.last_name);
            
            // Log della struttura dell'oggetto politician per debug
            console.log('Politician object structure:', JSON.stringify({
                has_politician_offices: !!politician.politician_offices,
                offices_length: politician.politician_offices ? politician.politician_offices.length : 0,
                first_office: politician.politician_offices && politician.politician_offices.length > 0 ? 
                    Object.keys(politician.politician_offices[0]) : []
            }));
            
            // Search for politician portrait using name and political role if available
            const name = `${politician.first_name} ${politician.last_name}`;
            console.log('Searching for portrait with name:', name);
            
            // Verifica se il politico ha un ruolo politico associato
            let role = '';
            
            // Utilizziamo type assertion per gestire diverse strutture di dati
            // Questo è necessario perché l'oggetto politician può avere strutture diverse
            // a seconda del contesto in cui viene utilizzato
            
            // Verifica se l'oggetto ha la proprietà politician_offices
            if (politician.politician_offices && politician.politician_offices.length > 0) {
                const firstOffice = politician.politician_offices[0];
                
                // Verifica se contiene political_office
                if (firstOffice.political_office && firstOffice.political_office.title) {
                    role = firstOffice.political_office.title;
                    console.log('Found political role from political_office:', role);
                }
                // Verifica se contiene office (struttura alternativa)
                else if ('office' in firstOffice && firstOffice.office) {
                    // @ts-ignore - Ignoriamo l'errore TypeScript perché sappiamo che questa proprietà potrebbe esistere
                    role = firstOffice.office.title;
                    console.log('Found political role from office property in politician_offices:', role);
                }
            }
            
            // Se non abbiamo trovato un ruolo, proviamo a caricarlo direttamente dal database
            if (!role) {
                try {
                    console.log('Trying to load political offices from database for politician ID:', politician.id);
                    const { data, error } = await supabase
                        .from('politician_offices')
                        .select(`
                            *,
                            political_office:political_offices(*)
                        `)
                        .eq('politician_id', politician.id);
                    
                    if (error) {
                        console.error('Error loading political offices:', error);
                    } else if (data && data.length > 0 && data[0].political_office) {
                        role = data[0].political_office.title;
                        console.log('Found political role from database query:', role);
                    }
                } catch (err) {
                    console.error('Error fetching political offices:', err);
                }
            }
            
            // Verifica se l'oggetto ha la proprietà office (come in LocalMayor.tsx)
            // @ts-ignore - Ignoriamo l'errore TypeScript perché sappiamo che questa proprietà potrebbe esistere
            if (!role && politician.office && politician.office.title) {
                // @ts-ignore
                role = politician.office.title;
                console.log('Found political role from office property:', role);
            }
            
            // Estrai la città/località dove il politico svolge la sua carica
            let city = '';
            if (politician.politician_offices && politician.politician_offices.length > 0) {
                const firstOffice = politician.politician_offices[0];
                
                // Verifica se contiene assigned_place_id
                if ('assigned_place_id' in firstOffice && firstOffice.assigned_place_id) {
                    // Verifica se l'oggetto ha già il luogo caricato
                    if ('assigned_place' in firstOffice && firstOffice.assigned_place) {
                        const assignedPlace = firstOffice.assigned_place as any;
                        if (assignedPlace && typeof assignedPlace === 'object' && 'name' in assignedPlace) {
                            city = assignedPlace.name;
                            console.log('Found city from assigned_place:', city);
                        }
                    }
                }
                // Verifica se contiene place (struttura alternativa)
                else if ('place' in firstOffice && firstOffice.place) {
                    // Verifica che place sia un oggetto e che abbia la proprietà name
                    const place = firstOffice.place as any;
                    if (place && typeof place === 'object' && 'name' in place) {
                        city = place.name;
                        console.log('Found city from place property:', city);
                    }
                }
            }
            
            // Se non abbiamo trovato una città, proviamo a caricarla direttamente dal database
            if (!city) {
                try {
                    console.log('Trying to load place information from database for politician ID:', politician.id);
                    const { data, error } = await supabase
                        .from('politician_offices')
                        .select(`
                            *,
                            assigned_place:places(*)
                        `)
                        .eq('politician_id', politician.id);
                    
                    if (error) {
                        console.error('Error loading place information:', error);
                    } else if (data && data.length > 0 && data[0].assigned_place) {
                        city = data[0].assigned_place.name;
                        console.log('Found city from database query:', city);
                    }
                } catch (err) {
                    console.error('Error fetching place information:', err);
                }
            }
            
            // Costruisci l'URL della richiesta con il nome, il ruolo (se disponibile) e la città (se disponibile)
            const searchUrl = `/api/search/google/politician-portrait?name=${encodeURIComponent(name)}${role ? `&role=${encodeURIComponent(role)}` : ''}${city ? `&city=${encodeURIComponent(city)}` : ''}`;
            console.log('Search URL:', searchUrl);
            
            const searchResponse = await fetch(searchUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            console.log('Search response status:', searchResponse.status);
            if (!searchResponse.ok) {
                const errorText = await searchResponse.text();
                console.error('Search response error:', errorText);
                throw new Error('Failed to find politician portrait');
            }

            const searchData = await searchResponse.json();
            console.log('Search data:', searchData);
            
            if (!searchData.imageUrl) {
                console.error('No image URL in search data:', searchData);
                throw new Error('No portrait found for politician');
            }

            console.log('Found portrait URL:', searchData.imageUrl);

            // Generate caricature from the portrait
            console.log('Generating caricature from portrait...');
            const caricatureResponse = await fetch('/api/caricature/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ imageUrl: searchData.imageUrl }),
            });

            console.log('Caricature response status:', caricatureResponse.status);
            if (!caricatureResponse.ok) {
                const errorText = await caricatureResponse.text();
                console.error('Caricature response error:', errorText);
                throw new Error('Failed to generate caricature');
            }

            const caricatureData = await caricatureResponse.json();
            console.log('Caricature data:', caricatureData);
            
            if (!caricatureData.success || !caricatureData.caricatureUrl) {
                console.error('Invalid caricature data:', caricatureData);
                throw new Error('Invalid caricature data');
            }

            console.log('Generated caricature URL:', caricatureData.caricatureUrl);

            // Save the caricature URL to the politician
            console.log('Saving caricature URL to politician...');
            const saveResponse = await fetch('/api/storage/save-image', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ imageUrl: caricatureData.caricatureUrl }),
            });

            console.log('Save response status:', saveResponse.status);
            if (!saveResponse.ok) {
                const errorText = await saveResponse.text();
                console.error('Save response error:', errorText);
                throw new Error('Failed to save caricature');
            }

            const saveData = await saveResponse.json();
            console.log('Save data:', saveData);
            
            if (!saveData.success || !saveData.publicUrl) {
                console.error('Invalid save data:', saveData);
                throw new Error('Invalid save data');
            }

            console.log('Saved caricature URL:', saveData.publicUrl);

            // Update the politician with the caricature URL
            const { error: updateError } = await supabaseAdmin
                .from('politicians')
                .update({ 
                    image_url: saveData.publicUrl
                })
                .eq('id', politician.id);

            if (updateError) {
                console.error('Error updating politician with caricature URL:', updateError);
                throw new Error('Failed to update politician with caricature URL');
            }

            console.log('Successfully updated politician with caricature URL');

            // Verifica se il politico ha una descrizione, se non ce l'ha chiama OpenAI per generarla
            if (!politician.description) {
                console.log('Politician has no description, generating one with OpenAI...');
                
                try {
                    // Costruisci le informazioni da passare a OpenAI
                    const politicianName = `${politician.first_name} ${politician.last_name}`;
                    let politicianInfo = politicianName;
                    
                    // Aggiungi il ruolo politico se disponibile
                    if (role) {
                        politicianInfo += ` ${role}`;
                    }
                    
                    // Aggiungi la città se disponibile
                    let city = '';
                    if (politician.politician_offices && politician.politician_offices.length > 0) {
                        const firstOffice = politician.politician_offices[0];
                        
                        // Verifica se contiene assigned_place_id
                        if ('assigned_place_id' in firstOffice && firstOffice.assigned_place_id) {
                            // Dovremmo recuperare il nome del luogo dal database usando l'ID
                            // Ma per semplicità, possiamo verificare se l'oggetto ha già il luogo caricato
                            if ('assigned_place' in firstOffice && firstOffice.assigned_place) {
                                const assignedPlace = firstOffice.assigned_place as any;
                                if (assignedPlace && typeof assignedPlace === 'object' && 'name' in assignedPlace) {
                                    city = assignedPlace.name;
                                }
                            }
                        }
                        // Verifica se contiene place (struttura alternativa)
                        else if ('place' in firstOffice && firstOffice.place) {
                            // Verifica che place sia un oggetto e che abbia la proprietà name
                            const place = firstOffice.place as any;
                            if (place && typeof place === 'object' && 'name' in place) {
                                city = place.name;
                            }
                        }
                    }
                    
                    // Aggiungi la città all'informazione se disponibile
                    if (city) {
                        politicianInfo += ` di ${city}`;
                    }
                    
                    console.log('Calling OpenAI with politician info:', politicianInfo);
                    
                    // Chiama il servizio OpenAI per generare la descrizione
                    const response = await fetch('/api/openai/generate', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ 
                            prompt: `Fornisci una breve biografia (massimo 150 parole) del politico italiano "${politicianInfo}".
Includi le seguenti informazioni se disponibili:
1. Data e luogo di nascita
2. Partito politico attuale
3. Principali ruoli politici ricoperti
4. Posizioni politiche principali
5. Eventuali controversie o successi significativi

Rispondi SOLO con la biografia, senza introduzioni o conclusioni.` 
                        }),
                    });
                    
                    if (!response.ok) {
                        const errorText = await response.text();
                        console.error('OpenAI response error:', errorText);
                        throw new Error('Failed to generate politician description');
                    }
                    
                    const openAiData = await response.json();
                    console.log('OpenAI data:', openAiData);
                    
                    if (!openAiData.response) {
                        console.error('No description in OpenAI data:', openAiData);
                        throw new Error('Invalid OpenAI data');
                    }
                    
                    // Aggiorna il politico con la descrizione generata
                    const { error: updateDescError } = await supabaseAdmin
                        .from('politicians')
                        .update({ 
                            description: openAiData.response
                        })
                        .eq('id', politician.id);
                    
                    if (updateDescError) {
                        console.error('Error updating politician with description:', updateDescError);
                        throw new Error('Failed to update politician with description');
                    }
                    
                    console.log('Successfully updated politician with description');
                } catch (descError) {
                    // Logga l'errore ma non interrompere il flusso principale
                    console.error('Error generating description:', descError);
                    // Non rilanciare l'errore per non interrompere il flusso principale
                }
            }

            // Clear the in-progress flag
            await this.clearCaricatureInProgress(politician.id);

            return saveData.publicUrl;
        } catch (error) {
            // Clear the in-progress flag on error
            await this.clearCaricatureInProgress(politician.id);
            console.error('Error generating caricature:', error);
            throw error;
        }
    }

    private async checkCaricatureInProgress(politicianId: string): Promise<boolean> {
        try {
            const { data, error } = await supabaseAdmin
                .from('caricature_progress')
                .select('*')
                .eq('politician_id', politicianId);
            
            if (error) {
                // If there's a permission error or any other error, log it but don't block the process
                console.error('Error checking caricature progress:', error);
                return false;
            }

            // If we have data and it's not empty, there's a caricature in progress
            return data && data.length > 0;
        } catch (error) {
            console.error('Error checking caricature progress:', error);
            return false;
        }
    }

    private async setCaricatureInProgress(politicianId: string, userId: string): Promise<void> {
        try {
            const { error } = await supabaseAdmin
                .from('caricature_progress')
                .insert({
                    politician_id: politicianId,
                    user_id: userId,
                    started_at: new Date().toISOString()
                });

            if (error) {
                // If there's a permission error, log it but don't block the process
                console.error('Error setting caricature in progress:', error);
                // Don't throw an error here, just log it and continue
            }
        } catch (error) {
            console.error('Error setting caricature in progress:', error);
            // Don't throw an error here, just log it and continue
        }
    }

    private async clearCaricatureInProgress(politicianId: string): Promise<void> {
        try {
            const { error } = await supabaseAdmin
                .from('caricature_progress')
                .delete()
                .eq('politician_id', politicianId);

            if (error) {
                console.error('Error clearing caricature progress:', error);
                // Don't throw an error here, just log it
            }
        } catch (error) {
            console.error('Error clearing caricature progress:', error);
            // Don't throw an error here, just log it
        }
    }
}

export const caricatureService = new CaricatureService();
