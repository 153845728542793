import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import {
  Box,
  Typography,
  Button,
  useTheme as useMuiTheme,
  CircularProgress
} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';
import type { Swiper as SwiperType } from 'swiper';
import { WelcomeTour } from '../../../components/Tour/WelcomeTour';
import { useAuth } from '../../../hooks/useAuth';
import { italianTranslations as t } from '../../../i18n/it';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SwipeRightAltIcon from '@mui/icons-material/SwipeRightAlt';
import SwipeLeftAltIcon from '@mui/icons-material/SwipeLeftAlt';
import SwipeUpAltIcon from '@mui/icons-material/SwipeUpAlt';
import { cardColors } from '../../../styles/cardStyles';
import { supabase } from '../../../lib/supabaseClient';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';

// Custom styles for the swiper
import '../Home/swiper-styles.css';

// Lazy load components
const LazyLocalRegionalPresident = lazy(() => import('../LocalRegionalPresident/LocalRegionalPresident').then(module => ({ default: module.LocalRegionalPresident })));
const LazyLocalProvincialPresident = lazy(() => import('../LocalProvincialPresident/LocalProvincialPresident').then(module => ({ default: module.LocalProvincialPresident })));
const LazyLocalMayor = lazy(() => import('../LocalMayor/LocalMayor').then(module => ({ default: module.LocalMayor })));

// Componente di caricamento
const LoadingComponent = () => {
  return (
    <Box 
      sx={{ 
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        bgcolor: '#1a1a1a', // Usa lo stesso colore di sfondo dello Swiper
        borderRadius: 0,
        boxShadow: 'none',
      }}
    >
      <CircularProgress size={60} color="primary" />
    </Box>
  );
};

export const UserPoliticianRegion: React.FC = () => {
  const muiTheme = useMuiTheme();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showIntro, setShowIntro] = useState(false);
  const swiperRef = useRef<SwiperType | null>(null);
  const { user, session } = useAuth();
  
  // Stato per tenere traccia di quali componenti sono stati caricati
  const [loadedComponents, setLoadedComponents] = useState({
    regionalPresident: true, // Sempre caricato all'inizio
    provincialPresident: false,
    mayor: false
  });

  // Stato per verificare l'esistenza di sindaco e presidente provinciale
  const [mayorExists, setMayorExists] = useState(false);
  const [provincialPresidentExists, setProvincialPresidentExists] = useState(false);

  // Stato per il reset delle card
  const [resetCardView, setResetCardView] = useState<number>(0);

  // Funzione per resettare le viste delle card
  const resetAllCardViews = () => {
    setResetCardView(prev => prev + 1);
  };

  // Funzione per verificare l'esistenza del sindaco
  const checkMayorExists = async () => {
    if (!session?.user?.user_metadata?.resident_place_id) {
      setMayorExists(false);
      return;
    }

    try {
      // Get the Sindaco office ID
      const { data: officeData, error: officeError } = await supabase
        .from('political_offices')
        .select('*')
        .eq('title', 'Sindaco');

      if (officeError || !officeData?.length) {
        setMayorExists(false);
        return;
      }

      // Get the politician assigned as Sindaco for the user's place
      const { data, error: politicianError } = await supabase
        .from('politician_offices')
        .select(`
          id,
          assigned_place_id,
          political_office_id,
          politician_id,
          politician:politicians!inner(
            id
          )
        `)
        .eq('political_office_id', officeData[0].id)
        .eq('assigned_place_id', session.user.user_metadata.resident_place_id);

      if (politicianError || !data || data.length === 0) {
        setMayorExists(false);
        return;
      }

      setMayorExists(true);
    } catch (error) {
      setMayorExists(false);
    }
  };

  // Funzione per verificare l'esistenza del presidente provinciale
  const checkProvincialPresidentExists = async () => {
    if (!session?.user?.user_metadata?.resident_place_id) {
      setProvincialPresidentExists(false);
      return;
    }

    try {
      // First get the user's place to find their state
      const { data: userPlace, error: userPlaceError } = await supabase
        .from('places')
        .select('state')
        .eq('id', session.user.user_metadata.resident_place_id)
        .single();

      if (userPlaceError || !userPlace) {
        setProvincialPresidentExists(false);
        return;
      }

      // Get the Presidente di Provincia office ID
      const { data: officeData, error: officeError } = await supabase
        .from('political_offices')
        .select('*')
        .eq('title', 'Presidente della Provincia');

      if (officeError || !officeData?.length) {
        setProvincialPresidentExists(false);
        return;
      }

      // Get the politician assigned as Presidente di Provincia for the user's state
      const { data, error: politicianError } = await supabase
        .from('politician_offices')
        .select(`
          id,
          assigned_place_id,
          political_office_id,
          politician_id,
          politician:politicians!inner(
            id
          ),
          assigned_place:places!inner(
            id,
            state
          )
        `)
        .eq('political_office_id', officeData[0].id)
        .eq('assigned_place.state', userPlace.state);

      if (politicianError || !data || data.length === 0) {
        setProvincialPresidentExists(false);
        return;
      }

      setProvincialPresidentExists(true);
    } catch (error) {
      setProvincialPresidentExists(false);
    }
  };

  // Prevent scrolling on the body
  useEffect(() => {
    // Save original styles
    const originalStyle = window.getComputedStyle(document.body).overflow;
    
    // Prevent scrolling
    document.body.style.overflow = 'hidden';
    
    // Cleanup function to restore original styles when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  useEffect(() => {
    // Precarica tutti i componenti all'avvio
    setLoadedComponents({
      regionalPresident: true,
      provincialPresident: true,
      mayor: true
    });
  }, []);

  useEffect(() => {
    checkMayorExists();
    checkProvincialPresidentExists();
  }, [session]);

  const handleStartDiscovering = () => {
    setShowIntro(false);
  };

  // Handle touch start and end for swipe detection
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    // If swiped left or right, navigate to politician cards
    if (isLeftSwipe || isRightSwipe) {
      handleStartDiscovering();
    }
  };

  // Mouse-based swipe detection for desktop
  const [mouseStart, setMouseStart] = useState<number | null>(null);

  const onMouseDown = (e: React.MouseEvent) => {
    setMouseStart(e.clientX);
  };

  const onMouseUp = (e: React.MouseEvent) => {
    if (!mouseStart) return;
    
    const distance = mouseStart - e.clientX;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    // If swiped left or right, navigate to politician cards
    if (isLeftSwipe || isRightSwipe) {
      handleStartDiscovering();
    }
    
    setMouseStart(null);
  };

  // Carica i componenti in base all'indice attivo
  useEffect(() => {
    if (!showIntro) {
      // Carica il componente attivo e quelli adiacenti
      const newLoadedComponents = { ...loadedComponents };
      
      if (activeIndex === 0 || activeIndex === 1) {
        newLoadedComponents.provincialPresident = true;
      }
      
      if (activeIndex === 1 || activeIndex === 2) {
        newLoadedComponents.mayor = true;
      }
      
      setLoadedComponents(newLoadedComponents);
    }
  }, [activeIndex, showIntro]);

  return (
    <>
      <WelcomeTour />
      {showIntro ? (
        <Box 
          sx={{ 
            width: '100vw', 
            height: 'calc(100vh - 56px)',
            display: 'flex', 
            flexDirection: 'column', 
            overflow: 'hidden',
            p: 0,
            m: 0,
            border: 'none', 
            borderRadius: 0,
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0
          }}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        >
          {/* Top half with landing page background */}
          <Box 
            sx={{
              width: '100%',
              height: '60%',
              backgroundImage: {
                xs: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("/BackgroundMobil.jpg")',
                sm: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("/BackgroundDesktop.jpg")'
              },
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <Box
              component="img"
              src="/logo.png"
              alt="Polinder Logo"
              sx={{
                width: { xs: '180px', sm: '220px' },
                height: 'auto',
                objectFit: 'contain',
                mb: 2
              }}
            />
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                color: '#ffffff',
                textAlign: 'center',
                textShadow: '0 2px 4px rgba(0,0,0,0.7)',
                px: 2
              }}
            >
              Politici Locali
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: '#ffffff',
                textAlign: 'center',
                textShadow: '0 2px 4px rgba(0,0,0,0.7)',
                mt: 1,
                px: 2
              }}
            >
              Scopri i politici della tua regione
            </Typography>
          </Box>
          
          {/* Bottom half with swipe instructions */}
          <Box 
            sx={{
              width: '100%',
              height: '40%',
              bgcolor: muiTheme.palette.background.default,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              p: 3,
              pt: 4
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  mb: 1.5,
                  color: muiTheme.palette.text.primary
                }}
              >
                {t.intro.swipeInstructions}
              </Typography>
              
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', mb: 1 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center'
                  }}>
                    <SwipeLeftAltIcon sx={{ fontSize: 40, color: '#f44336' }} />
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center'
                  }}>
                    <SwipeRightAltIcon sx={{ fontSize: 40, color: '#4caf50' }} />
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center'
                  }}>
                    <SwipeUpAltIcon sx={{ fontSize: 40, color: '#2196f3' }} />
                  </Box>
                </Box>
                
                <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', mt: 0.5 }}>
                  <Typography sx={{ textAlign: 'center', color: muiTheme.palette.text.secondary, fontSize: '0.9rem', flex: 2 }}>
                    Scorri sinistra / destra
                  </Typography>
                  
                  <Typography sx={{ textAlign: 'center', color: muiTheme.palette.text.secondary, fontSize: '0.9rem', flex: 1 }}>
                    Scorri su per le news
                  </Typography>
                </Box>
              </Box>
            
              <Button
                variant="contained"
                onClick={handleStartDiscovering}
                endIcon={<ArrowForwardIcon />}
                sx={{
                  bgcolor: '#FF4458',
                  color: '#ffffff',
                  py: 1.5,
                  px: 4,
                  fontSize: '1.1rem',
                  textTransform: 'none',
                  fontWeight: 'normal',
                  borderRadius: '2rem',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                  mt: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mx: 'auto',
                  width: '90%',
                  maxWidth: '350px',
                  whiteSpace: 'nowrap',
                  '& .MuiButton-endIcon': {
                    marginLeft: 2,
                    display: 'inline-flex'
                  },
                  '&:hover': {
                    bgcolor: '#E00007',
                    boxShadow: '0 6px 12px rgba(0,0,0,0.4)'
                  }
                }}
              >
                {t.intro.startButton}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ 
          width: '100vw', 
          height: 'calc(100vh - 56px)',
          display: 'flex', 
          flexDirection: 'column', 
          bgcolor: muiTheme.palette.background.default, 
          overflow: 'hidden',
          p: 0,
          m: 0,
          border: 'none', 
          borderRadius: 0,
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0
        }}>
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1.5,
              slideShadows: false,
            }}
            pagination={false}
            modules={[EffectCoverflow]}
            className="politician-swiper"
            style={{ 
              width: '100vw',
              height: '100%',
              margin: 0,
              padding: 0,
              left: 0,
              right: 0,
              backgroundColor: muiTheme.palette.background.default
            }}
            onSlideChange={(swiper: SwiperType) => {
              setActiveIndex(swiper.activeIndex);
              // Resetta le viste delle card quando cambia lo slide
              resetAllCardViews();
            }}
            onSwiper={(swiper: SwiperType) => {
              swiperRef.current = swiper;
            }}
            initialSlide={0} // Set initial slide to LocalRegionalPresident (index 0)
          >
            <SwiperSlide className="swiper-slide">
              <Suspense fallback={<LoadingComponent />}>
                <LazyLocalRegionalPresident resetView={resetCardView} />
              </Suspense>
            </SwiperSlide>
            {loadedComponents.provincialPresident && provincialPresidentExists && (
              <SwiperSlide className="swiper-slide">
                <Suspense fallback={<LoadingComponent />}>
                  <LazyLocalProvincialPresident resetView={resetCardView} />
                </Suspense>
              </SwiperSlide>
            )}
            {loadedComponents.mayor && mayorExists && (
              <SwiperSlide className="swiper-slide">
                <Suspense fallback={<LoadingComponent />}>
                  <LazyLocalMayor resetView={resetCardView} />
                </Suspense>
              </SwiperSlide>
            )}
          </Swiper>
        </Box>
      )}
    </>
  );
};
