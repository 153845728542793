// Lista delle nazioni in italiano con codici ISO3
export interface Country {
  name: string;  // Nome in italiano
  iso3: string;  // Codice ISO3
}

export const countries: Country[] = [
  { name: "Afghanistan", iso3: "AFG" },
  { name: "Albania", iso3: "ALB" },
  { name: "Algeria", iso3: "DZA" },
  { name: "Andorra", iso3: "AND" },
  { name: "Angola", iso3: "AGO" },
  { name: "Antigua e Barbuda", iso3: "ATG" },
  { name: "Argentina", iso3: "ARG" },
  { name: "Armenia", iso3: "ARM" },
  { name: "Australia", iso3: "AUS" },
  { name: "Austria", iso3: "AUT" },
  { name: "Azerbaigian", iso3: "AZE" },
  { name: "Bahamas", iso3: "BHS" },
  { name: "Bahrein", iso3: "BHR" },
  { name: "Bangladesh", iso3: "BGD" },
  { name: "Barbados", iso3: "BRB" },
  { name: "Bielorussia", iso3: "BLR" },
  { name: "Belgio", iso3: "BEL" },
  { name: "Belize", iso3: "BLZ" },
  { name: "Benin", iso3: "BEN" },
  { name: "Bhutan", iso3: "BTN" },
  { name: "Bolivia", iso3: "BOL" },
  { name: "Bosnia ed Erzegovina", iso3: "BIH" },
  { name: "Botswana", iso3: "BWA" },
  { name: "Brasile", iso3: "BRA" },
  { name: "Brunei", iso3: "BRN" },
  { name: "Bulgaria", iso3: "BGR" },
  { name: "Burkina Faso", iso3: "BFA" },
  { name: "Burundi", iso3: "BDI" },
  { name: "Cambogia", iso3: "KHM" },
  { name: "Camerun", iso3: "CMR" },
  { name: "Canada", iso3: "CAN" },
  { name: "Capo Verde", iso3: "CPV" },
  { name: "Repubblica Centrafricana", iso3: "CAF" },
  { name: "Ciad", iso3: "TCD" },
  { name: "Cile", iso3: "CHL" },
  { name: "Cina", iso3: "CHN" },
  { name: "Colombia", iso3: "COL" },
  { name: "Comore", iso3: "COM" },
  { name: "Congo", iso3: "COG" },
  { name: "Costa Rica", iso3: "CRI" },
  { name: "Costa d'Avorio", iso3: "CIV" },
  { name: "Croazia", iso3: "HRV" },
  { name: "Cuba", iso3: "CUB" },
  { name: "Cipro", iso3: "CYP" },
  { name: "Repubblica Ceca", iso3: "CZE" },
  { name: "Danimarca", iso3: "DNK" },
  { name: "Gibuti", iso3: "DJI" },
  { name: "Dominica", iso3: "DMA" },
  { name: "Repubblica Dominicana", iso3: "DOM" },
  { name: "Ecuador", iso3: "ECU" },
  { name: "Egitto", iso3: "EGY" },
  { name: "El Salvador", iso3: "SLV" },
  { name: "Guinea Equatoriale", iso3: "GNQ" },
  { name: "Eritrea", iso3: "ERI" },
  { name: "Estonia", iso3: "EST" },
  { name: "Etiopia", iso3: "ETH" },
  { name: "Figi", iso3: "FJI" },
  { name: "Finlandia", iso3: "FIN" },
  { name: "Francia", iso3: "FRA" },
  { name: "Gabon", iso3: "GAB" },
  { name: "Gambia", iso3: "GMB" },
  { name: "Georgia", iso3: "GEO" },
  { name: "Germania", iso3: "DEU" },
  { name: "Ghana", iso3: "GHA" },
  { name: "Grecia", iso3: "GRC" },
  { name: "Grenada", iso3: "GRD" },
  { name: "Guatemala", iso3: "GTM" },
  { name: "Guinea", iso3: "GIN" },
  { name: "Guinea-Bissau", iso3: "GNB" },
  { name: "Guyana", iso3: "GUY" },
  { name: "Haiti", iso3: "HTI" },
  { name: "Honduras", iso3: "HND" },
  { name: "Ungheria", iso3: "HUN" },
  { name: "Islanda", iso3: "ISL" },
  { name: "India", iso3: "IND" },
  { name: "Indonesia", iso3: "IDN" },
  { name: "Iran", iso3: "IRN" },
  { name: "Iraq", iso3: "IRQ" },
  { name: "Irlanda", iso3: "IRL" },
  { name: "Israele", iso3: "ISR" },
  { name: "Italia", iso3: "ITA" },
  { name: "Giamaica", iso3: "JAM" },
  { name: "Giappone", iso3: "JPN" },
  { name: "Giordania", iso3: "JOR" },
  { name: "Kazakistan", iso3: "KAZ" },
  { name: "Kenya", iso3: "KEN" },
  { name: "Kiribati", iso3: "KIR" },
  { name: "Corea del Nord", iso3: "PRK" },
  { name: "Corea del Sud", iso3: "KOR" },
  { name: "Kuwait", iso3: "KWT" },
  { name: "Kirghizistan", iso3: "KGZ" },
  { name: "Laos", iso3: "LAO" },
  { name: "Lettonia", iso3: "LVA" },
  { name: "Libano", iso3: "LBN" },
  { name: "Lesotho", iso3: "LSO" },
  { name: "Liberia", iso3: "LBR" },
  { name: "Libia", iso3: "LBY" },
  { name: "Liechtenstein", iso3: "LIE" },
  { name: "Lituania", iso3: "LTU" },
  { name: "Lussemburgo", iso3: "LUX" },
  { name: "Macedonia del Nord", iso3: "MKD" },
  { name: "Madagascar", iso3: "MDG" },
  { name: "Malawi", iso3: "MWI" },
  { name: "Malesia", iso3: "MYS" },
  { name: "Maldive", iso3: "MDV" },
  { name: "Mali", iso3: "MLI" },
  { name: "Malta", iso3: "MLT" },
  { name: "Isole Marshall", iso3: "MHL" },
  { name: "Mauritania", iso3: "MRT" },
  { name: "Mauritius", iso3: "MUS" },
  { name: "Messico", iso3: "MEX" },
  { name: "Micronesia", iso3: "FSM" },
  { name: "Moldavia", iso3: "MDA" },
  { name: "Monaco", iso3: "MCO" },
  { name: "Mongolia", iso3: "MNG" },
  { name: "Montenegro", iso3: "MNE" },
  { name: "Marocco", iso3: "MAR" },
  { name: "Mozambico", iso3: "MOZ" },
  { name: "Myanmar", iso3: "MMR" },
  { name: "Namibia", iso3: "NAM" },
  { name: "Nauru", iso3: "NRU" },
  { name: "Nepal", iso3: "NPL" },
  { name: "Paesi Bassi", iso3: "NLD" },
  { name: "Nuova Zelanda", iso3: "NZL" },
  { name: "Nicaragua", iso3: "NIC" },
  { name: "Niger", iso3: "NER" },
  { name: "Nigeria", iso3: "NGA" },
  { name: "Norvegia", iso3: "NOR" },
  { name: "Oman", iso3: "OMN" },
  { name: "Pakistan", iso3: "PAK" },
  { name: "Palau", iso3: "PLW" },
  { name: "Palestina", iso3: "PSE" },
  { name: "Panama", iso3: "PAN" },
  { name: "Papua Nuova Guinea", iso3: "PNG" },
  { name: "Paraguay", iso3: "PRY" },
  { name: "Perù", iso3: "PER" },
  { name: "Filippine", iso3: "PHL" },
  { name: "Polonia", iso3: "POL" },
  { name: "Portogallo", iso3: "PRT" },
  { name: "Qatar", iso3: "QAT" },
  { name: "Romania", iso3: "ROU" },
  { name: "Russia", iso3: "RUS" },
  { name: "Ruanda", iso3: "RWA" },
  { name: "Saint Kitts e Nevis", iso3: "KNA" },
  { name: "Santa Lucia", iso3: "LCA" },
  { name: "Saint Vincent e Grenadine", iso3: "VCT" },
  { name: "Samoa", iso3: "WSM" },
  { name: "San Marino", iso3: "SMR" },
  { name: "São Tomé e Príncipe", iso3: "STP" },
  { name: "Arabia Saudita", iso3: "SAU" },
  { name: "Senegal", iso3: "SEN" },
  { name: "Serbia", iso3: "SRB" },
  { name: "Seychelles", iso3: "SYC" },
  { name: "Sierra Leone", iso3: "SLE" },
  { name: "Singapore", iso3: "SGP" },
  { name: "Slovacchia", iso3: "SVK" },
  { name: "Slovenia", iso3: "SVN" },
  { name: "Isole Salomone", iso3: "SLB" },
  { name: "Somalia", iso3: "SOM" },
  { name: "Sudafrica", iso3: "ZAF" },
  { name: "Sud Sudan", iso3: "SSD" },
  { name: "Spagna", iso3: "ESP" },
  { name: "Sri Lanka", iso3: "LKA" },
  { name: "Sudan", iso3: "SDN" },
  { name: "Suriname", iso3: "SUR" },
  { name: "Svezia", iso3: "SWE" },
  { name: "Svizzera", iso3: "CHE" },
  { name: "Siria", iso3: "SYR" },
  { name: "Taiwan", iso3: "TWN" },
  { name: "Tagikistan", iso3: "TJK" },
  { name: "Tanzania", iso3: "TZA" },
  { name: "Thailandia", iso3: "THA" },
  { name: "Timor Est", iso3: "TLS" },
  { name: "Togo", iso3: "TGO" },
  { name: "Tonga", iso3: "TON" },
  { name: "Trinidad e Tobago", iso3: "TTO" },
  { name: "Tunisia", iso3: "TUN" },
  { name: "Turchia", iso3: "TUR" },
  { name: "Turkmenistan", iso3: "TKM" },
  { name: "Tuvalu", iso3: "TUV" },
  { name: "Uganda", iso3: "UGA" },
  { name: "Ucraina", iso3: "UKR" },
  { name: "Emirati Arabi Uniti", iso3: "ARE" },
  { name: "Regno Unito", iso3: "GBR" },
  { name: "Stati Uniti", iso3: "USA" },
  { name: "Uruguay", iso3: "URY" },
  { name: "Uzbekistan", iso3: "UZB" },
  { name: "Vanuatu", iso3: "VUT" },
  { name: "Città del Vaticano", iso3: "VAT" },
  { name: "Venezuela", iso3: "VEN" },
  { name: "Vietnam", iso3: "VNM" },
  { name: "Yemen", iso3: "YEM" },
  { name: "Zambia", iso3: "ZMB" },
  { name: "Zimbabwe", iso3: "ZWE" }
];
