import React, { useState } from 'react';
import { Box } from '@mui/material';
import HeroSection from './HeroSection';
import AppShowcase from './AppShowcase';
import FeatureDescription from './FeatureDescription';
import Footer from './Footer';
import PrivacyDialog from './PrivacyDialog';

const LandingPage: React.FC = () => {
  const [openPrivacy, setOpenPrivacy] = useState(false);

  const handleOpenPrivacy = () => {
    setOpenPrivacy(true);
  };

  return (
    <Box
      className="landing-page"
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#000000',
        color: '#ffffff',
        overflowY: 'auto',
        height: 'auto',
        minHeight: '100vh'
      }}
    >
      {/* Hero Section */}
      <HeroSection />
      
      {/* App Screenshots Showcase */}
      <AppShowcase />
      
      {/* Feature Description */}
      <FeatureDescription />
      
      {/* Footer */}
      <Footer onPrivacyClick={handleOpenPrivacy} />
      
      {/* Privacy Policy Dialog */}
      <PrivacyDialog 
        open={openPrivacy} 
        onClose={() => setOpenPrivacy(false)} 
      />
    </Box>
  );
};

export default LandingPage;
