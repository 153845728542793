import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from './contexts/ThemeContext';
import { router } from './router/router';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import { lightTheme } from './theme/theme';
import InstallBanner from './components/PWA/InstallBanner';

function App() {
  return (
    <ThemeProvider>
      <MuiThemeProvider theme={lightTheme}>
        <CssBaseline />
        <AuthProvider>
          <RouterProvider router={router} />
          <InstallBanner />
          <SpeedInsights />
          <Analytics />
        </AuthProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
}

export default App;
