import React, { useState, useEffect } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useAuth } from '../../hooks/useAuth';
import { italianTranslations as t } from '../../i18n/it';
import './tour-styles.css'; // Importa il foglio di stile personalizzato

interface WelcomeTourProps {
  onComplete?: () => void;
}

export const WelcomeTour: React.FC<WelcomeTourProps> = ({ onComplete }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);

  useEffect(() => {
    // Verifica se l'utente ha già completato il tour
    const checkTourStatus = async () => {
      if (!user) return;

      const metadata = user.user_metadata || {};
      const tourCompleted = metadata.tour_completed;

      if (tourCompleted) {
        return;
      }

      // Imposta i passi del tour in base alla pagina corrente
      if (location.pathname === '/app/user') {
        setSteps([
          {
            target: 'body',
            content: `${t.tour.welcome} ${t.tour.welcomeDescription}`,
            placement: 'center',
            disableBeacon: true,
          },
          {
            target: '.politician-swiper',
            content: t.tour.swipeHorizontal,
            placement: 'bottom',
          },
          {
            target: '.swiper-slide',
            content: t.tour.swipeUp,
            placement: 'bottom',
          },
          {
            target: '.MuiBottomNavigation-root',
            content: t.tour.navbar,
            placement: 'top',
          }
        ]);
        setRun(true);
      }
    };

    checkTourStatus();
  }, [user, location.pathname]);

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { status } = data;

    // Quando il tour è completato o saltato
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setRun(false);
      
      // Aggiorna i metadati dell'utente per indicare che il tour è stato completato
      if (user) {
        try {
          await supabase.auth.updateUser({
            data: {
              tour_completed: true
            }
          });
          
          if (onComplete) {
            onComplete();
          }
        } catch (error) {
          console.error('Errore durante l\'aggiornamento dei metadati:', error);
        }
      }
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      hideCloseButton
      run={run}
      scrollToFirstStep
      showProgress
      showSkipButton
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: '#ad0e38',
          textColor: '#333',
          backgroundColor: '#fff',
          arrowColor: '#fff',
          overlayColor: 'rgba(0, 0, 0, 0.7)',
        },
        buttonNext: {
          backgroundColor: '#ad0e38',
          color: '#fff',
          fontSize: '16px',
          borderRadius: '24px',
          padding: '8px 16px',
        },
        buttonBack: {
          color: '#ad0e38',
          fontSize: '16px',
          marginRight: 10,
        },
        buttonSkip: {
          color: '#666',
          fontSize: '16px',
        },
        tooltip: {
          borderRadius: '8px',
          fontSize: '16px',
          padding: '16px',
        },
      }}
      locale={{
        back: t.tour.back,
        close: t.tour.close,
        last: t.tour.last,
        next: t.tour.next,
        skip: t.tour.skip,
      }}
    />
  );
};

export default WelcomeTour;
