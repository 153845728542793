import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Tooltip,
  CircularProgress,
  Alert,
  TextField,
  InputAdornment,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { 
  Add as AddIcon, 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Search as SearchIcon,
  Language as WebsiteIcon,
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  CloudDownload as ImportIcon,
} from '@mui/icons-material';
import { Party } from '../../../types/database.types';
import { partyService } from '../../../services/partyService';
import { userService } from '../../../services/userService';
import { useAuth } from '../../../hooks/useAuth';
import { openaiService } from '../../../services/openaiService';
import { AdminPartyDialog } from './AdminPartyDialog';

export const AdminPartyList: React.FC<{ onPartyClick?: (partyId: string) => void }> = ({ onPartyClick }) => {
  const [parties, setParties] = useState<Party[]>([]);
  const [selectedParty, setSelectedParty] = useState<Party | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [creators, setCreators] = useState<{ [key: string]: string }>({});
  const [searchQuery, setSearchQuery] = useState('');
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [importText, setImportText] = useState('');
  const [importing, setImporting] = useState(false);
  const { isAdmin } = useAuth();

  const fetchParties = async () => {
    setLoading(true);
    try {
      const data = await partyService.getParties();
      setParties(data);
      
      // Load creator information for each party
      const creatorInfo: { [key: string]: string } = {};
      for (const party of data) {
        if (party.created_by) {
          creatorInfo[party.created_by] = await userService.getUserEmail(party.created_by);
        }
      }
      setCreators(creatorInfo);
    } catch (error) {
      console.error('Error loading parties:', error);
      setError('Failed to load parties');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParties();
  }, []);

  const handleSave = async (party: Party) => {
    try {
      if (party.id) {
        const { id, created_at, updated_at, created_by, ...updates } = party;
        await partyService.updateParty(id, updates);
      } else {
        await partyService.createParty(party);
      }
      fetchParties();
      return true;
    } catch (error) {
      console.error('Error saving party:', error);
      setError('Failed to save party');
      return false;
    }
  };

  const handleDelete = async (party: Party) => {
    if (!window.confirm('Are you sure you want to delete this party?')) {
      return;
    }

    try {
      await partyService.deleteParty(party.id);
      fetchParties();
    } catch (error) {
      console.error('Error deleting party:', error);
      setError('Failed to delete party');
    }
  };

  const handleImportParties = async () => {
    setImporting(true);
    setError(null);
    try {
      const partyNames = importText.split(',').map(name => name.trim()).filter(name => name);
      console.log('Importing parties:', partyNames);
      
      for (const name of partyNames) {
        console.log('Fetching information for party:', name);
        const partyInfo = await openaiService.searchPartyInformation(name);
        console.log('Received party info from OpenAI:', partyInfo);
        
        try {
          // Get headquarters ID if available
          const headquartersId = await partyService.getHeadquartersId(name);
          console.log('Got headquarters ID:', headquartersId);

          // Create party data object
          const partyData = {
            name: partyInfo.name || name,
            description: partyInfo.description || '',
            symbol: partyInfo.symbol || '',
            founded_date: partyInfo.founded_date || '',
            logo_url: partyInfo.logo_url || '',
            website_url: partyInfo.website_url || '',
            twitter_url: partyInfo.twitter_url || '',
            facebook_url: partyInfo.facebook_url || '',
            instagram_url: partyInfo.instagram_url || '',
            linkedin_url: partyInfo.linkedin_url || ''
          };

          // Only add headquarters_id if it exists
          if (headquartersId) {
            console.log('Adding headquarters ID to party:', headquartersId);
            Object.assign(partyData, { headquarters_id: headquartersId });
          }

          // Create party
          await partyService.createParty(partyData);
        } catch (createError) {
          console.error(`Error creating party ${name}:`, createError);
          throw new Error(`Failed to create party ${name}: ${createError instanceof Error ? createError.message : 'Unknown error'}`);
        }
      }
      
      await fetchParties();
      setImportDialogOpen(false);
      setImportText('');
    } catch (error) {
      console.error('Error importing parties:', error);
      setError(error instanceof Error ? error.message : 'Failed to import parties. Please try again.');
    } finally {
      setImporting(false);
    }
  };

  const filteredParties = parties.filter(party => {
    const searchLower = searchQuery.toLowerCase();
    return (
      party.name?.toLowerCase().includes(searchLower) ||
      party.symbol?.toLowerCase().includes(searchLower) ||
      party.description?.toLowerCase().includes(searchLower)
    );
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h1">
          Political Parties
        </Typography>
        <Box>
          {isAdmin && (
            <>
              <Button
                variant="outlined"
                startIcon={<ImportIcon />}
                onClick={() => setImportDialogOpen(true)}
                sx={{ mr: 2 }}
              >
                Import Parties
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setSelectedParty(null);
                  setDialogOpen(true);
                }}
              >
                Add Party
              </Button>
            </>
          )}
        </Box>
      </Box>

      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search parties..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mb: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {filteredParties.map((party) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={party.id}>
              <Card 
                sx={{ 
                  height: '100%', 
                  display: 'flex', 
                  flexDirection: 'column',
                  cursor: onPartyClick ? 'pointer' : 'default',
                  '&:hover': onPartyClick ? {
                    transform: 'translateY(-4px)',
                    boxShadow: 3,
                    transition: 'all 0.2s ease-in-out'
                  } : {}
                }}
                onClick={() => onPartyClick && onPartyClick(party.id)}
              >
                {party.logo_url && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={party.logo_url}
                    alt={`${party.name} logo`}
                    sx={{ objectFit: 'contain', bgcolor: 'white' }}
                  />
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="div" gutterBottom>
                    {party.name}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      mb: 1
                    }}
                  >
                    {party.description || 'No description available'}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                    {party.political_orientation && (
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          display: 'inline-block',
                          bgcolor: 'success.light',
                          color: 'white',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                          fontSize: '0.75rem'
                        }}
                      >
                        {party.political_orientation}
                      </Typography>
                    )}
                    {party.government_position && (
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          display: 'inline-block',
                          bgcolor: party.government_position === 'Opposizione' ? 'error.light' : 'info.light',
                          color: 'white',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                          fontSize: '0.75rem'
                        }}
                      >
                        {party.government_position}
                      </Typography>
                    )}
                  </Box>
                </CardContent>
                {isAdmin && (
                  <CardActions>
                    <IconButton 
                      size="small" 
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent card click
                        setSelectedParty(party);
                        setDialogOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton 
                      size="small" 
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent card click
                        handleDelete(party);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    {party.website_url && (
                      <IconButton 
                        size="small" 
                        component="a" 
                        href={party.website_url} 
                        target="_blank"
                        onClick={(e) => e.stopPropagation()} // Prevent card click
                      >
                        <WebsiteIcon />
                      </IconButton>
                    )}
                    {party.twitter_url && (
                      <IconButton 
                        size="small" 
                        component="a" 
                        href={party.twitter_url} 
                        target="_blank"
                        onClick={(e) => e.stopPropagation()} // Prevent card click
                      >
                        <TwitterIcon />
                      </IconButton>
                    )}
                    {party.facebook_url && (
                      <IconButton 
                        size="small" 
                        component="a" 
                        href={party.facebook_url} 
                        target="_blank"
                        onClick={(e) => e.stopPropagation()} // Prevent card click
                      >
                        <FacebookIcon />
                      </IconButton>
                    )}
                    {party.instagram_url && (
                      <IconButton 
                        size="small" 
                        component="a" 
                        href={party.instagram_url} 
                        target="_blank"
                        onClick={(e) => e.stopPropagation()} // Prevent card click
                      >
                        <InstagramIcon />
                      </IconButton>
                    )}
                    {party.linkedin_url && (
                      <IconButton 
                        size="small" 
                        component="a" 
                        href={party.linkedin_url} 
                        target="_blank"
                        onClick={(e) => e.stopPropagation()} // Prevent card click
                      >
                        <LinkedInIcon />
                      </IconButton>
                    )}
                  </CardActions>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {/* Import Dialog */}
      <Dialog open={importDialogOpen} onClose={() => setImportDialogOpen(false)}>
        <DialogTitle>Import Parties</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Enter party names separated by commas. The system will search for their information automatically.
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={importText}
            onChange={(e) => setImportText(e.target.value)}
            placeholder="Example: Democratic Party, Republican Party, Green Party"
            disabled={importing}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImportDialogOpen(false)} disabled={importing}>
            Cancel
          </Button>
          <Button
            onClick={handleImportParties}
            variant="contained"
            disabled={!importText.trim() || importing}
            startIcon={importing ? <CircularProgress size={20} /> : null}
          >
            {importing ? 'Importing...' : 'Import'}
          </Button>
        </DialogActions>
      </Dialog>

      <AdminPartyDialog
        open={dialogOpen}
        party={selectedParty}
        onClose={() => {
          setDialogOpen(false);
          setSelectedParty(null);
        }}
        onSave={handleSave}
      />
    </Box>
  );
};