import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Avatar,
  CircularProgress,
  Grid,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LineChart } from '@mui/x-charts/LineChart';
import { supabase } from '../../../lib/supabaseClient';
import { Party } from '../../../types/database.types';

interface PartyCardStatisticProps {
  party: Party | null;
  onClose: () => void;
}

interface CompetencyData {
  timestamp: string;
  [key: string]: string | number | undefined;
}

interface SkillVoteResponse {
  created_at: string;
  rating: number;
  skills: {
    property: string;
  };
}

const SkillChart: React.FC<{
  title: string;
  data: { timestamp: string; value: number | null }[];
  color: string;
}> = ({ title, data, color }) => {
  const theme = useTheme();
  
  return (
    <Paper 
      elevation={0}
      sx={{ 
        p: { xs: 1, sm: 2 }, 
        height: '100%', 
        bgcolor: 'transparent',
        boxShadow: 'none'
      }}
    >
      <Typography variant="h6" gutterBottom align="center" sx={{ color: theme.palette.text.primary }}>
        {title}
      </Typography>
      <Box sx={{ 
        width: '100%', 
        height: 250,
        ml: { xs: -1, sm: 0 },
        mr: { xs: -1, sm: 0 }  
      }}>
        <LineChart
          xAxis={[{
            data: data.map(d => d.timestamp),
            scaleType: 'point',
            valueFormatter: (timestamp: string) => {
              return '';  
            },
            tickLabelStyle: {
              display: 'none'  
            },
            tickSize: 0
          }]}
          yAxis={[{
            min: 1,
            max: 5,
            tickNumber: 5,
            tickLabelStyle: {
              fontSize: 12,
              textAnchor: 'end',
              fill: theme.palette.text.secondary
            }
          }]}
          series={[{
            data: data.map(d => d.value),
            color: color,
            showMark: true,
            curve: "linear"
          }]}
          height={220}
          margin={{ 
            left: 35,  
            right: 10, 
            top: 20, 
            bottom: 60 
          }}
          sx={{
            '.MuiLineElement-root': {
              strokeWidth: 2,
            },
            '.MuiChartsAxis-tickLabel': {
              '&.MuiChartsAxis-tickLabel--x': {
                display: 'none'  
              },
              '&.MuiChartsAxis-tickLabel--y': {
                textAlign: 'right',
                fontWeight: 500,
                fill: theme.palette.text.secondary
              }
            },
            '.MuiChartsAxis-line': {
              stroke: theme.palette.divider,
              strokeWidth: 1
            },
            '.MuiChartsAxis-tick': {
              stroke: theme.palette.divider,
              strokeWidth: 1
            }
          }}
        />
      </Box>
    </Paper>
  );
};

export const PartyCardStatistic: React.FC<PartyCardStatisticProps> = ({
  party,
  onClose
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<CompetencyData[]>([]);
  const [competencyProperties, setCompetencyProperties] = useState<string[]>([]);

  useEffect(() => {
    if (!party) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        // Fetch last 30 days of competency data for all politicians in this party
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        
        // Prima otteniamo tutti i politici che appartengono a questo partito
        const { data: partyPoliticians, error: politiciansError } = await supabase
          .from('politicians')
          .select('id')
          .eq('party_id', party.id);
          
        if (politiciansError) throw politiciansError;
        
        if (!partyPoliticians || partyPoliticians.length === 0) {
          setLoading(false);
          setError('Nessun politico trovato per questo partito');
          return;
        }
        
        const politicianIds = partyPoliticians.map(p => p.id);
        
        // Ora otteniamo tutti i voti per le competenze di questi politici
        const { data: competencyData, error: competencyError } = await supabase
          .from('politician_skill_votes')
          .select(`
            created_at,
            rating,
            skills!inner (
              property
            )
          `)
          .in('politician_id', politicianIds)
          .gte('created_at', thirtyDaysAgo.toISOString())
          .order('created_at', { ascending: true });

        if (competencyError) throw competencyError;

        // Process the data for the chart
        const votes = competencyData as unknown as SkillVoteResponse[];
        
        if (!votes || votes.length === 0) {
          setLoading(false);
          setError('Nessun dato disponibile per i politici di questo partito');
          return;
        }
        
        const dailyData = votes.reduce((acc: { [key: string]: { [key: string]: number[] } }, vote) => {
          // Get just the date part of the timestamp
          const date = new Date(vote.created_at).toISOString().split('T')[0];
          const property = vote.skills.property;
          
          if (!acc[date]) {
            acc[date] = {};
          }
          if (!acc[date][property]) {
            acc[date][property] = [];
          }
          acc[date][property].push(vote.rating);
          return acc;
        }, {});

        // Get unique competency properties
        const properties = Array.from(new Set(votes.map(vote => vote.skills.property)));
        setCompetencyProperties(properties);

        // Calculate daily averages
        const processedData = Object.entries(dailyData).map(([date, ratings]) => {
          const dayData: CompetencyData = { timestamp: date };
          properties.forEach(property => {
            if (ratings[property]) {
              const avg = ratings[property].reduce((sum, val) => sum + val, 0) / ratings[property].length;
              dayData[property] = Number(avg.toFixed(1));
            } else {
              dayData[property] = undefined;
            }
          });
          return dayData;
        });

        // Sort by date
        processedData.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
        setData(processedData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err instanceof Error ? err.message : 'Si è verificato un errore durante il recupero dei dati');
        setLoading(false);
      }
    };

    fetchData();
  }, [party]);

  if (!party) {
    return null;
  }

  // Chart colors
  const colors = ['#2196f3', '#4caf50', '#f44336', '#ff9800', '#9c27b0', '#795548'];

  return (
    <Paper
      elevation={0}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: theme.palette.background.default,
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        borderRadius: 0
      }}
    >
      {/* Header with logo and close button */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={party?.logo_url || '/party-placeholder.png'}
            alt={party?.name}
            sx={{ width: 50, height: 50, mr: 2 }}
          />
          <Box>
            <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
              {party?.name}
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              {party?.symbol || ''}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={onClose} sx={{ color: theme.palette.text.primary }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Statistics Content */}
      <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Preferenze
        </Typography>
        <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary, mb: 2 }}>
          Ultimi 30 giorni (Media di tutti i politici del partito)
        </Typography>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress sx={{ color: theme.palette.primary.main }} />
          </Box>
        ) : error ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <Typography color="error">{error}</Typography>
          </Box>
        ) : !data.length ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <Typography sx={{ color: theme.palette.text.secondary }}>
              Nessun dato disponibile per questo periodo
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {competencyProperties.map((property, index) => (
              <Grid item xs={12} sm={6} key={property}>
                <SkillChart
                  title={property}
                  data={data.map(item => ({
                    timestamp: item.timestamp,
                    value: (item[property] as number | undefined) ?? null
                  }))}
                  color={colors[index % colors.length]}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Paper>
  );
};
