import axios from 'axios';
import { Politician, Party } from '../types/database.types';

export interface NewsItem {
  title: string;
  date: string;
  source: string;
  summary: string;
  url?: string; // URL della notizia
  source_url?: string; // URL della fonte (formato alternativo restituito da OpenAI)
}

class OpenAINewsService {
  private readonly baseUrl = '/api/news';

  /**
   * Cerca le ultime notizie su un politico utilizzando OpenAI con ricerca web
   * @param politician Il politico di cui cercare le notizie
   * @param limit Numero massimo di notizie da restituire (default: 5)
   * @returns Array di notizie
   */
  async searchPoliticianNews(politician: Politician, limit: number = 5): Promise<NewsItem[]> {
    try {
      console.log(`Searching news for politician: ${politician.first_name} ${politician.last_name}`);
      
      const response = await axios.post(`${this.baseUrl}/search`, {
        query: `${politician.first_name} ${politician.last_name}`,
        limit
      });
      
      return response.data.news || [];
    } catch (error) {
      console.error('Error searching politician news with OpenAI:', error);
      return [];
    }
  }

  /**
   * Cerca le ultime notizie su un partito utilizzando OpenAI con ricerca web
   * @param party Il partito di cui cercare le notizie
   * @param limit Numero massimo di notizie da restituire (default: 5)
   * @returns Array di notizie
   */
  async searchPartyNews(party: Party, limit: number = 5): Promise<NewsItem[]> {
    try {
      console.log(`Searching news for party: ${party.name}`);
      
      const response = await axios.post(`${this.baseUrl}/search`, {
        query: `partito italiano: ${party.name}`,
        limit
      });
      
      return response.data.news || [];
    } catch (error) {
      console.error('Error searching party news with OpenAI:', error);
      return [];
    }
  }

  /**
   * Cerca le ultime notizie su un'entità (politico o partito) utilizzando OpenAI con ricerca web
   * @param query La query di ricerca
   * @param limit Numero massimo di notizie da restituire (default: 5)
   * @returns Array di notizie
   */
  async searchNews(query: string, limit: number = 5): Promise<NewsItem[]> {
    try {
      console.log(`Searching news for: ${query}`);
      
      const response = await axios.post(`${this.baseUrl}/search`, {
        query,
        limit
      });
      
      return response.data.news || [];
    } catch (error) {
      console.error(`Error searching news with OpenAI:`, error);
      return [];
    }
  }
}

export const openaiNewsService = new OpenAINewsService();
