import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Stack,
  Alert,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Close as CloseIcon
} from '@mui/icons-material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';
import type { Swiper as SwiperType } from 'swiper';
import { italianTranslations as t } from '../../../i18n/it';
import { Politician } from '../../../types/database.types';
import { supabase } from '../../../lib/supabaseClient';
import { PoliticianCard } from '../shared/PoliticianCard';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import './politician-swiper.css';

interface UserPoliticianListProps {
  selectedPartyId?: string | null;
  selectedRole?: string | null;
  onClearFilter?: () => void;
}

const POLITICIANS_PER_PAGE = 10;

export const UserPoliticianList: React.FC<UserPoliticianListProps> = ({ 
  selectedPartyId, 
  selectedRole, 
  onClearFilter 
}) => {
  console.log("UserPoliticianList - Props ricevute:", { selectedPartyId, selectedRole });
  const navigate = useNavigate();
  const [politicians, setPoliticians] = useState<Politician[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPartyDetails, setSelectedPartyDetails] = useState<any | null>(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [resetCardView, setResetCardView] = useState(0);
  const swiperRef = useRef<SwiperType | null>(null);
  const theme = useTheme();

  // Card colors for the PoliticianCard component
  const cardColors = [
    '#1976d2', '#388e3c', '#d32f2f', '#7b1fa2', '#c2185b',
    '#0288d1', '#689f38', '#f57c00', '#5d4037', '#455a64'
  ];

  // Prevent scrolling on the body
  useEffect(() => {
    // Save original styles
    const originalStyle = window.getComputedStyle(document.body).overflow;
    
    // Prevent scrolling
    document.body.style.overflow = 'hidden';
    
    // Cleanup function to restore original styles when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);

  // Riferimento per tenere traccia se i dati sono già stati caricati
  const dataLoadedRef = useRef<string>('');

  useEffect(() => {
    // Evita di caricare i dati più volte per gli stessi parametri
    const loadKey = `${selectedPartyId || 'null'}-${selectedRole || 'null'}`;
    
    if (dataLoadedRef.current === loadKey && politicians.length > 0) {
      console.log('Dati già caricati per questa combinazione di filtri, salto il caricamento');
      return;
    }
    
    console.log('Caricamento dati con nuovi filtri:', loadKey);
    dataLoadedRef.current = loadKey;
    
    setLoading(true);
    setPage(1);
    setPoliticians([]);
    loadPoliticians(true);
  }, [selectedPartyId, selectedRole]);

  useEffect(() => {
    if (selectedPartyId) {
      fetchPartyDetails();
    } else {
      setSelectedPartyDetails(null);
    }
  }, [selectedPartyId]);

  // Effect to check if we need to load more politicians
  useEffect(() => {
    if (activeIndex >= politicians.length - 3 && hasMore && !loading && !loadingMore) {
      // Load more when user is 3 cards away from the end
      loadPoliticians(false);
    }
  }, [activeIndex, politicians.length, hasMore, loading, loadingMore]);

  const fetchPartyDetails = async () => {
    if (!selectedPartyId) return;
    
    try {
      const { data, error } = await supabase
        .from('parties')
        .select('*')
        .eq('id', selectedPartyId)
        .single();

      if (error) throw error;
      setSelectedPartyDetails(data);
    } catch (error) {
      console.error('Error fetching party details:', error);
    }
  };

  // Riferimento per tenere traccia delle richieste in corso
  const loadingRef = useRef(false);

  // Funzione per caricare i politici
  const loadPoliticians = useCallback(async (reset: boolean = false) => {
    // Evita chiamate multiple contemporanee
    if (loadingRef.current) {
      console.log('Caricamento già in corso, richiesta ignorata');
      return;
    }
    
    try {
      if (reset) {
        setLoading(true);
      } else {
        setLoadingMore(true);
      }
      
      loadingRef.current = true;
      console.log('Inizio caricamento politici');
      
      setError(null);

      const startIndex = reset ? 0 : politicians.length;
      const limit = POLITICIANS_PER_PAGE;

      console.log(`Fetching politicians from ${startIndex} to ${startIndex + limit - 1}`);
      
      // Usa un ID univoco per questa richiesta per il logging
      const requestId = Date.now();
      console.log(`[Request ${requestId}] Inizio caricamento politici`);

      const fetchedPoliticians = await fetchPoliticiansWithRoleFilter(startIndex, limit, requestId);
      
      console.log(`[Request ${requestId}] Ricevuti ${fetchedPoliticians.length} politici`);

      // Deduplicazione dei politici
      let updatedPoliticians: Politician[];
      
      if (reset) {
        updatedPoliticians = fetchedPoliticians;
      } else {
        // Crea un Set di ID per i politici esistenti
        const existingIds = new Set(politicians.map(p => p.id));
        
        // Filtra i nuovi politici per rimuovere quelli già presenti
        const uniqueNewPoliticians = fetchedPoliticians.filter(p => !existingIds.has(p.id));
        
        console.log(`[Request ${requestId}] Filtrati ${fetchedPoliticians.length - uniqueNewPoliticians.length} politici duplicati`);
        
        updatedPoliticians = [...politicians, ...uniqueNewPoliticians];
      }
      
      setPoliticians(updatedPoliticians);

      // Check if there are more politicians to load
      setHasMore(fetchedPoliticians.length === limit);
      
      if (!reset) {
        setPage(prev => prev + 1);
      }
      
      setError(null);
      console.log(`[Request ${requestId}] Fine caricamento politici`);
    } catch (err) {
      console.error('Error fetching politicians:', err);
      setError('Si è verificato un errore durante il caricamento dei politici');
    } finally {
      setLoading(false);
      setLoadingMore(false);
      loadingRef.current = false;
    }
  }, [politicians]);

  const fetchPoliticiansWithRoleFilter = async (
    startIndex: number = 0,
    limit: number = POLITICIANS_PER_PAGE,
    requestId?: number
  ): Promise<Politician[]> => {
    console.log(`${requestId ? `[Request ${requestId}] ` : ''}Fetching politicians from ${startIndex} to ${startIndex + limit - 1}`);
    console.log(`Filtri applicati: Partito=${selectedPartyId || 'nessuno'}, Ruolo=${selectedRole || 'nessuno'}`);
    
    try {
      if (selectedRole) {
        console.log(`${requestId ? `[Request ${requestId}] ` : ''}Filtrando per ruolo: ${selectedRole}`);
        
        // Ottieni gli ID unici dei politici con il ruolo selezionato
        let politicianQuery = supabase
          .from('politician_offices')
          .select('politician_id, political_office:political_offices!inner(title)');
          
        if (selectedRole === 'Ministro') {
          politicianQuery = politicianQuery.ilike('political_office.title', '%Ministro%');
        } else if (selectedRole === 'Presidente') {
          // Per i presidenti, dobbiamo escludere quelli regionali e provinciali
          politicianQuery = politicianQuery
            .ilike('political_office.title', '%Presidente%')
            .not('political_office.title', 'ilike', '%Regione%')
            .not('political_office.title', 'ilike', '%Provincia%');
        } else {
          politicianQuery = politicianQuery.eq('political_office.title', selectedRole);
        }
        
        const { data: politicianIds, error: idsError } = await politicianQuery;
        
        if (idsError) {
          throw idsError;
        }
        
        // Estraiamo gli ID unici
        const uniqueIds = Array.from(new Set(politicianIds.map(p => p.politician_id)));
        
        console.log(`Trovati ${uniqueIds.length} politici unici con ruolo ${selectedRole}`);
        
        if (uniqueIds.length === 0) {
          return [];
        }
        
        // Ora otteniamo i dettagli completi dei politici
        let politiciQuery = supabase
          .from('politicians')
          .select(`
            *,
            party:parties!party_id(*),
            birth_place:places!birth_place_id(*),
            politician_offices(
              political_office:political_offices(
                title,
                description
              )
            )
          `)
          .in('id', uniqueIds);
          
        // Applica il filtro per partito se selezionato
        if (selectedPartyId) {
          console.log(`Applicando anche il filtro per partito: ${selectedPartyId}`);
          politiciQuery = politiciQuery.eq('party_id', selectedPartyId);
        }
        
        const { data: politici, error: politiciError } = await politiciQuery
          .order('last_name', { ascending: true })
          .range(startIndex, startIndex + limit - 1);
          
        if (politiciError) {
          throw politiciError;
        }
        
        console.log(`Recuperati ${politici?.length || 0} politici con ruolo ${selectedRole}${selectedPartyId ? ` e partito ${selectedPartyId}` : ''}`);
        
        return politici || [];
      }

      // Se non è stato specificato un ruolo, procedi con la query standard
      // Base query
      let query = supabase
        .from('politicians')
        .select(`
          *,
          party:parties!party_id(*),
          birth_place:places!birth_place_id(*),
          politician_offices(
            political_office:political_offices(
              title,
              description
            )
          )
        `);

      // Apply party filter if selected
      if (selectedPartyId) {
        console.log(`Filtrando solo per partito: ${selectedPartyId}`);
        query = query.eq('party_id', selectedPartyId);
      }
      
      // Get all data
      const { data: allPoliticians, error } = await query
        .order('last_name', { ascending: true })
        .range(startIndex, startIndex + limit - 1);

      if (error) {
        throw error;
      }

      // Filter out politicians with excluded roles
      const excludedTitles = [
        'Presidente di Regione',
        'Presidente della Provincia',
        'Sindaco'
      ];

      // Filtra i politici in base ai ruoli esclusi
      let filteredPoliticians = allPoliticians?.filter(politician => {
        // If politician has no offices, include them
        if (!politician.politician_offices?.length) {
          return true;
        }

        // Check if politician has ANY excluded office - if yes, exclude them
        const hasExcludedRole = politician.politician_offices.some(
          (office: { political_office: { title: string | null } }) => {
            const title = office.political_office?.title || '';
            // Check for exact match with any excluded title
            return excludedTitles.includes(title);
          }
        );
        
        // Only include politicians that don't have any excluded roles
        return !hasExcludedRole;
      }) || [];

      // Ordina i politici per cognome
      filteredPoliticians.sort((a, b) => {
        return (a.last_name || '').localeCompare(b.last_name || '');
      });

      return filteredPoliticians;
    } catch (error) {
      console.error('Error fetching politicians:', error);
      throw error;
    }
  };

  const handleCardClick = (politician: Politician) => {
    console.log('Politician card clicked:', politician.first_name, politician.last_name);
  };

  const handleLoadMore = () => {
    if (!loadingMore && hasMore) {
      loadPoliticians(false);
    }
  };

  // Function to determine role text for politician card
  const getPoliticianRole = (politician: Politician): string => {
    if (politician.politician_offices && politician.politician_offices.length > 0) {
      // Raccogliamo tutte le cariche e le uniamo con virgola e spazio
      return politician.politician_offices
        .map(office => office.political_office?.title)
        .filter(title => title) // Filtriamo eventuali titoli undefined o null
        .join(', ') || 'Politico';
    }
    return 'Politico';
  };

  // Funzione per resettare tutte le viste delle card
  const resetAllCardViews = () => {
    setResetCardView(prev => prev + 1);
  };

  return (
    <Box sx={{ 
      width: '100vw', 
      height: 'calc(100vh - 56px)',
      display: 'flex', 
      flexDirection: 'column', 
      overflow: 'hidden',
      p: 0,
      m: 0,
      border: 'none', 
      borderRadius: 0,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0
    }}>
      {error && (
        <Alert severity="error" sx={{ position: 'absolute', top: 70, left: 0, right: 0, zIndex: 100, mx: 2 }}>
          {error}
        </Alert>
      )}

      {/* Main content area */}
      <Box sx={{ flex: 1, overflow: 'hidden', position: 'relative', backgroundColor: theme.palette.background.default }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : politicians.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant="body1" color="text.secondary">
              Nessun politico disponibile.
            </Typography>
          </Box>
        ) : (
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1.5,
              slideShadows: false,
            }}
            pagination={false}
            modules={[EffectCoverflow]}
            className="politician-swiper"
            style={{ 
              width: '100%',
              height: '100%',
              margin: 0,
              padding: 0,
              backgroundColor: theme.palette.background.default
            }}
            onSlideChange={(swiper: SwiperType) => {
              setActiveIndex(swiper.activeIndex);
              resetAllCardViews();
            }}
            onSwiper={(swiper: SwiperType) => {
              swiperRef.current = swiper;
            }}
          >
            {politicians.map((politician) => (
              <SwiperSlide key={`politician-${politician.id}`} className="swiper-slide">
                <PoliticianCard
                  politician={politician}
                  loading={false}
                  error={null}
                  role={getPoliticianRole(politician)}
                  colorIndex={politicians.findIndex(p => p.id === politician.id) % cardColors.length}
                  cardColors={cardColors}
                  onCardClick={() => handleCardClick(politician)}
                  enableCaricatureGeneration={true}
                  replaceImageOnly={true}
                  resetView={resetCardView}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        
        {/* Load More Button - only show when needed */}
        {!loading && politicians.length > 0 && hasMore && loadingMore && (
          <Box className="load-more-container">
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
      
      {/* Filtro attivo - posizionato sopra la barra di ricerca come elemento separato */}
      {(selectedPartyId && selectedPartyDetails) || selectedRole ? (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 1.5,
            py: 0.5,
            borderRadius: 1,
            backgroundColor: theme.palette.background.default,
            border: '1px solid rgba(255, 255, 255, 0.1)',
            borderBottom: 'none'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="caption" sx={{ color: theme.palette.text.primary, fontWeight: 'medium', fontSize: '0.7rem', display: 'flex', alignItems: 'center' }}>
              <Box component="span" sx={{ color: theme.palette.text.secondary, fontSize: '0.6rem' }}>
                {(selectedPartyId && selectedPartyDetails) || selectedRole 
                  ? <React.Fragment>
                      <Box component="span" sx={{ color: theme.palette.primary.main }}>Filtro per:</Box>
                      {selectedPartyId && selectedPartyDetails && (
                        <Box component="span"> {selectedPartyDetails.name}</Box>
                      )}
                      {selectedPartyId && selectedPartyDetails && selectedRole && (
                        <Box component="span"> - </Box>
                      )}
                      {selectedRole && (
                        <Box component="span"> {selectedRole}</Box>
                      )}
                    </React.Fragment> 
                  : ''}
              </Box>
            </Typography>
          </Box>
          <IconButton 
            size="small" 
            onClick={onClearFilter}
            sx={{ color: theme.palette.text.secondary, p: 0.25 }}
          >
            <CloseIcon sx={{ fontSize: 14 }} />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
};

export default React.memo(UserPoliticianList, (prevProps, nextProps) => {
  // Renderizza nuovamente solo se i filtri sono effettivamente cambiati
  return prevProps.selectedPartyId === nextProps.selectedPartyId && 
         prevProps.selectedRole === nextProps.selectedRole;
});
