import { NewsItem } from './openaiNewsService';

/**
 * Interfaccia per i risultati di ricerca delle notizie
 * Precedentemente definita in newsApiService.ts
 */
export interface NewsSearchResult {
  title: string;
  link: string;
  snippet: string;
  source: string;
  sourceUrl?: string; // URL della fonte
  source_url?: string; // Formato alternativo dell'URL della fonte (da OpenAI)
  publishedDate?: string;
}

/**
 * Adattatore per convertire i risultati di openaiNewsService nel formato utilizzato da newsApiService
 */
export class NewsServiceAdapter {
  /**
   * Converte un array di NewsItem in un array di NewsSearchResult
   * @param newsItems Array di NewsItem da convertire
   * @returns Array di NewsSearchResult
   */
  static convertToNewsSearchResult(newsItems: NewsItem[]): NewsSearchResult[] {
    return newsItems.map(item => ({
      title: item.title,
      link: item.source_url || item.url || '', // Utilizziamo source_url o url se disponibile
      snippet: item.summary,
      source: item.source,
      sourceUrl: item.source_url || item.url || '', // Utilizziamo source_url o url se disponibile
      source_url: item.source_url || '', // Manteniamo anche il campo originale
      publishedDate: item.date
    }));
  }
}
