import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { Skill } from '../../types/database.types';
import { userService } from '../../services/userService';

interface SkillDialogProps {
  open: boolean;
  skill: Skill | null;
  onClose: () => void;
  onSave: (skill: Skill) => Promise<boolean>;
}

interface FormData {
  property: string;
  description: string;
  ord: number;
  type: string;
}

const emptyFormData: FormData = {
  property: '',
  description: '',
  ord: 0,
  type: '',
};

export const SkillDialog: React.FC<SkillDialogProps> = ({
  open,
  skill,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState<FormData>(emptyFormData);
  const [creatorEmail, setCreatorEmail] = useState<string>('');

  useEffect(() => {
    // Reset form when dialog opens/closes
    if (!open) {
      setFormData(emptyFormData);
      setCreatorEmail('');
      return;
    }
    
    // If editing existing skill, populate form
    if (skill) {
      setFormData({
        property: skill.property,
        description: skill.description || '',
        ord: skill.ord || 0,
        type: skill.type || '',
      });
      // Fetch creator email
      userService.getUserById(skill.created_by).then(user => {
        if (user?.email) {
          setCreatorEmail(user.email);
        }
      });
    } else {
      // If adding new skill, reset form
      setFormData(emptyFormData);
      setCreatorEmail('');
    }
  }, [skill, open]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ 
      ...prev, 
      [name]: name === 'ord' ? Number(value) : value 
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const currentTime = new Date().toISOString();
    
    // Prepara i dati da salvare
    const skillData = {
      ...formData,
      updated_at: currentTime,
      type: formData.type as 'Politico' | 'Giornalista' | undefined,
    };

    // Se stiamo modificando uno skill esistente, includi id, created_at e created_by
    if (skill) {
      Object.assign(skillData, {
        id: skill.id,
        created_at: skill.created_at,
        created_by: skill.created_by
      });
    }

    const success = await onSave(skillData as Skill);
    
    if (success) {
      onClose();
      // Reset form after successful save
      setFormData(emptyFormData);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {skill ? 'Edit Skill' : 'Add Skill'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
            <TextField
              name="property"
              label="Property"
              value={formData.property}
              onChange={handleChange}
              required
              fullWidth
            />
            <TextField
              name="description"
              label="Description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
            />
            <TextField
              name="ord"
              label="Display Order"
              type="number"
              value={formData.ord}
              onChange={handleChange}
              fullWidth
              helperText="Lower numbers will be displayed first"
            />
            <FormControl fullWidth>
              <InputLabel id="type-label">Tipo</InputLabel>
              <Select
                labelId="type-label"
                name="type"
                value={formData.type}
                onChange={handleSelectChange}
                label="Tipo"
              >
                <MenuItem value="">
                  <em>Nessuno</em>
                </MenuItem>
                <MenuItem value="Politico">Politico</MenuItem>
                <MenuItem value="Giornalista">Giornalista</MenuItem>
              </Select>
            </FormControl>
            {skill && (
              <Box sx={{ mt: 2, typography: 'caption', color: 'text.secondary' }}>
                <div>Created by: {creatorEmail || 'Loading...'}</div>
                <div>Created at: {new Date(skill.created_at).toLocaleString()}</div>
                <div>Updated at: {new Date(skill.updated_at).toLocaleString()}</div>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            onClose();
            setFormData(emptyFormData);
          }}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
