import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloseIcon from '@mui/icons-material/Close';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
}

const InstallBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    // Intercetta l'evento beforeinstallprompt
    const handleBeforeInstallPrompt = (e: Event) => {
      // Impedisce al browser di mostrare il prompt automaticamente
      e.preventDefault();
      // Salva l'evento per poterlo attivare più tardi
      setDeferredPrompt(e as BeforeInstallPromptEvent);
      // Mostra il banner personalizzato
      setShowBanner(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Controlla se l'app è già installata
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (isStandalone) {
      setShowBanner(false);
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstall = () => {
    if (!deferredPrompt) return;

    // Mostra il prompt di installazione
    deferredPrompt.prompt();

    // Attendi che l'utente risponda al prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('Utente ha accettato di installare l\'app');
      } else {
        console.log('Utente ha rifiutato di installare l\'app');
      }
      // Resetta il prompt differito - può essere usato solo una volta
      setDeferredPrompt(null);
      setShowBanner(false);
    });
  };

  const handleClose = () => {
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#ad0e38',
        color: 'white',
        padding: '12px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 9999,
        boxShadow: '0px -2px 10px rgba(0,0,0,0.1)'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <GetAppIcon sx={{ mr: 1 }} />
        <Typography variant="body1">
          Installa Polinder per un'esperienza a schermo intero
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          onClick={handleInstall}
          variant="contained"
          size="small"
          sx={{
            backgroundColor: 'white',
            color: '#ad0e38',
            mr: 1,
            '&:hover': {
              backgroundColor: '#f5f5f5'
            }
          }}
        >
          Installa
        </Button>
        <Button
          onClick={handleClose}
          sx={{ color: 'white', minWidth: 'auto', p: 0.5 }}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default InstallBanner;
