import React from 'react';
import { Container, Typography, Paper, Box, Link } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container 
      maxWidth="md" 
      sx={{ 
        py: { xs: 2, sm: 3 },
        px: { xs: 1, sm: 2 }
      }}
    >
      <Paper 
        elevation={3} 
        sx={{ 
          p: { xs: 2, sm: 3, md: 4 },
          my: { xs: 2, sm: 3, md: 4 }
        }}
      >
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.125rem' },
            mb: { xs: 2, sm: 3 }
          }}
        >
          Informativa sulla Privacy
        </Typography>

        <Box mb={{ xs: 2, sm: 3 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.25rem' }
            }}
          >
            1. Introduzione
          </Typography>
          <Typography 
            paragraph
            sx={{
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            La presente Informativa sulla Privacy descrive come raccogliamo e utilizziamo i dati personali degli utenti della nostra piattaforma.
          </Typography>
        </Box>

        <Box mb={{ xs: 2, sm: 3 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.25rem' }
            }}
          >
            2. Dati Personali Raccolti
          </Typography>
          <Typography 
            paragraph
            sx={{
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            Raccogliamo i seguenti dati personali esclusivamente per finalità statistiche:
          </Typography>
          <Box component="ul" sx={{ pl: { xs: 2, sm: 3 } }}>
            {[
              'Indirizzo email',
              'Nome completo',
              'Genere',
              'Età',
              'Luogo di residenza'
            ].map((item, index) => (
              <Typography 
                key={index}
                component="li"
                sx={{
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  mb: 0.5
                }}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Box>

        <Box mb={{ xs: 2, sm: 3 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.25rem' }
            }}
          >
            3. Finalità del Trattamento
          </Typography>
          <Typography 
            paragraph
            sx={{
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            I dati personali vengono raccolti e trattati esclusivamente per finalità statistiche e di analisi aggregate, 
            al fine di migliorare i nostri servizi e comprendere meglio le caratteristiche dei nostri utenti.
          </Typography>
        </Box>

        <Box mb={{ xs: 2, sm: 3 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.25rem' }
            }}
          >
            4. Anonimato delle Preferenze
          </Typography>
          <Typography 
            paragraph
            sx={{
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            Le tue preferenze sui politici saranno completamente anonime. Il nostro sistema è progettato per garantire 
            la massima privacy e riservatezza delle tue scelte e opinioni politiche.
          </Typography>
        </Box>

        <Box mb={{ xs: 2, sm: 3 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.25rem' }
            }}
          >
            5. Conservazione dei Dati
          </Typography>
          <Typography 
            paragraph
            sx={{
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            I dati personali vengono conservati in modo sicuro e per il tempo necessario alle finalità statistiche per cui sono stati raccolti, 
            nel rispetto delle normative vigenti sulla protezione dei dati personali.
          </Typography>
        </Box>

        <Box mb={{ xs: 2, sm: 3 }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.25rem' }
            }}
          >
            6. Diritti dell'Utente
          </Typography>
          <Typography 
            paragraph
            sx={{
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            Gli utenti hanno il diritto di:
          </Typography>
          <Box component="ul" sx={{ pl: { xs: 2, sm: 3 } }}>
            {[
              'Accedere ai propri dati personali',
              'Richiedere la rettifica dei dati',
              'Richiedere la cancellazione dei dati',
              'Opporsi al trattamento dei dati',
              'Richiedere la limitazione del trattamento'
            ].map((item, index) => (
              <Typography 
                key={index}
                component="li"
                sx={{
                  fontSize: { xs: '0.875rem', sm: '1rem' },
                  mb: 0.5
                }}
              >
                {item}
              </Typography>
            ))}
          </Box>
        </Box>

        <Box>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              fontSize: { xs: '1.1rem', sm: '1.25rem' }
            }}
          >
            7. Contatti
          </Typography>
          <Typography 
            paragraph
            sx={{
              fontSize: { xs: '0.875rem', sm: '1rem' }
            }}
          >
            Per qualsiasi domanda riguardante questa Informativa sulla Privacy o per esercitare i propri diritti, 
            gli utenti possono contattarci all'indirizzo email: <Link href="mailto:info@polinder.net" sx={{ color: '#ad0e38' }}>info@polinder.net</Link>
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;
