import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  Avatar,
  IconButton,
  Paper,
  CircularProgress,
  Rating,
  Tooltip,
  Collapse,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { supabase } from '../../../lib/supabaseClient';
import { Party } from '../../../types/database.types';

interface PartyCardDetailsProps {
  party: Party | null;
  onClose: () => void;
}

// Interface for skills
interface Skill {
  id: string;
  property: string;
  description: string;
  rating?: number;
  total_votes?: number;
  ord: number;
}

// Stile comune per le stelle di valutazione
const ratingStyles = {
  '& .MuiRating-iconFilled': {
    color: '#FFD700',
  },
  '& .MuiRating-iconEmpty': {
    color: 'rgba(255, 215, 0, 0.3)',
  },
  '& .MuiRating-iconHover': {
    color: '#FFD700',
  }
};

export const PartyCardDetails: React.FC<PartyCardDetailsProps> = ({
  party,
  onClose
}) => {
  const theme = useTheme();
  const [skills, setSkills] = useState<Skill[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedSkill, setExpandedSkill] = useState<string | null>(null);

  useEffect(() => {
    if (party) {
      loadPartySkills(party.id);
    }
  }, [party]);

  const loadPartySkills = async (partyId: string) => {
    try {
      setLoading(true);
      
      // Prima otteniamo tutti i politici che appartengono a questo partito
      const { data: partyPoliticians, error: politiciansError } = await supabase
        .from('politicians')
        .select('id')
        .eq('party_id', partyId);
        
      if (politiciansError) throw politiciansError;
      
      if (!partyPoliticians || partyPoliticians.length === 0) {
        setLoading(false);
        setError('Nessun politico trovato per questo partito');
        return;
      }
      
      const politicianIds = partyPoliticians.map(p => p.id);
      
      // Carica le skills con i voti dalla materialized view
      const { data: skillsData, error: skillsError } = await supabase
        .from('skills')
        .select(`
          id,
          property,
          description,
          ord
        `)
        .order('ord', { ascending: true })
        .order('property', { ascending: true });

      if (skillsError) throw skillsError;

      // Per ogni skill, calcola la media dei voti di tutti i politici del partito
      const skillsWithVotes = await Promise.all((skillsData || []).map(async (skill) => {
        // Ottieni i voti per questa skill per tutti i politici del partito
        const { data: voteData, error: voteError } = await supabase
          .from('skill_vote_stats')
          .select('avg_rating, total_votes')
          .eq('skill_id', skill.id)
          .in('politician_id', politicianIds);

        if (voteError) throw voteError;

        // Calcola la media dei voti per questa skill
        let totalRating = 0;
        let totalVotes = 0;
        
        if (voteData && voteData.length > 0) {
          voteData.forEach(vote => {
            if (vote.avg_rating && vote.total_votes) {
              totalRating += vote.avg_rating * vote.total_votes;
              totalVotes += vote.total_votes;
            }
          });
        }
        
        const avgRating = totalVotes > 0 ? totalRating / totalVotes : 0;
        
        return {
          id: skill.id,
          property: skill.property,
          description: skill.description,
          ord: skill.ord || 0,
          rating: avgRating,
          total_votes: totalVotes
        };
      }));

      setSkills(skillsWithVotes);
      setError(null);
    } catch (error: any) {
      console.error('Error loading party skills:', error);
      setError('Errore nel caricamento delle competenze del partito');
    } finally {
      setLoading(false);
    }
  };

  // Funzione per formattare il numero di voti
  const formatVotes = (votes: number = 0) => {
    if (votes === 0) return 'Nessun voto';
    if (votes === 1) return '1 voto';
    return `${votes} voti`;
  };

  if (!party) {
    return null;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: theme.palette.background.default,
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        borderRadius: 0
      }}
    >
      {/* Header with party logo and close button */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={party?.logo_url || '/party-placeholder.png'}
            alt={party?.name}
            sx={{ width: 50, height: 50, mr: 2 }}
          />
          <Box>
            <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
              {party?.name}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={onClose} sx={{ color: theme.palette.text.primary }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Skills/Preferences */}
      <Box sx={{ p: 1.5, flex: 1, overflow: 'auto' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ color: theme.palette.text.primary }}>
          Competenze
        </Typography>
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
            <CircularProgress color="primary" />
            <Typography sx={{ ml: 2, color: theme.palette.text.secondary }}>
              Caricamento preferenze...
            </Typography>
          </Box>
        ) : error ? (
          <Typography sx={{ color: theme.palette.text.secondary, py: 2 }}>
            {error}
          </Typography>
        ) : skills.length === 0 ? (
          <Typography sx={{ color: theme.palette.text.secondary, py: 2 }}>
            Nessuna preferenza disponibile per questo partito
          </Typography>
        ) : (
          <Box sx={{ mt: 1 }}>
            {skills.map((skill) => (
              <Box key={skill.id} sx={{ mb: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Mostra descrizione">
                      <IconButton 
                        size="small" 
                        sx={{ mr: 1, color: theme.palette.primary.main }}
                        onClick={() => setExpandedSkill(expandedSkill === skill.id ? null : skill.id)}
                      >
                        {expandedSkill === skill.id ? 
                          <ExpandLessIcon fontSize="small" /> : 
                          <ExpandMoreIcon fontSize="small" />
                        }
                      </IconButton>
                    </Tooltip>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: '0.95rem' }}>
                      {skill.property}
                    </Typography>
                  </Box>
                  <Rating 
                    value={skill.rating || 0}
                    readOnly
                    precision={0.1}
                    sx={ratingStyles}
                  />
                </Box>
                
                <Collapse in={expandedSkill === skill.id}>
                  <Typography sx={{ 
                    color: theme.palette.text.secondary, 
                    mb: 1, 
                    fontSize: '0.9rem',
                    pl: 1,
                    borderLeft: `2px solid ${theme.palette.primary.main}`,
                    mt: 1
                  }}>
                    {skill.description}
                  </Typography>
                  <Typography variant="caption" sx={{ 
                    display: 'block', 
                    color: theme.palette.text.secondary, 
                    pl: 1, 
                    fontSize: '0.8rem',
                    fontStyle: 'italic'
                  }}>
                    {formatVotes(skill.total_votes)}
                  </Typography>
                </Collapse>
                
                <Divider sx={{ mt: 1.5, bgcolor: theme.palette.divider }} />
              </Box>
            ))}
          </Box>
        )}
        
        {/* Informazioni sul calcolo delle preferenze */}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', px: 2, pb: 2 }}>
          <Box sx={{ 
            bgcolor: theme.palette.primary.main, 
            color: theme.palette.primary.contrastText,
            p: 1.5,
            width: '100%',
            maxWidth: '360px',
            borderRadius: '8px',
            textAlign: 'center',
            mx: 'auto'
          }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              Media delle preferenze
            </Typography>
            <Typography variant="caption" sx={{ opacity: 0.9 }}>
              Calcolata su tutti i politici del partito
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
