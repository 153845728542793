import { supabase } from '../lib/supabaseClient';

class StorageService {
  private readonly IMAGES_BUCKET = 'images';
  private initialized = false;

  private async checkAuth() {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      throw new Error('Authentication required. Please sign in.');
    }
    return session;
  }

  private async ensureBucketExists() {
    if (this.initialized) return;

    try {
      await this.checkAuth();

      // Check if bucket exists
      const { data: buckets } = await supabase.storage.listBuckets();
      const bucketExists = buckets?.some(b => b.name === this.IMAGES_BUCKET);

      if (!bucketExists) {
        // Create the bucket if it doesn't exist
        const { error: createError } = await supabase.storage.createBucket(this.IMAGES_BUCKET, {
          public: false,
          allowedMimeTypes: ['image/png', 'image/jpeg', 'image/jpg'],
          fileSizeLimit: 1024 * 1024 // 1MB
        });

        if (createError) {
          console.error('Error creating bucket:', createError);
          throw createError;
        }
      }

      this.initialized = true;
    } catch (error) {
      console.error('Error in ensureBucketExists:', error);
      if (error instanceof Error) {
        throw new Error(`Failed to initialize storage: ${error.message}`);
      }
      throw error;
    }
  }

  async uploadImage(file: File, folder: string): Promise<string> {
    try {
      const session = await this.checkAuth();
      await this.ensureBucketExists();

      // Validate file type
      if (!file.type.match(/^image\/(png|jpeg|jpg)$/)) {
        throw new Error('Only PNG and JPG images are allowed');
      }

      // Validate file size (1MB)
      if (file.size > 1024 * 1024) {
        throw new Error('Image size must be less than 1MB');
      }

      // Create a unique file name using user ID and timestamp
      const fileExt = file.name.split('.').pop();
      const fileName = `${folder}/${session.user.id}_${Date.now()}.${fileExt}`;

      // Upload the file
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from(this.IMAGES_BUCKET)
        .upload(fileName, file, {
          cacheControl: '3600',
          upsert: true
        });

      if (uploadError) {
        console.error('Error uploading file:', uploadError);
        throw uploadError;
      }

      if (!uploadData) {
        throw new Error('No data returned from upload');
      }

      // Get the public URL
      const { data: { publicUrl } } = supabase.storage
        .from(this.IMAGES_BUCKET)
        .getPublicUrl(fileName);

      if (!publicUrl) {
        throw new Error('Failed to get public URL');
      }

      return publicUrl;
    } catch (error: any) {
      console.error('Error in uploadImage:', error);
      throw error;
    }
  }

  async deleteImage(url: string): Promise<void> {
    try {
      console.log('deleteImage called with URL:', url);
      await this.checkAuth();
      await this.ensureBucketExists();

      // Extract the file path from the URL
      const urlObj = new URL(url);
      console.log('URL object:', urlObj);
      const pathParts = urlObj.pathname.split('/');
      console.log('Path parts:', pathParts);
      const fileName = pathParts[pathParts.length - 1];
      const folder = pathParts[pathParts.length - 2];
      const filePath = `${folder}/${fileName}`;
      console.log('Constructed file path:', filePath);

      const { error } = await supabase.storage
        .from(this.IMAGES_BUCKET)
        .remove([filePath]);

      if (error) {
        console.error('Error deleting file:', error);
        throw error;
      }
      console.log('File successfully deleted from storage');
    } catch (error) {
      console.error('Error in deleteImage:', error);
      throw error;
    }
  }
}

export const storageService = new StorageService();
