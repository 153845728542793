import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress, 
  Button, 
  Divider, 
  useTheme, 
  Paper, 
  IconButton,
  Avatar,
  LinearProgress,
  Link
} from '@mui/material';
import { Party } from '../../../types/database.types';
import { partyNewsService } from '../../../services/partyNewsService';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import InfoIcon from '@mui/icons-material/Info';
import { NEWS_UPDATE_FREQUENCY, NEWS_GENERATION_POLLING_INTERVAL } from '../../../config/newsConfig';

// Intervallo di polling in millisecondi
const POLLING_INTERVAL = NEWS_GENERATION_POLLING_INTERVAL;

// Frequenza di aggiornamento in giorni
const UPDATE_FREQUENCY = NEWS_UPDATE_FREQUENCY;

interface PartyCardNewsProps {
  party: Party | null;
  onClose: () => void;
  open?: boolean;
}

// Definizione dell'interfaccia per le notizie
interface News {
  id: string;
  content: string;
  news_date: string;
  sources?: (string | NewsSource)[];
}

interface NewsSource {
  name?: string;
  url?: string;
}

// Interfaccia per gli articoli nel formato JSON
interface NewsArticle {
  title: string;
  content: string;
  date: string;
  source: string;
  url?: string;
}

// Interfaccia per il formato JSON delle notizie
interface NewsData {
  articles: NewsArticle[];
}

const PartyCardNews: React.FC<PartyCardNewsProps> = ({
  party,
  onClose,
  open
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [newsItems, setNewsItems] = useState<any[]>([]);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [waitingForGeneration, setWaitingForGeneration] = useState<boolean>(false);
  const [elapsedSeconds, setElapsedSeconds] = useState<number>(0);
  const [pollingId, setPollingId] = useState<number | null>(null);
  const [needsUpdate, setNeedsUpdate] = useState<boolean>(false);
  const [daysUntilUpdate, setDaysUntilUpdate] = useState<number>(0);

  // Funzione per verificare se la generazione è in corso
  const checkGenerationStatus = useCallback(async () => {
    if (!party) return;
    
    try {
      // Verifica se la generazione è in corso
      const generationStatus = await partyNewsService.isNewsGenerationInProgress(party.id);
      
      if (generationStatus.inProgress) {
        // La generazione è ancora in corso
        setWaitingForGeneration(true);
        setElapsedSeconds(generationStatus.elapsedSeconds);
      } else {
        // La generazione è terminata o non è mai iniziata
        if (waitingForGeneration) {
          // Se stavamo aspettando, carichiamo le notizie
          loadNews();
        }
        
        // Ferma il polling
        if (pollingId !== null) {
          clearInterval(pollingId);
          setPollingId(null);
        }
        
        setWaitingForGeneration(false);
        setElapsedSeconds(0);
      }
    } catch (error) {
      console.error('Error checking generation status:', error);
      // In caso di errore, assumiamo che la generazione sia terminata
      setWaitingForGeneration(false);
      setElapsedSeconds(0);
    }
  }, [party, waitingForGeneration, pollingId]);

  // Funzione per calcolare i giorni rimanenti prima dell'aggiornamento
  const calculateDaysUntilUpdate = useCallback((lastNewsDate: string) => {
    const lastDate = new Date(lastNewsDate);
    const now = new Date();
    const daysSinceLastUpdate = (now.getTime() - lastDate.getTime()) / (1000 * 60 * 60 * 24);
    const daysRemaining = Math.max(0, Math.ceil(UPDATE_FREQUENCY - daysSinceLastUpdate));
    return daysRemaining;
  }, []);

  // Funzione per caricare le notizie
  const loadNews = useCallback(async () => {
    if (!party) return false;
    
    try {
      setLoading(true);
      setError(null);
      
      // Carica le notizie dal database
      const result = await partyNewsService.getNews(party, 20);
      
      if (result.error) {
        console.error('Error fetching news:', result.error);
        setError('Errore nel caricamento delle notizie');
        return false;
      } else if (result.data && result.data.length > 0) {
        setNewsItems(result.data);
        setNeedsUpdate(result.needsUpdate);
        
        // Calcola i giorni rimanenti prima dell'aggiornamento
        const daysRemaining = calculateDaysUntilUpdate(result.data[0].news_date);
        setDaysUntilUpdate(daysRemaining);
        
        return true;
      } else {
        setNewsItems([]);
        setNeedsUpdate(true);
        setDaysUntilUpdate(0);
        return false;
      }
    } catch (error) {
      console.error('Error loading news:', error);
      setError('Errore nel caricamento delle notizie');
      return false;
    } finally {
      setLoading(false);
    }
  }, [party, calculateDaysUntilUpdate]);

  // Funzione per generare nuove notizie
  const generateNews = useCallback(async () => {
    if (!party) return;
    
    try {
      setIsGenerating(true);
      setError(null);
      
      // Tenta di generare nuove notizie
      const news = await partyNewsService.getTodayNews(party);
      
      if (news) {
        // Notizie generate con successo
        await loadNews();
      } else {
        try {
          // Verifica se la generazione è in corso da parte di un altro utente
          const generationStatus = await partyNewsService.isNewsGenerationInProgress(party.id);
          
          if (generationStatus.inProgress) {
            // La generazione è in corso da parte di un altro utente
            setWaitingForGeneration(true);
            setElapsedSeconds(generationStatus.elapsedSeconds);
            
            // Avvia il polling per verificare periodicamente lo stato
            if (pollingId === null) {
              const id = window.setInterval(checkGenerationStatus, POLLING_INTERVAL);
              setPollingId(id);
            }
          } else {
            // Nessuna generazione in corso, probabilmente c'è stato un errore
            setError('Errore nella generazione delle notizie');
          }
        } catch (error) {
          console.error('Error checking news generation status:', error);
          setError('Errore nella verifica dello stato di generazione delle notizie');
        }
      }
    } catch (error) {
      console.error('Error generating news:', error);
      setError('Errore nella generazione delle notizie');
    } finally {
      if (!waitingForGeneration) {
        setIsGenerating(false);
      }
    }
  }, [party, loadNews, checkGenerationStatus, pollingId]);

  // Carica le notizie all'avvio o quando cambia il partito
  useEffect(() => {
    if (party) {
      loadNews().then(async (hasNews) => {
        // Se non ci sono notizie o se le notizie necessitano di aggiornamento e non è in corso una generazione
        if ((!hasNews || needsUpdate) && !waitingForGeneration && !isGenerating) {
          await generateNews();
        }
      });
    }
    
    // Pulizia al dismount
    return () => {
      if (pollingId !== null) {
        clearInterval(pollingId);
      }
    };
  }, [party, loadNews, generateNews, pollingId, waitingForGeneration, needsUpdate, isGenerating]);

  if (!party) {
    return null;
  }

  // Formatta la data in formato italiano
  const formatDate = (dateString: string | null) => {
    if (!dateString) return '';
    
    try {
      // Controlla se la data è nel formato GG/MM/AAAA
      if (dateString.includes('/')) {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`).toLocaleDateString('it-IT', {
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        });
      }
      
      // Altrimenti prova a parsare la data normalmente
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        // Se non è una data valida, restituisci la stringa originale
        return dateString;
      }
      
      return date.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return dateString; // Restituisci la stringa originale in caso di errore
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: theme.palette.background.default,
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        borderRadius: 0
      }}
    >
      {/* Header with logo and close button */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={party?.logo_url || '/placeholder.png'}
            alt={party?.name}
            sx={{ width: 50, height: 50, mr: 2 }}
          />
          <Box>
            <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
              {party?.name}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={onClose} sx={{ color: theme.palette.text.primary }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* News Content */}
      <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          mb: 2,
          pb: 1,
          borderBottom: `1px solid ${theme.palette.divider}`
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <NewspaperIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
            <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
              Ultime notizie
            </Typography>
          </Box>
        </Box>
        
        {loading || isGenerating ? (
          <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            minHeight: '200px',
            my: 4,
            p: 3,
          }}>
            <CircularProgress size={60} thickness={4} />
            <Typography variant="h6" sx={{ mt: 3, mb: 1, color: theme.palette.primary.main, fontWeight: 'bold' }}>
              {isGenerating ? 'Generazione notizie in corso...' : 'Caricamento notizie...'}
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, textAlign: 'center', maxWidth: '80%' }}>
              {isGenerating ? 
                'Stiamo cercando e analizzando le ultime notizie per questo partito. Questo processo potrebbe richiedere alcuni secondi.' : 
                'Stiamo caricando le notizie più recenti dal database.'}
            </Typography>
            {isGenerating && (
              <Box sx={{ mt: 3, width: '100%', maxWidth: '400px' }}>
                <LinearProgress 
                  color="primary" 
                  sx={{ 
                    height: 4, 
                    borderRadius: 2
                  }} 
                />
                <Typography variant="caption" sx={{ mt: 1, display: 'block', textAlign: 'center', color: theme.palette.text.secondary }}>
                  Questo processo potrebbe richiedere fino a 30 secondi
                </Typography>
              </Box>
            )}
          </Box>
        ) : waitingForGeneration ? (
          <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center',
            minHeight: '200px',
            my: 4,
            p: 3,
          }}>
            <CircularProgress size={60} thickness={4} />
            <Typography variant="h6" sx={{ mt: 3, mb: 1, color: theme.palette.primary.main, fontWeight: 'bold' }}>
              In attesa della generazione delle notizie...
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, textAlign: 'center', maxWidth: '80%' }}>
              La generazione delle notizie è in corso da parte di un altro utente. Attendiamo il completamento del processo.
            </Typography>
            <Box sx={{ mt: 3, width: '100%', maxWidth: '400px' }}>
              <LinearProgress 
                color="primary" 
                sx={{ 
                  height: 4, 
                  borderRadius: 2
                }} 
                variant="determinate"
                value={Math.min((elapsedSeconds / 120) * 100, 100)} // Massimo 120 secondi (2 minuti)
              />
              <Typography variant="caption" sx={{ mt: 1, display: 'block', textAlign: 'center', color: theme.palette.text.secondary }}>
                Tempo trascorso: {elapsedSeconds} secondi
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box>
            {newsItems.length > 0 ? (
              <>
                {newsItems.map((newsItem, index) => (
                  <React.Fragment key={newsItem.id}>
                    <Box sx={{ mb: 3 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                        <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary }}>
                          Aggiornato al: {newsItem.news_date ? formatDate(newsItem.news_date) : 'N/A'}
                        </Typography>
                        
                        {/* Indicatore dei giorni mancanti all'aggiornamento */}
                        {index === 0 && (
                          <Typography 
                            variant="subtitle2" 
                            sx={{ 
                              color: daysUntilUpdate > 0 ? theme.palette.info.main : theme.palette.primary.main,
                              fontWeight: 'medium',
                              mt: 0.5
                            }}
                          >
                            {daysUntilUpdate > 0 
                              ? `Prossimo aggiornamento tra ${daysUntilUpdate} ${daysUntilUpdate === 1 ? 'giorno' : 'giorni'}`
                              : 'Aggiornamento disponibile oggi'}
                          </Typography>
                        )}
                      </Box>
                      
                      {/* Parsing e formattazione del contenuto */}
                      {(() => {
                        try {
                          // Tenta di analizzare il contenuto come JSON
                          const newsData = JSON.parse(newsItem.content);
                          
                          // Se abbiamo un oggetto JSON valido con articoli, renderizzali
                          if (newsData && newsData.articles && Array.isArray(newsData.articles)) {
                            return (
                              <Box>
                                {newsData.articles.map((article: NewsArticle, articleIndex: number) => (
                                  <React.Fragment key={`article-${articleIndex}`}>
                                    {articleIndex > 0 && <Divider sx={{ my: 2 }} />}
                                    
                                    {/* Titolo dell'articolo */}
                                    <Typography 
                                      variant="h6" 
                                      sx={{ 
                                        mt: articleIndex > 0 ? 3 : 0, 
                                        mb: 1, 
                                        fontWeight: 'bold',
                                        color: theme.palette.primary.main
                                      }}
                                    >
                                      {article.title}
                                    </Typography>
                                    
                                    {/* Contenuto dell'articolo */}
                                    <Typography 
                                      variant="body1" 
                                      sx={{ 
                                        mb: 1,
                                        color: theme.palette.text.primary
                                      }}
                                    >
                                      {article.content}
                                    </Typography>
                                    
                                    {/* Fonte e data dell'articolo sulla stessa riga */}
                                    {(article.source || article.date) && (
                                      <Typography 
                                        variant="caption" 
                                        sx={{ 
                                          display: 'block', 
                                          mb: 2,
                                          color: theme.palette.text.secondary,
                                          fontStyle: 'italic'
                                        }}
                                      >
                                        {article.date && (
                                          <>Data: {formatDate(article.date)}</>
                                        )}
                                        
                                        {article.date && article.source && (
                                          <> - </>
                                        )}
                                        
                                        {article.source && (
                                          <>
                                            Fonte: {article.url ? (
                                              <Link 
                                                href={article.url} 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                sx={{ 
                                                  color: theme.palette.primary.main,
                                                  textDecoration: 'underline',
                                                  '&:hover': {
                                                    textDecoration: 'none'
                                                  }
                                                }}
                                              >
                                                {article.source}
                                              </Link>
                                            ) : (
                                              article.source
                                            )}
                                          </>
                                        )}
                                      </Typography>
                                    )}
                                  </React.Fragment>
                                ))}
                              </Box>
                            );
                          }
                        } catch (error) {
                          // Se non è possibile analizzare come JSON, utilizza il formato precedente
                          console.log('Unable to parse news content as JSON, using fallback rendering');
                        }
                        
                        // Fallback: rendering del formato precedente
                        return (
                          <>
                            {newsItem.content.split('\n\n').map((paragraph: string, pIndex: number) => {
                              // Controlla se il paragrafo è un titolo (inizia con #)
                              if (paragraph.trim().startsWith('#')) {
                                // Rimuove il # all'inizio e alla fine se presente
                                let title = paragraph.trim().substring(1);
                                
                                // Rimuove il # alla fine se presente
                                if (title.endsWith('#')) {
                                  title = title.substring(0, title.length - 1);
                                }
                                
                                return (
                                  <Typography 
                                    key={`title-${pIndex}`}
                                    variant="h6" 
                                    sx={{ 
                                      mt: pIndex > 0 ? 3 : 0, 
                                      mb: 1, 
                                      fontWeight: 'bold',
                                      color: theme.palette.primary.main
                                    }}
                                  >
                                    {title.trim()}
                                  </Typography>
                                );
                              }
                              // Controlla se il paragrafo è una fonte (inizia con "Fonte:")
                              else if (paragraph.trim().startsWith('Fonte:')) {
                                return (
                                  <Typography 
                                    key={`source-${pIndex}`}
                                    variant="caption" 
                                    sx={{ 
                                      display: 'block', 
                                      mb: 2,
                                      color: theme.palette.text.secondary,
                                      fontStyle: 'italic'
                                    }}
                                  >
                                    {paragraph.trim()}
                                  </Typography>
                                );
                              }
                              // Controlla se il paragrafo è un separatore
                              else if (paragraph.trim() === '---') {
                                return <Divider key={`divider-${pIndex}`} sx={{ my: 2 }} />;
                              }
                              // Altrimenti è un paragrafo normale
                              else {
                                return (
                                  <Typography 
                                    key={`paragraph-${pIndex}`}
                                    variant="body1" 
                                    sx={{ 
                                      mb: 1,
                                      color: theme.palette.text.primary
                                    }}
                                  >
                                    {paragraph.trim()}
                                  </Typography>
                                );
                              }
                            })}
                          </>
                        );
                      })()}
                    </Box>
                  </React.Fragment>
                ))}
              </>
            ) : (
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                  Nessuna notizia trovata per questo partito.
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default PartyCardNews;
