import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Chip,
  Stack,
  Paper,
  MenuItem,
  Tooltip,
  CircularProgress,
  FormControlLabel,
  Switch
} from '@mui/material';
import { 
  Add as AddIcon, 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  Search as SearchIcon,
  Male as MaleIcon,
  Female as FemaleIcon,
  Transgender as TransgenderIcon,
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  CloudDownload as ImportIcon,
  Close as CloseIcon,
  Person as PersonIcon,
  Face as FaceIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import { Politician, Party, Place, PoliticalOffice } from '../../../types/database.types';
import { politicianService } from '../../../services/politicianService';
import { politicalOfficeService } from '../../../services/politicalOfficeService';
import { userService } from '../../../services/userService';
import { AdminPoliticianDialog } from './AdminPoliticianDialog';
import { supabase } from '../../../lib/supabaseClient';
import { openaiService } from '../../../services/openaiService';
import { caricatureService } from '../../../services/caricatureService';
import { CaricatureConfirmDialog } from './CaricatureConfirmDialog';
import { useAuth } from '../../../hooks/useAuth';

export const AdminPoliticianList: React.FC<{ selectedPartyId?: string | null, onClearFilter?: () => void }> = ({ 
  selectedPartyId,
  onClearFilter 
}) => {
  const { session, isAdmin } = useAuth();
  const [politicians, setPoliticians] = useState<Politician[]>([]);
  const [selectedPolitician, setSelectedPolitician] = useState<Politician | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [importText, setImportText] = useState('');
  const [importing, setImporting] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [totalCount, setTotalCount] = useState(0);
  const [expandedDescriptions, setExpandedDescriptions] = useState<Record<string, boolean>>({});
  const [generatingCaricature, setGeneratingCaricature] = useState<Record<string, boolean>>({});
  const [showNoParty, setShowNoParty] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>('Tutti');
  const [caricatureConfirmOpen, setCaricatureConfirmOpen] = useState(false);
  const [politicianForCaricature, setPoliticianForCaricature] = useState<Politician | null>(null);

  useEffect(() => {
    const checkAdminRole = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setCurrentUser(user);
      }
    };

    checkAdminRole();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  useEffect(() => {
    fetchPoliticians();
  }, [page, rowsPerPage, debouncedSearchQuery, showNoParty, selectedRole]);

  const fetchPoliticians = async () => {
    try {
      console.log('Fetching politicians with filters:', {
        showNoParty,
        page,
        searchQuery: debouncedSearchQuery,
        selectedRole
      });
      setLoading(true);
      setError(null);

      let query;

      // Query with office filters (uses inner join)
      if (selectedRole !== 'Tutti') {
        query = supabase
          .from('politicians')
          .select(`
            *,
            party:parties!politicians_party_id_fkey(*),
            birth_place:places!birth_place_id(*),
            politician_offices!inner(
              id,
              political_office:political_offices!inner(
                id,
                title
              )
            )
          `, { count: 'exact' });

        let searchTerm = '';
        switch (selectedRole) {
          case 'Presidenti di Provincia':
            searchTerm = 'Presidente della Provincia';
            break;
          case 'Presidenti di Regione':
            searchTerm = 'Presidente di Regione';
            break;
          case 'Deputati':
            searchTerm = 'Deputato';
            break;
          case 'Senatori':
            searchTerm = 'Senatore';
            break;
          case 'Sindaci':
            searchTerm = 'Sindaco';
            break;
        }
        query = query.filter('politician_offices.political_office.title', 'ilike', `%${searchTerm}%`);
      } 
      // Query without office filters (no join with offices)
      else {
        query = supabase
          .from('politicians')
          .select(`
            *,
            party:parties!politicians_party_id_fkey(*),
            birth_place:places!birth_place_id(*),
            politician_offices(
              id,
              political_office:political_offices(
                id,
                title
              )
            )
          `, { count: 'exact' });
      }

      // Apply no party filter if selected
      if (showNoParty) {
        query = query.is('party_id', null);
      }

      // Apply party filter if selected
      if (selectedPartyId) {
        query = query.eq('party_id', selectedPartyId);
      }

      // Apply search filter at database level
      if (debouncedSearchQuery.trim()) {
        const searchTerms = debouncedSearchQuery.trim().split(' ');
        const searchConditions = searchTerms.map(term => 
          `or(first_name.ilike.%${term}%,last_name.ilike.%${term}%)`
        ).join(',');
        query = query.or(searchConditions);
      }

      // Get total count first
      const countQuery = await query;
      if (countQuery.error) throw countQuery.error;
      console.log('Count query result:', countQuery.count);
      setTotalCount(countQuery.count || 0);

      // Then get paginated data
      const from = page * rowsPerPage;
      const to = from + rowsPerPage - 1;
      
      const { data, error } = await query
        .order('last_name', { ascending: true })
        .range(from, to);

      if (error) throw error;
      
      console.log('Query result:', {
        data,
        filters: {
          showNoParty,
          selectedPartyId,
          selectedRole
        }
      });

      setPoliticians(data || []);

    } catch (error: any) {
      console.error('Error fetching politicians:', error);
      setError(error.message || 'Failed to load politicians');
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    setSelectedPolitician(null);
    setOpenDialog(true);
  };

  const handleEditPolitician = async (politician: Politician, e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    try {
      console.log('Fetching politician by ID:', politician.id);
      const fullPolitician = await politicianService.getPoliticianById(politician.id);
      if (fullPolitician) {
        console.log('Opening dialog with politician data:', fullPolitician);
        setSelectedPolitician(fullPolitician);
        setOpenDialog(true);
      }
    } catch (error) {
      console.error('Error loading politician details:', error);
      setError('Failed to load politician details');
    }
  };

  const handleDelete = async (politician: Politician) => {
    if (!window.confirm('Are you sure you want to delete this politician?')) {
      return;
    }
    try {
      await politicianService.deletePolitician(politician.id);
      fetchPoliticians();
    } catch (error) {
      console.error('Error deleting politician:', error);
      setError('Failed to delete politician');
    }
  };

  const handleSavePolitician = async (
    politician: Omit<Politician, 'id'> & { id?: string }, 
    politicianOffices: { political_office_id: string; assigned_place_id?: string }[]
  ): Promise<void> => {
    try {
      if (selectedPolitician) {
        // For updates, we know we have an ID
        await politicianService.updatePolitician(selectedPolitician.id, politician);
        await politicalOfficeService.updatePoliticianOffices(selectedPolitician.id, politicianOffices);
      } else {
        // For creation, omit the id field
        const { id: _, ...politicianWithoutId } = politician;
        const newPolitician = await politicianService.createPolitician(politicianWithoutId);
        await politicalOfficeService.updatePoliticianOffices(newPolitician.id, politicianOffices);
      }
      await fetchPoliticians();
      setOpenDialog(false);
      setSelectedPolitician(null);
    } catch (error) {
      console.error('Error updating politician:', error);
      throw error;
    }
  };

  const handleImport = async () => {
    setImporting(true);
    try {
      const politicianNames = importText.split('\n').filter(name => name.trim());
      
      for (const name of politicianNames) {
        console.log('Fetching information for politician:', name);
        const politicianInfo = await openaiService.searchPoliticianInformation(name);
        console.log('Received politician info:', politicianInfo);
        
        try {
          await politicianService.createPolitician(politicianInfo);
        } catch (createError) {
          console.error(`Error creating politician ${name}:`, createError);
        }
      }
      
      await fetchPoliticians();
      setImportDialogOpen(false);
      setImportText('');
    } catch (error) {
      console.error('Error importing politicians:', error);
      setError('Failed to import politicians');
    } finally {
      setImporting(false);
    }
  };

  const handleGenerateCaricature = async (politician: Politician, e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setPoliticianForCaricature(politician);
    setCaricatureConfirmOpen(true);
  };

  const handleConfirmCaricature = async () => {
    if (!politicianForCaricature || !session?.user?.id) return;

    try {
      setCaricatureConfirmOpen(false);
      setGeneratingCaricature(prev => ({ ...prev, [politicianForCaricature.id]: true }));
      await caricatureService.generatePoliticianCaricature(politicianForCaricature, session.user.id);
      await fetchPoliticians(); // Refresh the list to show the new image
    } catch (error) {
      console.error('Error generating caricature:', error);
      if (error instanceof Error && error.message === 'Caricatura già in fase di sviluppo') {
        setError('Caricatura già in fase di sviluppo da un altro utente');
      } else {
        setError(error instanceof Error ? error.message : 'Failed to generate caricature');
      }
    } finally {
      setGeneratingCaricature(prev => ({ ...prev, [politicianForCaricature.id]: false }));
      setPoliticianForCaricature(null);
    }
  };

  const handleCloseCaricatureConfirm = () => {
    setCaricatureConfirmOpen(false);
    setPoliticianForCaricature(null);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedPolitician(null);
    setError(null);
  };

  const calculateAge = (birthDate: string | null | undefined): string => {
    if (!birthDate) return '';
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    
    return `${age} anni`;
  };

  const toggleDescription = (politicianId: string) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [politicianId]: !prev[politicianId]
    }));
  };

  const renderPoliticianCard = (politician: Politician) => {
    const isExpanded = expandedDescriptions[politician.id] || false;
    const maxLength = 100; // Show first 100 characters

    return (
      <Card 
        sx={{ 
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        }}
      >
        <Box sx={{ position: 'relative' }}>
          {generatingCaricature[politician.id] ? (
            <Box 
              sx={{ 
                height: 140, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                bgcolor: 'rgba(0, 0, 0, 0.1)'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <CardMedia
              component="img"
              height="140"
              image={politician.image_url || '/placeholder-politician.png'}
              alt={`${politician.first_name} ${politician.last_name}`}
              sx={{ objectFit: 'cover' }}
            />
          )}
        </Box>
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h6" component="div" sx={{ mb: 0 }}>
            {politician.first_name} {politician.last_name}
          </Typography>
          
          {politician.party && (
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              {politician.party.name}
            </Typography>
          )}

          {politician.description && (
            <>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ 
                  mb: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: isExpanded ? 'unset' : 3,
                  WebkitBoxOrient: 'vertical',
                  cursor: 'pointer'
                }}
                onClick={() => toggleDescription(politician.id)}
              >
                {politician.description}
              </Typography>
              {politician.description.length > maxLength && (
                <Button 
                  size="small" 
                  onClick={() => toggleDescription(politician.id)}
                  sx={{ p: 0, minWidth: 'auto', textTransform: 'none' }}
                >
                  {isExpanded ? 'Mostra meno' : 'Mostra tutto'}
                </Button>
              )}
            </>
          )}

          {politician.birth_date && politician.birth_place && (
            <Typography variant="body2" color="text.secondary">
              {calculateAge(politician.birth_date)} · {politician.birth_place.name}
            </Typography>
          )}
          {politician.politician_offices && politician.politician_offices.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
              {politician.politician_offices.map((office: any) => {
                if (!office?.political_office?.title) return null;
                return (
                  <Chip 
                    key={office.id}
                    label={office.political_office.title} 
                    size="small" 
                    color="primary"
                    variant="outlined"
                    sx={{ 
                      borderRadius: 1,
                      height: '24px',
                      fontSize: '0.75rem'
                    }}
                  />
                );
              })}
            </Box>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          {!politician.image_url && (
            <Tooltip title="Trova foto e genera caricatura">
              <IconButton 
                size="small" 
                onClick={(e) => handleGenerateCaricature(politician, e)}
                color="primary"
              >
                <FaceIcon />
              </IconButton>
            </Tooltip>
          )}
          {isAdmin && (
            <>
              <IconButton 
                size="small" 
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleEditPolitician(politician, e);
                }}
                color="primary"
              >
                <EditIcon />
              </IconButton>
              <IconButton 
                size="small" 
                onClick={() => handleDelete(politician)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </CardActions>
      </Card>
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset to first page when search changes
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          alignItems: 'center',
          flexWrap: 'wrap'
        }}>
          <TextField
            size="small"
            placeholder="Cerca politici..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setPage(0);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 300 }}
          />
          <TextField
            select
            size="small"
            label="Filtra per carica"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            sx={{ minWidth: 200 }}
          >
            <MenuItem value="Tutti">Tutti</MenuItem>
            <MenuItem value="Presidenti di Provincia">Presidenti di Provincia</MenuItem>
            <MenuItem value="Presidenti di Regione">Presidenti di Regione</MenuItem>
            <MenuItem value="Deputati">Deputati</MenuItem>
            <MenuItem value="Senatori">Senatori</MenuItem>
            <MenuItem value="Sindaci">Sindaci</MenuItem>
          </TextField>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Switch
              checked={showNoParty}
              onChange={(e) => setShowNoParty(e.target.checked)}
              size="small"
            />
            <Tooltip title="Mostra solo politici senza partito" arrow>
              <InfoIcon fontSize="small" sx={{ color: 'text.secondary' }} />
            </Tooltip>
          </Box>
          {isAdmin && (
            <>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAdd}
                sx={{ ml: 'auto' }}
              >
                Aggiungi Politico
              </Button>
              <Button
                variant="outlined"
                startIcon={<ImportIcon />}
                onClick={() => setImportDialogOpen(true)}
              >
                Importa
              </Button>
            </>
          )}
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        {politicians.map((politician) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={politician.id}>
            {renderPoliticianCard(politician)}
          </Grid>
        ))}
      </Grid>

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
        <Stack spacing={2}>
          <Typography variant="body2" color="text.secondary">
            {`${Math.min(page * rowsPerPage + 1, totalCount)}-${Math.min((page + 1) * rowsPerPage, totalCount)} di ${totalCount} politici`}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button
              disabled={page === 0}
              onClick={(e) => handleChangePage(e, page - 1)}
            >
              Precedente
            </Button>
            <Typography variant="body1">
              Pagina {page + 1}
            </Typography>
            <Button
              disabled={(page + 1) * rowsPerPage >= totalCount}
              onClick={(e) => handleChangePage(e, page + 1)}
            >
              Successiva
            </Button>
          </Box>
        </Stack>
      </Box>

      <AdminPoliticianDialog
        open={openDialog}
        politician={selectedPolitician}
        onClose={handleClose}
        onSave={handleSavePolitician}
      />

      <CaricatureConfirmDialog
        open={caricatureConfirmOpen}
        politician={politicianForCaricature}
        onConfirm={handleConfirmCaricature}
        onClose={handleCloseCaricatureConfirm}
      />

      {/* Import Dialog */}
      <Dialog open={importDialogOpen} onClose={() => !importing && setImportDialogOpen(false)}>
        <DialogTitle>Import Politicians</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              Enter politician names separated by commas. The system will automatically search for their information.
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={4}
              value={importText}
              onChange={(e) => setImportText(e.target.value)}
              placeholder="e.g. Giorgia Meloni, Matteo Salvini, Giuseppe Conte"
              disabled={importing}
            />
          </Box>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {importing && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImportDialogOpen(false)} disabled={importing}>
            Cancel
          </Button>
          <Button
            onClick={handleImport}
            variant="contained"
            disabled={!importText.trim() || importing}
          >
            Import
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
