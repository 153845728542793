import { supabase } from '../lib/supabaseClient';
import { Place } from '../types/database.types';

type PlaceInsert = Omit<Place, 'created_at' | 'updated_at'>;
type PlaceUpdate = Partial<PlaceInsert>;

export const placesService = {
  getPlaces: async (searchQuery?: string): Promise<Place[]> => {
    let query = supabase
      .from('places')
      .select('*');

    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      try {
        query = query.filter('name', 'ilike', `${searchLower}%`);
        console.log('Search query:', `${searchLower}%`);
      } catch (err) {
        console.error('Error in filter:', err);
        throw err;
      }
    }

    const { data, error } = await query.order('name');

    if (error) throw error;
    return data;
  },

  getPlace: async (id: string): Promise<Place> => {
    const { data, error } = await supabase
      .from('places')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  },

  createPlace: async (place: PlaceInsert): Promise<Place> => {
    const { data, error } = await supabase
      .from('places')
      .insert([place])
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  updatePlace: async (id: string, place: PlaceUpdate): Promise<Place> => {
    const { data, error } = await supabase
      .from('places')
      .update(place)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  deletePlace: async (id: string): Promise<void> => {
    const { error } = await supabase
      .from('places')
      .delete()
      .eq('id', id);

    if (error) throw error;
  },

  getActivePlaces: async (searchQuery?: string): Promise<Place[]> => {
    let query = supabase
      .from('places')
      .select('*')
      .is('ending_date', null);

    if (searchQuery) {
      const searchLower = searchQuery.toLowerCase();
      try {
        query = query.filter('name', 'ilike', `${searchLower}%`);
        console.log('Search query:', `${searchLower}%`);
      } catch (err) {
        console.error('Error in filter:', err);
        throw err;
      }
    }

    const { data, error } = await query.order('name');

    if (error) throw error;
    return data;
  },
};
