import { SxProps, Theme } from '@mui/material';

// Card background colors
export const cardColors = [
  '#e3f2fd', // Light Blue - President
  '#e8f5e9', // Light Green - Prime Minister
  '#fff3e0', // Light Orange - Regional President
  '#f3e5f5', // Light Purple - Mayor
  '#e0f7fa', // Light Cyan - Provincial President
];

// Shared card styles
export const sharedCardStyles: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 0,
  boxShadow: 'none',
  overflow: 'hidden',
  transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  '&:hover': {
    transform: 'none',
    boxShadow: 'none',
  },
  position: 'relative',
  padding: 0,
  margin: 0,
  background: (theme) => `${theme.palette.background.paper}`,
};

// Card content styles
export const cardContentStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  p: 2,
  flex: 1,
  width: '100%',
};

// Card image container styles
export const cardImageContainerStyles: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  aspectRatio: '16/9',
  boxShadow: 'none',
  marginBottom: 0,
};

// Card image styles
export const cardImageStyles: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'transform 0.5s ease',
  '&:hover': {
    transform: 'none',
  },
};

// Card text container styles
export const cardTextContainerStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  mt: 1,
};

// Card title styles
export const cardTitleStyles: SxProps<Theme> = {
  fontWeight: 'bold',
  textAlign: 'center',
  mb: 0.5,
  fontSize: '1.4rem',
  letterSpacing: '0.5px',
  color: 'text.primary',
  textShadow: '0 1px 2px rgba(0,0,0,0.05)',
};

// Card role text styles
export const cardRoleTextStyles: SxProps<Theme> = {
  color: 'text.secondary',
  textAlign: 'center',
  fontSize: '1.1rem',
  mt: 0.5,
  fontWeight: 500,
  letterSpacing: '0.3px',
};

// Generate caricature button styles
export const generateCaricatureButtonStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: '220px',
  aspectRatio: '1/1',
  borderRadius: '50%',
  bgcolor: 'rgba(0, 0, 0, 0.05)',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  mb: 4,
  mt: 2,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
  border: '3px dashed rgba(0, 0, 0, 0.1)',
  '&:hover': {
    bgcolor: 'rgba(0, 0, 0, 0.08)',
    transform: 'scale(1.03)',
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.12)',
  },
};

// Card role styles (for bottom role display)
export const cardRoleStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 2,
  bgcolor: '#212121',
  color: '#ffffff',
  borderTop: '1px solid rgba(255, 255, 255, 0.1)',
  fontSize: '1.2rem',
  mt: 'auto',
  fontWeight: 500,
  letterSpacing: '0.5px',
  backdropFilter: 'blur(10px)',
};
