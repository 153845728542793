import React from 'react';
import { Box } from '@mui/material';
import UserSearch from '../components/Search/UserSearch';

export const SearchPage: React.FC = () => {
  return (
    <Box sx={{ 
      width: '100%', 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      overflow: 'hidden',
      backgroundColor: (theme) => theme.palette.background.default
    }}>
      <UserSearch />
    </Box>
  );
};

export default SearchPage;
