import React from 'react';
import { Box, Typography } from '@mui/material';
import PhoneShowcase from './PhoneShowcase';

const AppShowcase: React.FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1200px',
        py: { xs: 6, md: 8 },
        px: { xs: 2, sm: 4 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mb: 4,
        position: 'relative',
        zIndex: 1,
        background: 'linear-gradient(145deg, #f8f8f8, #f4f4f4)', 
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
        borderRadius: '20px',
        mt: { xs: 4, md: 6 },
        mx: { xs: 2, md: 4 }
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontWeight: 'bold',
          fontSize: { xs: '2rem', sm: '2.5rem' },
          color: '#333333', 
          mb: { xs: 4, md: 6 },
          textAlign: 'center',
          textShadow: '0 1px 2px rgba(0,0,0,0.1)'
        }}
      >
        Esplora Polinder
      </Typography>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: { xs: 8, sm: 10 },
          mb: 8
        }}
      >
        {/* Phone 1 - Profilo politico con caricatura */}
        <PhoneShowcase 
          imageUrl="/image1.png"
          altText="Polinder App - Profilo di Giorgia Meloni"
          title="Profili Politici Dettagliati"
          description1="Scopri i profili dei politici italiani con rappresentazioni caricaturali uniche e informazioni essenziali come ruolo, età e città. Ogni profilo mostra chiaramente il partito di appartenenza e offre accesso rapido a statistiche, informazioni e possibilità di voto."
          description2="La navigazione semplice con tab per Home, Partiti, Politici, Statistiche e Profilo personale ti permette di esplorare facilmente l'intero panorama politico italiano."
          isReversed={false}
          darkText={true} 
        />

        {/* Phone 2 - Scheda partito */}
        <PhoneShowcase 
          imageUrl="/image2.png"
          altText="Polinder App - Scheda del partito Fratelli d'Italia"
          title="Esplora i Partiti Politici"
          description1="Visualizza informazioni dettagliate sui partiti politici italiani, inclusi logo, sigla, orientamento politico e posizione nel governo. Scopri il numero di rappresentanti in Parlamento, con dettagli su deputati e senatori."
          description2="Accedi facilmente alle statistiche e alle informazioni approfondite su ciascun partito, con la possibilità di cercare rapidamente altri partiti attraverso la barra di ricerca integrata."
          isReversed={true}
          darkText={true} 
        />

        {/* Phone 3 - Dettaglio partito */}
        <PhoneShowcase 
          imageUrl="/image3.png"
          altText="Polinder App - Dettaglio del partito Fratelli d'Italia"
          title="Informazioni Complete sui Partiti"
          description1="Accedi a dati completi sui partiti politici italiani: sigla, orientamento politico, posizione nel governo, data di fondazione e sede. La descrizione dettagliata ti offre un quadro chiaro della storia e dei valori del partito."
          description2="Leggi la storia e i principi fondanti di ciascun partito, con informazioni su fondatori, valori di riferimento e posizioni politiche su temi chiave come economia, immigrazione e sicurezza."
          isReversed={false}
          darkText={true} 
        />

        {/* Phone 4 - Valutazione competenze */}
        <PhoneShowcase 
          imageUrl="/image4.png"
          altText="Polinder App - Valutazione delle competenze di Giorgia Meloni"
          title="Valuta i Tuoi Rappresentanti"
          description1="Esprimi la tua opinione sui politici valutando le loro competenze in diverse aree chiave: integrità e coerenza, capacità decisionale e leadership, empatia e servizio pubblico, competenze comunicative e visione strategica."
          description2="Il sistema di rating a stelle ti permette di dare un feedback chiaro e misurabile sulle performance dei rappresentanti, contribuendo a creare un quadro collettivo delle loro capacità percepite dalla cittadinanza."
          isReversed={true}
          darkText={true} 
        />

        {/* Phone 5 - Notizie recenti */}
        <PhoneShowcase 
          imageUrl="/image5.png"
          altText="Polinder App - Notizie recenti su Sergio Mattarella"
          title="Aggiornamenti in Tempo Reale"
          description1="Resta informato sulle ultime attività dei rappresentanti politici con aggiornamenti quotidiani. Leggi le notizie più recenti direttamente dall'ANSA e altre fonti autorevoli, con data di pubblicazione e link alla fonte originale."
          description2="Segui gli impegni istituzionali, i viaggi ufficiali e le dichiarazioni dei politici italiani, con particolare attenzione agli eventi di rilevanza nazionale e internazionale."
          isReversed={false}
          darkText={true} 
        />

        {/* Phone 6 - Galleria politici */}
        <PhoneShowcase 
          imageUrl="/image6.png"
          altText="Polinder App - Galleria di politici italiani"
          title="Esplora il Panorama Politico"
          description1="Naviga tra i profili dei politici italiani attraverso una galleria visiva accattivante che mostra le caricature di tutti i rappresentanti. Seleziona facilmente la categoria che ti interessa: tutti i politici, deputati, senatori, presidenti o ministri."
          description2="L'interfaccia intuitiva con pulsanti colorati ti permette di filtrare rapidamente i profili in base al ruolo istituzionale, facilitando la ricerca e l'esplorazione del panorama politico italiano."
          isReversed={true}
          darkText={true} 
        />

        {/* Phone 7 - Statistiche */}
        <PhoneShowcase 
          imageUrl="/image7.png"
          altText="Polinder App - Statistiche della Camera per genere"
          title="Analisi e Statistiche Dettagliate"
          description1="Esplora dati statistici sulla composizione del Parlamento italiano attraverso grafici interattivi e visualizzazioni intuitive. Analizza la distribuzione per genere tra governo e opposizione con percentuali precise."
          description2="Approfondisci ulteriori statistiche demografiche come l'età dei rappresentanti, il livello di istruzione e l'esperienza politica, ottenendo una visione completa e trasparente della classe politica italiana."
          isReversed={false}
          darkText={true} 
        />
      </Box>
    </Box>
  );
};

export default AppShowcase;
