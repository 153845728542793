import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Skill } from '../../types/database.types';
import { skillService } from '../../services/skillService';
import { SkillDialog } from './SkillDialog';
import { useAuth } from '../../hooks/useAuth';
import { ConfirmDialog } from '../Common/ConfirmDialog';

export const SkillList: React.FC = () => {
  const { session, isAdmin } = useAuth();
  const [skills, setSkills] = useState<Skill[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const loadSkills = async () => {
    try {
      const data = await skillService.getAll();
      setSkills(data);
    } catch (err) {
      console.error('Error loading skills:', err);
      setError(err instanceof Error ? err.message : 'Failed to load skills');
    }
  };

  useEffect(() => {
    console.log('Current user:', session?.user);
    console.log('Is admin?', isAdmin);
    console.log('User metadata:', session?.user?.user_metadata);
    loadSkills();
  }, [session]);

  const handleAdd = () => {
    setSelectedSkill(null);
    setOpenDialog(true);
  };

  const handleEdit = (skill: Skill) => {
    setSelectedSkill(skill);
    setOpenDialog(true);
  };

  const handleDelete = (id: string) => {
    setDeleteId(id);
    setOpenConfirmDelete(true);
  };

  const handleConfirmDelete = async () => {
    if (!deleteId) return;

    try {
      await skillService.delete(deleteId);
      await loadSkills();
    } catch (err) {
      console.error('Error deleting skill:', err);
      setError(err instanceof Error ? err.message : 'Failed to delete skill');
    }
    setOpenConfirmDelete(false);
    setDeleteId(null);
  };

  const handleSave = async (skill: Skill) => {
    try {
      // Verifica che l'utente sia autenticato
      if (!session?.user?.id) {
        setError('Devi essere autenticato per salvare uno skill');
        return false;
      }

      if (selectedSkill) {
        // Aggiornamento di uno skill esistente
        await skillService.update(selectedSkill.id, skill);
      } else {
        // Creazione di un nuovo skill
        // Rimuovi i campi id, created_at e updated_at che verranno gestiti dal servizio
        const { id, created_at, updated_at, ...skillData } = skill;
        
        await skillService.create({
          ...skillData,
          created_by: session.user.id,
        });
      }
      await loadSkills();
      setOpenDialog(false);
      return true;
    } catch (err) {
      console.error('Error saving skill:', err);
      setError(err instanceof Error ? err.message : 'Failed to save skill');
      return false;
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5">Skills</Typography>
        {isAdmin && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAdd}
          >
            Add Skill
          </Button>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order</TableCell>
              <TableCell>Property</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Tipo</TableCell>
              {isAdmin && <TableCell align="right">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {skills.map((skill) => (
              <TableRow key={skill.id}>
                <TableCell>{skill.ord || 0}</TableCell>
                <TableCell>{skill.property}</TableCell>
                <TableCell>{skill.description}</TableCell>
                <TableCell>{skill.type || '-'}</TableCell>
                {isAdmin && (
                  <TableCell align="right">
                    <IconButton onClick={() => handleEdit(skill)}>
                      <EditIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(skill.id)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {isAdmin && (
        <>
          <SkillDialog
            open={openDialog}
            skill={selectedSkill}
            onClose={() => setOpenDialog(false)}
            onSave={handleSave}
          />

          <ConfirmDialog
            open={openConfirmDelete}
            title="Delete Skill"
            content="Are you sure you want to delete this skill?"
            onConfirm={handleConfirmDelete}
            onCancel={() => setOpenConfirmDelete(false)}
          />
        </>
      )}
    </Box>
  );
};
