import React, { useState } from 'react';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Chip,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Group as GroupIcon,
  Person as PersonIcon,
  Logout as LogoutIcon,
  WorkHistory as WorkHistoryIcon,
  Psychology as PsychologyIcon,
  LocationCity as LocationIcon,
  ChevronLeft,
  ChevronRight,
  ManageAccounts as ManageAccountsIcon,
  AccountCircle,
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useAuth } from '../../hooks/useAuth';
import { Dashboard } from '../Dashboard/Dashboard';
import { ListsView } from '../Lists/ListsView';

export const AdminLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { session } = useAuth();
  const drawerWidth = isMinimized ? 65 : 240;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMinimizeToggle = () => {
    setIsMinimized(!isMinimized);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    setMobileOpen(false);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem('supabase.auth.token');
      sessionStorage.removeItem('supabase.auth.token');
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/');
      handleClose();
    } catch (error) {
      console.error('Error signing out:', error);
      navigate('/');
    }
  };

  const drawer = (
    <Box>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', minHeight: '64px' }}>
        {!isMinimized && (
          <Typography variant="h6" noWrap component="div">
            Admin Menu
          </Typography>
        )}
        <IconButton onClick={handleMinimizeToggle}>
          {isMinimized ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton 
            onClick={() => handleNavigation('/app/admin/dashboard')} 
            selected={location.pathname === '/app/admin/dashboard'}
            sx={{ 
              minHeight: 48,
              justifyContent: isMinimized ? 'center' : 'initial',
              px: 2.5,
            }}
          >
            <ListItemIcon sx={{ 
              minWidth: 0,
              mr: isMinimized ? 0 : 3,
              justifyContent: 'center',
            }}>
              <img src="/favicon.png" alt="Polinder" style={{ width: 24, height: 24, objectFit: 'contain' }} />
            </ListItemIcon>
            {!isMinimized && <ListItemText primary="Dashboard" />}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton 
            onClick={() => handleNavigation('/app/admin/parties')} 
            selected={location.pathname === '/app/admin/parties'}
            sx={{ 
              minHeight: 48,
              justifyContent: isMinimized ? 'center' : 'initial',
              px: 2.5,
            }}
          >
            <ListItemIcon sx={{ 
              minWidth: 0,
              mr: isMinimized ? 0 : 3,
              justifyContent: 'center',
            }}>
              <GroupIcon />
            </ListItemIcon>
            {!isMinimized && <ListItemText primary="Parties" />}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton 
            onClick={() => handleNavigation('/app/admin/politicians')} 
            selected={location.pathname === '/app/admin/politicians'}
            sx={{ 
              minHeight: 48,
              justifyContent: isMinimized ? 'center' : 'initial',
              px: 2.5,
            }}
          >
            <ListItemIcon sx={{ 
              minWidth: 0,
              mr: isMinimized ? 0 : 3,
              justifyContent: 'center',
            }}>
              <PersonIcon />
            </ListItemIcon>
            {!isMinimized && <ListItemText primary="Politicians" />}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton 
            onClick={() => handleNavigation('/app/admin/political-offices')} 
            selected={location.pathname === '/app/admin/political-offices'}
            sx={{ 
              minHeight: 48,
              justifyContent: isMinimized ? 'center' : 'initial',
              px: 2.5,
            }}
          >
            <ListItemIcon sx={{ 
              minWidth: 0,
              mr: isMinimized ? 0 : 3,
              justifyContent: 'center',
            }}>
              <WorkHistoryIcon />
            </ListItemIcon>
            {!isMinimized && <ListItemText primary="Political Offices" />}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton 
            onClick={() => handleNavigation('/app/admin/places')} 
            selected={location.pathname === '/app/admin/places'}
            sx={{ 
              minHeight: 48,
              justifyContent: isMinimized ? 'center' : 'initial',
              px: 2.5,
            }}
          >
            <ListItemIcon sx={{ 
              minWidth: 0,
              mr: isMinimized ? 0 : 3,
              justifyContent: 'center',
            }}>
              <LocationIcon />
            </ListItemIcon>
            {!isMinimized && <ListItemText primary="Places" />}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton 
            onClick={() => handleNavigation('/app/admin/skills')} 
            selected={location.pathname === '/app/admin/skills'}
            sx={{ 
              minHeight: 48,
              justifyContent: isMinimized ? 'center' : 'initial',
              px: 2.5,
            }}
          >
            <ListItemIcon sx={{ 
              minWidth: 0,
              mr: isMinimized ? 0 : 3,
              justifyContent: 'center',
            }}>
              <PsychologyIcon />
            </ListItemIcon>
            {!isMinimized && <ListItemText primary="Skills" />}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }} className="admin-portal">
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2 } }}>
            <IconButton
              color="inherit"
              onClick={() => navigate('/app/admin')}
              edge="start"
              sx={{ 
                p: 0.5,
                '& img': {
                  width: 24,
                  height: 24,
                  objectFit: 'contain'
                }
              }}
            >
              <img src="/favicon.png" alt="Polinder" />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                display: { xs: 'none', sm: 'block' },
                fontWeight: 500,
                letterSpacing: '0.5px'
              }}
            >
              Polinder Admin
            </Typography>
          </Box>
          {session && (
            <Stack direction="row" spacing={2} alignItems="center">
              <IconButton
                onClick={handleMenu}
                color="inherit"
                size="small"
                sx={{ p: 0 }}
              >
                <AccountCircle 
                  sx={{ 
                    color: 'white',
                    fontSize: '2rem'
                  }} 
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => { handleClose(); navigate('/app/user/profile'); }}>
                  <AccountCircle sx={{ mr: 1 }} /> Profile
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <LogoutIcon sx={{ mr: 1 }} /> Logout
                </MenuItem>
              </Menu>
            </Stack>
          )}
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth,
              transition: 'width 0.2s ease-in-out'
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: drawerWidth,
              transition: 'width 0.2s ease-in-out'
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          overflow: 'auto', // Add overflow auto to enable scrolling
          height: '100vh', // Set a height to make overflow work
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};
