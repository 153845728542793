import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  Avatar,
  Button,
  TextField,
  CircularProgress,
  Alert,
  Stack,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@mui/material';
import { Person as PersonIcon, DeleteForever as DeleteForeverIcon } from '@mui/icons-material';
import { supabase } from '../../../lib/supabaseClient';
import { useAuth } from '../../../hooks/useAuth';
import { italianTranslations as t } from '../../../i18n/it';
import { placesService } from '../../../services/placesService';
import { Place } from '../../../types/database.types';
import { useNavigate } from 'react-router-dom';
import { countries } from '../../../utils/countries';

export const UserProfile: React.FC = () => {
  const { session } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [fullName, setFullName] = useState('');
  const [originalFullName, setOriginalFullName] = useState('');
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [placeInfo, setPlaceInfo] = useState<Place | null>(null);
  const [countryName, setCountryName] = useState<string | null>(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    loadUserData();
  }, []);

  useEffect(() => {
    setHasChanges(fullName !== originalFullName);
  }, [fullName, originalFullName]);

  const loadUserData = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      
      if (user) {
        console.log('User data:', user);
        console.log('User identities:', user.identities);
        console.log('User metadata:', user.user_metadata);

        const metadata = user.user_metadata || {};
        setFullName(metadata.full_name || '');
        setOriginalFullName(metadata.full_name || '');
        
        // Get avatar URL from metadata
        const avatarUrl = metadata.picture || metadata.avatar_url;
        console.log('Avatar URL:', avatarUrl);
        setAvatarUrl(avatarUrl);

        // Load place info if we have resident_place_id
        if (metadata.resident_place_id) {
          try {
            const place = await placesService.getPlace(metadata.resident_place_id);
            setPlaceInfo(place);
          } catch (error) {
            console.error('Error loading place info:', error);
          }
        }
        
        // Get country name from ISO3 code
        if (metadata.country_iso3) {
          const country = countries.find(c => c.iso3 === metadata.country_iso3);
          setCountryName(country?.name || metadata.country_name || metadata.country_iso3);
        }
      }
    } catch (error: any) {
      console.error('Error loading user data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!session?.user || !hasChanges) return;

    try {
      setSaving(true);
      setError(null);
      setSuccess(false);

      const { data, error: updateError } = await supabase.auth.updateUser({
        data: { full_name: fullName }
      });

      if (updateError) throw updateError;

      setSuccess(true);
      setOriginalFullName(fullName);
      setHasChanges(false);
    } catch (error: any) {
      console.error('Error updating user:', error);
      setError(error.message);
    } finally {
      setSaving(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (!session?.user) return;

    // Verify email matches
    if (confirmEmail !== session.user.email) {
      setError(t.profile.emailMismatch);
      return;
    }

    try {
      setDeleting(true);
      setError(null);

      // Delete user and all their data using a database function
      const { error: deleteError } = await supabase.rpc('delete_user');

      if (deleteError) throw deleteError;

      // Close delete dialog and show success dialog
      setDeleteDialogOpen(false);
      setDeleteSuccessOpen(true);

      // Wait 3 seconds before signing out and redirecting
      setTimeout(async () => {
        await supabase.auth.signOut();
        navigate('/');
      }, 3000);
    } catch (error: any) {
      console.error('Error deleting account:', error);
      setError(error.message);
      setDeleting(false);
    }
  };

  const handleDeleteDialogOpen = () => {
    setDeleteDialogOpen(true);
    setConfirmEmail('');
    setError(null);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setConfirmEmail('');
    setError(null);
  };

  const handleButtonClick = () => {
    if (hasChanges) {
      handleSave();
    } else {
      navigate('/app/user');
    }
  };

  const getGenderTranslation = (gender: string | undefined | null): string => {
    if (!gender) return '';
    const genderKey = gender.toLowerCase();
    switch (genderKey) {
      case 'male':
        return t.auth.male;
      case 'female':
        return t.auth.female;
      case 'other':
        return t.auth.other;
      default:
        return '';
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const metadata = session?.user?.user_metadata || {};

  return (
    <Box 
      sx={{ 
        width: '100vw',
        height: '100vh',
        bgcolor: 'background.default',
        p: 0,
        m: 0,
        overflow: 'auto',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}
    >
      <Container 
        maxWidth={false}
        disableGutters
        sx={{ 
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 0,
          m: 0
        }}
      >
        <Card
          elevation={0}
          sx={{
            p: { xs: 2, sm: 3 },
            width: '100%',
            minHeight: '100vh',
            height: 'auto',
            maxWidth: '100%',
            m: 0,
            borderRadius: 0,
            boxShadow: 'none',
            bgcolor: '#2c2c2c',
            color: 'white',
            overflow: 'auto',
            pb: '180px'
          }}
        >
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 3
          }}>
            <Avatar 
              src={avatarUrl || undefined}
              alt={fullName}
              imgProps={{
                referrerPolicy: "no-referrer",
                crossOrigin: "anonymous"
              }}
              sx={{ 
                width: { xs: 80, sm: 100 },
                height: { xs: 80, sm: 100 },
                bgcolor: 'primary.main',
                mb: 2,
                border: '3px solid',
                borderColor: 'primary.main'
              }}
            >
              {!avatarUrl && <PersonIcon sx={{ fontSize: { xs: 40, sm: 50 } }} />}
            </Avatar>
            <Typography 
              variant="h5" 
              component="h1" 
              gutterBottom
              sx={{
                fontWeight: 500,
                textAlign: 'center',
                fontSize: { xs: '1.5rem', sm: '1.75rem' },
                color: 'white'
              }}
            >
              Tuo Profilo
            </Typography>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {success && (
            <Alert severity="success" sx={{ mb: 3 }}>
              Profilo aggiornato con successo!
            </Alert>
          )}

          <Box 
            component="form" 
            onSubmit={(e) => {
              e.preventDefault();
              handleSave();
            }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            <TextField
              label="Nome completo"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)'
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)'
                },
                '& .MuiOutlinedInput-input': {
                  color: 'white'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.2)'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.3)'
                }
              }}
            />

            <Stack spacing={2} divider={<Divider flexItem />}>
              <Box>
                <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                  Email
                </Typography>
                <Typography variant="body1" color="white">
                  {session?.user?.email}
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                  Età
                </Typography>
                <Typography variant="body1" color="white">
                  {metadata.age || '-'}
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                  Luogo di residenza
                </Typography>
                <Typography variant="body1" color="white">
                  {placeInfo ? `${placeInfo.name}, ${placeInfo.state}` : '-'}
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                  Nazione
                </Typography>
                <Typography variant="body1" color="white">
                  {metadata.living_abroad 
                    ? (countryName || metadata.country_name || metadata.country_iso3 || '-') 
                    : 'Italia'}
                </Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2" color="rgba(255, 255, 255, 0.7)" gutterBottom>
                  Genere
                </Typography>
                <Typography variant="body1" color="white">
                  {getGenderTranslation(metadata.gender)}
                </Typography>
              </Box>
            </Stack>

            <Stack spacing={2} sx={{ mt: 4, mb: 8 }}>
              {error && <Alert severity="error">{error}</Alert>}
              {success && <Alert severity="success">{t.profile.updateSuccess}</Alert>}
              
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={saving || !hasChanges}
                sx={{
                  width: '100%',
                  py: 1.5,
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '1rem'
                }}
              >
                {saving ? <CircularProgress size={24} /> : t.common.save}
              </Button>

              <Divider sx={{ my: 2, bgcolor: 'rgba(255, 255, 255, 0.1)' }} />

              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteForeverIcon />}
                onClick={handleDeleteDialogOpen}
                disabled={deleting}
                sx={{
                  width: '100%',
                  py: 1.5,
                  borderRadius: '8px',
                  textTransform: 'none',
                  fontSize: '1rem',
                  borderColor: 'rgba(255, 82, 82, 0.5)',
                  color: 'rgb(255, 82, 82)',
                  '&:hover': {
                    borderColor: 'rgb(255, 82, 82)',
                    backgroundColor: 'rgba(255, 82, 82, 0.08)'
                  },
                  mb: 12
                }}
              >
                {deleting ? <CircularProgress size={24} /> : t.profile.deleteAccount}
              </Button>
            </Stack>
          </Box>
        </Card>

        {/* Delete Account Confirmation Dialog */}
        <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
          PaperProps={{
            sx: {
              bgcolor: '#2c2c2c',
              color: 'white',
              borderRadius: '12px'
            }
          }}
        >
          <DialogTitle id="delete-dialog-title" sx={{ color: 'white' }}>
            {t.profile.deleteAccountConfirmTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              {t.profile.deleteAccountConfirmMessage}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="confirm-email"
              label={t.profile.enterEmail}
              type="email"
              fullWidth
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              variant="outlined"
              sx={{
                mt: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)'
                },
                '& .MuiInputLabel-root': {
                  color: 'rgba(255, 255, 255, 0.7)'
                },
                '& .MuiOutlinedInput-input': {
                  color: 'white'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.2)'
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary">
              {t.common.cancel}
            </Button>
            <Button
              onClick={handleDeleteAccount}
              color="error"
              disabled={deleting || !confirmEmail}
              startIcon={deleting ? <CircularProgress size={20} /> : <DeleteForeverIcon />}
            >
              {t.profile.confirmDelete}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Success Dialog */}
        <Dialog
          open={deleteSuccessOpen}
          aria-labelledby="delete-success-dialog-title"
          aria-describedby="delete-success-dialog-description"
        >
          <DialogTitle id="delete-success-dialog-title">
            {t.profile.deleteSuccess}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-success-dialog-description">
              {t.profile.deleteSuccessMessage}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Container>
    </Box>
  );
};
