import { supabase, supabaseAdmin } from '../lib/supabaseClient';
import { PoliticianNews, Politician } from '../types/database.types';
import { openaiNewsService } from './openaiNewsService';
import { NewsServiceAdapter, NewsSearchResult } from './newsServiceAdapter';
import { NEWS_UPDATE_FREQUENCY, NEWS_GENERATION_LOCK_TIMEOUT } from '../config/newsConfig';

class PoliticianNewsService {
  // Cache per i ruoli dei politici
  private politicianRolesCache: Map<string, any[]> = new Map();
  private lastRolesCacheUpdate: Date | null = null;
  
  /**
   * Verifica se è in corso una generazione di notizie per un politico
   * @param politicianId ID del politico
   * @returns Oggetto con informazioni sullo stato della generazione
   */
  async isNewsGenerationInProgress(politicianId: string): Promise<{ inProgress: boolean; elapsedSeconds: number }> {
    try {
      // Verifica se esiste un lock nel database
      const { data, error } = await supabase
        .from('news_generation_progress')
        .select('*')
        .eq('politician_id', politicianId);
      
      if (error) {
        console.error('Error checking news generation progress:', error);
        return { inProgress: false, elapsedSeconds: 0 };
      }

      // Se non ci sono dati, non c'è una generazione in corso
      if (!data || data.length === 0) {
        return { inProgress: false, elapsedSeconds: 0 };
      }
      
      // Verifica se il lock è scaduto
      const now = new Date();
      const startTime = new Date(data[0].started_at);
      const elapsedTime = now.getTime() - startTime.getTime();
      
      if (elapsedTime > NEWS_GENERATION_LOCK_TIMEOUT) {
        // Il lock è scaduto, rimuovilo
        console.log('Lock timeout expired for politician:', politicianId);
        await this.clearNewsGenerationProgress(politicianId);
        return { inProgress: false, elapsedSeconds: 0 };
      }
      
      // Calcola i secondi trascorsi
      const elapsedSeconds = Math.floor(elapsedTime / 1000);
      return { inProgress: true, elapsedSeconds };
    } catch (error) {
      console.error('Error checking news generation progress:', error);
      return { inProgress: false, elapsedSeconds: 0 };
    }
  }
  
  /**
   * Ottieni le ultime N news per un politico specifico
   * @param politician Il politico di cui recuperare le news
   * @param limit Il numero massimo di news da recuperare (default: 20)
   * @returns Un array di notizie ordinate per data (dalla più recente)
   */
  async getNews(politician: Politician, limit: number = 20): Promise<{ data: PoliticianNews[] | null, error: any, needsUpdate: boolean }> {
    try {
      // Aggiorna la cache dei ruoli del politico
      await this.updatePoliticianRolesCache(politician.id);
      
      // Recupera le notizie dal database
      const { data, error } = await supabase
        .from('politician_news')
        .select('*')
        .eq('politician_id', politician.id)
        .order('news_date', { ascending: false })
        .limit(limit);
      
      if (error) {
        console.error('Error fetching politician news:', error);
        return { data: null, error, needsUpdate: false };
      }
      
      // Controlla se è necessario generare nuove notizie
      const needsUpdate = this.needsNewsUpdate(politician.id, data);
      
      // Restituisci i dati senza elaborazione delle fonti
      return { data, needsUpdate, error: null };
    } catch (error) {
      console.error('Error in getNews:', error);
      return { data: null, error, needsUpdate: false };
    }
  }
  
  /**
   * Ottieni le news del giorno per un politico specifico
   * Se le news non esistono per la data odierna, genera nuove news
   * Tutti gli utenti autenticati possono generare nuove news
   */
  async getTodayNews(politician: Politician): Promise<PoliticianNews | null> {
    try {
      // Verifica se è già in corso una generazione di notizie per questo politico
      const generationStatus = await this.isNewsGenerationInProgress(politician.id);
      if (generationStatus.inProgress) {
        console.log('News generation already in progress for politician:', politician.id);
        return null;
      }
      
      // Ottieni la data di oggi in formato YYYY-MM-DD
      const today = new Date().toISOString().split('T')[0];
      console.log(`Getting news for politician ${politician.id} for date ${today}`);
      
      // Genera nuove news
      console.log('Generating new news for politician:', politician.id);
      return await this.generateAndSaveNews(politician);
    } catch (error) {
      console.error('Failed to get today news:', error);
      return null;
    }
  }
  
  /**
   * Ottieni il ruolo attuale di un politico
   * @param politicianId ID del politico
   * @returns Il ruolo attuale, o null se non trovato
   */
  async getPoliticianRole(politicianId: string): Promise<string | null> {
    try {
      // Aggiorna la cache dei ruoli se necessario
      await this.updatePoliticianRolesCache(politicianId);
      
      // Cerca il ruolo nella cache
      const roles = this.politicianRolesCache.get(politicianId);
      if (roles && roles.length > 0) {
        // Ordina i ruoli per data di inizio (decrescente) e prendi il primo
        const sortedRoles = [...roles].sort((a, b) => {
          const dateA = new Date(a.start_date || '1900-01-01');
          const dateB = new Date(b.start_date || '1900-01-01');
          return dateB.getTime() - dateA.getTime();
        });
        
        // Restituisci il nome del ruolo più recente
        if (sortedRoles[0].political_office && sortedRoles[0].political_office.title) {
          console.log('Found role from political_office:', sortedRoles[0].political_office.title);
          return sortedRoles[0].political_office.title;
        }
        
        // Fallback per strutture alternative
        // @ts-ignore - Ignoriamo l'errore TypeScript perché sappiamo che questa proprietà potrebbe esistere
        if (sortedRoles[0].office && sortedRoles[0].office.title) {
          // @ts-ignore
          console.log('Found role from office property:', sortedRoles[0].office.title);
          // @ts-ignore
          return sortedRoles[0].office.title;
        }
        
        if (sortedRoles[0].role_name) {
          console.log('Found role from role_name property:', sortedRoles[0].role_name);
          return sortedRoles[0].role_name;
        }
      }
    } catch (error) {
      console.error('Error getting politician role:', error);
    }
    
    return null;
  }
  
  /**
   * Aggiorna la cache dei ruoli di un politico
   * @param politicianId ID del politico
   */
  private async updatePoliticianRolesCache(politicianId: string): Promise<void> {
    try {
      console.log('Updating politician roles cache for:', politicianId);
      
      // Utilizziamo politician_offices invece di politician_roles
      const { data: roles, error: rolesError } = await supabase
        .from('politician_offices')
        .select('*, political_office:political_offices(*), assigned_place:places(*)')
        .eq('politician_id', politicianId);
      
      if (rolesError) {
        console.error('Error fetching politician roles:', rolesError);
        return;
      }
      
      // Se non abbiamo trovato ruoli, proviamo a cercare direttamente nella tabella politicians
      if (!roles || roles.length === 0) {
        console.log('No roles found in politician_offices, checking politician table directly');
        const { data: politician, error: politicianError } = await supabase
          .from('politicians')
          .select('*')
          .eq('id', politicianId)
          .single();
        
        if (politicianError) {
          console.error('Error fetching politician:', politicianError);
          return;
        }
        
        // @ts-ignore - Ignoriamo l'errore TypeScript perché sappiamo che questa proprietà potrebbe esistere
        if (politician && politician.office && politician.office.title) {
          console.log('Found office directly in politician table');
          // Creiamo un ruolo virtuale basato sulla proprietà office
          this.politicianRolesCache.set(politicianId, [{
            // @ts-ignore
            office: politician.office
          }]);
          return;
        }
      }
      
      // Aggiorna la cache
      this.politicianRolesCache.set(politicianId, roles || []);
    } catch (error) {
      console.error('Error updating politician roles cache:', error);
    }
  }
  
  /**
   * Imposta lo stato di generazione in corso per un politico
   * @param politicianId ID del politico
   * @param userId ID dell'utente che sta generando le notizie
   */
  private async setNewsGenerationInProgress(politicianId: string, userId: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('news_generation_progress')
        .insert({
          politician_id: politicianId,
          user_id: userId,
          started_at: new Date().toISOString()
        });

      if (error) {
        console.error('Error setting news generation in progress:', error);
        // Non lanciare un errore qui, solo loggarlo e continuare
      }
    } catch (error) {
      console.error('Error setting news generation in progress:', error);
      // Non lanciare un errore qui, solo loggarlo e continuare
    }
  }

  /**
   * Rimuove lo stato di generazione in corso per un politico
   * @param politicianId ID del politico
   */
  private async clearNewsGenerationProgress(politicianId: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('news_generation_progress')
        .delete()
        .eq('politician_id', politicianId);

      if (error) {
        console.error('Error clearing news generation progress:', error);
        // Non lanciare un errore qui, solo loggarlo
      }
    } catch (error) {
      console.error('Error clearing news generation progress:', error);
      // Non lanciare un errore qui, solo loggarlo
    }
  }
  
  /**
   * Genera nuove news per un politico utilizzando OpenAI News Service e le salva nel database
   * Questa funzione può essere chiamata da qualsiasi utente autenticato
   */
  private async generateAndSaveNews(politician: Politician): Promise<PoliticianNews | null> {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No authenticated user found');
        return null;
      }
      
      // Controlla se è già in corso una generazione di notizie per questo politico
      const generationStatus = await this.isNewsGenerationInProgress(politician.id);
      if (generationStatus.inProgress) {
        console.log('News generation already in progress for politician:', politician.id);
        return null;
      }
      
      // Imposta il lock di generazione
      const userId = user?.id || 'anonymous';
      await this.setNewsGenerationInProgress(politician.id, userId);
      
      try {
        // Ottieni il ruolo politico del politico
        const role = await this.getPoliticianRole(politician.id);
        
        // Costruisci la query di ricerca per OpenAI News Service
        let searchQuery = `${politician.first_name} ${politician.last_name}`;
        
        // Aggiungi il ruolo se disponibile
        if (role) {
          searchQuery += ` ${role}`;
        }
        
        // Cerca notizie recenti utilizzando OpenAI News Service
        console.log('Searching news with query:', searchQuery);
        let newsResults: NewsSearchResult[] = [];
        try {
          const openaiResults = await openaiNewsService.searchNews(searchQuery, 3);
          newsResults = NewsServiceAdapter.convertToNewsSearchResult(openaiResults);
          console.log(`Found ${newsResults.length} news results from OpenAI News Service (last week)`);
        } catch (error) {
          console.error('Error searching news:', error);
          console.log('Raw news articles:', newsResults);
        }
        
        // Se non abbiamo trovato notizie, prova a cercare notizie più vecchie
        if (newsResults.length === 0) {
          try {
            console.log('No recent news found, searching for older news');
            const openaiResults = await openaiNewsService.searchPoliticianNews(politician, 3);
            newsResults = NewsServiceAdapter.convertToNewsSearchResult(openaiResults);
            console.log(`Found ${newsResults.length} news results from OpenAI News Service (last month)`);
          } catch (error) {
            console.error('Error searching older news:', error);
          }
        }
        
        // Se ancora non abbiamo trovato notizie, prova a cercare con una query più generica
        if (newsResults.length === 0) {
          try {
            console.log('No news found, trying with a more generic query');
            const genericQuery = `${politician.first_name} ${politician.last_name} politica italiana`;
            const openaiResults = await openaiNewsService.searchNews(genericQuery, 3);
            newsResults = NewsServiceAdapter.convertToNewsSearchResult(openaiResults);
            console.log(`Found ${newsResults.length} news results from OpenAI News Service with generic query`);
          } catch (error) {
            console.error('Error searching with generic query:', error);
          }
        }
        
        // Elabora le notizie direttamente senza OpenAI
        let newsData = null;
        
        if (newsResults.length > 0) {
          console.log('Using news articles directly from OpenAI News Service');
          
          // Prendi le prime 3 notizie (o meno se non ce ne sono abbastanza)
          const newsToUse = newsResults.slice(0, Math.min(newsResults.length, 3));
          
          // Crea un oggetto JSON con le notizie
          newsData = {
            articles: newsToUse.map(news => ({
              title: news.title,
              content: news.snippet,
              date: news.publishedDate || '',
              source: news.source,
              url: news.sourceUrl || news.source_url || news.link || ''
            }))
          };
        } else {
          // Se non ci sono notizie, crea un record con descrizione "Nessuna notizia trovata"
          console.log('No news found, creating a record with "Nessuna notizia trovata"');
          newsData = {
            articles: [{
              title: "Nessuna notizia trovata",
              content: "Non sono state trovate notizie recenti per questo politico.",
              date: "",
              source: "Sistema",
              url: ""
            }]
          };
        }
        
        if (!newsData) {
          console.error('Failed to generate news content');
          return null;
        }
        
        console.log('Generated news data:', newsData);
        
        // Salva le news nel database
        const today = new Date().toISOString().split('T')[0];
        const now = new Date().toISOString();
        
        console.log('Saving news to database for politician:', politician.id, 'date:', today);
        const { data: savedNews, error: saveError } = await supabase
          .from('politician_news')
          .insert([{
            politician_id: politician.id,
            news_date: today,
            content: JSON.stringify(newsData),
            created_at: now,
            updated_at: now,
            created_by: userId,
          }])
          .select()
          .single();
        
        if (saveError) {
          console.error('Error saving politician news:', saveError);
          return null;
        }
        
        console.log('Successfully generated and saved news:', savedNews);
        
        // Verifica che la notizia sia stata salvata correttamente
        if (!savedNews) {
          console.error('News were not saved correctly');
          return null;
        }
        
        return savedNews;
      } finally {
        // Rimuovi il lock di generazione
        await this.clearNewsGenerationProgress(politician.id);
      }
    } catch (error) {
      console.error('Failed to generate and save news:', error);
      return null;
    }
  }
  
  /**
   * Crea una notizia di fallback quando non è possibile generarne una
   * @param politician Il politico per cui creare la notizia di fallback
   * @returns La notizia di fallback creata
   */
  private async createFallbackNews(politician: Politician): Promise<PoliticianNews | null> {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const userId = user?.id || 'anonymous';
      
      const now = new Date().toISOString();
      
      const { data, error } = await supabase
        .from('politician_news')
        .insert([{
          politician_id: politician.id,
          news_date: now,
          content: "Nessuna notizia trovata per questo politico.",
          created_at: now,
          updated_at: now,
          created_by: userId
        }])
        .select()
        .single();
      
      if (error) {
        console.error('Error creating fallback news:', error);
        return null;
      }
      
      return data;
    } catch (error) {
      console.error('Error in createFallbackNews:', error);
      return null;
    }
  }
  
  /**
   * Verifica se è necessario aggiornare le notizie per un politico
   * @param politicianId ID del politico
   * @param existingNews Notizie esistenti
   * @returns true se è necessario aggiornare le notizie, false altrimenti
   */
  private needsNewsUpdate(politicianId: string, existingNews: PoliticianNews[] | null): boolean {
    // Se non ci sono notizie, è necessario generarne di nuove
    if (!existingNews || existingNews.length === 0) {
      return true;
    }
    
    // Ottieni la frequenza di aggiornamento (7 giorni fissi)
    const updateFrequency = NEWS_UPDATE_FREQUENCY;
    
    // Verifica se è passato abbastanza tempo dall'ultima notizia
    const lastNewsDate = new Date(existingNews[0].news_date);
    const now = new Date();
    const daysSinceLastUpdate = (now.getTime() - lastNewsDate.getTime()) / (1000 * 60 * 60 * 24);
    
    console.log(`Giorni dall'ultimo aggiornamento: ${daysSinceLastUpdate.toFixed(2)}, soglia: ${updateFrequency}`);
    
    return daysSinceLastUpdate >= updateFrequency;
  }
}

export const politicianNewsService = new PoliticianNewsService();
