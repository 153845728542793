import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { 
  Group as GroupIcon,
  AccountBalance as AccountBalanceIcon,
  Work as WorkIcon,
  Assignment as AssignmentIcon,
  Psychology as PsychologyIcon,
  Person as PersonIcon,
  LocationOn as LocationIcon,
  Gavel as GavelIcon,
  EmojiEvents as EmojiEventsIcon,
  Apartment as ApartmentIcon,
  Policy as PolicyIcon,
  Chair as ChairIcon
} from '@mui/icons-material';
import { supabase } from '../../lib/supabaseClient';
import { useTheme } from '@mui/material/styles';

interface CountData {
  parties: number;
  politicians: number;
  political_offices: number;
  skills: number;
  users: number;
  places: number;
  deputati: number;
  senatori: number;
  ministri: number;
  sindaci: number;
  presidenti: number;
}

interface PoliticalOffice {
  id: number;
  title: string;
}

interface PoliticianOffice {
  id: number;
  politician_id: number;
  political_office_id: number;
  political_offices: PoliticalOffice;
}

export const Dashboard: React.FC = () => {
  const [counts, setCounts] = useState<CountData>({
    parties: 0,
    politicians: 0,
    political_offices: 0,
    skills: 0,
    users: 0,
    places: 0,
    deputati: 0,
    senatori: 0,
    ministri: 0,
    sindaci: 0,
    presidenti: 0,
  });

  const theme = useTheme();

  // Funzione per contare i record in una tabella
  const fetchCount = async (table: string) => {
    const { count, error } = await supabase
      .from(table)
      .select('*', { count: 'exact', head: true });
    
    if (error) {
      console.error(`Error fetching ${table} count:`, error);
      return 0;
    }
    return count || 0;
  };

  // Funzione per verificare la struttura delle tabelle
  const checkTablesStructure = async () => {
    try {
      console.log('Checking database structure...');
      
      // Elenco delle tabelle
      const { data: tableList, error: tableError } = await supabase
        .from('pg_catalog.pg_tables')
        .select('tablename')
        .eq('schemaname', 'public');
        
      if (tableError) {
        console.error('Error fetching table list:', tableError);
      } else {
        console.log('Available tables:', tableList?.map(t => t.tablename));
      }
      
      // Verifica political_offices
      const { data: officesData, error: officesError } = await supabase
        .from('political_offices')
        .select('*')
        .limit(5);
      
      if (officesError) {
        console.error('Error checking political_offices structure:', officesError);
      } else {
        console.log('Political offices sample:', officesData);
        if (officesData && officesData.length > 0) {
          console.log('Political offices fields:', Object.keys(officesData[0]));
        }
      }

      // Verifica politicians
      const { data: politiciansData, error: politiciansError } = await supabase
        .from('politicians')
        .select('*')
        .limit(5);
      
      if (politiciansError) {
        console.error('Error checking politicians structure:', politiciansError);
      } else {
        console.log('Politicians sample:', politiciansData);
        if (politiciansData && politiciansData.length > 0) {
          console.log('Politicians fields:', Object.keys(politiciansData[0]));
        }
      }
      
      // Verifica cariche dei politici
      const { data: politicianOfficesData, error: politicianOfficesError } = await supabase
        .from('politician_offices')
        .select('*, political_office:political_offices(*), assigned_place:places(*)')
        .limit(5);
      
      if (politicianOfficesError && politicianOfficesError.code !== 'PGRST116') {
        console.error('Error checking politician_offices structure:', politicianOfficesError);
      } else if (politicianOfficesData) {
        console.log('Politician offices sample:', politicianOfficesData);
      } else {
        console.log('Table politician_offices not found, check database structure');
      }
    } catch (error) {
      console.error('Error in checkTablesStructure:', error);
    }
  };

  // Funzione per verificare la struttura specifica della tabella politician_offices
  const checkPoliticianOfficesStructure = async () => {
    try {
      console.log('Checking politician_offices structure...');
      
      // Verifica la struttura con una query di esempio
      const { data, error } = await supabase
        .from('politician_offices')
        .select(`
          id,
          politician_id,
          political_office_id,
          political_offices (
            id,
            title
          )
        `)
        .limit(5);
      
      if (error) {
        console.error('Error checking politician_offices structure:', error);
      } else {
        console.log('Politician offices sample with join:', data);
        if (data && data.length > 0) {
          console.log('Sample politician_office record:', data[0]);
          
          // Ottieni tutti i titoli unici delle cariche politiche
          const { data: titlesData, error: titlesError } = await supabase
            .from('political_offices')
            .select('title')
            .order('title');
          
          if (titlesError) {
            console.error('Error fetching political office titles:', titlesError);
          } else {
            console.log('All political office titles:', titlesData?.map(t => t.title));
          }
          
          // Verifica se ci sono politici con cariche di tipo Deputato
          const { data: deputatiData, error: deputatiError } = await supabase
            .from('politician_offices')
            .select(`
              id,
              politician_id,
              political_offices!inner (
                id,
                title
              )
            `)
            .eq('political_offices.title', 'Deputato');
          
          if (deputatiError) {
            console.error('Error checking Deputati:', deputatiError);
          } else {
            console.log(`Found ${deputatiData?.length || 0} Deputati records`);
            console.log('Sample Deputati:', deputatiData?.slice(0, 2));
          }
          
          // Verifica se ci sono politici con cariche di tipo Ministro
          const { data: ministriData, error: ministriError } = await supabase
            .from('politician_offices')
            .select(`
              id,
              politician_id,
              political_offices!inner (
                id,
                title
              )
            `)
            .ilike('political_offices.title', '%Ministro%');
          
          if (ministriError) {
            console.error('Error checking Ministri:', ministriError);
          } else {
            console.log(`Found ${ministriData?.length || 0} Ministri records`);
            console.log('Sample Ministri:', ministriData?.slice(0, 2));
          }
        }
      }
    } catch (error) {
      console.error('Error in checkPoliticianOfficesStructure:', error);
    }
  };

  // Funzione per contare i politici per tipo di carica
  const fetchPoliticiansByOfficeType = async (officeType: string) => {
    try {
      let query;
      
      // Per Ministri e Presidenti cerchiamo pattern nel titolo
      if (officeType === 'Ministro' || officeType === 'Presidente') {
        query = supabase
          .from('politician_offices')
          .select<string, PoliticianOffice>(`
            id,
            politician_id,
            political_offices!inner (
              id,
              title
            )
          `)
          .ilike('political_offices.title', `%${officeType}%`);
      } else if (officeType === 'Camera dei Deputati') {
        // Per i deputati cerchiamo il titolo esatto
        query = supabase
          .from('politician_offices')
          .select<string, PoliticianOffice>(`
            id,
            politician_id,
            political_offices!inner (
              id,
              title
            )
          `)
          .eq('political_offices.title', officeType);
      } else if (officeType === 'Senato della Repubblica') {
        // Per i senatori cerchiamo il titolo esatto
        query = supabase
          .from('politician_offices')
          .select<string, PoliticianOffice>(`
            id,
            politician_id,
            political_offices!inner (
              id,
              title
            )
          `)
          .eq('political_offices.title', officeType);
      } else {
        // Per gli altri tipi cerchiamo exact match
        query = supabase
          .from('politician_offices')
          .select<string, PoliticianOffice>(`
            id,
            politician_id,
            political_offices!inner (
              id,
              title
            )
          `)
          .eq('political_offices.title', officeType);
      }
      
      const { data, error } = await query;
      
      if (error) {
        console.error(`Error fetching ${officeType} politicians:`, error);
        return 0;
      }
      
      // Raccogliamo gli ID unici dei politici
      const uniquePoliticians = new Set<number>();
      data?.forEach((item: PoliticianOffice) => {
        if (item.politician_id) {
          uniquePoliticians.add(item.politician_id);
        }
      });
      
      console.log(`Found ${data?.length || 0} ${officeType} offices and ${uniquePoliticians.size} unique politicians`);
      if (data && data.length > 0) {
        console.log('Sample data:', data.slice(0, 3));
        const titles = data.slice(0, 3).map(d => d.political_offices?.title).filter(Boolean);
        console.log('Sample titles:', titles);
      }
      
      return uniquePoliticians.size || 0;
    } catch (error) {
      console.error(`Error in fetchPoliticiansByOfficeType for ${officeType}:`, error);
      return 0;
    }
  };

  // Funzione per ottenere tutti i conteggi delle cariche politiche
  const fetchAllPoliticalOfficesCounts = async () => {
    try {
      // Inizializziamo i conteggi a zero
      const counts = {
        deputati: 0,
        senatori: 0,
        ministri: 0,
        sindaci: 0,
        presidenti: 0
      };

      console.log('Avvio fetchAllPoliticalOfficesCounts...');

      // Query per Deputati
      const { count: deputatiCount, error: deputatiError } = await supabase
        .from('politician_offices')
        .select(`
          id,
          politician_id,
          political_offices!inner (
            id,
            title
          )
        `, { count: 'exact', head: true })
        .eq('political_offices.title', 'Deputato');
      
      if (deputatiError) {
        console.error('Errore nel conteggio dei Deputati:', deputatiError);
      } else {
        counts.deputati = deputatiCount || 0;
        console.log(`Trovati ${deputatiCount || 0} record di Deputati`);
      }

      // Query per Senatori
      const { count: senatoriCount, error: senatoriError } = await supabase
        .from('politician_offices')
        .select(`
          id,
          politician_id,
          political_offices!inner (
            id,
            title
          )
        `, { count: 'exact', head: true })
        .eq('political_offices.title', 'Senatore');
      
      if (senatoriError) {
        console.error('Errore nel conteggio dei Senatori:', senatoriError);
      } else {
        counts.senatori = senatoriCount || 0;
        console.log(`Trovati ${senatoriCount || 0} record di Senatori`);
      }

      // Query per Ministri
      const { count: ministriCount, error: ministriError } = await supabase
        .from('politician_offices')
        .select(`
          id,
          politician_id,
          political_offices!inner (
            id,
            title
          )
        `, { count: 'exact', head: true })
        .ilike('political_offices.title', '%Ministro%');
      
      if (ministriError) {
        console.error('Errore nel conteggio dei Ministri:', ministriError);
      } else {
        counts.ministri = ministriCount || 0;
        console.log(`Trovati ${ministriCount || 0} record di Ministri`);
      }

      // Query per Sindaci
      const { count: sindaciCount, error: sindaciError } = await supabase
        .from('politician_offices')
        .select(`
          id,
          politician_id,
          political_offices!inner (
            id,
            title
          )
        `, { count: 'exact', head: true })
        .eq('political_offices.title', 'Sindaco');
      
      if (sindaciError) {
        console.error('Errore nel conteggio dei Sindaci:', sindaciError);
      } else {
        counts.sindaci = sindaciCount || 0;
        console.log(`Trovati ${sindaciCount || 0} record di Sindaci`);
      }

      // Query per Presidenti
      const { count: presidentiCount, error: presidentiError } = await supabase
        .from('politician_offices')
        .select(`
          id,
          politician_id,
          political_offices!inner (
            id,
            title
          )
        `, { count: 'exact', head: true })
        .ilike('political_offices.title', '%Presidente%');
      
      if (presidentiError) {
        console.error('Errore nel conteggio dei Presidenti:', presidentiError);
      } else {
        counts.presidenti = presidentiCount || 0;
        console.log(`Trovati ${presidentiCount || 0} record di Presidenti`);
      }

      console.log('Conteggi finali dopo query dirette:', counts);
      return counts;
    } catch (error) {
      console.error('Errore in fetchAllPoliticalOfficesCounts:', error);
      // In caso di errore, restituiamo conteggi a zero
      return {
        deputati: 0,
        senatori: 0,
        ministri: 0,
        sindaci: 0,
        presidenti: 0
      };
    }
  };

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        // Verifichiamo la struttura delle tabelle
        await checkTablesStructure();
        await checkPoliticianOfficesStructure();
      } catch (error) {
        console.error('Error in fetchCounts:', error);
      }

      const partiesCount = await fetchCount('parties');
      const politiciansCount = await fetchCount('politicians');
      const politicalOfficesCount = await fetchCount('political_offices');
      const skillsCount = await fetchCount('skills');
      const placesCount = await fetchCount('places');
      
      // Fetch actual user count using the stored procedure
      let usersData = null;
      let usersError = null;
      
      try {
        const { data, error } = await supabase.rpc('get_user_count');
        usersData = data;
        usersError = error;
        
        if (error) {
          console.error('Error fetching users count:', error);
          console.error('Error details:', error.details);
          console.error('Error hint:', error.hint);
        }
      } catch (error) {
        console.error('Exception in users count:', error);
      }

      // Conteggio per tipo di carica
      const politicalOfficesCounts = await fetchAllPoliticalOfficesCounts();

      // Log the response for debugging
      console.log('Users query response:', { usersData, usersError });
      console.log('Cariche counts:', politicalOfficesCounts);

      setCounts({
        parties: partiesCount,
        politicians: politiciansCount,
        political_offices: politicalOfficesCount,
        skills: skillsCount,
        users: usersData || 0,
        places: placesCount,
        deputati: politicalOfficesCounts.deputati,
        senatori: politicalOfficesCounts.senatori,
        ministri: politicalOfficesCounts.ministri,
        sindaci: politicalOfficesCounts.sindaci,
        presidenti: politicalOfficesCounts.presidenti,
      });
    };
    fetchCounts();
  }, []);

  return (
    <Box sx={{ p: 3, bgcolor: theme.palette.background.paper }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{ 
          mb: 4,
          fontWeight: 500,
          color: '#1a1a1a'
        }}
      >
        Dashboard Overview
      </Typography>
      <Grid container spacing={3}>
        {[
          { title: 'Parties', count: counts.parties, Icon: GroupIcon },
          { title: 'Politicians', count: counts.politicians, Icon: AccountBalanceIcon },
          { title: 'Political Offices', count: counts.political_offices, Icon: WorkIcon },
          { title: 'Places', count: counts.places, Icon: LocationIcon },
          { title: 'Skills', count: counts.skills, Icon: PsychologyIcon },
          { title: 'Users', count: counts.users, Icon: PersonIcon },
        ].map(({ title, count, Icon }) => (
          <Grid key={title} item xs={12} sm={6} md={4} lg={2}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: 'white',
                borderRadius: 2,
                minHeight: 160,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Icon 
                color="primary" 
                sx={{ 
                  fontSize: 32,
                  mb: 3,
                  opacity: 0.9
                }} 
              />
              <Typography 
                variant="body1" 
                component="h2" 
                align="center"
                sx={{ 
                  mb: 2,
                  color: '#666',
                  fontSize: '0.875rem',
                  fontWeight: 400,
                }}
              >
                {title}
              </Typography>
              <Typography 
                variant="h4" 
                color="primary"
                sx={{ 
                  fontWeight: 500,
                  fontSize: '1.75rem'
                }}
              >
                {count.toLocaleString()}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Sezione per i conteggi per tipo di carica */}
      <Typography 
        variant="h5" 
        gutterBottom 
        sx={{ 
          mt: 6,
          mb: 3,
          fontWeight: 500,
          color: '#1a1a1a'
        }}
      >
        Cariche Politiche
      </Typography>
      <Grid container spacing={3}>
        {[
          { title: 'Deputati', count: counts.deputati, Icon: GavelIcon },
          { title: 'Senatori', count: counts.senatori, Icon: ChairIcon },
          { title: 'Ministri', count: counts.ministri, Icon: PolicyIcon },
          { title: 'Sindaci', count: counts.sindaci, Icon: ApartmentIcon },
          { title: 'Presidenti', count: counts.presidenti, Icon: EmojiEventsIcon },
        ].map(({ title, count, Icon }) => (
          <Grid key={title} item xs={12} sm={6} md={4} lg={2}>
            <Paper
              elevation={0}
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                bgcolor: 'white',
                borderRadius: 2,
                minHeight: 160,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Icon 
                color="secondary" 
                sx={{ 
                  fontSize: 32,
                  mb: 3,
                  opacity: 0.9
                }} 
              />
              <Typography 
                variant="body1" 
                component="h2" 
                align="center"
                sx={{ 
                  mb: 2,
                  color: '#666',
                  fontSize: '0.875rem',
                  fontWeight: 400,
                }}
              >
                {title}
              </Typography>
              <Typography 
                variant="h4" 
                color="secondary"
                sx={{ 
                  fontWeight: 500,
                  fontSize: '1.75rem'
                }}
              >
                {count.toLocaleString()}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
