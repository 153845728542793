import React from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  useTheme as useMuiTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { italianTranslations as t } from '../../../i18n/it';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WorkIcon from '@mui/icons-material/Work';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FlagIcon from '@mui/icons-material/Flag';
import LocationCityIcon from '@mui/icons-material/LocationCity';

export const UserHome: React.FC = () => {
  const muiTheme = useMuiTheme();
  const navigate = useNavigate();

  const handleNavigate = (type: string) => {
    if (type === 'all') {
      navigate('/app/user/politicians');
    } else if (type === 'deputati') {
      navigate('/app/user/politicians?role=Deputato');
    } else if (type === 'senatori') {
      navigate('/app/user/politicians?role=Senatore');
    } else if (type === 'presidenti') {
      navigate('/app/user/politicians?role=Presidente');
    } else if (type === 'ministri') {
      navigate('/app/user/politicians?role=Ministro');
    } else if (type === 'giornalisti') {
      navigate('/app/user/politicians?role=Giornalista');
    } else if (type === 'partiti') {
      navigate('/app/user/parties');
    } else if (type === 'politici-locali') {
      // Creiamo una nuova rotta per i politici locali
      navigate('/app/user/local-politicians');
    }
  };

  return (
    <Box 
      sx={{ 
        width: '100vw', 
        height: 'calc(100vh - 56px)',
        display: 'flex', 
        flexDirection: 'column', 
        overflow: 'hidden',
        p: 0,
        m: 0,
        border: 'none', 
        borderRadius: 0,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bgcolor: muiTheme.palette.background.default
      }}
    >
      {/* Header section with welcome message */}
      <Box 
        sx={{
          width: '100%',
          py: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          background: `linear-gradient(135deg, ${muiTheme.palette.primary.main} 0%, ${muiTheme.palette.primary.dark} 100%)`,
          borderRadius: '0 0 20px 20px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 'bold',
            color: '#ffffff',
            textAlign: 'center',
            px: 2
          }}
        >
          Benvenuti in Polinder
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: '#ffffff',
            textAlign: 'center',
            mt: 1,
            px: 2,
            opacity: 0.9
          }}
        >
          {t.politicians.intro.subtitle}
        </Typography>
      </Box>
      
      {/* Category buttons section */}
      <Box 
        sx={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          p: 3,
          pt: 4,
          pb: { xs: 7, sm: 5 },
          overflow: 'auto'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          width: '100%', 
          height: '100%',
          maxWidth: { xs: '100%', sm: 600, md: 900, lg: 1200 },
          mx: 'auto'
        }}>
          <Grid 
            container 
            spacing={3} 
            justifyContent="center"
            sx={{ 
              height: '100%',
              alignContent: 'flex-start'
            }}
          >
            {[
              { type: 'politici-locali', icon: <LocationCityIcon />, label: t.politicians.intro.localPoliticians, color: '#009688', hoverColor: '#00796b' },
              { type: 'deputati', icon: <GroupsIcon />, label: t.politicians.intro.deputies, color: '#2196f3', hoverColor: '#1976d2' },
              { type: 'senatori', icon: <AccountBalanceIcon />, label: t.politicians.intro.senators, color: '#4caf50', hoverColor: '#388e3c' },
              { type: 'presidenti', icon: <EmojiEventsIcon />, label: t.politicians.intro.presidents, color: '#ff9800', hoverColor: '#f57c00' },
              { type: 'ministri', icon: <WorkIcon />, label: t.politicians.intro.ministers, color: '#f44336', hoverColor: '#d32f2f' },
              { type: 'giornalisti', icon: <NewspaperIcon />, label: t.politicians.intro.journalists, color: '#9c27b0', hoverColor: '#7b1fa2' },
              { type: 'partiti', icon: <FlagIcon />, label: t.politicians.intro.parties, color: '#607d8b', hoverColor: '#455a64' },
            ].map((item, index) => (
              <Grid 
                item 
                xs={6} 
                sm={4} 
                md={3} 
                key={index} 
                sx={{ 
                  display: 'flex',
                  height: { xs: 'auto', md: '33.33%' }
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleNavigate(item.type)}
                  sx={{
                    bgcolor: item.color,
                    color: '#ffffff',
                    py: { xs: 1.5, sm: 2, md: 3 },
                    px: { xs: 1, md: 2 },
                    minHeight: { xs: 90, sm: 110, md: 130 },
                    textTransform: 'none',
                    fontWeight: 'normal',
                    borderRadius: '12px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      bgcolor: item.hoverColor,
                      boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                      transform: 'translateY(-2px)'
                    }
                  }}
                >
                  {item.icon && (
                    <Box 
                      component="span" 
                      sx={{ 
                        display: 'flex',
                        fontSize: { xs: '1.8rem', sm: '2rem', md: '2.5rem' },
                        mb: 1
                      }}
                    >
                      {item.icon}
                    </Box>
                  )}
                  <Typography 
                    sx={{ 
                      fontSize: { xs: '0.85rem', sm: '0.95rem', md: '1.1rem' },
                      textAlign: 'center',
                      lineHeight: 1.2
                    }}
                  >
                    {item.label}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
