import React from 'react';
import { Dialog, DialogContent, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrivacyPolicy from '../Privacy/PrivacyPolicy';

interface PrivacyDialogProps {
  open: boolean;
  onClose: () => void;
}

const PrivacyDialog: React.FC<PrivacyDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          margin: 0,
          maxHeight: '100%',
          height: '100%',
          backgroundColor: '#ffffff'
        },
        '& .MuiDialogContent-root': {
          p: 0,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '4px'
          }
        }
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          top: 16,
          right: 16,
          zIndex: 9999
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            backgroundColor: 'rgba(173, 14, 56, 0.9)',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: 'rgba(140, 11, 45, 1)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <PrivacyPolicy />
      </DialogContent>
    </Dialog>
  );
};

export default PrivacyDialog;
