import React from 'react';
import { UserPartyList } from '../components/Parties/UserPartyList';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const PartiesPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedPartyId = searchParams.get('selectedPartyId');
  
  console.log('PartiesPage - selectedPartyId dal URL:', selectedPartyId);

  const handlePartyClick = (partyId: string) => {
    // Navigate to politicians filtered by party
    navigate(`/app/user/politicians?partyId=${partyId}`);
  };

  return (
    <UserPartyList 
      onPartyClick={handlePartyClick} 
      selectedPartyId={selectedPartyId}
    />
  );
};
