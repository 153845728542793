import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography,
    Box
} from '@mui/material';
import { Politician } from '../../../types/database.types';

interface CaricatureConfirmDialogProps {
    open: boolean;
    politician: Politician | null;
    onConfirm: () => void;
    onClose: () => void;
}

export const CaricatureConfirmDialog: React.FC<CaricatureConfirmDialogProps> = ({
    open,
    politician,
    onConfirm,
    onClose
}) => {
    if (!politician) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                Genera Caricatura
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Stai per generare una caricatura per:
                </DialogContentText>
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="h6">
                        {politician.first_name} {politician.last_name}
                    </Typography>
                    {politician.party && (
                        <Typography variant="body2" color="text.secondary">
                            {politician.party.name}
                        </Typography>
                    )}
                </Box>
                <DialogContentText>
                    Il processo:
                </DialogContentText>
                <Box component="ol" sx={{ mt: 1 }}>
                    <Typography component="li">
                        Cercherà una foto del politico
                    </Typography>
                    <Typography component="li">
                        Genererà una caricatura dalla foto trovata
                    </Typography>
                    <Typography component="li">
                        Salverà la caricatura e la imposterà come immagine del politico
                    </Typography>
                </Box>
                <DialogContentText sx={{ mt: 2 }}>
                    Vuoi procedere con la generazione della caricatura?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>
                    Annulla
                </Button>
                <Button 
                    onClick={onConfirm} 
                    variant="contained" 
                    color="primary"
                >
                    Genera Caricatura
                </Button>
            </DialogActions>
        </Dialog>
    );
};
