import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Avatar,
  Divider,
  CircularProgress,
  Link,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Politician } from '../../../types/database.types';
import { supabase } from '../../../lib/supabaseClient';

interface PoliticianCardInfoProps {
  politician: Politician | null;
  role?: string;
  onClose: () => void;
}

export const PoliticianCardInfo: React.FC<PoliticianCardInfoProps> = ({
  politician,
  role = "",
  onClose
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [politicalOffices, setPoliticalOffices] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [politicianData, setPoliticianData] = useState<Politician | null>(politician);

  useEffect(() => {
    console.log("Politico ricevuto:", politician);
    setPoliticianData(politician);
    if (politician && !politician.politician_offices) {
      loadPoliticalOffices();
    } else if (politician && politician.politician_offices) {
      setPoliticalOffices(politician.politician_offices);
    }

    // Se il politico non ha una descrizione, carica i dati completi
    if (politician && !politician.description) {
      console.log("Descrizione mancante, carico i dettagli completi");
      loadPoliticianDetails();
    } else {
      console.log("Descrizione già presente:", politician?.description);
    }
  }, [politician]);

  const loadPoliticalOffices = async () => {
    if (!politicianData) return;
    
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('politician_offices')
        .select(`
          *,
          political_office:political_offices(*)
        `)
        .eq('politician_id', politicianData.id);
      
      if (error) {
        console.error('Error loading political offices:', error);
        setError('Errore nel caricamento delle cariche politiche');
        return;
      }
      
      setPoliticalOffices(data || []);
    } catch (error) {
      console.error('Error:', error);
      setError('Si è verificato un errore');
    } finally {
      setLoading(false);
    }
  };

  const loadPoliticianDetails = async () => {
    if (!politicianData) return;
    
    try {
      const { data, error } = await supabase
        .from('politicians')
        .select('*, party:parties(*), birth_place:places(*)')
        .eq('id', politicianData.id)
        .single();
      
      if (error) {
        console.error('Error loading politician details:', error);
        return;
      }
      
      if (data) {
        console.log("Dati completi caricati:", data);
        console.log("Descrizione caricata:", data.description);
        setPoliticianData(data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (!politicianData) {
    return null;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: theme.palette.background.default,
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        borderRadius: 0
      }}
    >
      {/* Header with photo and close button */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={politicianData?.image_url || '/placeholder.png'}
            alt={`${politicianData?.first_name} ${politicianData?.last_name}`}
            sx={{ width: 50, height: 50, mr: 2 }}
          />
          <Box>
            <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
              {politicianData?.first_name} {politicianData?.last_name}
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              {role}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={onClose} sx={{ color: theme.palette.text.primary }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Politician Info */}
      <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
        <Typography variant="h6" gutterBottom sx={{ color: theme.palette.text.primary }}>
          
        </Typography>
        
        {/* Basic Info */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, mb: 1 }}>
            Dati personali
          </Typography>
          <Box sx={{ pl: 2, color: theme.palette.text.secondary }}>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              <strong>Età:</strong> {politicianData?.birth_date ? 
                `${new Date().getFullYear() - new Date(politicianData?.birth_date).getFullYear()} anni` : 
                "Non disponibile"}
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              <strong>Luogo di nascita:</strong> {politicianData?.birth_place ? 
                politicianData?.birth_place.name : 
                "Non disponibile"}
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              <strong>Titolo di studio:</strong> {politicianData?.education_level || "Non disponibile"}
            </Typography>
            {politicianData?.party && (
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                <strong>Partito:</strong> {politicianData?.party.name}
              </Typography>
            )}
          </Box>
        </Box>

        {/* Description */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, mb: 1 }}>
            Descrizione
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, pl: 2 }}>
            {politicianData?.description || "Nessuna descrizione disponibile per questo politico."}
          </Typography>
        </Box>

        {/* Social Media Links */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, mb: 1 }}>
            Social Media
          </Typography>
          <Box sx={{ pl: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            {politicianData?.twitter_url && (
              <Link
                href={politicianData?.twitter_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5, 
                  textDecoration: 'none',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: '#1DA1F2'
                  }
                }}
              >
                <TwitterIcon sx={{ color: '#1DA1F2', fontSize: '1.5rem' }} />
                <Typography sx={{ fontSize: '1rem' }}>Twitter</Typography>
              </Link>
            )}
            {politicianData?.facebook_url && (
              <Link
                href={politicianData?.facebook_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5, 
                  textDecoration: 'none',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: '#4267B2'
                  }
                }}
              >
                <FacebookIcon sx={{ color: '#4267B2', fontSize: '1.5rem' }} />
                <Typography sx={{ fontSize: '1rem' }}>Facebook</Typography>
              </Link>
            )}
            {politicianData?.instagram_url && (
              <Link
                href={politicianData?.instagram_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5, 
                  textDecoration: 'none',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: '#E4405F'
                  }
                }}
              >
                <InstagramIcon sx={{ color: '#E4405F', fontSize: '1.5rem' }} />
                <Typography sx={{ fontSize: '1rem' }}>Instagram</Typography>
              </Link>
            )}
            {politicianData?.linkedin_url && (
              <Link
                href={politicianData?.linkedin_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5, 
                  textDecoration: 'none',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: '#0A66C2'
                  }
                }}
              >
                <LinkedInIcon sx={{ color: '#0A66C2', fontSize: '1.5rem' }} />
                <Typography sx={{ fontSize: '1rem' }}>LinkedIn</Typography>
              </Link>
            )}
            {!politicianData?.twitter_url && !politicianData?.facebook_url && 
             !politicianData?.instagram_url && !politicianData?.linkedin_url && (
              <Typography sx={{ color: theme.palette.text.disabled, fontStyle: 'italic' }}>
                Nessun social media disponibile
              </Typography>
            )}
          </Box>
        </Box>

        {/* Political Offices */}
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress size={24} sx={{ color: theme.palette.primary.main }} />
          </Box>
        ) : error ? (
          <Typography color="error" sx={{ pl: 2 }}>{error}</Typography>
        ) : (politicalOffices && politicalOffices.length > 0) ? (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, mb: 1 }}>
              Cariche politiche
            </Typography>
            <Box sx={{ pl: 2 }}>
              {politicalOffices.map((office, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontWeight: 'bold' }}>
                    {office.political_office?.title || office.political_office?.name || "Carica non specificata"}
                  </Typography>
                  {office.political_office?.description && (
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary, ml: 1, fontSize: '0.9rem' }}>
                      {office.political_office.description}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, mb: 1 }}>
              Cariche politiche
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, pl: 2 }}>
              Nessuna carica politica disponibile.
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};
