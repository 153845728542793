import { createContext, useContext, useEffect, useState } from 'react';
import { Session, User } from '@supabase/supabase-js';
import { supabase } from '../lib/supabaseClient';

interface AuthContextType {
  session: Session | null;
  user: User | null;
  loading: boolean;
  isAdmin: boolean;
  isProfileComplete: boolean;
}

const AuthContext = createContext<AuthContextType>({
  session: null,
  user: null,
  loading: true,
  isAdmin: false,
  isProfileComplete: false,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isProfileComplete, setIsProfileComplete] = useState(false);

  const checkProfileCompletion = (user: User | null) => {
    if (!user) {
      setIsProfileComplete(false);
      return false;
    }

    const metadata = user.user_metadata || {};
    const appMetadata = user.app_metadata || {};
    const isAdmin = appMetadata.role === 'app_admin';
    
    const isComplete = isAdmin || !!(
      metadata.age && 
      metadata.resident_place_id && 
      metadata.gender
    );
    
    setIsProfileComplete(isComplete);
    return isComplete;
  };

  const checkIsAdmin = (user: User | null) => {
    if (!user) {
      setIsAdmin(false);
      return false;
    }

    const appMetadata = user.app_metadata || {};
    
    // Usa il nuovo sistema basato sul ruolo app_admin
    const adminStatus = appMetadata.role === 'app_admin';
    
    setIsAdmin(adminStatus);
    return adminStatus;
  };

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setUser(session?.user ?? null);
      checkIsAdmin(session?.user ?? null);
      checkProfileCompletion(session?.user ?? null);
      setLoading(false);
    });

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state changed:', event, session?.user?.email);
      
      if (event === 'SIGNED_OUT') {
        setSession(null);
        setUser(null);
        setIsAdmin(false);
        setIsProfileComplete(false);
        localStorage.removeItem('supabase.auth.token');
        sessionStorage.removeItem('supabase.auth.token');
      } else {
        setSession(session);
        setUser(session?.user ?? null);
        const isAdminUser = checkIsAdmin(session?.user ?? null);
        const isComplete = checkProfileCompletion(session?.user ?? null);
        
        // Controlla se siamo su una pagina di politico
        const isPoliticianPage = window.location.pathname.startsWith('/politician/');
        
        // Se il profilo è stato appena completato e siamo sulla pagina auth, reindirizza alla pagina appropriata
        if (isComplete && window.location.pathname === '/auth') {
          window.location.href = isAdminUser ? '/app/admin/dashboard' : '/app/user';
        }
        // Se il profilo è incompleto e non siamo su una pagina pubblica (landing, auth o pagina politico), reindirizza a auth
        else if (!isComplete && !['/auth', '/'].includes(window.location.pathname) && !isPoliticianPage) {
          window.location.href = '/auth';
        }
      }

      setLoading(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ session, user, loading, isAdmin, isProfileComplete }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
