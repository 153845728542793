import { supabase } from '../lib/supabaseClient';
import { Skill } from '../types/database.types';
import { validate as uuidValidate } from 'uuid';

class SkillService {
  async getAll(): Promise<Skill[]> {
    const { data, error } = await supabase
      .from('skills')
      .select('*')
      .order('ord', { ascending: true })
      .order('property', { ascending: true });

    if (error) throw error;
    return data;
  }

  async getById(id: string): Promise<Skill | null> {
    const { data, error } = await supabase
      .from('skills')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  }

  async create(skill: Omit<Skill, 'id' | 'created_at' | 'updated_at'>): Promise<Skill> {
    try {
      // Verifica che created_by sia un UUID valido
      if (!skill.created_by || !uuidValidate(skill.created_by)) {
        throw new Error('created_by è richiesto e deve essere un UUID valido');
      }

      // Get the maximum ord value
      const { data: maxOrdData, error: maxOrdError } = await supabase
        .from('skills')
        .select('ord')
        .order('ord', { ascending: false })
        .limit(1);

      if (maxOrdError) throw maxOrdError;

      // Set the new skill's ord to max + 1 (or 1 if no skills exist)
      const nextOrd = maxOrdData && maxOrdData.length > 0 ? (maxOrdData[0].ord || 0) + 1 : 1;
      
      // Ensure all required fields are present and valid
      const currentTime = new Date().toISOString();
      const skillWithOrd = { 
        ...skill, 
        ord: skill.ord || nextOrd,
        created_at: currentTime,
        updated_at: currentTime
      };

      const { data, error } = await supabase
        .from('skills')
        .insert([skillWithOrd])
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error creating skill:', error);
      throw error;
    }
  }

  async update(id: string, skill: Partial<Omit<Skill, 'id' | 'created_at' | 'created_by'>>): Promise<Skill> {
    try {
      // Ensure updated_at is always set to current time
      const updateData = {
        ...skill,
        updated_at: new Date().toISOString()
      };

      const { data, error } = await supabase
        .from('skills')
        .update(updateData)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error updating skill:', error);
      throw error;
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('skills')
        .delete()
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      console.error('Error deleting skill:', error);
      throw error;
    }
  }
}

export const skillService = new SkillService();
