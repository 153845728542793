import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  useTheme
} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';
import type { Swiper as SwiperType } from 'swiper';
import { Party } from '../../../types/database.types';
import { partyService } from '../../../services/partyService';
import { italianTranslations as t } from '../../../i18n/it';
import { PartyCard } from '../shared/PartyCard';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import '../Politicians/politician-swiper.css';

interface UserPartyListProps {
  onPartyClick: (partyId: string) => void;
  selectedPartyId?: string | null;
}

// Estendere il tipo Party per includere i conteggi dei membri
interface PartyWithMemberCounts extends Party {
  memberCounts?: {
    deputati: number;
    senatori: number;
  };
}

export const UserPartyList: React.FC<UserPartyListProps> = ({ onPartyClick, selectedPartyId }) => {
  const [parties, setParties] = useState<PartyWithMemberCounts[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const cardColors = ['#212121', '#212121', '#212121', '#212121'];
  const swiperRef = React.useRef<SwiperType | null>(null);
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);
  const [resetCardView, setResetCardView] = useState(0);

  // Funzione per resettare tutte le viste delle card
  const resetAllCardViews = () => {
    setResetCardView(prev => prev + 1);
  };

  useEffect(() => {
    fetchParties();
  }, []);

  // Log quando il componente riceve selectedPartyId
  useEffect(() => {
    if (selectedPartyId) {
      console.log(`UserPartyList ricevuto selectedPartyId: ${selectedPartyId}`);
    }
  }, [selectedPartyId]);

  // Effetto per gestire l'inizializzazione dello swiper
  useEffect(() => {
    if (swiperRef.current) {
      console.log('Swiper inizializzato');
    }
  }, [swiperRef.current]);

  // Effetto per selezionare il partito corretto
  useEffect(() => {
    if (selectedPartyId && parties.length > 0) {
      console.log(`Tentativo di selezionare il partito con ID ${selectedPartyId}. Partiti disponibili: ${parties.length}`);
      
      if (swiperRef.current) {
        const partyIndex = parties.findIndex(party => party.id === selectedPartyId);
        console.log(`Risultato ricerca partito: indice ${partyIndex}`);
        
        if (partyIndex !== -1) {
          console.log(`Selezionando il partito all'indice ${partyIndex} con ID ${selectedPartyId}`);
          // Aggiungiamo un piccolo ritardo per assicurarci che lo swiper sia completamente inizializzato
          setTimeout(() => {
            if (swiperRef.current) {
              swiperRef.current.slideTo(partyIndex);
              console.log(`Partito selezionato con successo`);
            }
          }, 300);
        } else {
          console.log(`Partito con ID ${selectedPartyId} non trovato nella lista`);
          console.log('IDs dei partiti disponibili:', parties.map(p => p.id));
        }
      } else {
        console.log('Swiper non ancora inizializzato');
      }
    }
  }, [selectedPartyId, parties]);

  const fetchParties = async () => {
    try {
      setLoading(true);
      const data = await partyService.getPartiesWithMemberCounts();
      setParties(data || []);
    } catch (error) {
      console.error('Error fetching parties:', error);
      setError(t.errors.loadingFailed);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ 
      width: '100vw', 
      height: 'calc(100vh - 56px)',
      display: 'flex', 
      flexDirection: 'column', 
      overflow: 'hidden',
      p: 0,
      m: 0,
      border: 'none', 
      borderRadius: 0,
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0
    }}>
      {error && (
        <Alert severity="error" sx={{ position: 'absolute', top: 70, left: 0, right: 0, zIndex: 100, mx: 2 }}>
          {error}
        </Alert>
      )}

      {/* Main content area */}
      <Box sx={{ flex: 1, overflow: 'hidden', position: 'relative', backgroundColor: theme.palette.background.default }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : parties.length === 0 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Typography variant="body1" color="text.secondary">
              Nessun partito disponibile.
            </Typography>
          </Box>
        ) : (
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 1.5,
              slideShadows: false,
            }}
            pagination={false}
            modules={[EffectCoverflow]}
            className="politician-swiper"
            style={{ 
              width: '100%',
              height: '100%',
              margin: 0,
              padding: 0,
              backgroundColor: theme.palette.background.default
            }}
            onSlideChange={(swiper: SwiperType) => {
              setActiveIndex(swiper.activeIndex);
              resetAllCardViews();
            }}
            onSwiper={(swiper: SwiperType) => {
              swiperRef.current = swiper;
            }}
          >
            {parties.map((party, index) => (
              <SwiperSlide key={party.id} className="swiper-slide">
                <PartyCard
                  party={party}
                  loading={false}
                  error={null}
                  colorIndex={index % cardColors.length}
                  cardColors={cardColors}
                  onCardClick={() => onPartyClick(party.id)}
                  resetView={resetCardView}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>
    </Box>
  );
};