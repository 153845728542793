import { useState, useEffect } from 'react';
import { supabase } from '../../lib/supabaseClient';
import { 
  Box, 
  Container, 
  Typography, 
  TextField, 
  Button, 
  CircularProgress, 
  Alert, 
  Paper,
  IconButton,
  InputAdornment,
  Link
} from '@mui/material';
import { useNavigate, useSearchParams, Link as RouterLink } from 'react-router-dom';
import { Visibility, VisibilityOff, Google } from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import { italianTranslations as t } from '../../i18n/it';
import ActivationDialog from './ActivationDialog';

export default function Auth() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showActivation, setShowActivation] = useState(false);
  const navigate = useNavigate();
  const { session } = useAuth();

  useEffect(() => {
    // Check if we're in a verification flow
    const isVerificationFlow = searchParams.get('type') === 'signup';
    const token = searchParams.get('token');
    const isRecoveryFlow = window.location.href.includes('type=recovery');
    const isResetMode = searchParams.get('mode') === 'reset';
    
    if (isVerificationFlow && token) {
      // After email verification, verify the token and get session
      const verifyEmailAndLogin = async () => {
        try {
          setLoading(true);
          
          // Get the session after verification
          const { data: { session: currentSession }, error } = await supabase.auth.getSession();
          
          if (error) {
            console.error('Error getting session:', error);
            return;
          }

          if (currentSession) {
            // Check if user has mandatory fields or is admin
            const metadata = currentSession.user.user_metadata || {};
            const appMetadata = currentSession.user.app_metadata || {};
            const isAdmin = appMetadata.role === 'app_admin';
            const isComplete = isAdmin || !!(
              metadata.age && 
              metadata.resident_place_id && 
              metadata.gender
            );

            if (!isComplete) {
              setShowActivation(true);
            } else {
              navigate(isAdmin ? '/app/admin/dashboard' : '/app/user');
            }
          }
        } catch (error) {
          console.error('Error during verification:', error);
        } finally {
          setLoading(false);
        }
      };
      
      verifyEmailAndLogin();
    } else if (isRecoveryFlow || isResetMode) {
      setIsResetPassword(true);
      setIsSignUp(false);
      // If we have a session but we're in reset flow, sign out
      if (session) {
        supabase.auth.signOut();
      }
    } else {
      setIsSignUp(searchParams.get('mode') === 'signup');
      setIsResetPassword(false);
    }
  }, [searchParams, session]);

  useEffect(() => {
    // Handle the recovery flow
    const handleRecoveryToken = async () => {
      const hash = window.location.hash;
      if (hash && hash.includes('type=recovery')) {
        try {
          setLoading(true);
          const hashParams = new URLSearchParams(hash.substring(1));
          const accessToken = hashParams.get('access_token');
          const refreshToken = hashParams.get('refresh_token');
          
          if (accessToken) {
            const { error } = await supabase.auth.setSession({
              access_token: accessToken,
              refresh_token: refreshToken || '',
            });
            
            if (error) throw error;
            
            setIsResetPassword(true);
            // Remove the hash from the URL without triggering a refresh
            window.history.replaceState(null, '', window.location.pathname + window.location.search);
          }
        } catch (error: any) {
          console.error('Error handling recovery:', error);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      }
    };

    handleRecoveryToken();
  }, []);

  useEffect(() => {
    if (session && !isResetPassword && !showActivation) {
      if (!session.user) return;

      // Check if user has mandatory fields
      const metadata = session.user.user_metadata || {};
      const appMetadata = session.user.app_metadata || {};
      const isAdmin = appMetadata.role === 'app_admin';
      const isComplete = isAdmin || !!(
        metadata.age && 
        metadata.resident_place_id && 
        metadata.gender
      );

      if (!isComplete) {
        setShowActivation(true);
      } else {
        // Redirect to user portal instead of landing page
        navigate(isAdmin ? '/app/admin/dashboard' : '/app/user');
      }
    }
  }, [session, navigate, isResetPassword, showActivation]);

  const handleActivationComplete = () => {
    setShowActivation(false);
    // Redirect to user portal after activation
    navigate('/app/user', { replace: true });
  };

  const handleActivationCancel = () => {
    setShowActivation(false);
    // Sign out the user if they cancel activation
    supabase.auth.signOut().then(() => {
      navigate('/', { replace: true });
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    try {
      setError(null);
      setLoading(true);
      
      console.log('Attempting login with email:', email);
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      console.log('Login successful, user:', data.user);
      
    } catch (error: any) {
      console.error('Login error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    try {
      setError(null);
      
      // Verify passwords match
      if (password !== confirmPassword) {
        setPasswordsMatch(false);
        setError(t.auth.passwordsDoNotMatch);
        return;
      }
      
      setLoading(true);

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/auth?type=signup`
        }
      });

      if (error) throw error;

      // Check if email confirmation is required
      if (data?.user?.identities?.length === 0) {
        // This means the user already exists but hasn't confirmed their email
        setError(t.auth.emailAlreadyInUse);
        return;
      }
      
      if (!data.user?.confirmed_at) {
        // Email confirmation required
        setSuccessMessage(t.auth.signUpEmailConfirmation);
        return;
      }
      
      // If email is already confirmed (rare case), proceed with sign in
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (signInError) throw signInError;
      
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError(t.auth.emailRequired);
      return;
    }
    
    try {
      setLoading(true);
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/auth?mode=reset`,
      });
      
      if (error) throw error;
      
      setSuccessMessage(t.auth.forgotPasswordSuccess);
      setError(null);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!newPassword) {
      setError(t.auth.newPasswordRequired);
      return;
    }

    try {
      setLoading(true);
      
      // Get the access token from the URL hash
      const hash = window.location.hash;
      let accessToken = '';
      
      if (hash) {
        const hashParams = new URLSearchParams(hash.substring(1));
        accessToken = hashParams.get('access_token') || '';
      }

      // If we have an access token, set the session first
      if (accessToken) {
        const { error: sessionError } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: '',
        });
        
        if (sessionError) throw sessionError;
      }

      // Update the password
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      setSuccessMessage(t.auth.passwordResetSuccess);
      setError(null);
      setIsResetPassword(false);
      setNewPassword('');
      
      // Sign out after password reset
      await supabase.auth.signOut();
      
      // Clear the URL parameters and hash
      window.history.replaceState(null, '', '/auth');
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      setError(null);
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/auth`,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
          scopes: 'email profile'
        }
      });
      if (error) throw error;
    } catch (error: any) {
      setError(error.message || t.common.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ActivationDialog 
        open={showActivation} 
        onComplete={handleActivationComplete}
        onCancel={handleActivationCancel}
      />
      
      {loading && searchParams.get('type') === 'signup' ? (
        <Box
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
          }}
        >
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2, color: 'white' }}>
            {t.auth.verifyingEmail}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            minHeight: '100vh',
            width: '100vw',
            background: '#ebebeb',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: 3,
            pt: 8,
            position: 'relative'
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '400px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box
              component="img"
              src="/logo.png"
              alt="Polinder Logo"
              sx={{
                width: 'auto',
                height: '161px', 
                mb: 4
              }}
            />
            <Typography 
              variant="h5" 
              sx={{
                color: 'text.secondary',
                mb: 3,
                textAlign: 'center',
                fontSize: '26px',
                fontWeight: 'normal',
                display: 'none'
              }}
            >
              {isResetPassword 
                ? t.auth.resetPassword 
                : isSignUp 
                  ? t.auth.signUp 
                  : t.auth.signIn}
            </Typography>

            {error && (
              <Alert severity="error" sx={{ width: '100%', mb: 2, fontSize: '16px' }}>
                {error}
              </Alert>
            )}

            {successMessage && (
              <Alert severity="success" sx={{ width: '100%', mb: 2, fontSize: '16px' }}>
                {successMessage}
              </Alert>
            )}

            {isResetPassword ? (
              <>
                <TextField
                  label={t.auth.newPassword}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  fullWidth
                  margin="normal"
                  type={showPassword ? 'text' : 'password'}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { fontSize: '16px' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '16px' }
                  }}
                  sx={{ 
                    mb: 1.5,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Button
                    variant="contained"
                    onClick={handlePasswordReset}
                    disabled={loading}
                    fullWidth
                    sx={{
                      mb: 2,
                      height: '48px',
                      borderRadius: '24px',
                      textTransform: 'none',
                      fontSize: '18px',
                      fontWeight: 'normal',
                      backgroundColor: '#ad0e38',
                      '&:hover': {
                        backgroundColor: '#8c0b2d'
                      }
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      t.auth.updatePassword
                    )}
                  </Button>
                </Box>

                <Button
                  onClick={() => {
                    setIsResetPassword(false);
                    setError(null);
                    setSuccessMessage(null);
                  }}
                  sx={{
                    textTransform: 'none',
                    color: '#ad0e38',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                    }
                  }}
                >
                  {t.auth.backToSignIn}
                </Button>
              </>
            ) : (
              <>
                <TextField
                  label={t.auth.email}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  margin="normal"
                  type="email"
                  required
                  InputProps={{
                    style: { fontSize: '16px' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '16px' }
                  }}
                  sx={{ 
                    mb: 1.5,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                />

                <TextField
                  label={t.auth.password}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (isSignUp) {
                      setPasswordsMatch(e.target.value === confirmPassword);
                    }
                  }}
                  fullWidth
                  margin="normal"
                  type={showPassword ? 'text' : 'password'}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: { fontSize: '16px' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '16px' }
                  }}
                  sx={{ 
                    mt: 0.5,
                    mb: !isSignUp ? 1 : 1.5,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                />

                {isSignUp && (
                  <TextField
                    label={t.auth.confirmPassword}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setPasswordsMatch(password === e.target.value);
                    }}
                    fullWidth
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    required
                    error={!passwordsMatch}
                    helperText={!passwordsMatch ? t.auth.passwordsDoNotMatch : ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: { fontSize: '16px' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '16px' }
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: '14px' }
                    }}
                    sx={{ 
                      mt: 0.5,
                      mb: 1.5,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                      }
                    }}
                  />
                )}
                {!isSignUp && (
                  <Box sx={{ width: '100%', mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      onClick={handleForgotPassword}
                      sx={{
                        textTransform: 'none',
                        color: '#ad0e38',
                        fontSize: '14px',
                        fontWeight: 'normal',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          textDecoration: 'underline',
                        }
                      }}
                    >
                      {t.auth.forgotPassword}
                    </Button>
                  </Box>
                )}

                <Box sx={{ width: '100%', mt: 2 }}>
                  <Button
                    variant="contained"
                    onClick={isSignUp ? handleSignUp : handleLogin}
                    disabled={loading}
                    fullWidth
                    sx={{
                      mb: 2,
                      height: '48px',
                      borderRadius: '24px',
                      textTransform: 'none',
                      fontSize: '18px',
                      fontWeight: 'normal',
                      backgroundColor: '#ad0e38',
                      '&:hover': {
                        backgroundColor: '#8c0b2d'
                      }
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : isSignUp ? (
                      t.auth.signUp
                    ) : (
                      t.auth.signIn
                    )}
                  </Button>
                  
                  <Button
                    variant="outlined"
                    onClick={handleGoogleSignIn}
                    disabled={loading}
                    fullWidth
                    sx={{ 
                      mb: 2,
                      height: '48px',
                      borderRadius: '24px',
                      textTransform: 'none',
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: '#3c4043',
                      borderColor: '#dadce0',
                      backgroundColor: 'white',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'center',
                      alignItems: 'center',
                      px: 3,
                      '&:hover': {
                        borderColor: '#dadce0',
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.12)'
                      }
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                          <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
                          <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
                          <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
                          <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
                        </svg>
                        <span style={{ marginLeft: 8 }}>{isSignUp ? "Registrati con Google" : "Accedi con Google"}</span>
                      </>
                    )}
                  </Button>
                </Box>

                <Button
                  onClick={() => {
                    setIsSignUp(!isSignUp);
                    setError(null);
                    setSuccessMessage(null);
                  }}
                  sx={{
                    textTransform: 'none',
                    color: '#ad0e38',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                    }
                  }}
                >
                  {isSignUp ? t.auth.alreadyHaveAccount : t.auth.dontHaveAccount}
                </Button>
              </>
            )}
          </Box>

        </Box>
      )}
    </>
  );
}
