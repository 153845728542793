import { createBrowserRouter, Navigate, Outlet, isRouteErrorResponse, useRouteError, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Dashboard } from '../components/Dashboard/Dashboard';
import { AdminPartyList } from '../admin/components/Parties/AdminPartyList';
import { AdminPoliticianList } from '../admin/components/Politicians/AdminPoliticianList';
import { PoliticalOfficesList } from '../components/PoliticalOffices/PoliticalOfficesList';
import { SkillList } from '../components/Skills/SkillList';
import { PlacesView } from '../components/Places/PlacesView';
import Auth from '../components/Auth/Auth';
import { AdminLayout } from '../components/Layout/AdminLayout';
import { UserLayout } from '../components/Layout/UserLayout';
import { useAuth } from '../hooks/useAuth';
import { Box, CircularProgress, Typography } from '@mui/material';
import LandingPage from '../components/Landing/LandingPage';
import PoliticianLandingPage from '../components/Landing/PoliticianLandingPage';
import { UserProfile } from '../user/components/Profile/UserProfile';
import { PoliticianStatistics } from '../user/components/Politicians/PoliticianStatistics'; 
import { UserPartyList } from '../user/components/Parties/UserPartyList';
import { UserPoliticianList } from '../user/components/Politicians/UserPoliticianList';
import { StatisticsView } from '../user/components/Statistics/StatisticsView';
import { StatisticsIntroPage } from '../user/pages/StatisticsIntroPage';
import { PartiesPage } from '../user/pages/PartiesPage';
import { PartiesIntroPage } from '../user/pages/PartiesIntroPage';
import { UserHome } from '../user/components/Home/UserHome';
import { UserPoliticianRegion } from '../user/components/Politicians/UserPoliticianRegion';
import PrivacyPage from '../pages/PrivacyPage';
import { SearchPage } from '../user/pages/SearchPage';

// Protected layout that handles auth state
function ProtectedLayout() {
  const { session, loading, isAdmin } = useAuth();

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!session) {
    return <Navigate to="/auth" replace />;
  }

  return <Outlet />;
}

// Admin-only layout
function AdminOnlyLayout() {
  const { isAdmin } = useAuth();

  if (!isAdmin) {
    return <Navigate to="/lists" replace />;
  }

  return (
    <AdminLayout>
      <Box sx={{ p: 3 }}>
        <Outlet />
      </Box>
    </AdminLayout>
  );
}

// User layout wrapper
function UserLayoutWrapper() {
  return (
    <UserLayout>
      <Box sx={{ p: 3 }}>
        <Outlet />
      </Box>
    </UserLayout>
  );
}

// Public layout that redirects to dashboard if already authenticated
function PublicLayout() {
  const { session, loading, isAdmin, isProfileComplete } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh' 
    }}>
      <CircularProgress />
    </Box>
  );
}

  // Se l'utente sta cercando di accedere alla pagina di un politico, non reindirizzarlo
  const isPoliticianPage = location.pathname.startsWith('/politician/');
  
  // Solo se non è una pagina di politico, reindirizza gli utenti autenticati
  if (session && isProfileComplete && !isPoliticianPage) {
    return <Navigate to={isAdmin ? "/app/admin/dashboard" : "/app/user"} replace />;
  }

  // Otherwise show the public page (landing or auth)
  return <Outlet />;
}

// Root route handler
function RootRoute() {
  const { isAdmin, loading } = useAuth();
  
  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return <Navigate to={isAdmin ? '/app/admin/dashboard' : '/app/user'} replace />;
}

function ErrorBoundary() {
  const error = useRouteError();
  
  if (isRouteErrorResponse(error)) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h5" color="error" gutterBottom>
          {error.status} {error.statusText}
        </Typography>
        <Typography variant="body1">
          {error.data?.message || 'Something went wrong'}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Typography variant="h5" color="error" gutterBottom>
        Oops! Something went wrong
      </Typography>
      <Typography variant="body1">
        Please try again later or contact support if the problem persists.
      </Typography>
    </Box>
  );
}

// Wrapper for UserPartyList with navigation
const UserPartyListWrapper = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedPartyId = searchParams.get('selectedPartyId');
  
  return (
    <UserPartyList 
      onPartyClick={(partyId) => navigate(`/app/user/politicians?partyId=${partyId}`)}
      selectedPartyId={selectedPartyId}
    />
  );
};

// Wrapper for UserPoliticianList with URL params
const UserPoliticianListWrapper = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedPartyId = searchParams.get('partyId');
  const selectedRole = searchParams.get('role');
  
  console.log("UserPoliticianListWrapper - Parametri URL:", { 
    selectedPartyId, 
    selectedRole,
    searchParams: Object.fromEntries(searchParams.entries())
  });

  return (
    <UserPoliticianList
      selectedPartyId={selectedPartyId}
      selectedRole={selectedRole}
      onClearFilter={() => setSearchParams({})}
    />
  );
};

export const router = createBrowserRouter([
  {
    element: <PublicLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <LandingPage />
      },
      {
        path: '/auth',
        element: <Auth />
      },
      {
        path: '/privacy',
        element: <PrivacyPage />
      },
      {
        path: '/politician/:id',
        element: <PoliticianLandingPage />
      },
    ]
  },
  {
    path: '/app',
    element: <ProtectedLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '',
        element: <RootRoute />
      },
      {
        path: 'admin',
        element: <AdminOnlyLayout />,
        children: [
          {
            path: 'dashboard',
            element: <Dashboard />
          },
          {
            path: 'parties',
            element: <AdminPartyList />
          },
          {
            path: 'politicians',
            element: <AdminPoliticianList />
          },
          {
            path: 'political-offices',
            element: <PoliticalOfficesList />
          },
          {
            path: 'skills',
            element: <SkillList />
          },
          {
            path: 'places',
            element: <PlacesView />
          }
        ]
      },
      {
        path: 'user',
        element: <UserLayoutWrapper />,
        children: [
          {
            path: '',
            element: <UserHome />
          },
          {
            path: 'local-politicians',
            element: <UserPoliticianRegion />
          },
          {
            path: 'profile',
            element: <UserProfile />
          },
          {
            path: 'search',
            element: <SearchPage />
          },
          {
            path: 'parties',
            element: <PartiesPage />
          },
          {
            path: 'parties-intro',
            element: <PartiesIntroPage />
          },
          {
            path: 'statistics-intro',
            element: <StatisticsIntroPage />
          },
          {
            path: 'statistics',
            element: <StatisticsView />
          },
          {
            path: 'politicians',
            children: [
              {
                index: true,
                element: <UserPoliticianListWrapper />
              },
              {
                path: ':id',
                element: <Navigate to="/app/user/politicians" replace />
              },
              {
                path: ':id/statistics',
                element: <PoliticianStatistics />
              }
            ]
          }
        ]
      }
    ]
  }
]);
