import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Chip,
  Menu,
  MenuItem,
  Container,
  Tooltip,
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Popover
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import {
  AccountCircle,
  Logout as LogoutIcon,
  Person as PersonIcon,
  LightMode as LightModeIcon,
  DarkMode as DarkModeIcon,
  Groups as GroupsIcon,
  AccountBalance as AccountBalanceIcon,
  Home as HomeIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../../lib/supabaseClient';
import { useAuth } from '../../hooks/useAuth';
import { useTheme } from '../../contexts/ThemeContext';
import { lightTheme, darkTheme } from '../../theme/theme';

export const UserLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { session } = useAuth();
  const { isDarkMode, toggleTheme } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [navValue, setNavValue] = useState(0);

  // Set the active navigation tab based on the current path
  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/app/user/search')) {
      setNavValue(1);
    } else if (path.includes('/app/user/statistics')) {
      setNavValue(2);
    } else if (path.includes('/app/user')) {
      setNavValue(0);
    }
  }, [location]);

  useEffect(() => {
    if (session?.user) {
      const metadata = session.user.user_metadata || {};
      const url = metadata.picture || metadata.avatar_url;
      setAvatarUrl(url);
    }
  }, [session]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem('supabase.auth.token');
      sessionStorage.removeItem('supabase.auth.token');
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/');
      handleClose();
    } catch (error) {
      console.error('Error signing out:', error);
      navigate('/');
    }
  };

  const handleProfile = () => {
    navigate('/app/user/profile');
    handleClose();
  };

  const handleBottomNavChange = (event: React.SyntheticEvent, newValue: number) => {
    setNavValue(newValue);
    
    // Navigate based on the selected bottom navigation item
    switch(newValue) {
      case 0:
        navigate('/app/user');
        break;
      case 1:
        navigate('/app/user/search');
        break;
      case 2:
        // Navigate to the statistics intro page instead of opening the submenu
        navigate('/app/user/statistics-intro');
        break;
      case 3:
        // Don't navigate for profile, just open the menu
        handleMenu(event as React.MouseEvent<HTMLElement>);
        break;
    }
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} className="user-portal">
        <Box 
          component="main" 
          sx={{
            flexGrow: 1,
            minHeight: '100vh',
            width: '100vw',
            bgcolor: 'background.default', 
            mt: 0,
            mb: '64px', 
            display: 'flex',
            flexDirection: 'column',
            p: 0,
            m: 0,
            overflow: 'hidden',
            border: 'none'
          }}
        >
          {children}
        </Box>
        
        {/* Bottom Navigation - Fixed at bottom */}
        <BottomNavigation
          value={navValue}
          onChange={handleBottomNavChange}
          showLabels
          sx={{
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            height: '56px',
            bgcolor: 'background.paper',
            borderTop: '1px solid',
            borderColor: 'divider',
            zIndex: 1100,
            boxShadow: 'none',
            '& .MuiBottomNavigationAction-root': {
              minWidth: 'auto',
              padding: '8px 10px', 
              color: 'text.secondary',
              '&.Mui-selected': {
                color: 'primary.main',
              },
              '& .MuiBottomNavigationAction-label': {
                fontSize: '0.7rem', 
                marginTop: '4px' 
              },
              '& .MuiSvgIcon-root': {
                fontSize: '1.5rem' 
              }
            }
          }}
        >
          <BottomNavigationAction 
            label="Home" 
            icon={<HomeIcon />} 
          />
          <BottomNavigationAction 
            label="Cerca" 
            icon={<SearchIcon />} 
          />
          <BottomNavigationAction 
            label="Statistiche" 
            icon={<AccountBalanceIcon />} 
          />
          <BottomNavigationAction 
            label="Profilo" 
            icon={
              avatarUrl ? (
                <Avatar 
                  src={avatarUrl} 
                  alt="Profilo"
                  imgProps={{
                    referrerPolicy: "no-referrer",
                    crossOrigin: "anonymous"
                  }}
                  sx={{ 
                    width: 30, 
                    height: 30,
                    border: '1px solid',
                    borderColor: 'primary.main'
                  }}
                />
              ) : (
                <AccountCircle />
              )
            }
          />
        </BottomNavigation>
        
        {/* Profile Menu */}
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            elevation: 2,
            sx: {
              mb: 1,
              minWidth: 180,
              backgroundColor: 'background.default',
              '& .MuiMenuItem-root': {
                px: 2,
                py: 1,
                typography: 'body2',
                borderRadius: 0,
                '& .MuiSvgIcon-root': {
                  fontSize: 20,
                  color: 'text.secondary',
                  marginRight: 1.5,
                },
              },
            },
          }}
        >
          <MenuItem onClick={toggleTheme}>
            {isDarkMode ? (
              <LightModeIcon />
            ) : (
              <DarkModeIcon />
            )}
            {isDarkMode ? 'Light Mode' : 'Dark Mode'}
          </MenuItem>
          <MenuItem onClick={handleProfile}>
            <PersonIcon />
            Profilo
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <LogoutIcon />
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </ThemeProvider>
  );
};
