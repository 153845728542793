import axios from 'axios';

interface GoogleSearchItem {
    title: string;
    link: string;
    mime?: string;
    fileFormat?: string;
}

export interface NewsSearchResult {
    title: string;
    link: string;
    snippet: string;
    source: string;
    publishedDate?: string;
}

export class GoogleSearchService {
    private readonly baseUrl = '/api/search/google';

    async searchImages(query: string): Promise<string> {
        try {
            console.log(`Searching images with query: ${query}`);
            const response = await axios.get(`${this.baseUrl}/images`, {
                params: { query }
            });

            if (!response.data || !response.data.imageUrl) {
                console.error('Invalid response format:', response.data);
                throw new Error('Invalid response format from API');
            }

            return response.data.imageUrl;
        } catch (error) {
            console.error('Error searching images:', error);
            throw error;
        }
    }

    async searchPoliticianPortrait(name: string, role?: string, city?: string): Promise<string> {
        try {
            console.log(`Searching politician portrait with name: ${name}, role: ${role || 'not specified'}, city: ${city || 'not specified'}`);
            const params: Record<string, string> = { name };
            if (role) {
                params.role = role;
            }
            if (city) {
                params.city = city;
            }
            
            const response = await axios.get(`${this.baseUrl}/politician-portrait`, {
                params
            });

            if (!response.data || !response.data.imageUrl) {
                console.error('Invalid response format:', response.data);
                throw new Error('Invalid response format from API');
            }

            return response.data.imageUrl;
        } catch (error) {
            console.error('Error searching politician portrait:', error);
            throw error;
        }
    }

    async searchNews(query: string, limit: number = 5): Promise<NewsSearchResult[]> {
        try {
            console.log(`Searching news with query: ${query}, limit: ${limit}`);
            const response = await axios.get(`${this.baseUrl}/news`, {
                params: { query, limit }
            });

            if (!response.data || !response.data.news || !Array.isArray(response.data.news)) {
                console.error('Invalid response format:', response.data);
                throw new Error('Invalid response format from API');
            }

            return response.data.news;
        } catch (error) {
            console.error('Error searching news:', error);
            throw error;
        }
    }

    async isValidImageUrl(url: string): Promise<boolean> {
        if (!url) return false;

        try {
            new URL(url);
        } catch {
            return false;
        }

        const imageExtensions = ['.jpg', '.jpeg', '.png', '.svg'];
        const hasImageExtension = imageExtensions.some(ext => 
            url.toLowerCase().endsWith(ext) || 
            url.toLowerCase().includes(`${ext}?`) ||
            url.toLowerCase().includes(`${ext}&`)
        );

        if (!hasImageExtension) {
            return false;
        }

        try {
            const response = await axios.head(url, {
                timeout: 5000
            });
            return response.status === 200;
        } catch (error) {
            console.error('Error checking image URL:', error);
            return false;
        }
    }
}

export const googleSearchService = new GoogleSearchService();
