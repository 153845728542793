import React from 'react';
import { Box, Typography, Link } from '@mui/material';

interface FooterProps {
  onPrivacyClick: () => void;
}

const Footer: React.FC<FooterProps> = ({ onPrivacyClick }) => {
  return (
    <Box
      sx={{
        width: '100%',
        marginTop: 'auto',
        backgroundColor: 'transparent',
        py: 0.5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: 'white'
      }}
    >
      <Typography
        sx={{ 
          color: 'white',
          opacity: 0.7,
          fontSize: 'inherit',
          mb: 0.25
        }}
      >
        2025 Polinder. All rights reserved.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Link
          href="mailto:info@polinder.net"
          sx={{
            color: 'white',
            textDecoration: 'none',
            opacity: 0.7,
            '&:hover': { opacity: 1 }
          }}
        >
          info@polinder.net
        </Link>
        <Box component="span" sx={{ mx: 1, color: 'white', opacity: 0.7 }}>|</Box>
        <Link
          component="button"
          onClick={onPrivacyClick}
          sx={{
            color: 'white',
            textDecoration: 'none',
            opacity: 0.7,
            background: 'none',
            border: 'none',
            padding: 0,
            font: 'inherit',
            cursor: 'pointer',
            '&:hover': { opacity: 1 }
          }}
        >
          Privacy Policy
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
