import { supabase } from '../lib/supabaseClient';

export interface SearchResult {
  id: string;
  type: 'politician' | 'party';
  name: string;
  imageUrl?: string;
  position?: string;
}

interface PoliticianSearchResult {
  id: string;
  first_name: string;
  last_name: string;
  image_url?: string;
  party?: { id: string; name: string; } | null;
  position?: string;
}

interface PartySearchResult {
  id: string;
  name: string;
  logo_url?: string;
}

class SearchService {
  async searchPoliticians(query: string): Promise<PoliticianSearchResult[]> {
    if (!query || query.trim() === '') {
      return [];
    }

    try {
      const searchTerm = query.trim().toLowerCase();
      console.log('Searching politicians with term:', searchTerm);

      // Step 1: Get all politicians matching the search term
      const { data, error } = await supabase
        .from('politicians')
        .select(`
          id,
          first_name,
          last_name,
          image_url,
          party:parties!politicians_party_id_fkey(id, name)
        `)
        .or(`first_name.ilike.%${searchTerm}%,last_name.ilike.%${searchTerm}%`)
        .order('last_name', { ascending: true })
        .limit(500);

      if (error) {
        console.error('Error searching politicians:', error);
        throw error;
      }

      console.log('Raw data from search:', data);
      
      if (!data || data.length === 0) {
        return [];
      }

      // Transform the data to ensure party is a single object, not an array
      const transformedData = data.map((politician: any) => ({
        id: politician.id,
        first_name: politician.first_name,
        last_name: politician.last_name,
        image_url: politician.image_url,
        party: politician.party && politician.party.length > 0 ? politician.party[0] : null
      }));
      
      // Approccio alternativo: ottieni direttamente le cariche politiche
      try {
        // Ottieni tutte le cariche politiche in una query separata
        const { data: allOffices, error: officesQueryError } = await supabase
          .from('political_offices')
          .select('id, title');
        
        if (officesQueryError) {
          console.error('Error fetching political offices:', officesQueryError);
          return transformedData;
        }
        
        // Ottieni le associazioni tra politici e cariche
        const { data: politicianOfficesData, error: associationsError } = await supabase
          .from('politician_offices')
          .select('politician_id, political_office_id')
          .in('politician_id', transformedData.map(p => p.id));
        
        if (associationsError) {
          console.error('Error fetching politician-office associations:', associationsError);
          return transformedData;
        }
        
        // Crea una mappa di ID cariche a titoli
        const officesMap: Record<string, string> = {};
        allOffices?.forEach((office: any) => {
          officesMap[office.id] = office.title;
        });
        
        // Crea una mappa di politici alle loro cariche
        const politicianOffices: Record<string, Array<{title: string}>> = {};
        
        politicianOfficesData?.forEach((association: any) => {
          const politicianId = association.politician_id;
          const officeId = association.political_office_id;
          const officeTitle = officesMap[officeId];
          
          if (!officeTitle) return;
          
          if (!politicianOffices[politicianId]) {
            politicianOffices[politicianId] = [];
          }
          
          politicianOffices[politicianId].push({
            title: officeTitle
          });
        });
        
        console.log('Politician offices map:', politicianOffices);
        
        // Step 3: Filter out politicians with excluded roles
        const filteredData = transformedData.filter(politician => {
          const offices = politicianOffices[politician.id] || [];
          
          // If politician has no offices, include them
          if (offices.length === 0) {
            console.log(`Politico ${politician.first_name} ${politician.last_name} non ha cariche, incluso nei risultati`);
            return true;
          }
          
          // Check if any office title contains "Sindaco", "Presidente di Regione", or "Presidente della Provincia"
          const hasExcludedRole = offices.some(office => {
            if (!office.title) return false;
            
            const titleLower = office.title.toLowerCase();
            const isExcluded = (
              titleLower.includes('sindaco') || 
              titleLower.includes('presidente di regione') || 
              titleLower.includes('presidente della regione') ||
              titleLower.includes('presidente della provincia') ||
              titleLower.includes('presidente di provincia')
            );
            
            console.log(`Carica "${office.title}" (${titleLower}) per ${politician.first_name} ${politician.last_name} - esclusa: ${isExcluded}`);
            
            return isExcluded;
          });
          
          console.log(`Politico ${politician.first_name} ${politician.last_name} - hasExcludedRole: ${hasExcludedRole}`);
          
          // Keep the politician only if they do NOT have an excluded role
          return !hasExcludedRole;
        });
        
        console.log('Filtered politicians (excluding excluded roles):', filteredData);
        
        // Step 4: Add the main position to each politician
        const politiciansWithPositions = filteredData.map(politician => {
          const offices = politicianOffices[politician.id] || [];
          let position = offices.length > 0 ? offices[0].title : undefined;
          
          return {
            ...politician,
            position
          };
        });
        
        return politiciansWithPositions;
      } catch (officesError) {
        console.error('Error in offices processing:', officesError);
        // In caso di errore, restituisci i risultati non filtrati
        return transformedData;
      }
    } catch (error) {
      console.error('Failed to search politicians:', error);
      return [];
    }
  }

  async searchParties(query: string): Promise<PartySearchResult[]> {
    if (!query || query.trim() === '') {
      return [];
    }

    try {
      const searchTerm = query.trim().toLowerCase();
      console.log('Searching parties with term:', searchTerm);

      const { data, error } = await supabase
        .from('parties')
        .select(`
          id,
          name,
          logo_url
        `)
        .ilike('name', `%${searchTerm}%`)
        .order('name', { ascending: true })
        .limit(20);

      if (error) {
        console.error('Error searching parties:', error);
        throw error;
      }

      console.log('Found parties:', data);
      return data || [];
    } catch (error) {
      console.error('Failed to search parties:', error);
      return [];
    }
  }

  async search(query: string): Promise<SearchResult[]> {
    if (!query || query.trim() === '') {
      return [];
    }

    try {
      const [politicians, parties] = await Promise.all([
        this.searchPoliticians(query),
        this.searchParties(query)
      ]);

      const politicianResults: SearchResult[] = politicians.map(politician => ({
        id: politician.id,
        type: 'politician',
        name: `${politician.first_name} ${politician.last_name}`,
        imageUrl: politician.image_url || undefined,
        position: politician.position || undefined
      }));

      const partyResults: SearchResult[] = parties.map(party => ({
        id: party.id,
        type: 'party',
        name: party.name,
        imageUrl: party.logo_url || undefined
      }));

      // Combine and sort results
      const combinedResults = [...politicianResults, ...partyResults];
      
      // Sort by relevance (exact match first, then alphabetically)
      const searchTerm = query.trim().toLowerCase();
      combinedResults.sort((a, b) => {
        const aNameLower = a.name.toLowerCase();
        const bNameLower = b.name.toLowerCase();
        
        // Exact matches first
        const aExactMatch = aNameLower === searchTerm;
        const bExactMatch = bNameLower === searchTerm;
        
        if (aExactMatch && !bExactMatch) return -1;
        if (!aExactMatch && bExactMatch) return 1;
        
        // Starts with search term next
        const aStartsWith = aNameLower.startsWith(searchTerm);
        const bStartsWith = bNameLower.startsWith(searchTerm);
        
        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;
        
        // Alphabetical order
        return aNameLower.localeCompare(bNameLower);
      });

      return combinedResults;
    } catch (error) {
      console.error('Failed to search:', error);
      return [];
    }
  }
}

export const searchService = new SearchService();
