import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
} from '@mui/material';
import { PieChart } from '@mui/x-charts';

interface ChartDataItem {
  id: number;
  value: number;
  label: string;
  color: string;
}

interface SenatoStatisticsProps {
  chartData: any;
  loading: boolean;
  error: string | null;
}

export const SenatoStatistics: React.FC<SenatoStatisticsProps> = ({ 
  chartData, 
  loading, 
  error 
}) => {
  const useChartDimensions = () => {
    const [dimensions, setDimensions] = useState({ width: 400, height: 400 });

    useEffect(() => {
      const updateDimensions = () => {
        const width = window.innerWidth;
        // For mobile devices - make it smaller
        if (width < 600) {
          setDimensions({ width: 280, height: 280 });
        }
        // For tablets
        else if (width < 960) {
          setDimensions({ width: 380, height: 380 });
        }
        // For desktop
        else {
          setDimensions({ width: 380, height: 380 });
        }
      };

      updateDimensions();
      window.addEventListener('resize', updateDimensions);
      return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return dimensions;
  };

  const { width, height } = useChartDimensions();

  const chartSetting = {
    height,
    width,
    margin: { right: 0 },
    legend: {
      hidden: true
    }
  };

  const LegendItem = ({ color, label, percentage }: { color: string; label: string; percentage: string }) => (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      mb: 1,
      width: '100%',
      maxWidth: { xs: '280px', sm: '100%' },
      gap: 1
    }}>
      <Box 
        sx={{ 
          width: 10, 
          height: 10, 
          bgcolor: color, 
          borderRadius: '50%',
          flexShrink: 0
        }} 
      />
      <Typography 
        variant="body2" 
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          whiteSpace: 'nowrap',
          fontSize: { xs: '0.8rem', sm: '0.875rem' },
          color: 'text.primary'
        }}
      >
        {label}: {percentage}%
      </Typography>
    </Box>
  );

  const ChartWithLegend = ({ 
    data, 
    title 
  }: { 
    data: ChartDataItem[];
    title: string;
  }) => {
    const { totals } = getTotals(data);
    
    return (
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom align="center" sx={{ pt: 2, color: 'text.primary' }}>
          {title}
        </Typography>
        <Box sx={{ 
          py: 2,
          px: { xs: 1, sm: 2 }, 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' }, 
          alignItems: 'center',
          justifyContent: 'center',
          gap: { xs: 2, sm: 3 },
          position: 'relative',
          width: '100%',
          overflow: 'hidden'
        }}>
          <Box sx={{ 
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'center', sm: 'flex-start' },
            justifyContent: { xs: 'center', sm: 'flex-start' },
            position: 'relative',
            gap: { xs: 2, sm: 4 }
          }}>
            <Box sx={{
              width: { xs: '100%', sm: 'auto' },
              display: 'flex',
              justifyContent: 'center'
            }}>
              <PieChart
                series={[{
                  data,
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30 },
                }]}
                {...chartSetting}
              />
            </Box>
            <Box sx={{ 
              position: { xs: 'static', sm: 'static' },
              width: '100%',
              maxWidth: { xs: '280px', sm: '200px' },
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', sm: 'flex-start' },
              pl: { xs: 0, sm: 0 },
              mt: { xs: 0, sm: 4 }
            }}>
              {totals.map((item, index) => (
                <LegendItem
                  key={index}
                  color={data[index].color}
                  label={item.label}
                  percentage={item.percentage}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const getSenatoRoleChartData = (): ChartDataItem[] => {
    if (!chartData) return [];
    return [
      {
        id: 0,
        value: chartData.Governo.Senatore,
        label: 'Governo',
        color: '#42a5f5' // Blu più chiaro
      },
      {
        id: 1,
        value: chartData.Opposizione.Senatore,
        label: 'Opposizione',
        color: '#ef5350' // Rosso più chiaro
      }
    ];
  };

  const getSenatoGenderChartData = (): ChartDataItem[] => {
    if (!chartData) return [];
    
    return [
      {
        id: 0,
        value: chartData.Governo.senatori_male,
        label: 'Uomini Governo',
        color: '#42a5f5' // Blu più chiaro
      },
      {
        id: 1,
        value: chartData.Governo.senatori_female,
        label: 'Donne Governo',
        color: '#90caf9' // Blu ancora più chiaro
      },
      {
        id: 2,
        value: chartData.Opposizione.senatori_male,
        label: 'Uomini Opposizione',
        color: '#ef5350' // Rosso più chiaro
      },
      {
        id: 3,
        value: chartData.Opposizione.senatori_female,
        label: 'Donne Opposizione',
        color: '#ffcdd2' // Rosso ancora più chiaro
      }
    ];
  };

  const getSenatoAgeChartData = (): ChartDataItem[] => {
    if (!chartData) return [];
    const ageRanges = Object.keys(chartData.Governo.senatori_age_ranges);
    const data: ChartDataItem[] = [];
    let id = 0;

    // Prima aggiungiamo i dati del governo
    for (const range of ageRanges) {
      const governoValue = chartData.Governo.senatori_age_ranges[range];
      if (governoValue > 0) {
        data.push({
          id: id++,
          value: governoValue,
          label: `${range} (Governo)`,
          color: `rgba(66, 165, 245, ${0.6 + (id * 0.1)})` // Blu più chiaro con opacità variabile
        });
      }
    }

    // Poi aggiungiamo i dati dell'opposizione
    for (const range of ageRanges) {
      const opposizioneValue = chartData.Opposizione.senatori_age_ranges[range];
      if (opposizioneValue > 0) {
        data.push({
          id: id++,
          value: opposizioneValue,
          label: `${range} (Opposizione)`,
          color: `rgba(239, 83, 80, ${0.6 + (id * 0.1)})` // Rosso più chiaro con opacità variabile
        });
      }
    }

    return data;
  };

  const getTotals = (data: ChartDataItem[]) => {
    const total = data.reduce((sum, item) => sum + item.value, 0);
    const totals = data.map(item => ({
      label: item.label,
      percentage: ((item.value / total) * 100).toFixed(1)
    }));
    return { total, totals };
  };

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error" align="center" sx={{ mt: 4, mb: 4 }}>{error}</Typography>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <ChartWithLegend
            data={getSenatoRoleChartData()}
            title="Generale"
          />
          <ChartWithLegend
            data={getSenatoGenderChartData()}
            title="x Genere"
          />
          <ChartWithLegend
            data={getSenatoAgeChartData()}
            title="x Età"
          />
        </Box>
      )}
    </>
  );
};
