import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  CircularProgress,
} from '@mui/material';
import { supabase } from '../../../lib/supabaseClient';
import { CameraStatistics } from './CameraStatistics';
import { SenatoStatistics } from './SenatoStatistics';
import { useLocation } from 'react-router-dom';

type ViewType = 'role' | 'gender' | 'age';

interface DeputatiData {
  government_position: 'Governo' | 'Opposizione';
  gender: 'male' | 'female';
  title: 'Deputato' | 'Senatore';
  age_range: string;
  politician_count: number;
}

interface ChartDataItem {
  Deputato: number;
  Senatore: number;
  deputati_male: number;
  deputati_female: number;
  senatori_male: number;
  senatori_female: number;
  deputati_age_ranges: Record<string, number>;
  senatori_age_ranges: Record<string, number>;
  total: number;
}

interface ChartData {
  Governo: ChartDataItem;
  Opposizione: ChartDataItem;
}

export const StatisticsView: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<DeputatiData[]>([]);
  const [total, setTotal] = useState(0);
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const location = useLocation();
  
  // Determina quale vista mostrare in base al parametro nell'URL
  const searchParams = new URLSearchParams(location.search);
  const viewType = searchParams.get('view') || 'camera';

  const processDataForChart = (rawData: DeputatiData[]): ChartData => {
    // Inizializza l'oggetto con i valori di default
    const initialData: ChartData = {
      Governo: {
        Deputato: 0,
        Senatore: 0,
        deputati_male: 0,
        deputati_female: 0,
        senatori_male: 0,
        senatori_female: 0,
        deputati_age_ranges: {},
        senatori_age_ranges: {},
        total: 0
      },
      Opposizione: {
        Deputato: 0,
        Senatore: 0,
        deputati_male: 0,
        deputati_female: 0,
        senatori_male: 0,
        senatori_female: 0,
        deputati_age_ranges: {},
        senatori_age_ranges: {},
        total: 0
      }
    };

    // Inizializza i range di età
    const ageRanges = ['18-30', '31-40', '41-50', '51-60', '61-70', '71+'];
    ageRanges.forEach(range => {
      initialData.Governo.deputati_age_ranges[range] = 0;
      initialData.Governo.senatori_age_ranges[range] = 0;
      initialData.Opposizione.deputati_age_ranges[range] = 0;
      initialData.Opposizione.senatori_age_ranges[range] = 0;
    });

    const result = rawData.reduce((acc, item) => {
      // Validate the required fields
      if (!item || typeof item !== 'object') {
        console.warn('Invalid item in rawData:', item);
        return acc;
      }

      const key = item.government_position;
      if (!key || (key !== 'Governo' && key !== 'Opposizione')) {
        console.warn('Invalid government_position:', item);
        return acc;
      }

      const title = item.title;
      if (!title || (title !== 'Deputato' && title !== 'Senatore')) {
        console.warn('Invalid title:', item);
        return acc;
      }
      
      // Count by title
      acc[key][title] += item.politician_count;
      
      // Count by gender and title
      if (item.gender === 'male') {
        if (item.title === 'Deputato') {
          acc[key].deputati_male += item.politician_count;
        } else if (item.title === 'Senatore') {
          acc[key].senatori_male += item.politician_count;
        }
      } else if (item.gender === 'female') {
        if (item.title === 'Deputato') {
          acc[key].deputati_female += item.politician_count;
        } else if (item.title === 'Senatore') {
          acc[key].senatori_female += item.politician_count;
        }
      }

      // Count by age range and title
      if (item.title === 'Deputato') {
        if (!acc[key].deputati_age_ranges[item.age_range]) {
          acc[key].deputati_age_ranges[item.age_range] = 0;
        }
        acc[key].deputati_age_ranges[item.age_range] += item.politician_count;
      } else if (item.title === 'Senatore') {
        if (!acc[key].senatori_age_ranges[item.age_range]) {
          acc[key].senatori_age_ranges[item.age_range] = 0;
        }
        acc[key].senatori_age_ranges[item.age_range] += item.politician_count;
      }

      // Update total count for this position
      acc[key].total = acc[key].Deputato + acc[key].Senatore;
      
      return acc;
    }, initialData);

    // Log dei totali per verifica
    console.log('=== CAMERA ===');
    console.log('Governo Deputati:', result.Governo.Deputato);
    console.log('Governo Deputati M/F:', result.Governo.deputati_male, result.Governo.deputati_female);
    console.log('Governo Deputati età:', result.Governo.deputati_age_ranges);
    console.log('Opposizione Deputati:', result.Opposizione.Deputato);
    console.log('Opposizione Deputati M/F:', result.Opposizione.deputati_male, result.Opposizione.deputati_female);
    console.log('Opposizione Deputati età:', result.Opposizione.deputati_age_ranges);

    console.log('=== SENATO ===');
    console.log('Governo Senatori:', result.Governo.Senatore);
    console.log('Governo Senatori M/F:', result.Governo.senatori_male, result.Governo.senatori_female);
    console.log('Governo Senatori età:', result.Governo.senatori_age_ranges);
    console.log('Opposizione Senatori:', result.Opposizione.Senatore);
    console.log('Opposizione Senatori M/F:', result.Opposizione.senatori_male, result.Opposizione.senatori_female);
    console.log('Opposizione Senatori età:', result.Opposizione.senatori_age_ranges);

    return result;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: rawData, error: politiciansError } = await supabase
          .rpc('get_deputati_data');

        console.log('Raw data from Supabase:', JSON.stringify(rawData, null, 2));

        if (politiciansError) {
          console.error('Error fetching politicians:', politiciansError);
          throw politiciansError;
        }

        if (!rawData) {
          throw new Error('No data received from Supabase');
        }

        setData(rawData);
        setTotal(rawData.reduce((sum: number, item: DeputatiData) => sum + item.politician_count, 0));
        setChartData(processDataForChart(rawData));
        
      } catch (err) {
        console.error('Detailed error:', err);
        let errorMessage = 'Si è verificato un errore durante il caricamento dei dati.';
        if (err instanceof Error) {
          errorMessage += ' Dettagli: ' + err.message;
        } else if (typeof err === 'object' && err !== null) {
          errorMessage += ' Dettagli: ' + JSON.stringify(err);
        }
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {/* Titolo fisso */}
      <Box 
        sx={{ 
          position: 'fixed', 
          top: 0,
          left: 0,
          right: 0,
          width: '100%',
          zIndex: 900, 
          backgroundColor: 'background.paper',
          pt: 1.5,
          pb: 1.5,
          borderBottom: '2px solid',
          borderColor: viewType === 'camera' ? 'primary.main' : 'error.main',
          display: 'flex',
          justifyContent: 'center',
          boxShadow: 2
        }}
      >
        <Typography variant="h5" align="center" sx={{ color: 'text.primary', fontWeight: 'bold' }}>
          Statistiche {viewType === 'camera' ? 'Camera' : 'Senato'}
        </Typography>
      </Box>
      
      <Container maxWidth="xl">
        {/* Spazio vuoto per compensare il titolo fisso */}
        <Box sx={{ height: '60px' }} />
        
        <Box sx={{ mb: 4, px: 0 }}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography color="error" align="center">{error}</Typography>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
              {viewType === 'camera' ? (
                <CameraStatistics chartData={chartData} loading={loading} error={error} />
              ) : (
                <SenatoStatistics chartData={chartData} loading={loading} error={error} />
              )}
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};
