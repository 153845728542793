/**
 * Utility per gestire la modalità fullscreen su dispositivi mobili
 */

// Verifica se il dispositivo è mobile
export const isMobile = (): boolean => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Richiede la modalità fullscreen
export const requestFullscreen = (): void => {
  if (!isMobile()) return;
  
  const doc = document.documentElement;
  
  if (doc.requestFullscreen) {
    doc.requestFullscreen().catch(err => {
      console.log('Errore durante la richiesta fullscreen:', err);
    });
  } else {
    // Fallback per iOS
    const metaViewport = document.querySelector('meta[name=viewport]');
    if (metaViewport) {
      metaViewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, minimal-ui');
    }
  }
};

// Esce dalla modalità fullscreen
export const exitFullscreen = (): void => {
  if (document.exitFullscreen && document.fullscreenElement) {
    document.exitFullscreen().catch(err => {
      console.log('Errore durante l\'uscita da fullscreen:', err);
    });
  }
};

// Attiva la modalità fullscreen al tocco (da utilizzare come event listener)
export const toggleFullscreenOnTouch = (): void => {
  if (!document.fullscreenElement) {
    requestFullscreen();
  }
};

// Inizializza i listener per la modalità fullscreen
export const initFullscreenHandlers = (): void => {
  if (!isMobile()) return;
  
  // Aggiungi listener per il tocco
  document.addEventListener('touchend', toggleFullscreenOnTouch, { passive: true });
  
  // Gestisci l'orientamento per iOS
  window.addEventListener('orientationchange', () => {
    setTimeout(() => {
      if (!document.fullscreenElement) {
        requestFullscreen();
      }
    }, 300);
  });
  
  // Richiedi fullscreen al caricamento
  window.addEventListener('load', () => {
    setTimeout(requestFullscreen, 1000);
  });
};
