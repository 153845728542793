import { createTheme, ThemeOptions } from '@mui/material/styles';

const baseTheme: Partial<ThemeOptions> = {
  typography: {
    fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h1: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: '3.5rem',
      fontWeight: 800,
      letterSpacing: '-0.02em',
    },
    h2: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: '2.5rem',
      fontWeight: 700,
      letterSpacing: '-0.01em',
    },
    h3: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: '2rem',
      fontWeight: 700,
    },
    h4: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h5: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h6: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.6,
    },
    button: {
      textTransform: 'none' as const,
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '8px 24px',
          fontSize: '1rem',
          '&.Mui-disabled': {
            backgroundColor: '#ad0e38',
            color: '#fff'
          }
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#ad0e38',
          },
        },
        outlinedSecondary: {
          borderColor: '#CE2B37',
          color: '#CE2B37',
          '&:hover': {
            borderColor: '#B71C1C',
            backgroundColor: 'rgba(206, 43, 55, 0.04)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
  },
};

export const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'light',
    primary: {
      main: '#ad0e38', // Primary color
      light: '#c42e54',
      dark: '#8c0b2d',
      contrastText: '#fff'
    },
    secondary: {
      main: '#CE2B37', // Italian flag red
      light: '#E34653',
      dark: '#B71C1C',
      contrastText: '#ffffff'
    },
    background: {
      default: '#f4f4f4',
      paper: '#ffffff',
    },
    text: {
      primary: '#1A1A1A',
      secondary: '#666666'
    },
    error: {
      main: '#CE2B37',
      light: '#E34653',
      dark: '#B71C1C'
    },
    success: {
      main: '#ad0e38',
      light: '#c42e54',
      dark: '#8c0b2d',
      contrastText: '#fff'
    },
    info: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#ad0e38',
      contrastText: '#fff'
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#ad0e38',
      contrastText: '#fff'
    },
  },
} as ThemeOptions);

export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: '#c42e54', // Brighter color for dark mode
      light: '#ff5f6d',
      dark: '#ad0e38',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#E34653', // Brighter red for dark mode
      light: '#FF5F6D',
      dark: '#CE2B37',
      contrastText: '#ffffff'
    },
    background: {
      default: '#1a1a1a', // Modificato da #333333 a #1a1a1a
      paper: '#444444',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B0B0B0'
    },
    error: {
      main: '#E34653',
      light: '#FF5F6D',
      dark: '#CE2B37'
    },
    success: {
      main: '#c42e54',
      light: '#ff5f6d',
      dark: '#ad0e38'
    },
    info: {
      main: '#1976d2',
      light: '#42a5f5',
      dark: '#ad0e38',
      contrastText: '#fff'
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#ad0e38',
      contrastText: '#fff'
    },
  },
} as ThemeOptions);
