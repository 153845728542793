import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CssBaseline } from '@mui/material';
import './index.css'; // Import global CSS
import { initFullscreenHandlers } from './utils/fullscreen';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CssBaseline />
    <App />
  </React.StrictMode>
);

// Registrazione del service worker per la PWA
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registrato con successo:', registration.scope);
      })
      .catch(error => {
        console.log('Errore nella registrazione del Service Worker:', error);
      });
  });
}

// Inizializza i gestori della modalità fullscreen
initFullscreenHandlers();

// Funzione per nascondere le barre del browser solo su dispositivi mobili
const hideAddressBar = () => {
  // Verifica se è un dispositivo mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
  // Attiva fullscreen solo su dispositivi mobili
  if (isMobile && document.documentElement.requestFullscreen && !document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  }
};

// Aggiungere un listener per il click sull'app per attivare la modalità fullscreen solo su mobile
document.addEventListener('click', hideAddressBar);
