import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  Avatar,
  IconButton,
  Paper,
  CircularProgress,
  Rating,
  Tooltip,
  Collapse,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { supabase } from '../../../lib/supabaseClient';
import { Politician } from '../../../types/database.types';
import { politicianService } from '../../../services/politicianService';

interface PoliticianCardDetailsProps {
  politician: Politician | null;
  role: string;
  onClose: () => void;
  onVoteSubmitted?: () => void;
}

// Interface for skills
interface Skill {
  id: string;
  property: string;
  description: string;
  rating?: number;
  total_votes?: number;
  politician_id: string;
  ord: number;
  type?: string;
}

// Stile comune per le stelle di valutazione
const ratingStyles = {
  '& .MuiRating-iconFilled': {
    color: '#FFD700',
  },
  '& .MuiRating-iconEmpty': {
    color: 'rgba(255, 215, 0, 0.3)',
  },
  '& .MuiRating-iconHover': {
    color: '#FFD700',
  }
};

export const PoliticianCardDetails: React.FC<PoliticianCardDetailsProps> = ({
  politician,
  role,
  onClose,
  onVoteSubmitted
}) => {
  const theme = useTheme();
  const [skills, setSkills] = useState<Skill[]>([]);
  const [filteredSkills, setFilteredSkills] = useState<Skill[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [expandedSkill, setExpandedSkill] = useState<string | null>(null);
  const [canVote, setCanVote] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [lastVoteInfo, setLastVoteInfo] = useState<{
    date: Date;
    daysRemaining: number;
  } | null>(null);
  const [pendingVotes, setPendingVotes] = useState<Record<string, number>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [voteCount, setVoteCount] = useState<number>(0);

  useEffect(() => {
    if (politician) {
      loadSkills(politician.id);
      checkLastVote(politician.id);
      loadVoteCount(politician.id);
    }
  }, [politician]);

  // Filtra gli skill in base al ruolo del politico
  useEffect(() => {
    if (skills.length > 0) {
      // Se il politico ha una carica come "Giornalista", mostra solo gli skill di tipo "Giornalista"
      // altrimenti mostra solo gli skill di tipo "Politico"
      const isJournalist = role.toLowerCase().includes('giornalista');
      const skillType = isJournalist ? 'Giornalista' : 'Politico';
      
      console.log(`Filtering skills by type: ${skillType} for role: ${role}`);
      
      const filtered = skills.filter(skill => skill.type === skillType);
      setFilteredSkills(filtered);
      
      console.log('Filtered skills:', filtered);
    } else {
      setFilteredSkills([]);
    }
  }, [skills, role]);

  const loadSkills = async (politicianId: string) => {
    try {
      console.log('Loading skills for politician ID:', politicianId);
      setLoading(true);
      
      // Carica le skills con i voti dalla materialized view
      const { data: skillsData, error: skillsError } = await supabase
        .from('skills')
        .select(`
          id,
          property,
          description,
          ord,
          type,
          skill_vote_stats!left(
            total_votes,
            avg_rating
          )
        `)
        .eq('skill_vote_stats.politician_id', politicianId)
        .order('ord', { ascending: true })
        .order('property', { ascending: true });

      if (skillsError) throw skillsError;

      console.log('Raw skills data with votes:', skillsData);

      // Trasforma i dati nel formato corretto usando i dati pre-calcolati
      const skillsWithVotes = (skillsData || []).map(skill => {
        const stats = skill.skill_vote_stats?.[0];
        
        return {
          id: skill.id,
          property: skill.property,
          description: skill.description,
          ord: skill.ord || 0,
          politician_id: politicianId,
          rating: stats?.avg_rating || 0,
          total_votes: stats?.total_votes || 0,
          type: skill.type || 'Politico'
        };
      });

      console.log('Final skills with votes:', skillsWithVotes);
      setSkills(skillsWithVotes);
      setError(null);
    } catch (error: any) {
      console.error('Error loading skills:', error);
      setError('Errore nel caricamento delle competenze');
    } finally {
      setLoading(false);
    }
  };

  const checkLastVote = async (politicianId: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.log('No user found, disabling voting');
        setCanVote(false);
        setLastVoteInfo(null);
        return;
      }

      // Verifica l'ultimo voto dell'utente per questo politico
      const { data } = await supabase
        .from('politician_votes_history')
        .select('created_at')
        .eq('politician_id', politicianId)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (data) {
        const lastVoteDate = new Date(data.created_at);
        const today = new Date();
        
        // Calcola quanti giorni sono passati dall'ultimo voto
        const daysSinceLastVote = Math.floor((today.getTime() - lastVoteDate.getTime()) / (1000 * 60 * 60 * 24));
        
        // Se sono passati meno di 30 giorni, l'utente non può votare di nuovo
        if (daysSinceLastVote < 30) {
          setCanVote(false);
          setLastVoteInfo({
            date: lastVoteDate,
            daysRemaining: 30 - daysSinceLastVote
          });
        } else {
          setCanVote(true);
          setLastVoteInfo(null);
        }
      } else {
        // Se non ha mai votato, può votare
        setCanVote(true);
        setLastVoteInfo(null);
      }
    } catch (error) {
      console.error('Error checking last vote:', error);
      setCanVote(false);
    }
  };

  const loadVoteCount = async (politicianId: string) => {
    try {
      const count = await politicianService.getPoliticianVoteCount(politicianId);
      setVoteCount(count);
    } catch (error) {
      console.error('Error loading vote count:', error);
    }
  };

  const handleSkillVote = (skillId: string, rating: number) => {
    setPendingVotes(prev => ({
      ...prev,
      [skillId]: rating
    }));
  };

  const handleSubmitVotes = async () => {
    try {
      setIsSaving(true);
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      
      if (userError) throw userError;
      if (!user) throw new Error('User not found');
      if (!politician?.id) throw new Error('Politician ID not found');

      console.log('Saving votes:', pendingVotes);

      // Save each vote using the function
      for (const [skillId, rating] of Object.entries(pendingVotes)) {
        const { error } = await supabase
          .rpc('insert_vote_and_refresh_stats', {
            p_politician_id: politician.id,
            p_skill_id: skillId,
            p_rating: rating
          });

        if (error) throw error;
      }

      // Record voting history (with user_id for tracking who voted)
      const { error: historyError } = await supabase
        .from('politician_votes_history')
        .insert({
          politician_id: politician.id,
          user_id: user.id,
          voted_at: new Date().toISOString()
        });

      if (historyError) throw historyError;

      // Wait a short moment for any pending refreshes
      await new Promise(resolve => setTimeout(resolve, 500));

      // Reload skills to show updated ratings
      await loadSkills(politician.id);
      
      // Reload vote count to show updated count
      await loadVoteCount(politician.id);

      setEditMode(false);
      setPendingVotes({});
      setSuccessMessage(true);
      await checkLastVote(politician.id);
      
      // Call the onVoteSubmitted callback if provided
      if (onVoteSubmitted) {
        onVoteSubmitted();
      }
    } catch (error) {
      console.error('Error saving votes:', error);
      setError('Errore nel salvataggio dei voti. ' + (error as Error).message);
    } finally {
      setIsSaving(false);
    }
  };

  const areAllSkillsVoted = () => {
    return filteredSkills.every(skill => pendingVotes[skill.id] !== undefined);
  };

  const handleEditMode = () => {
    setEditMode(true);
    setPendingVotes({});  
  };

  if (!politician) {
    return null;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: theme.palette.background.default,
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        borderRadius: 0
      }}
    >
      {/* Header with photo and close button */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={politician?.image_url || '/placeholder.png'}
            alt={`${politician?.first_name} ${politician?.last_name}`}
            sx={{ width: 50, height: 50, mr: 2 }}
          />
          <Box>
            <Typography variant="h6" sx={{ color: theme.palette.text.primary, fontSize: '1.1rem' }}>
              {politician?.first_name} {politician?.last_name}
            </Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: '0.85rem' }}>
              {role}
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                color: theme.palette.primary.main, 
                fontWeight: 'bold',
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.85rem'
              }}
            >
              <HowToVoteIcon sx={{ fontSize: '0.9rem', mr: 0.5 }} />
              È stato votato {voteCount}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={onClose} sx={{ color: theme.palette.text.primary }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Skills/Preferences */}
      <Box sx={{ p: 1.5, flex: 1, overflow: 'auto' }}>
        <Typography variant="subtitle1" gutterBottom sx={{ color: theme.palette.text.primary, fontSize: '0.95rem' }}>
          Competenze
        </Typography>
        
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
            <CircularProgress color="primary" />
            <Typography sx={{ ml: 2, color: theme.palette.text.secondary }}>
              Caricamento preferenze...
            </Typography>
          </Box>
        ) : error ? (
          <Typography sx={{ color: theme.palette.text.secondary, py: 2 }}>
            {error}
          </Typography>
        ) : filteredSkills.length === 0 ? (
          <Typography sx={{ color: theme.palette.text.secondary, py: 2 }}>
            Nessuna competenza disponibile per questo politico.
          </Typography>
        ) : (
          <Box sx={{ mt: 1 }}>
            {filteredSkills.map((skill, index) => (
              <Box 
                key={skill.id} 
                sx={{ 
                  mb: 2, 
                  position: 'relative',
                  borderRadius: 1
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Mostra descrizione">
                      <IconButton 
                        size="small" 
                        sx={{ mr: 1, color: theme.palette.primary.main }}
                        onClick={() => setExpandedSkill(expandedSkill === skill.id ? null : skill.id)}
                      >
                        {expandedSkill === skill.id ? 
                          <ExpandLessIcon fontSize="small" /> : 
                          <ExpandMoreIcon fontSize="small" />
                        }
                      </IconButton>
                    </Tooltip>
                    <Typography sx={{ color: theme.palette.text.primary, fontSize: '0.85rem' }}>
                      {skill.property}
                    </Typography>
                  </Box>
                  <Rating 
                    value={editMode ? pendingVotes[skill.id] || 0 : skill.rating || 0}
                    precision={editMode ? 1 : 0.1}
                    readOnly={!editMode}
                    onChange={(event, newValue) => {
                      if (editMode) {
                        handleSkillVote(skill.id, newValue || 0);
                      }
                    }}
                    sx={ratingStyles}
                  />
                </Box>
                
                <Collapse in={expandedSkill === skill.id}>
                  <Typography sx={{ 
                    color: theme.palette.text.secondary, 
                    mb: 1, 
                    fontSize: '0.8rem',
                    fontStyle: 'italic',
                    pl: 4
                  }}>
                    {skill.description}
                  </Typography>
                </Collapse>
                
                <Divider sx={{ mt: 1.5, bgcolor: theme.palette.divider }} />
              </Box>
            ))}
          </Box>
        )}
        
        {/* Bottoni per votare */}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', px: 2, pb: 2 }}>
          {lastVoteInfo ? (
            <Box sx={{ 
              bgcolor: theme.palette.error.main, 
              color: theme.palette.error.contrastText,
              p: 1.5,
              width: '100%',
              maxWidth: '360px',
              borderRadius: '8px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              mx: 'auto'
            }}>
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                  Ultima preferenza
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.9 }}>
                  {lastVoteInfo.date.toLocaleDateString('it-IT', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Potrai rivotarlo fra
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                  {lastVoteInfo?.daysRemaining} giorni
                </Typography>
              </Box>
            </Box>
          ) : canVote && !editMode ? (
            // Mostra il bottone solo se non ha mai votato e non è in modalità modifica
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleEditMode}
            >
              Esprimi la tua preferenza
            </Button>
          ) : null}

          {editMode && canVote && (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 2, 
              width: '100%' 
            }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmitVotes}
                disabled={!areAllSkillsVoted() || isSaving}
              >
                {isSaving ? 'Salvataggio in corso...' : 'Salva preferenze'}
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                onClick={() => {
                  setEditMode(false);
                  setPendingVotes({});
                }}
                sx={{ color: theme.palette.text.primary, borderColor: theme.palette.divider }}
              >
                Annulla
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Snackbar 
        open={successMessage} 
        autoHideDuration={6000} 
        onClose={() => setSuccessMessage(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSuccessMessage(false)} 
          severity="success"
          sx={{ 
            bgcolor: theme.palette.success.main, 
            color: theme.palette.success.contrastText,
            fontWeight: 'bold',
            width: '100%',
            '& .MuiAlert-icon': {
              color: theme.palette.success.contrastText
            }
          }}
        >
          Preferenze salvate con successo!
        </Alert>
      </Snackbar>
    </Paper>
  );
};
