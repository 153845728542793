export const italianTranslations = {
  common: {
    loading: 'Caricamento...',
    error: 'Si è verificato un errore',
    success: 'Operazione completata con successo',
    save: 'Salva',
    cancel: 'Annulla',
    edit: 'Modifica',
    delete: 'Elimina',
    back: 'Indietro',
    close: 'Chiudi',
    search: 'Cerca',
    noDescription: 'Nessuna descrizione disponibile.',
    details: 'Dettagli',
    actions: 'Azioni',
    confirm: 'Conferma',
    next: 'Avanti',
  },
  landing: {
    title: 'Connettere i Cittadini con la Democrazia Locale',
    subtitle: 'Connettiti con la Tua Democrazia Locale',
    description: 'Rimani informato, interagisci con i politici e fai sentire la tua voce nella tua comunità.',
    anonymityNote: 'Le tue preferenze sui politici saranno completamente anonime.',
    features: {
      politicians: {
        title: 'Connettiti con i Politici',
        description: 'Interagisci direttamente con i tuoi rappresentanti locali e rimani aggiornato sulle loro attività.'
      },
      engagement: {
        title: 'Partecipazione Democratica',
        description: 'Partecipa alla democrazia locale seguendo le iniziative politiche e fornendo feedback.'
      },
      transparency: {
        title: 'Governance Trasparente',
        description: 'Accedi a informazioni chiare sui partiti politici e le loro posizioni sulle questioni locali.'
      }
    },
    buttons: {
      signIn: 'Accedi',
      signUp: 'Registrati'
    }
  },
  auth: {
    welcome: 'Benvenuto in Polinder',
    signInPrompt: 'Accedi per continuare',
    signIn: 'Accedi',
    signUp: 'Registrati',
    continueWithGoogle: 'Continua con Google',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Conferma Password',
    passwordsDoNotMatch: 'Le password non corrispondono',
    emailAlreadyInUse: 'Email già in uso',
    emailNotConfirmed: 'Email non confermata. Ti abbiamo inviato una nuova email di conferma.',
    confirmationEmailResent: 'Email di conferma inviata con successo',
    resetPassword: 'Reimposta Password',
    forgotPassword: 'Password dimenticata?',
    updatePassword: 'Aggiorna Password',
    newPassword: 'Nuova Password',
    backToSignIn: 'Torna al login',
    alreadyHaveAccount: 'Hai già un account? Accedi',
    dontHaveAccount: 'Non hai un account? Registrati',
    verifyingEmail: 'Verifica dell\'email in corso...',
    age: 'Età',
    ageHelperText: 'Devi avere almeno 18 anni',
    signUpEmailConfirmation: 'Ti abbiamo inviato una email di conferma. Per favore, controlla la tua casella email e clicca sul link per completare la registrazione.',
    emailRequired: 'Email richiesta',
    newPasswordRequired: 'Nuova password richiesta',
    forgotPasswordSuccess: 'Le istruzioni per il reset della password sono state inviate alla tua email',
    passwordResetSuccess: 'Password aggiornata con successo! Ora puoi accedere con la nuova password.',
    birthDate: 'Data di nascita',
    birthDateRequired: 'Data di nascita richiesta',
    residentPlace: 'Luogo di residenza',
    residentPlaceRequired: 'Luogo di residenza richiesto',
    gender: 'Genere',
    selectGender: 'Seleziona genere',
    male: 'Maschio',
    female: 'Femmina',
    other: 'Altro',
    activateAccount: 'Attiva il tuo Account',
    activate: 'Attiva',
    allFieldsRequired: 'Tutti i campi sono obbligatori',
    activationRequired: 'Per accedere al portale, completa l\'attivazione del tuo account',
    livingAbroad: 'Vivi all\'estero',
    country: 'Nazione',
    selectCountry: 'Seleziona nazione',
  },
  tour: {
    welcome: 'Benvenuto in Polinder!',
    welcomeDescription: 'Ti guideremo attraverso le funzionalità principali dell\'app.',
    swipeHorizontal: 'Scorri a destra o sinistra per navigare tra i diversi politici. Puoi esprimere le tue preferenze con un semplice swipe.',
    swipeUp: 'Scorri verso l\'alto per vedere più dettagli e dare la tua preferenza al politico.',
    navbar: 'Usa la barra di navigazione in basso per accedere alle diverse sezioni dell\'app: Home, Partiti, Politici, Statistiche e il tuo Profilo.',
    skip: 'Salta',
    next: 'Avanti',
    back: 'Indietro',
    last: 'Fine',
    close: 'Chiudi'
  },
  intro: {
    title: 'Benvenuto su Polinder',
    subtitle: 'Scopri e interagisci con i politici italiani',
    swipeInstructions: 'Come funziona',
    swipeLeftDislike: 'Scorri a sinistra',
    swipeRightLike: 'Scorri a destra',
    startButton: 'Inizia a scoprire'
  },
  politicians: {
    intro: {
      title: 'Politici Italiani',
      subtitle: 'Esplora i profili dei politici italiani',
      selectCategory: 'Seleziona una categoria',
      allPoliticians: 'Tutti i Politici',
      deputies: 'Deputati',
      senators: 'Senatori',
      presidents: 'Presidenti',
      ministers: 'Ministri',
      journalists: 'Giornalisti',
      parties: 'Partiti',
      localPoliticians: 'Politici Regionali'
    }
  },
  statistics: {
    intro: {
      title: 'Statistiche',
      subtitle: 'Esplora le statistiche dei politici italiani',
      selectCategory: 'Seleziona una categoria',
      camera: 'Camera',
      senato: 'Senato'
    }
  },
  navigation: {
    dashboard: 'Dashboard',
    politicians: 'Politici',
    parties: 'Partiti',
    places: 'Luoghi',
    politicalOffices: 'Cariche Politiche',
    skills: 'Competenze',
    users: 'Utenti',
    lists: 'Liste',
  },
  politician: {
    title: 'Politici',
    details: 'Dettagli Politico',
    about: 'Informazioni',
    personalDetails: 'Dati Personali',
    politicalOffices: 'Cariche Politiche',
    educationLevel: 'Titolo di studio',
    socialMedia: 'Social Media',
    birthDate: 'Data di Nascita',
    birthPlace: 'Luogo di Nascita',
    gender: {
      male: 'Maschio',
      female: 'Femmina',
      other: 'Altro',
    },
    skills: 'Competenze',
    addNew: 'Aggiungi Politico',
    edit: 'Modifica Politico',
    delete: 'Elimina Politico',
    deleteConfirm: 'Sei sicuro di voler eliminare questo politico?',
    import: 'Importa Politici',
    importDescription: 'Inserisci i nomi dei politici separati da virgole. Il sistema cercherà automaticamente le loro informazioni.',
    backToList: 'Torna alla Lista',
    filterActive: 'Filtro Partito Attivo',
    clearFilter: 'Rimuovi Filtro',
    searchPlaceholder: 'Cerca politici per nome',
    officeFilter: 'Filtra per Carica',
    noOfficeSelected: 'Tutte le Cariche',
    voting: {
      lastVote: 'Ultima preferenza',
      canVoteAgain: 'Potrai rivotarlo fra',
      days: 'giorni',
      vote: 'Vota',
      submitted: 'Voto inviato',
      submitError: 'Errore nell\'invio del voto'
    },
  },
  party: {
    title: 'Partiti',
    details: 'Dettagli Partito',
    addNew: 'Aggiungi Partito',
    edit: 'Modifica Partito',
    delete: 'Elimina Partito',
    deleteConfirm: 'Sei sicuro di voler eliminare questo partito?',
  },
  parties: {
    intro: {
      title: 'Partiti Italiani',
      subtitle: 'Esplora i profili dei partiti politici italiani',
    }
  },
  politicalOffice: {
    title: 'Cariche Politiche',
    details: 'Dettagli Carica',
    addNew: 'Aggiungi Carica',
    edit: 'Modifica Carica',
    delete: 'Elimina Carica',
    deleteConfirm: 'Sei sicuro di voler eliminare questa carica?',
  },
  place: {
    title: 'Luoghi',
    details: 'Dettagli Luogo',
    addNew: 'Aggiungi Luogo',
    edit: 'Modifica Luogo',
    delete: 'Elimina Luogo',
    deleteConfirm: 'Sei sicuro di voler eliminare questo luogo?',
  },
  user: {
    title: 'Utenti',
    settings: 'Impostazioni',
    management: 'Gestione Utenti',
    roles: {
      admin: 'Amministratore',
      user: 'Utente',
    },
  },
  profile: {
    title: 'Profilo',
    personalInfo: 'Informazioni Personali',
    updateSuccess: 'Profilo aggiornato con successo',
    deleteAccount: 'Elimina Account',
    deleteAccountConfirmTitle: 'Conferma Eliminazione Account',
    deleteAccountConfirmMessage: 'Per eliminare il tuo account, inserisci la tua email per confermare. Questa azione è irreversibile e cancellerà tutti i tuoi dati e la cronologia.',
    confirmDelete: 'Conferma Eliminazione',
    enterEmail: 'Inserisci la tua email',
    emailMismatch: 'L\'email inserita non corrisponde',
    deleteSuccess: 'Account Eliminato',
    deleteSuccessMessage: 'Il tuo account è stato eliminato correttamente. Tutti i tuoi dati personali sono stati rimossi definitivamente.',
  },
  errors: {
    loadingFailed: 'Caricamento fallito',
    savingFailed: 'Salvataggio fallito',
    deletionFailed: 'Eliminazione fallita',
    notFound: 'Non trovato',
    unauthorized: 'Non autorizzato',
    generic: 'Si è verificato un errore',
  },
};
