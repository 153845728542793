import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Chip,
  Autocomplete,
  CircularProgress,
  Typography,
  Divider,
  IconButton,
  Alert,
  SelectChangeEvent,
} from '@mui/material';
import { 
  Twitter, 
  Facebook, 
  Instagram, 
  LinkedIn,
  PhotoCamera,
  Clear
} from '@mui/icons-material';
import { 
  Politician, 
  PoliticalOffice, 
  Database,
  Party,
  Place 
} from '../../../types/database.types';
import { politicalOfficeService } from '../../../services/politicalOfficeService';
import { partyService } from '../../../services/partyService';
import { placesService } from '../../../services/placesService';
import { storageService } from '../../../services/storageService';
import { userService } from '../../../services/userService';
import { debounce } from 'lodash';
import { supabase } from '../../../lib/supabaseClient';

interface AdminPoliticianDialogProps {
  open: boolean;
  politician: Politician | null;
  onClose: () => void;
  onSave: (politician: Omit<Politician, 'id'> & { id?: string }, politicianOffices: { political_office_id: string; assigned_place_id?: string }[]) => Promise<void>;
}

type PoliticianUpdate = Database['public']['Tables']['politicians']['Update'];

export const AdminPoliticianDialog: React.FC<AdminPoliticianDialogProps> = ({
  open,
  politician,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState<Partial<Politician>>(politician || {});
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [parties, setParties] = useState<Party[]>([]);
  const [birthPlaces, setBirthPlaces] = useState<Place[]>([]);
  const [birthPlace, setBirthPlace] = useState<Place | null>(null);
  const [assignedPlacesList, setAssignedPlacesList] = useState<Place[]>([]);
  const [assignedPlaces, setAssignedPlaces] = useState<{ [officeId: string]: Place | null }>({});
  const [searchingPlaces, setSearchingPlaces] = useState(false);
  const [politicalOffices, setPoliticalOffices] = useState<PoliticalOffice[]>([]);
  const [selectedOffices, setSelectedOffices] = useState<PoliticalOffice[]>([]);
  const [creatorEmail, setCreatorEmail] = useState<string>('');

  useEffect(() => {
    if (open) {
      console.log('Dialog opened with politician:', politician);
      const initialFormData = {
        first_name: '',
        last_name: '',
        description: '',
        party_id: '',
        birth_place_id: '',
        birth_date: '',
        image_url: '',
        gender: undefined,
        education_level: null,
        twitter_url: '',
        facebook_url: '',
        instagram_url: '',
        linkedin_url: '',
      };
      
      if (politician) {
        // Format birth_date if it exists
        let formattedBirthDate = '';
        if (politician.birth_date) {
          try {
            const date = new Date(politician.birth_date);
            if (!isNaN(date.getTime())) {
              formattedBirthDate = date.toISOString().split('T')[0];
            }
          } catch (e) {
            console.warn('Invalid birth date format:', politician.birth_date);
          }
        }

        const politicianData = {
          first_name: politician.first_name,
          last_name: politician.last_name,
          description: politician.description || '',
          party_id: politician.party_id || null,
          birth_place_id: politician.birth_place_id || null,
          birth_date: formattedBirthDate,
          image_url: politician.image_url || '',
          gender: politician.gender || undefined,
          education_level: politician.education_level || null,
          twitter_url: politician.twitter_url || '',
          facebook_url: politician.facebook_url || '',
          instagram_url: politician.instagram_url || '',
          linkedin_url: politician.linkedin_url || ''
        };
        
        console.log('Setting politician data:', politicianData);
        setFormData(politicianData);
        setImagePreview(politician.image_url || null);

        // Load the birth place if it exists
        if (politician.birth_place) {
          console.log('Setting birth place:', politician.birth_place);
          setBirthPlace(politician.birth_place);
          setBirthPlaces([politician.birth_place]);
        }
        
        // Fetch creator email
        if (politician.created_by) {
          userService.getUserEmail(politician.created_by).then(email => {
            setCreatorEmail(email);
          }).catch(err => {
            console.error('Error fetching creator email:', err);
          });
        } else {
          console.log('No created_by field found on politician:', politician);
        }
      } else {
        console.log('Setting initial form data:', initialFormData);
        setFormData(initialFormData);
        setImagePreview(null);
        setBirthPlaces([]);
      }
      
      setError(null);
      setAssignedPlacesList([]);
      
      loadInitialData().then(({ parties, offices }) => {
        if (politician?.politician_offices) {
          console.log('Raw politician offices:', politician.politician_offices);
          const mappedOffices = politician.politician_offices.map((po: any) => po.political_office);
          console.log('Mapped offices:', mappedOffices);
          setSelectedOffices(mappedOffices);

          // Initialize assigned places
          const initialAssignedPlaces: { [officeId: string]: Place | null } = {};
          politician.politician_offices.forEach((po: any) => {
            if (po.assigned_place) {
              initialAssignedPlaces[po.political_office.id] = po.assigned_place;
            }
          });
          console.log('Setting initial assigned places:', initialAssignedPlaces);
          setAssignedPlaces(initialAssignedPlaces);
        } else {
          setSelectedOffices([]);
          setAssignedPlaces({});
        }
      });
    }
  }, [open, politician]);

  const loadInitialData = async () => {
    try {
      console.log('Loading initial data...');
      const [partiesData, officesData] = await Promise.all([
        partyService.getParties(),
        politicalOfficeService.getPoliticalOffices(),
      ]);
      console.log('Loaded parties:', partiesData);
      console.log('Loaded political offices:', officesData);
      
      if (!partiesData || partiesData.length === 0) {
        console.warn('No parties loaded');
      }
      if (!officesData || officesData.length === 0) {
        console.warn('No political offices loaded');
      }
      
      setParties(partiesData);
      setPoliticalOffices(officesData || []);
      console.log('Initial data loaded:', { 
        parties: partiesData, 
        offices: officesData,
        selectedOffices,
        currentParty: formData.party_id
      });
      return { parties: partiesData, offices: officesData };
    } catch (error) {
      console.error('Error loading initial data:', error);
      setError('Failed to load initial data');
      return { parties: [], offices: [] };
    }
  };

  const handleClose = () => {
    setFormData({});
    setSelectedOffices([]);
    setImagePreview(null);
    setError(null);
    setCreatorEmail('');
    onClose();
  };

  // Search function for birth places (all places)
  const searchBirthPlaces = async (query: string) => {
    setSearchingPlaces(true);
    try {
      const places = await placesService.getPlaces(query);
      console.log('Found birth places:', places);
      setBirthPlaces(places);
    } catch (error) {
      console.error('Error searching places:', error);
    } finally {
      setSearchingPlaces(false);
    }
  };

  // Search function for assigned places (only active places)
  const searchAssignedPlaces = async (query: string) => {
    try {
      setSearchingPlaces(true);
      const results = await placesService.getActivePlaces(query);
      setAssignedPlacesList(results);
    } catch (error) {
      console.error('Error searching places:', error);
    } finally {
      setSearchingPlaces(false);
    }
  };

  const debouncedSearchBirthPlaces = useMemo(
    () => debounce(searchBirthPlaces, 300),
    []
  );

  const debouncedSearchAssignedPlaces = debounce(searchAssignedPlaces, 300);

  const handleInputChange = (field: keyof Politician, value: string | null | undefined) => {
    // For social media URLs, ensure they are either valid URLs or null
    if (['facebook_url', 'twitter_url', 'instagram_url', 'linkedin_url'].includes(field)) {
      if (value) {
        value = value.trim();
        if (value && !value.startsWith('https://')) {
          value = `https://${value}`;
        }
      }
      // Convert empty strings to null
      if (value === '' || value === undefined) {
        value = null;
      }
    }
    
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleGenderChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    const genderValue = value === '' ? undefined : value as 'male' | 'female' | 'other';
    setFormData((prev: any) => ({ ...prev, gender: genderValue }));
  };

  const handleEducationLevelChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setFormData(prev => ({
      ...prev,
      education_level: value === '' ? null : value as Politician['education_level']
    }));
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      setLoading(true);
      setError(null);

      // Upload to Supabase Storage using the storage service
      const publicUrl = await storageService.uploadImage(file, 'politician-images');
      
      setFormData((prev: any) => ({ ...prev, image_url: publicUrl }));
      setImagePreview(URL.createObjectURL(file));
    } catch (err) {
      console.error('Error uploading image:', err);
      setError(err instanceof Error ? err.message : 'Failed to upload image');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = async () => {
    try {
      setLoading(true);
      console.log('Removing image, current formData:', formData);
      if (formData.image_url) {
        console.log('Attempting to delete image from storage:', formData.image_url);
        await storageService.deleteImage(formData.image_url);
        console.log('Image deleted from storage successfully');
      }
      console.log('Setting image_url to null in formData');
      setFormData(prev => {
        const updated = { ...prev, image_url: null };
        console.log('Updated formData:', updated);
        return updated;
      });
      setImagePreview(null);
    } catch (err) {
      console.error('Error removing image:', err);
      setError(err instanceof Error ? err.message : 'Failed to remove image');
    } finally {
      setLoading(false);
    }
  };

  const formatUrl = (url: string | undefined): string | undefined => {
    if (!url) return undefined;
    if (url.trim() === '') return undefined;
    
    let formattedUrl = url.trim();
    // Remove http:// or https:// for processing
    formattedUrl = formattedUrl.replace(/^https?:\/\//, '');
    
    // Basic URL validation
    if (!formattedUrl.includes('.')) return undefined;
    if (formattedUrl.includes(' ')) return undefined;
    
    return `https://${formattedUrl}`;
  };

  const handleSubmit = async () => {
    if (!formData.first_name || !formData.last_name) {
      setError('First name and last name are required');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      console.log('Submitting form with data:', formData);

      // Validate assigned places for Sindaco and Presidente della Provincia offices
      const officesRequiringPlace = selectedOffices.filter(office => 
        office.title === 'Sindaco' || office.title === 'Presidente della Provincia' || office.title === 'Presidente di Regione'
      );
      for (const office of officesRequiringPlace) {
        if (!assignedPlaces[office.id]) {
          setError(`Assigned place is required for ${office.title} office`);
          return;
        }
      }

      const politicianOffices = selectedOffices.map(office => ({
        political_office_id: office.id,
        assigned_place_id: (office.title === 'Sindaco' || office.title === 'Presidente della Provincia' || office.title === 'Presidente di Regione') 
          ? assignedPlaces[office.id]?.id 
          : undefined
      }));

      // Create a type-safe politician object with all required fields
      const politicianData: Omit<Politician, 'id'> & { id?: string } = {
        ...(politician?.id && { id: politician.id }), // Only include id if editing an existing politician
        first_name: formData.first_name!, // We've validated this above
        last_name: formData.last_name!,   // We've validated this above
        description: formData.description,
        gender: formData.gender as 'male' | 'female' | 'other' | undefined,
        education_level: formData.education_level,
        party_id: formData.party_id || null,
        birth_place_id: formData.birth_place_id || null,
        birth_date: formData.birth_date || null,
        image_url: formData.image_url || null,
        created_at: politician?.created_at || new Date().toISOString(),
        updated_at: new Date().toISOString(),
        created_by: politician?.created_by || '',
        facebook_url: formData.facebook_url?.trim() || null,
        twitter_url: formData.twitter_url?.trim() || null,
        instagram_url: formData.instagram_url?.trim() || null,
        linkedin_url: formData.linkedin_url?.trim() || null
      };

      console.log('Final politician data to save:', politicianData);
      await onSave(politicianData, politicianOffices);
      handleClose();
    } catch (error) {
      console.error('Error saving politician:', error);
      setError('Error saving politician');
    } finally {
      setLoading(false);
    }
  };

  const handleSocialMediaChange = (
    field: 'twitter_url' | 'facebook_url' | 'instagram_url' | 'linkedin_url',
    value: string
  ) => {
    let formattedValue = value.trim();
    // Remove http:// or https:// for display
    formattedValue = formattedValue.replace(/^https?:\/\//, '');
    
    handleInputChange(field, formattedValue);
  };

  const handleOfficeChange = (_: React.SyntheticEvent, newValue: PoliticalOffice[]) => {
    console.log('Autocomplete selection changed:', newValue);
    setSelectedOffices(newValue);
    
    // Clear assigned places for removed offices
    const newAssignedPlaces = { ...assignedPlaces };
    Object.keys(newAssignedPlaces).forEach(officeId => {
      if (!newValue.find(office => office.id === officeId)) {
        delete newAssignedPlaces[officeId];
      }
    });
    setAssignedPlaces(newAssignedPlaces);
  };

  const renderAssignedPlaceField = (office: PoliticalOffice) => {
    if (office.title !== 'Sindaco' && office.title !== 'Presidente della Provincia' && office.title !== 'Presidente di Regione') return null;

    return (
      <Box key={`assigned-place-${office.id}`} sx={{ mt: 2 }}>
        <FormControl fullWidth>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Assigned Place for {office.title}
          </Typography>
          <Autocomplete
            options={assignedPlacesList}
            getOptionLabel={(place) => `${place.name}${place.state ? `, ${place.state}` : ''}`}
            value={assignedPlaces[office.id] || null}
            onChange={(_, newValue) => {
              setAssignedPlaces(prev => ({
                ...prev,
                [office.id]: newValue
              }));
            }}
            onInputChange={(_, value) => {
              if (value && value.length >= 2) {
                debouncedSearchAssignedPlaces(value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search for a place"
                required
                error={!assignedPlaces[office.id] && selectedOffices.some(o => o.id === office.id)}
                helperText={!assignedPlaces[office.id] && selectedOffices.some(o => o.id === office.id) ? `Assigned place is required for ${office.title}` : ''}
              />
            )}
          />
        </FormControl>
      </Box>
    );
  };

  useEffect(() => {
    if (open && politician?.birth_place) {
      console.log('Setting initial birth place:', politician.birth_place);
      setBirthPlace(politician.birth_place);
      setBirthPlaces([politician.birth_place]);
    }
  }, [open, politician]);

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: '80vh',
          maxHeight: '90vh'
        }
      }}
    >
      <DialogTitle>{politician ? 'Edit Politician' : 'New Politician'}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            display: 'grid',
            gap: 3,
            py: 2,
            '& .MuiTextField-root': { 
              width: '100%',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'background.paper',
                '&:hover': {
                  backgroundColor: 'action.hover',
                }
              }
            },
            '& .full-width': { gridColumn: '1 / -1' }
          }}
        >
          {error && <Alert severity="error" className="full-width" sx={{ mb: 1 }}>{error}</Alert>}

          <Box sx={{ display: 'grid', gridTemplateColumns: '200px 1fr', gap: 3, alignItems: 'start' }}>
            {/* Image Section */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ 
                width: '200px',
                aspectRatio: '1',
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: 'background.paper'
              }}>
                {formData.image_url ? (
                  <>
                    <img
                      src={formData.image_url}
                      alt={`${formData.first_name} ${formData.last_name}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                    <IconButton
                      size="small"
                      onClick={handleRemoveImage}
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'background.paper',
                        '&:hover': { backgroundColor: 'action.hover' }
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </>
                ) : (
                  <Box sx={{ 
                    height: '100%', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: 1,
                    p: 2,
                    textAlign: 'center'
                  }}>
                    <PhotoCamera sx={{ fontSize: 40, color: 'text.secondary' }} />
                    <Typography variant="caption" color="text.secondary">
                      No image uploaded
                    </Typography>
                  </Box>
                )}
              </Box>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="image-upload"
                type="file"
                onChange={handleImageUpload}
                disabled={loading}
              />
              <Button
                component="label"
                htmlFor="image-upload"
                variant="outlined"
                startIcon={<PhotoCamera />}
                disabled={loading}
                fullWidth
                size="small"
              >
                Upload Photo
              </Button>
            </Box>

            {/* Name Fields */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                autoFocus
                label="First Name"
                value={formData.first_name}
                onChange={(e) => handleInputChange('first_name', e.target.value)}
                required
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Last Name"
                value={formData.last_name}
                onChange={(e) => handleInputChange('last_name', e.target.value)}
                required
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControl fullWidth>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select
                  labelId="gender-label"
                  value={formData.gender || ''}
                  onChange={handleGenderChange}
                  label="Gender"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="education-level-label">Titolo di studio</InputLabel>
                <Select
                  labelId="education-level-label"
                  value={formData.education_level || ''}
                  onChange={handleEducationLevelChange}
                  label="Titolo di studio"
                >
                  <MenuItem value="">
                    <em>Non indicato</em>
                  </MenuItem>
                  <MenuItem value="Nessun Titolo">Nessun Titolo</MenuItem>
                  <MenuItem value="Istruzione Primaria">Istruzione Primaria</MenuItem>
                  <MenuItem value="Istruzione Secondaria di Primo Grado">Istruzione Secondaria di Primo Grado</MenuItem>
                  <MenuItem value="Istruzione Secondaria di Secondo Grado">Istruzione Secondaria di Secondo Grado</MenuItem>
                  <MenuItem value="Laurea Triennale">Laurea Triennale</MenuItem>
                  <MenuItem value="Laurea Magistrale">Laurea Magistrale</MenuItem>
                  <MenuItem value="Post Laurea">Post Laurea</MenuItem>
                  <MenuItem value="Diploma di istruzione secondaria superiore">Diploma di istruzione secondaria superiore</MenuItem>
                  <MenuItem value="Laurea">Laurea</MenuItem>
                  <MenuItem value="Laurea di primo livello">Laurea di primo livello</MenuItem>
                  <MenuItem value="Laurea Magistrale (già specialistica)">Laurea Magistrale (già specialistica)</MenuItem>
                  <MenuItem value="Licenza media">Licenza media</MenuItem>
                  <MenuItem value="Titolo post-laurea">Titolo post-laurea</MenuItem>
                  <MenuItem value="Titolo di studio non rilevato">Titolo di studio non rilevato</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Birth Date"
                type="date"
                value={formData.birth_date || ''}
                onChange={(e) => handleInputChange('birth_date', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Autocomplete
                value={birthPlace}
                onChange={(event, newValue) => {
                  console.log('Birth place changed:', newValue);
                  setBirthPlace(newValue);
                  setFormData(prev => ({
                    ...prev,
                    birth_place_id: newValue?.id || null
                  }));
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={birthPlaces}
                getOptionLabel={(place) => `${place.name}${place.state ? `, ${place.state}` : ''}`}
                onInputChange={(_, value) => {
                  if (value && value.length >= 2) {
                    debouncedSearchBirthPlaces(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Luogo di nascita"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {searchingPlaces ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Box>

          <TextField
            className="full-width"
            label="Description"
            value={formData.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            multiline
            rows={4}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Divider sx={{ my: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Social Media Links
            </Typography>
          </Divider>

          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
            <TextField
              label="Twitter"
              value={formData.twitter_url?.replace(/^https?:\/\//, '') || ''}
              onChange={(e) => handleSocialMediaChange('twitter_url', e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              placeholder="twitter.com/username"
              InputProps={{
                startAdornment: <Twitter sx={{ color: '#1DA1F2', mr: 1 }} />
              }}
            />
            <TextField
              label="Facebook"
              value={formData.facebook_url?.replace(/^https?:\/\//, '') || ''}
              onChange={(e) => handleSocialMediaChange('facebook_url', e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              placeholder="facebook.com/username"
              InputProps={{
                startAdornment: <Facebook sx={{ color: '#4267B2', mr: 1 }} />
              }}
            />
            <TextField
              label="Instagram"
              value={formData.instagram_url?.replace(/^https?:\/\//, '') || ''}
              onChange={(e) => handleSocialMediaChange('instagram_url', e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              placeholder="instagram.com/username"
              InputProps={{
                startAdornment: <Instagram sx={{ color: '#E4405F', mr: 1 }} />
              }}
            />
            <TextField
              label="LinkedIn"
              value={formData.linkedin_url?.replace(/^https?:\/\//, '') || ''}
              onChange={(e) => handleSocialMediaChange('linkedin_url', e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              placeholder="linkedin.com/in/username"
              InputProps={{
                startAdornment: <LinkedIn sx={{ color: '#0A66C2', mr: 1 }} />
              }}
            />
          </Box>

          <FormControl fullWidth>
            <InputLabel>Party</InputLabel>
            <Select
              value={formData.party_id || ''}
              onChange={(e) => handleInputChange('party_id', e.target.value)}
              label="Party"
              sx={{
                backgroundColor: 'background.paper',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'divider',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary.main',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'primary.main',
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {parties.map((party) => (
                <MenuItem key={party.id} value={party.id}>
                  {party.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
              Political Offices
            </Typography>
            <Autocomplete
              multiple
              options={politicalOffices}
              getOptionLabel={(option) => option.title}
              value={selectedOffices}
              onChange={handleOfficeChange}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select offices"
                />
              )}
              renderTags={(selected, getTagProps) =>
                selected.map((option, index) => (
                  <Chip
                    label={option.title}
                    {...getTagProps({ index })}
                    sx={{ m: 0.5 }}
                  />
                ))
              }
            />
          </FormControl>

          <Box sx={{ mt: 3 }}>
            {selectedOffices.map((office) => renderAssignedPlaceField(office))}
          </Box>

          {politician && (
            <Box sx={{ mt: 2, typography: 'caption', color: 'text.secondary', gridColumn: '1 / -1' }}>
              <div>Created by: {creatorEmail || (politician.created_by ? politician.created_by.substring(0, 8) + '...' : 'Loading...')}</div>
              <div>Created at: {new Date(politician.created_at).toLocaleString()}</div>
              <div>Updated at: {new Date(politician.updated_at).toLocaleString()}</div>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
        >
          {loading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
