import React from 'react';
import { Box, Typography } from '@mui/material';

const FeatureDescription: React.FC = () => {
  return (
    <Box
      sx={{
        maxWidth: '800px',
        textAlign: 'center',
        px: { xs: 2, sm: 4 },
        py: 4,
        mb: 6,
        borderRadius: 0,
        background: '#000000',
        border: 'none',
        boxShadow: 'none'
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontWeight: 600,
          fontSize: { xs: '1.5rem', sm: '1.75rem' },
          color: '#ffffff',
          mb: 3
        }}
      >
        Un'esperienza completa per la democrazia locale
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem' },
          color: '#ffffff',
          lineHeight: 1.6
        }}
      >
        Polinder ti offre tutti gli strumenti necessari per connetterti con i rappresentanti locali, 
        esprimere le tue opinioni sulle questioni che ti stanno a cuore e partecipare attivamente 
        al processo democratico nella tua comunità. Scopri profili dettagliati dei politici, 
        segui le loro attività e contribuisci al dialogo democratico in modo semplice e diretto.
      </Typography>
    </Box>
  );
};

export default FeatureDescription;
