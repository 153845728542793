import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Skeleton,
  Avatar,
  Chip,
  Stack,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Party } from '../../../types/database.types';
import {
  sharedCardStyles,
  cardContentStyles,
  cardImageStyles,
  cardTitleStyles,
  cardImageContainerStyles,
} from '../../../styles/cardStyles';
import PeopleIcon from '@mui/icons-material/People';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PartyCardInfo } from './PartyCardInfo';
import { PartyCardStatistic } from './PartyCardStatistic';
import { PartyCardDetails } from './PartyCardDetails';
import PartyCardNews from './PartyCardNews';

// Estendere il tipo Party per includere i conteggi dei membri
interface PartyWithMemberCounts extends Party {
  memberCounts?: {
    deputati: number;
    senatori: number;
  };
}

interface PartyCardProps {
  party: PartyWithMemberCounts | null;
  loading: boolean;
  error: string | null;
  colorIndex: number;
  cardColors: string[];
  onCardClick?: () => void;
  resetView?: number;
}

export const PartyCard: React.FC<PartyCardProps> = ({
  party,
  loading,
  error,
  colorIndex,
  cardColors,
  onCardClick,
  resetView,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showInfo, setShowInfo] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showNews, setShowNews] = useState(false);

  // Effetto per resettare le viste quando cambia resetView
  useEffect(() => {
    if (resetView !== undefined) {
      setShowDetails(false);
      setShowInfo(false);
      setShowStats(false);
      setShowNews(false);
    }
  }, [resetView]);

  const handleCardClick = (e?: React.MouseEvent) => {
    // Mostra i dettagli del partito (come fa l'icona di voto per i politici)
    setShowDetails(true);
    
    // Rimuoviamo la chiamata a onCardClick per evitare la navigazione
    // if (onCardClick) {
    //   onCardClick();
    // }
  };

  const handleNewsClick = () => {
    setShowNews(true);
  };

  const handleCloseNews = () => {
    setShowNews(false);
  };

  const handleInfoClick = () => {
    setShowInfo(true);
  };

  const handleCloseInfo = () => {
    setShowInfo(false);
  };

  const handleStatsClick = () => {
    setShowStats(true);
  };

  const handleCloseStats = () => {
    setShowStats(false);
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
  };

  const handleDeputatiClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Evita che il click si propaghi alla card
    e.preventDefault(); // Previene il comportamento predefinito
    if (party?.id) {
      console.log("Navigazione a Deputati:", `partyId=${party.id}&role=Deputato`);
      navigate(`/app/user/politicians?partyId=${party.id}&role=Deputato`);
    }
  };

  const handleSenatoriClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Evita che il click si propaghi alla card
    e.preventDefault(); // Previene il comportamento predefinito
    if (party?.id) {
      console.log("Navigazione a Senatori:", `partyId=${party.id}&role=Senatore`);
      navigate(`/app/user/politicians?partyId=${party.id}&role=Senatore`);
    }
  };

  if (loading) {
    return (
      <Card sx={{ 
        ...sharedCardStyles, 
        bgcolor: theme.palette.background.default, 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        padding: 0,
      }}>
        <Skeleton 
          variant="rectangular" 
          width="100%" 
          sx={{ 
            height: 0,
            paddingTop: '56.25%', // 16:9 aspect ratio
            transform: 'scale(1)', // Ensures it reaches the edges
            margin: 0,
            borderRadius: 0,
          }} 
        />
        <Box sx={{ 
          p: 2, 
          backgroundColor: theme.palette.background.default,
          width: '100%',
        }}>
          <Skeleton variant="text" width="80%" height={40} sx={{ mb: 1, bgcolor: 'rgba(255, 255, 255, 0.3)' }} />
          <Skeleton variant="text" width="60%" height={30} sx={{ mb: 1, bgcolor: 'rgba(255, 255, 255, 0.3)' }} />
          <Skeleton variant="text" width="40%" height={20} sx={{ bgcolor: 'rgba(255, 255, 255, 0.3)' }} />
        </Box>
      </Card>
    );
  }

  if (error) {
    return (
      <Card sx={{ 
        ...sharedCardStyles, 
        bgcolor: theme.palette.background.default, 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        padding: 0,
      }}>
        <Box sx={{
          width: '100%',
          height: 0,
          paddingTop: '56.25%', // 16:9 aspect ratio
          bgcolor: 'rgba(0, 0, 0, 0.05)',
          position: 'relative',
        }}>
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Typography color="text.secondary" variant="subtitle1" align="center">
              Image not available
            </Typography>
          </Box>
        </Box>
        <Box sx={{ 
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Typography color="error" variant="body1" align="center" sx={{ color: '#ffffff' }}>
            {error}
          </Typography>
          <Typography 
            variant="body2" 
            align="center"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)', 
              mt: 1,
              fontSize: '0.85rem',
            }}
          >
            Informazioni non disponibili
          </Typography>
        </Box>
      </Card>
    );
  }

  if (!party) {
    return null;
  }

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%', backgroundColor: theme.palette.background.default }}>
      {/* Regular Card */}
      <Card 
        sx={{
          width: '100vw',
          height: '100%',
          boxShadow: 'none',
          borderRadius: 0,
          position: 'relative',
          m: 0,
          p: 0,
          border: 'none',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.background.default
        }}
      >
        <Box sx={{
          width: '100vw',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          position: 'relative',
          m: 0,
          overflow: 'hidden',
          backgroundColor: theme.palette.background.default,
        }}>
          <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            margin: 0,
            flexGrow: 0,
            overflow: 'hidden',
            position: 'relative',
            flexDirection: 'column',
            paddingTop: '30px',
            paddingBottom: '30px',
            backgroundColor: '#AD0E38', // Colore di sfondo rosso bordeaux
            height: 'calc(100vh - 300px)', // Altezza simile alla foto nella scheda del politico
            minHeight: '200px', // Ensure a minimum height for smaller screens
            maxHeight: 'calc(100vh - 300px)', // Match PoliticianCard maxHeight
          }}>
            {/* Circular Logo */}
            <Avatar
              src={party?.logo_url || '/party-placeholder.png'}
              alt={party?.name}
              onClick={handleCardClick}
              sx={{
                width: 200,
                height: 200,
                border: '2px solid rgba(255, 255, 255, 0.2)',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                },
                objectFit: 'contain', // Match PoliticianCard objectFit
              }}
            />
          </Box>
          <Box sx={{ 
            padding: '15px 10px',
            width: '100%',
            margin: 0,
            marginTop: '10px', 
            border: 'none',
            borderRadius: 0,
            position: 'relative',
            top: 0, 
            left: 0,
            right: 0,
            zIndex: 5,
            flexGrow: 0,
            flexShrink: 0,
            borderTop: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start', 
            textAlign: 'center',
            backgroundColor: theme.palette.background.default,
          }}
          onClick={handleCardClick}
          >
            <Typography 
              variant="h5" 
              component="div"
              align="center"
              sx={{
                ...cardTitleStyles,
                textShadow: '0px 1px 2px rgba(0,0,0,0.3)',
                fontSize: '1.5rem',
                fontWeight: 'normal',
                color: theme.palette.text.primary,
                marginTop: 0,
                width: '100%',
                textAlign: 'center'
              }}
            >
              {party?.name}
            </Typography>
            
            {party?.symbol && (
              <Typography 
                variant="subtitle1" 
                align="center"
                sx={{
                  fontWeight: 'normal',
                  letterSpacing: 0.5,
                  mt: 0.5,
                  fontSize: '0.85rem',
                  color: theme.palette.text.secondary,
                  width: '100%',
                  textAlign: 'center',
                  px: 2, 
                  lineHeight: 1.4, 
                  wordBreak: 'break-word', 
                }}
              >
                {party.symbol}
              </Typography>
            )}

            {/* Orientamento politico e posizione nel governo */}
            <Box sx={{ mt: 1, mb: 1, width: '100%' }}>
              {party?.political_orientation && (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  mb: 0.5
                }}>
                  <Typography 
                    variant="body2" 
                    sx={{
                      color: theme.palette.text.secondary, 
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                      mr: 0.5
                    }}
                  >
                    Orientamento:
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{
                      color: theme.palette.text.primary, 
                      fontSize: '0.85rem',
                      fontWeight: 'bold'
                    }}
                  >
                    {party.political_orientation}
                  </Typography>
                </Box>
              )}
              
              {party?.government_position && (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  mt: 0.5
                }}>
                  <Typography 
                    variant="body2" 
                    sx={{
                      color: theme.palette.text.secondary, 
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                      mr: 0.5
                    }}
                  >
                    Posizione:
                  </Typography>
                  <Typography 
                    variant="body2" 
                    sx={{
                      color: theme.palette.text.primary, 
                      fontSize: '0.85rem',
                      fontWeight: 'bold'
                    }}
                  >
                    {party.government_position}
                  </Typography>
                </Box>
              )}
            </Box>

            {/* Conteggio Deputati e Senatori */}
            {party.memberCounts && (
              <Stack 
                direction="row" 
                spacing={2} 
                sx={{ 
                  mt: 2, 
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Chip
                  icon={<PeopleIcon />}
                  label={`${party.memberCounts.deputati} Deputati`}
                  variant="outlined"
                  onClick={handleDeputatiClick}
                  sx={{
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.divider,
                    '& .MuiChip-icon': {
                      color: theme.palette.text.primary,
                      fontSize: '1.4rem', // Aumenta la dimensione dell'icona
                    },
                    '& .MuiChip-label': {
                      fontSize: '0.95rem', // Aumenta la dimensione del testo
                      padding: '0 12px', // Aumenta il padding orizzontale
                    },
                    height: '36px', // Aumenta l'altezza del chip
                    cursor: 'pointer',
                    zIndex: 10, // Aumenta lo z-index per assicurarsi che il clic venga rilevato
                  }}
                />
                <Chip
                  icon={<PeopleIcon />}
                  label={`${party.memberCounts.senatori} Senatori`}
                  variant="outlined"
                  onClick={handleSenatoriClick}
                  sx={{
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.divider,
                    '& .MuiChip-icon': {
                      color: theme.palette.text.primary,
                      fontSize: '1.4rem', // Aumenta la dimensione dell'icona
                    },
                    '& .MuiChip-label': {
                      fontSize: '0.95rem', // Aumenta la dimensione del testo
                      padding: '0 12px', // Aumenta il padding orizzontale
                    },
                    height: '36px', // Aumenta l'altezza del chip
                    cursor: 'pointer',
                    zIndex: 10, // Aumenta lo z-index per assicurarsi che il clic venga rilevato
                  }}
                />
              </Stack>
            )}
            
            {/* Icone */}
            <Box 
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mt: 2,
                mb: 1,
                px: { xs: 2, sm: 4, md: 6 },
                width: '100%',
                gap: { xs: 4, sm: 6, md: 8 }
              }}
            >
              {/* Icona Stats */}
              <Box
                sx={{
                  width: { xs: '50px', sm: '65px', md: '75px' },
                  height: { xs: '50px', sm: '65px', md: '75px' },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  zIndex: 10, // Aumenta lo z-index
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                  transition: 'transform 0.2s ease'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  console.log("Stat cliccato");
                  handleStatsClick();
                }}
              >
                <Box
                  component="img"
                  src="/icon_statistic.svg"
                  alt="Statistics"
                  sx={{
                    width: '100%',
                    height: '100%'
                  }}
                />
              </Box>

              {/* Icona News */}
              <Box
                sx={{
                  width: { xs: '50px', sm: '65px', md: '75px' },
                  height: { xs: '50px', sm: '65px', md: '75px' },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  zIndex: 10, // Aumenta lo z-index
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                  transition: 'transform 0.2s ease'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  console.log("News cliccato");
                  handleNewsClick();
                }}
              >
                <Box
                  component="img"
                  src="/icon_news.svg"
                  alt="News"
                  sx={{
                    width: '100%',
                    height: '100%'
                  }}
                />
              </Box>

              {/* Icona Info */}
              <Box
                sx={{
                  width: { xs: '50px', sm: '65px', md: '75px' },
                  height: { xs: '50px', sm: '65px', md: '75px' },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  zIndex: 10, // Aumenta lo z-index
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                  transition: 'transform 0.2s ease'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  console.log("Info cliccato");
                  handleInfoClick();
                }}
              >
                <Box
                  component="img"
                  src="/icon_Info.svg"
                  alt="Info"
                  sx={{
                    width: '100%',
                    height: '100%'
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>

      {/* Info Dialog */}
      {showInfo && (
        <PartyCardInfo
          party={party}
          onClose={handleCloseInfo}
        />
      )}
      
      {/* Stats View (Full Screen) */}
      {showStats && party && (
        <PartyCardStatistic 
          party={party}
          onClose={handleCloseStats}
        />
      )}

      {/* Details View (Full Screen) - Mostra le preferenze */}
      {showDetails && party && (
        <PartyCardDetails 
          party={party}
          onClose={handleCloseDetails}
        />
      )}

      {/* News Dialog */}
      {showNews && (
        <PartyCardNews
          party={party}
          onClose={handleCloseNews}
          open={showNews}
        />
      )}
    </Box>
  );
};
