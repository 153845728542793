/**
 * Configurazione per i servizi di news
 * 
 * Nota: In futuro questi valori potrebbero essere recuperati dai parametri globali nel database
 */

// Frequenza di aggiornamento delle notizie in giorni
export const NEWS_UPDATE_FREQUENCY = 7;

// Timeout per il lock di generazione delle notizie (in millisecondi) - 2 minuti
export const NEWS_GENERATION_LOCK_TIMEOUT = 120000;

// Intervallo di polling per il controllo dello stato di generazione (in millisecondi)
export const NEWS_GENERATION_POLLING_INTERVAL = 5000;
