import axios from 'axios';
import { supabase } from '../lib/supabaseClient';
import { GoogleSearchService } from './googleSearchService';

export class OpenAIService {
  private googleSearchService: GoogleSearchService;
  private readonly baseUrl = '/api/openai';

  constructor() {
    console.log('Initializing OpenAI Service...');
    this.googleSearchService = new GoogleSearchService();
  }

  private async isValidImageUrl(url: string): Promise<boolean> {
    if (!url) {
        console.log('Empty URL provided');
        return false;
    }

    try {
        new URL(url);
    } catch {
        console.log('Invalid URL format:', url);
        return false;
    }

    const imageExtensions = ['.jpg', '.jpeg', '.png', '.svg'];
    const hasImageExtension = imageExtensions.some(ext => 
        url.toLowerCase().endsWith(ext) || 
        url.toLowerCase().includes(`${ext}?`) ||
        url.toLowerCase().includes(`${ext}&`)
    );

    if (!hasImageExtension) {
        console.log('URL does not have a valid image extension:', url);
        return false;
    }

    return true;
  }

  async generateResponse(prompt: string): Promise<string> {
    try {
      const response = await axios.post(`${this.baseUrl}/generate`, {
        prompt
      });
      return response.data.response;
    } catch (error) {
      console.error('Error generating response:', error);
      throw error;
    }
  }

  private async findBestLogoUrl(partyName: string): Promise<string> {
    try {
        console.log('Starting logo search for:', partyName);
        
        // First try Google Custom Search API
        console.log('Attempting Google Custom Search...');
        const googleLogoUrl = await this.googleSearchService.searchImages(`${partyName} logo partito politico`);
        if (googleLogoUrl) {
            console.log('Found valid logo URL via Google Search:', googleLogoUrl);
            return googleLogoUrl;
        }

        console.log('No valid logo URL found');
        return '';
    } catch (error) {
        console.error('Error in findBestLogoUrl:', error);
        return '';
    }
  }

  private convertDateFormat(dateStr: string): string {
    try {
      // Handle different input formats
      let parts: string[];
      
      if (dateStr.includes('/')) {
        parts = dateStr.split('/');
        // DD/MM/YYYY format
        if (parts.length === 3) {
          const [day, month, year] = parts;
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }
      } else if (dateStr.includes('-')) {
        parts = dateStr.split('-');
        // Already in YYYY-MM-DD format
        if (parts.length === 3 && parts[0].length === 4) {
          return dateStr;
        }
      }

      // Try parsing as a date object
      const date = new Date(dateStr);
      if (!isNaN(date.getTime())) {
        return date.toISOString().split('T')[0];
      }

      console.error('Invalid date format:', dateStr);
      return dateStr;
    } catch (error) {
      console.error('Error converting date:', error);
      return dateStr;
    }
  }

  private async createHeadquarters(city: string): Promise<string | undefined> {
    try {
      // Check if place already exists
      const { data: existingPlaces } = await supabase
        .from('places')
        .select('id')
        .eq('name', city)
        .eq('state', 'Italia')
        .limit(1);

      if (existingPlaces && existingPlaces.length > 0) {
        return existingPlaces[0].id;
      }

      // Create new place
      const { data: newPlace, error } = await supabase
        .from('places')
        .insert([{
          name: city,
          state: 'Italia'
        }])
        .select('id')
        .single();

      if (error) {
        console.error('Error creating place:', error);
        return undefined;
      }

      return newPlace?.id;
    } catch (error) {
      console.error('Error in createHeadquarters:', error);
      return undefined;
    }
  }

  async getHeadquartersInfo(partyName: string): Promise<{ city: string; state: string } | null> {
    try {
      console.log('Getting headquarters info for:', partyName);
      const response = await this.generateResponse(`Sei un esperto dei partiti politici italiani. Devi fornire informazioni sulla sede centrale del partito richiesto.
            
            IMPORTANTE:
            - Per la città (city) usa il nome ufficiale in italiano (es. "Roma", "Milano")
            - Per lo stato (provincia) usa il codice di 2 caratteri della provincia (es. "RM" per Roma, "MI" per Milano)
            
            Restituisci SOLO un oggetto JSON con:
            1. city: nome della città della sede centrale
            2. state: codice provincia di 2 caratteri in maiuscolo
            
            Esempio di risposta:
            {
              "city": "Roma",
              "state": "RM"
            }
            
            Qual è la sede centrale del "${partyName}"?`);

      if (response) {
        try {
          const info = JSON.parse(response);
          console.log('Headquarters info:', info);
          // Ensure state is uppercase
          return {
            city: info.city,
            state: info.state?.toUpperCase() || ''
          };
        } catch (error) {
          console.error('Error parsing headquarters info:', error);
        }
      }
      return null;
    } catch (error) {
      console.error('Error getting headquarters info:', error);
      return null;
    }
  }

  async searchPartyInformation(partyName: string): Promise<any> {
    console.log('🔍 DEBUG: Starting search for party:', partyName);
    
    try {
      // First, get party information and description
      let infoResponse;
      try {
        console.log('📤 DEBUG: About to call OpenAI API');
        infoResponse = await this.generateResponse(`Fornisci informazioni sul partito politico in formato JSON. Esempio:
{
  "name": "Nome del partito",
  "description": "Descrizione del partito",
  "symbol": "Sigla",
  "founded_date": "DD/MM/YYYY",
  "headquarters_city": "Roma",
  "headquarters_state": "RM",
  "website_url": "",
  "twitter_url": "",
  "facebook_url": "",
  "instagram_url": "",
  "linkedin_url": ""
}
Trova informazioni sul partito "${partyName}" in formato JSON.`);
        console.log('📥 DEBUG: OpenAI API response received:', infoResponse);
      } catch (error: any) {
        console.error('❌ DEBUG: OpenAI API Error:', error);
        throw new Error(`OpenAI API Error: ${error?.message || 'Unknown error occurred'}`);
      }

      const content = infoResponse;
      console.log('📄 DEBUG: Raw content:', content);

      if (!content) {
        console.error('❌ DEBUG: No content in OpenAI response');
        return {};
      }

      // Try to clean the content if it's not valid JSON
      let cleanContent = content;
      try {
        // Remove any text before the first {
        const firstBrace = content.indexOf('{');
        const lastBrace = content.lastIndexOf('}');
        if (firstBrace !== -1 && lastBrace !== -1) {
          cleanContent = content.substring(firstBrace, lastBrace + 1);
        }
        console.log('🧹 DEBUG: Cleaned content:', cleanContent);
        
        // Parse the JSON
        const partyInfo = JSON.parse(cleanContent);
        console.log('✅ DEBUG: Successfully parsed JSON:', partyInfo);

        // Get logo URL using Google Search
        console.log('Searching for logo URL using Google Search...');
        const logoUrl = await this.googleSearchService.searchImages(partyName);
        partyInfo.logo_url = logoUrl;
        console.log('Found logo URL:', logoUrl);

        // Create headquarters place if city and state are provided
        if (partyInfo.headquarters_city && partyInfo.headquarters_state) {
          // Format city and state as "CITY, STATE"
          const headquartersString = `${partyInfo.headquarters_city.toUpperCase()}, ${partyInfo.headquarters_state.toUpperCase()}`;
          console.log('Looking up headquarters:', headquartersString);

          // Split the headquarters string to get city and state
          const [city, state] = headquartersString.split(', ');
          
          const { data: existingPlaces } = await supabase
            .from('places')
            .select('id')
            .eq('name', city)
            .eq('state', state)
            .limit(1);

          if (existingPlaces && existingPlaces.length > 0) {
            partyInfo.headquarters_id = existingPlaces[0].id;
            console.log('Found existing headquarters with ID:', partyInfo.headquarters_id);
          } else {
            console.log('No matching place found for:', headquartersString);
          }

          // Remove temporary fields
          delete partyInfo.headquarters_city;
          delete partyInfo.headquarters_state;
        }

        // Convert date format to YYYY-MM-DD
        if (partyInfo.founded_date) {
          partyInfo.founded_date = this.convertDateFormat(partyInfo.founded_date);
          console.log('Converted date:', partyInfo.founded_date);
        }

        // Validate all fields are within 255 characters (except description)
        Object.keys(partyInfo).forEach(key => {
          if (key !== 'description' && partyInfo[key] && typeof partyInfo[key] === 'string') {
            partyInfo[key] = partyInfo[key].substring(0, 255);
          }
        });

        // Ensure all social media URLs are properly formatted
        const socialFields = ['website_url', 'twitter_url', 'facebook_url', 'instagram_url', 'linkedin_url'];
        socialFields.forEach(field => {
          if (partyInfo[field]) {
            // Add https:// if missing
            if (!partyInfo[field].startsWith('http://') && !partyInfo[field].startsWith('https://')) {
              partyInfo[field] = 'https://' + partyInfo[field];
            }
          } else {
            partyInfo[field] = ''; // Ensure empty string if not provided
          }
        });

        console.log('Final party info:', partyInfo);
        return partyInfo;
      } catch (error) {
        console.error('Error parsing party information:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error searching party information:', error);
      throw error;
    }
  }

  async searchPoliticianInformation(politicianName: string): Promise<any> {
    console.log('🔍 DEBUG: Starting search for politician:', politicianName);
    
    try {
      // First, get politician information and description
      let infoResponse;
      try {
        console.log('📤 DEBUG: About to call OpenAI API');
        infoResponse = await this.generateResponse(`Fornisci informazioni sul politico in formato JSON. Esempio:
{
  "first_name": "Nome",
  "last_name": "Cognome",
  "description": "Descrizione del politico",
  "gender": "male/female/other",
  "birth_date": "DD/MM/YYYY",
  "birth_place_city": "Roma",
  "birth_place_state": "RM",
  "twitter_url": "",
  "facebook_url": "",
  "instagram_url": "",
  "linkedin_url": ""
}
Trova informazioni sul politico "${politicianName}" in formato JSON.`);
        console.log('📥 DEBUG: OpenAI API response received:', infoResponse);
      } catch (error: any) {
        console.error('❌ DEBUG: OpenAI API Error:', error);
        throw new Error(`OpenAI API Error: ${error?.message || 'Unknown error occurred'}`);
      }

      const content = infoResponse;
      console.log('📄 DEBUG: Raw content:', content);

      if (!content) {
        console.error('❌ DEBUG: No content in OpenAI response');
        return {};
      }

      // Try to clean the content if it's not valid JSON
      let cleanContent = content;
      try {
        // Remove any text before the first {
        const firstBrace = content.indexOf('{');
        const lastBrace = content.lastIndexOf('}');
        if (firstBrace !== -1 && lastBrace !== -1) {
          cleanContent = content.substring(firstBrace, lastBrace + 1);
        }
        console.log('🧹 DEBUG: Cleaned content:', cleanContent);
        
        // Parse the JSON
        const politicianInfo = JSON.parse(cleanContent);
        console.log('✅ DEBUG: Successfully parsed JSON:', politicianInfo);

        // Get image URL using Google Search
        console.log('Searching for portrait photo using Google Search...');
        const imageUrl = await this.googleSearchService.searchPoliticianPortrait(`${politicianInfo.first_name} ${politicianInfo.last_name}`);
        politicianInfo.image_url = imageUrl;
        console.log('Found portrait URL:', imageUrl);

        // Create birth place if city and state are provided
        if (politicianInfo.birth_place_city && politicianInfo.birth_place_state) {
          // Format city and state
          const birthPlaceCity = politicianInfo.birth_place_city.toUpperCase();
          const birthPlaceState = politicianInfo.birth_place_state.toUpperCase();
          console.log('Looking up birth place:', `${birthPlaceCity}, ${birthPlaceState}`);
          
          const { data: existingPlaces } = await supabase
            .from('places')
            .select('id')
            .eq('name', birthPlaceCity)
            .eq('state', birthPlaceState)
            .limit(1);

          if (existingPlaces && existingPlaces.length > 0) {
            politicianInfo.birth_place_id = existingPlaces[0].id;
            console.log('Found existing birth place with ID:', politicianInfo.birth_place_id);
          } else {
            // Create new place
            const { data: newPlace, error } = await supabase
              .from('places')
              .insert([{
                name: birthPlaceCity,
                state: birthPlaceState
              }])
              .select('id')
              .single();

            if (!error && newPlace) {
              politicianInfo.birth_place_id = newPlace.id;
              console.log('Created new birth place with ID:', politicianInfo.birth_place_id);
            }
          }

          // Remove temporary fields
          delete politicianInfo.birth_place_city;
          delete politicianInfo.birth_place_state;
        }

        // Convert date format to YYYY-MM-DD
        if (politicianInfo.birth_date) {
          politicianInfo.birth_date = this.convertDateFormat(politicianInfo.birth_date);
          console.log('Converted birth date:', politicianInfo.birth_date);
        }

        // Validate all fields are within 255 characters (except description)
        Object.keys(politicianInfo).forEach(key => {
          if (key !== 'description' && politicianInfo[key] && typeof politicianInfo[key] === 'string') {
            politicianInfo[key] = politicianInfo[key].substring(0, 255);
          }
        });

        // Ensure all social media URLs are properly formatted or null
        const socialFields = ['twitter_url', 'facebook_url', 'instagram_url', 'linkedin_url'];
        socialFields.forEach(field => {
          let url = politicianInfo[field];
          
          // If URL is empty or just whitespace, set to null
          if (!url || typeof url !== 'string' || url.trim() === '') {
            politicianInfo[field] = null;
            return;
          }

          // Clean the URL
          url = url.trim();

          try {
            // Add https:// if missing
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
              url = 'https://' + url;
            }

            // Format specific social media URLs
            if (field === 'linkedin_url') {
              // Ensure LinkedIn URL starts with https://www.linkedin.com/in/
              if (!url.includes('linkedin.com/in/')) {
                url = url.replace(/^https?:\/\//, '')
                       .replace(/^www\./, '')
                       .replace(/^linkedin\.com\/?/, '')
                       .replace(/^in\/?/, '');
                url = `https://www.linkedin.com/in/${url}`;
              }
            }

            // Final validation - ensure URL starts with http:// or https://
            if (!url.match(/^https?:\/\/.+$/)) {
              politicianInfo[field] = null;
            } else {
              politicianInfo[field] = url;
            }
          } catch (error) {
            console.error(`Error formatting ${field}:`, error);
            politicianInfo[field] = null;
          }
        });

        console.log('Final politician info:', politicianInfo);
        return politicianInfo;
      } catch (error) {
        console.error('Error parsing politician information:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error searching politician information:', error);
      throw error;
    }
  }
}

export const openaiService = new OpenAIService();
