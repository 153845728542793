import { supabase, supabaseAdmin } from '../lib/supabaseClient';
import { PartyNews, Party } from '../types/database.types';
import { openaiNewsService } from './openaiNewsService';
import { NewsServiceAdapter, NewsSearchResult } from './newsServiceAdapter';
import { NEWS_UPDATE_FREQUENCY, NEWS_GENERATION_LOCK_TIMEOUT } from '../config/newsConfig';

class PartyNewsService {
  // Cache per i dati dei partiti
  private partyCache: Map<string, any> = new Map();
  private lastCacheUpdate: Date | null = null;
  
  /**
   * Verifica se è in corso una generazione di notizie per un partito
   * @param partyId ID del partito
   * @returns Oggetto con informazioni sullo stato della generazione
   */
  async isNewsGenerationInProgress(partyId: string): Promise<{ inProgress: boolean; elapsedSeconds: number }> {
    try {
      // Verifica se esiste un lock nel database
      const { data, error } = await supabase
        .from('party_news_generation_progress')
        .select('*')
        .eq('party_id', partyId);
      
      if (error) {
        console.error('Error checking party news generation progress:', error);
        return { inProgress: false, elapsedSeconds: 0 };
      }

      // Se non ci sono dati, non c'è una generazione in corso
      if (!data || data.length === 0) {
        return { inProgress: false, elapsedSeconds: 0 };
      }
      
      // Verifica se il lock è scaduto
      const now = new Date();
      const startTime = new Date(data[0].started_at);
      const elapsedTime = now.getTime() - startTime.getTime();
      
      if (elapsedTime > NEWS_GENERATION_LOCK_TIMEOUT) {
        // Il lock è scaduto, rimuovilo
        console.log('Lock timeout expired for party:', partyId);
        await this.clearNewsGenerationProgress(partyId);
        return { inProgress: false, elapsedSeconds: 0 };
      }
      
      // Calcola i secondi trascorsi
      const elapsedSeconds = Math.floor(elapsedTime / 1000);
      return { inProgress: true, elapsedSeconds };
    } catch (error) {
      console.error('Error checking party news generation progress:', error);
      return { inProgress: false, elapsedSeconds: 0 };
    }
  }
  
  /**
   * Ottieni le ultime N news per un partito specifico
   * @param party Il partito di cui recuperare le news
   * @param limit Il numero massimo di news da recuperare (default: 20)
   * @returns Un array di notizie ordinate per data (dalla più recente)
   */
  async getNews(party: Party, limit: number = 20): Promise<{ data: PartyNews[] | null, error: any, needsUpdate: boolean }> {
    try {
      // Recupera le notizie dal database
      const { data, error } = await supabase
        .from('party_news')
        .select('*')
        .eq('party_id', party.id)
        .order('news_date', { ascending: false })
        .limit(limit);
      
      if (error) {
        console.error('Error fetching party news:', error);
        return { data: null, error, needsUpdate: false };
      }
      
      // Controlla se è necessario generare nuove notizie
      const needsUpdate = this.needsNewsUpdate(party.id, data);
      
      // Restituisci i dati senza elaborazione delle fonti
      return { data, needsUpdate, error: null };
    } catch (error) {
      console.error('Error in getNews:', error);
      return { data: null, error, needsUpdate: false };
    }
  }
  
  /**
   * Ottieni le news del giorno per un partito specifico
   * Se le news non esistono per la data odierna, genera nuove news
   * Tutti gli utenti autenticati possono generare nuove news
   */
  async getTodayNews(party: Party): Promise<PartyNews | null> {
    try {
      // Verifica se è già in corso una generazione di notizie per questo partito
      const generationStatus = await this.isNewsGenerationInProgress(party.id);
      if (generationStatus.inProgress) {
        console.log('News generation already in progress for party:', party.id);
        return null;
      }
      
      // Ottieni la data di oggi in formato YYYY-MM-DD
      const today = new Date().toISOString().split('T')[0];
      console.log(`Getting news for party ${party.id} for date ${today}`);
      
      // Genera nuove news
      console.log('Generating new news for party:', party.id);
      return await this.generateAndSaveNews(party);
    } catch (error) {
      console.error('Failed to get today news:', error);
      return null;
    }
  }
  
  /**
   * Imposta lo stato di generazione in corso per un partito
   * @param partyId ID del partito
   * @param userId ID dell'utente che sta generando le notizie
   */
  private async setNewsGenerationInProgress(partyId: string, userId: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('party_news_generation_progress')
        .insert({
          party_id: partyId,
          user_id: userId,
          started_at: new Date().toISOString()
        });

      if (error) {
        console.error('Error setting party news generation in progress:', error);
        // Non lanciare un errore qui, solo loggarlo e continuare
      }
    } catch (error) {
      console.error('Error setting party news generation in progress:', error);
      // Non lanciare un errore qui, solo loggarlo e continuare
    }
  }

  /**
   * Rimuove lo stato di generazione in corso per un partito
   * @param partyId ID del partito
   */
  private async clearNewsGenerationProgress(partyId: string): Promise<void> {
    try {
      const { error } = await supabase
        .from('party_news_generation_progress')
        .delete()
        .eq('party_id', partyId);

      if (error) {
        console.error('Error clearing party news generation progress:', error);
        // Non lanciare un errore qui, solo loggarlo
      }
    } catch (error) {
      console.error('Error clearing party news generation progress:', error);
      // Non lanciare un errore qui, solo loggarlo
    }
  }
  
  /**
   * Genera nuove news per un partito utilizzando OpenAI News Service e le salva nel database
   * Questa funzione può essere chiamata da qualsiasi utente autenticato
   */
  private async generateAndSaveNews(party: Party): Promise<PartyNews | null> {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No authenticated user found');
        return null;
      }
      
      // Controlla se è già in corso una generazione di notizie per questo partito
      const generationStatus = await this.isNewsGenerationInProgress(party.id);
      if (generationStatus.inProgress) {
        console.log('News generation already in progress for party:', party.id);
        return null;
      }
      
      // Imposta il lock di generazione
      const userId = user?.id || 'anonymous';
      await this.setNewsGenerationInProgress(party.id, userId);
      
      try {
        // Costruisci la query di ricerca per OpenAI News Service
        let searchQuery = `partito italiano: ${party.name}`;
        
        // Cerca notizie recenti utilizzando OpenAI News Service
        console.log('Searching news with query:', searchQuery);
        let newsResults: NewsSearchResult[] = [];
        try {
          const openaiResults = await openaiNewsService.searchNews(searchQuery, 3);
          newsResults = NewsServiceAdapter.convertToNewsSearchResult(openaiResults);
          console.log(`Found ${newsResults.length} news results from OpenAI News Service (last week)`);
        } catch (error) {
          console.error('Error searching news:', error);
          console.log('Raw news articles:', newsResults);
        }
        
        // Se non abbiamo trovato notizie, prova a cercare notizie più vecchie
        if (newsResults.length === 0) {
          try {
            console.log('No recent news found, searching for older news');
            const openaiResults = await openaiNewsService.searchPartyNews(party, 3);
            newsResults = NewsServiceAdapter.convertToNewsSearchResult(openaiResults);
            console.log(`Found ${newsResults.length} news results from OpenAI News Service (last month)`);
          } catch (error) {
            console.error('Error searching older news:', error);
          }
        }
        
        // Se ancora non abbiamo trovato notizie, prova a cercare con una query più generica
        if (newsResults.length === 0) {
          try {
            console.log('No news found, trying with a more generic query');
            const genericQuery = `partito italiano: ${party.name} politica`;
            const openaiResults = await openaiNewsService.searchNews(genericQuery, 3);
            newsResults = NewsServiceAdapter.convertToNewsSearchResult(openaiResults);
            console.log(`Found ${newsResults.length} news results from OpenAI News Service with generic query`);
          } catch (error) {
            console.error('Error searching with generic query:', error);
          }
        }
        
        // Elabora le notizie direttamente senza OpenAI
        let processedNewsContent = '';
        
        if (newsResults.length > 0) {
          console.log('Using news articles directly from OpenAI News Service');
          
          // Prendi le prime 3 notizie (o meno se non ce ne sono abbastanza)
          const newsToUse = newsResults.slice(0, Math.min(newsResults.length, 3));

          // Crea un oggetto JSON con le notizie
          const newsData = {
            articles: newsToUse.map(news => ({
              title: news.title,
              content: news.snippet,
              date: news.publishedDate || '',
              source: news.source,
              url: news.sourceUrl || news.source_url || news.link || ''
            }))
          };

          // Converti in JSON
          processedNewsContent = JSON.stringify(newsData);
        } else {
          // Se non ci sono notizie, crea un record con descrizione "Nessuna notizia trovata"
          console.log('No news found, creating a record with "Nessuna notizia trovata"');
          const newsData = {
            articles: [{
              title: "Nessuna notizia trovata",
              content: "Non sono state trovate notizie recenti per questo partito.",
              date: new Date().toISOString().split('T')[0],
              source: "",
              url: ""
            }]
          };
          processedNewsContent = JSON.stringify(newsData);
        }
        
        if (!processedNewsContent) {
          console.error('Failed to generate news content');
          return null;
        }
        
        console.log('Generated news content:', processedNewsContent);
        
        // Salva le news nel database
        const today = new Date().toISOString().split('T')[0];
        const now = new Date().toISOString();
        
        console.log('Saving news to database for party:', party.id, 'date:', today);
        const { data: savedNews, error: saveError } = await supabase
          .from('party_news')
          .insert([{
            party_id: party.id,
            news_date: today,
            content: processedNewsContent,
            created_at: now,
            updated_at: now,
            created_by: userId,
          }])
          .select()
          .single();
        
        if (saveError) {
          console.error('Error saving party news:', saveError);
          return null;
        }
        
        console.log('Successfully generated and saved news:', savedNews);
        
        // Verifica che la notizia sia stata salvata correttamente
        if (!savedNews) {
          console.error('News were not saved correctly');
          return null;
        }
        
        return savedNews;
      } finally {
        // Rimuovi il lock di generazione
        await this.clearNewsGenerationProgress(party.id);
      }
    } catch (error) {
      console.error('Failed to generate and save news:', error);
      return null;
    }
  }
  
  /**
   * Crea una notizia di fallback quando non è possibile generarne una
   * @param party Il partito per cui creare la notizia di fallback
   * @returns La notizia di fallback creata
   */
  private async createFallbackNews(party: Party): Promise<PartyNews | null> {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('No authenticated user found');
        return null;
      }
      
      const today = new Date().toISOString().split('T')[0];
      const now = new Date().toISOString();
      
      const fallbackContent = "Nessuna notizia trovata per questo partito.";
      
      const { data: savedNews, error: saveError } = await supabase
        .from('party_news')
        .insert([{
          party_id: party.id,
          news_date: today,
          content: fallbackContent,
          created_at: now,
          updated_at: now,
          created_by: user.id,
        }])
        .select()
        .single();
      
      if (saveError) {
        console.error('Error saving fallback party news:', saveError);
        return null;
      }
      
      return savedNews;
    } catch (error) {
      console.error('Failed to create fallback news:', error);
      return null;
    }
  }
  
  /**
   * Verifica se è necessario aggiornare le notizie per un partito
   * @param partyId ID del partito
   * @param existingNews Notizie esistenti
   * @returns true se è necessario aggiornare le notizie, false altrimenti
   */
  needsNewsUpdate(partyId: string, existingNews: PartyNews[] | null): boolean {
    if (!existingNews || existingNews.length === 0) {
      return true;
    }
    
    // Ottieni la data dell'ultima notizia
    const lastNewsDate = new Date(existingNews[0].news_date);
    const now = new Date();
    
    // Calcola la differenza in giorni
    const daysSinceLastUpdate = (now.getTime() - lastNewsDate.getTime()) / (1000 * 60 * 60 * 24);
    
    // Verifica se sono passati più giorni di quelli specificati nella configurazione
    return daysSinceLastUpdate >= NEWS_UPDATE_FREQUENCY;
  }
}

export const partyNewsService = new PartyNewsService();
