import React from 'react';
import { Box, Typography } from '@mui/material';

interface PhoneShowcaseProps {
  imageUrl: string;
  altText: string;
  title: string;
  description1: string;
  description2: string;
  isReversed?: boolean;
  darkText?: boolean;
}

const PhoneShowcase: React.FC<PhoneShowcaseProps> = ({
  imageUrl,
  altText,
  title,
  description1,
  description2,
  isReversed = false,
  darkText = false
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: isReversed ? 'row-reverse' : 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        gap: { xs: 4, md: 6 },
        mb: 8
      }}
    >
      {/* Phone Container */}
      <Box
        sx={{
          position: 'relative',
          width: { xs: '280px', sm: '320px' },
          height: { xs: '560px', sm: '640px' },
          perspective: '1000px',
          zIndex: 1,
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-8px) rotateY(2deg)',
            '& .phone-shadow': {
              opacity: 0.7,
              width: '85%',
              filter: 'blur(20px)',
            }
          }
        }}
      >
        {/* Ombra sotto il telefono con gradiente realistico */}
        <Box
          className="phone-shadow"
          sx={{
            position: 'absolute',
            bottom: '-15px',
            left: '10%',
            width: '80%',
            height: '20px',
            borderRadius: '50%',
            background: 'radial-gradient(ellipse at center, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.1) 60%, rgba(0,0,0,0) 100%)',
            filter: 'blur(15px)',
            zIndex: -1,
            transition: 'all 0.3s ease-in-out',
            opacity: 0.6
          }}
        />
        
        {/* Contenitore dello schermo e del frame con effetto 3D */}
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            transform: 'rotateY(2deg) rotateX(1deg)',
            transformStyle: 'preserve-3d',
            transition: 'all 0.3s ease-in-out'
          }}
        >
          {/* Schermo dell'app - posizionato per adattarsi al frame */}
          <Box
            sx={{
              position: 'absolute',
              top: '2.5%',
              left: '6.5%',
              width: '87%',
              height: '92%',
              borderRadius: '38px',
              overflow: 'hidden',
              zIndex: 1
            }}
          >
            <Box
              component="img"
              src={imageUrl}
              alt={altText}
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover', 
                objectPosition: 'center center', 
                transform: 'scale(1.02)', 
                transformOrigin: 'center 20%', 
              }}
            />
            
            {/* Effetto riflesso sullo schermo */}
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '40%',
                background: 'linear-gradient(135deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0.05) 40%, rgba(255,255,255,0) 60%)',
                zIndex: 2,
                pointerEvents: 'none'
              }}
            />
            
            {/* Gradiente di ombra sui bordi dello schermo */}
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                boxShadow: 'inset 0 0 20px rgba(0,0,0,0.15)',
                zIndex: 3,
                pointerEvents: 'none'
              }}
            />
          </Box>
          
          {/* Frame dell'iPhone X - sempre sopra lo screenshot */}
          <Box
            component="img"
            src="/iphoneX.png"
            alt="iPhone X frame"
            sx={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              zIndex: 10,
              pointerEvents: 'none'
            }}
          />
        </Box>
      </Box>
      
      {/* Text Content */}
      <Box
        sx={{
          flex: 1,
          maxWidth: { xs: '100%', md: '50%' }
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '1.8rem', sm: '2.2rem' },
            color: darkText ? '#333333' : '#ffffff',
            mb: 3
          }}
        >
          {title}
        </Typography>
        
        <Typography
          sx={{
            fontSize: { xs: '1rem', sm: '1.1rem' },
            color: darkText ? '#555555' : '#cccccc',
            mb: 2,
            lineHeight: 1.6
          }}
        >
          {description1}
        </Typography>
        
        <Typography
          sx={{
            fontSize: { xs: '1rem', sm: '1.1rem' },
            color: darkText ? '#555555' : '#cccccc',
            lineHeight: 1.6
          }}
        >
          {description2}
        </Typography>
      </Box>
    </Box>
  );
};

export default PhoneShowcase;
