import React, { useEffect, useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
  Alert,
  IconButton,
  Divider,
  Autocomplete,
  Grid
} from '@mui/material';
import { 
  PhotoCamera, 
  Clear,
  Twitter,
  Facebook,
  Instagram,
  LinkedIn,
  Language
} from '@mui/icons-material';
import { Party, Place } from '../../../types/database.types';
import { partyService } from '../../../services/partyService';
import { storageService } from '../../../services/storageService';
import { placesService } from '../../../services/placesService';
import { useAuth } from '../../../hooks/useAuth';
import { userService } from '../../../services/userService';

interface AdminPartyDialogProps {
  open: boolean;
  party: Party | null;
  onClose: () => void;
  onSave: (party: Party) => Promise<boolean | void>;
}

export const AdminPartyDialog: React.FC<AdminPartyDialogProps> = ({
  open,
  party,
  onClose,
  onSave,
}) => {
  const { session } = useAuth();
  const [formData, setFormData] = useState<Omit<Party, 'id' | 'created_at' | 'updated_at' | 'created_by'>>({
    name: '',
    description: '',
    symbol: '',
    logo_url: '',
    website_url: '',
    twitter_url: '',
    facebook_url: '',
    instagram_url: '',
    linkedin_url: '',
    headquarters_id: '',
    founded_date: '',
    political_orientation: undefined,
    government_position: undefined
  });
 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [creatorEmail, setCreatorEmail] = useState<string>('');
  const [places, setPlaces] = useState<Place[]>([]);
  const [searchingPlaces, setSearchingPlaces] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);

  const searchPlaces = async (query: string) => {
    if (!query) {
      setPlaces([]);
      return;
    }
    
    setSearchingPlaces(true);
    try {
      const placesData = await placesService.getPlaces(query);
      setPlaces(placesData);
    } catch (error) {
      console.error('Error searching places:', error);
    } finally {
      setSearchingPlaces(false);
    }
  };

  const debouncedSearchPlaces = useCallback(
    ((query: string) => {
      searchPlaces(query);
    }) as any,
    []
  );

  useEffect(() => {
    if (party) {
      console.log('Setting form data from party:', party);
      setFormData({
        name: party.name,
        description: party.description,
        symbol: party.symbol,
        logo_url: party.logo_url || '',
        website_url: party.website_url || '',
        twitter_url: party.twitter_url || '',
        facebook_url: party.facebook_url || '',
        instagram_url: party.instagram_url || '',
        linkedin_url: party.linkedin_url || '',
        headquarters_id: party.headquarters_id || '',
        founded_date: party.founded_date || '',
        political_orientation: party.political_orientation,
        government_position: party.government_position
      });
      setImagePreview(party.logo_url || null);
      if (party.headquarters) {
        setSelectedPlace(party.headquarters);
      }
      // Fetch creator email
      userService.getUserById(party.created_by).then(user => {
        if (user?.email) {
          setCreatorEmail(user.email);
        }
      });
    } else {
      console.log('Resetting form data');
      setFormData({
        name: '',
        description: '',
        symbol: '',
        logo_url: '',
        website_url: '',
        twitter_url: '',
        facebook_url: '',
        instagram_url: '',
        linkedin_url: '',
        headquarters_id: '',
        founded_date: '',
        political_orientation: undefined,
        government_position: undefined
      });
      setImagePreview(null);
      setCreatorEmail('');
    }
    setError(null);
  }, [party, open]);

  useEffect(() => {
    if (party?.headquarters_id) {
      placesService.getPlace(party.headquarters_id)
        .then(place => {
          setSelectedPlace(place);
        })
        .catch(error => {
          console.error('Error loading headquarters:', error);
        });
    }
  }, [party?.headquarters_id]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSocialMediaChange = (
    field: 'website_url' | 'twitter_url' | 'facebook_url' | 'instagram_url' | 'linkedin_url',
    value: string
  ) => {
    let formattedValue = value.trim();
    // Remove http:// or https:// for display
    formattedValue = formattedValue.replace(/^https?:\/\//, '');
    setFormData(prev => ({ ...prev, [field]: formattedValue }));
  };

  const formatUrl = (url: string | undefined): string | undefined => {
    if (!url) return undefined;
    if (url.trim() === '') return undefined;
    
    let formattedUrl = url.trim();
    // Remove http:// or https:// for processing
    formattedUrl = formattedUrl.replace(/^https?:\/\//, '');
    
    // Basic URL validation
    if (!formattedUrl.includes('.')) return undefined;
    if (formattedUrl.includes(' ')) return undefined;
    
    return `https://${formattedUrl}`;
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!session) {
      setError('Please sign in to upload images');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // Upload to Supabase Storage using the storage service
      const publicUrl = await storageService.uploadImage(file, 'party-logos');
      
      setFormData(prev => ({ ...prev, logo_url: publicUrl }));
      setImagePreview(URL.createObjectURL(file));
    } catch (err) {
      console.error('Error uploading image:', err);
      setError(err instanceof Error ? err.message : 'Failed to upload image');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = async () => {
    if (!session) {
      setError('Please sign in to remove images');
      return;
    }

    if (formData.logo_url) {
      try {
        setLoading(true);
        await storageService.deleteImage(formData.logo_url);
        setFormData(prev => ({ ...prev, logo_url: '' }));
        setImagePreview(null);
      } catch (err) {
        console.error('Error removing image:', err);
        setError(err instanceof Error ? err.message : 'Failed to remove image');
      } finally {
        setLoading(false);
      }
    } else {
      setFormData(prev => ({ ...prev, logo_url: '' }));
      setImagePreview(null);
    }
  };

  const handleSubmit = async () => {
    try {
      console.log('Submitting form data:', formData);
      setLoading(true);
      setError('');

      if (!formData.name.trim()) {
        setError('Party name is required');
        setLoading(false);
        return;
      }

      // Format URLs before saving
      const website = formatUrl(formData.website_url);
      const twitter = formatUrl(formData.twitter_url);
      const facebook = formatUrl(formData.facebook_url);
      const instagram = formatUrl(formData.instagram_url);
      const linkedin = formatUrl(formData.linkedin_url);

      const updatedParty: Partial<Party> = {
        ...formData,
        website_url: website,
        twitter_url: twitter,
        facebook_url: facebook,
        instagram_url: instagram,
        linkedin_url: linkedin,
        headquarters_id: selectedPlace?.id
      };

      // Only include id and other fields if editing an existing party
      if (party) {
        updatedParty.id = party.id;
        updatedParty.created_at = party.created_at;
        updatedParty.updated_at = new Date().toISOString();
        updatedParty.created_by = party.created_by;
      } else {
        updatedParty.updated_at = new Date().toISOString();
      }

      const result = await onSave(updatedParty as Party);

      if (result === false) {
        setLoading(false);
        return;
      }

      onClose();
    } catch (err) {
      console.error('Error saving party:', err);
      setError('Failed to save party');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {party ? 'Edit Party' : 'Add Party'}
      </DialogTitle>

      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, my: 2 }}>
          {/* Top Section - Logo and Basic Info */}
          <Box sx={{ display: 'flex', gap: 4 }}>
            {/* Logo Section */}
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center', 
              gap: 2,
              minWidth: 250 
            }}>
              {imagePreview ? (
                <Box
                  component="img"
                  src={imagePreview}
                  alt="Logo preview"
                  sx={{
                    width: 200,
                    height: 200,
                    objectFit: 'contain',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                />
              ) : (
                <Box
                  sx={{
                    width: 200,
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    border: '1px dashed',
                    borderColor: 'divider'
                  }}
                >
                  <Typography color="text.secondary">No logo uploaded</Typography>
                </Box>
              )}
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="logo-upload"
                  type="file"
                  onChange={handleImageUpload}
                />
                <label htmlFor="logo-upload">
                  <Button
                    variant="outlined"
                    component="span"
                    startIcon={<PhotoCamera />}
                  >
                    Upload Logo
                  </Button>
                </label>
                {imagePreview && (
                  <IconButton onClick={handleRemoveImage} color="error">
                    <Clear />
                  </IconButton>
                )}
              </Box>
            </Box>

            {/* Basic Information Section */}
            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>Basic Information</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Symbol"
                    name="symbol"
                    value={formData.symbol || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Founded Date"
                    name="founded_date"
                    value={formData.founded_date || ''}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    options={places}
                    value={selectedPlace}
                    onChange={(_, newValue) => {
                      setSelectedPlace(newValue);
                      setFormData(prev => ({
                        ...prev,
                        headquarters_id: newValue?.id || ''
                      }));
                    }}
                    onInputChange={(_, newInputValue) => {
                      debouncedSearchPlaces(newInputValue);
                    }}
                    getOptionLabel={(option) => `${option.name}, ${option.state}`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Headquarters"
                        placeholder="Type to search places..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {searchingPlaces ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    loading={searchingPlaces}
                    loadingText="Searching places..."
                    noOptionsText="No places found"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Description"
                    name="description"
                    value={formData.description || ''}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Political Orientation"
                    name="political_orientation"
                    value={formData.political_orientation || ''}
                    onChange={handleChange}
                    SelectProps={{
                      native: true
                    }}
                  >
                    <option value="">Select orientation</option>
                    <option value="Sinistra">Sinistra - Progressismo, uguaglianza sociale, stato sociale, ambientalismo</option>
                    <option value="Centro-Sinistra">Centro-Sinistra - Moderato, riformista, europeista</option>
                    <option value="Centro">Centro - Liberalismo, economia di mercato con intervento moderato dello Stato</option>
                    <option value="Centro-Destra">Centro-Destra - Conservatorismo moderato, liberismo economico</option>
                    <option value="Destra">Destra - Nazionalismo, tradizionalismo, sovranismo, autorità dello Stato</option>
                    <option value="Populista progressista">Populista progressista - Ecologista, anti-casta, riformista</option>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Government Position"
                    name="government_position"
                    value={formData.government_position || ''}
                    onChange={handleChange}
                    SelectProps={{
                      native: true
                    }}
                  >
                    <option value="">Select position</option>
                    <option value="Governo">Governo</option>
                    <option value="Opposizione">Opposizione</option>
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* Social Media Section */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>Website & Social Media</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Website URL"
                  name="website_url"
                  value={formData.website_url || ''}
                  onChange={(e) => handleSocialMediaChange('website_url', e.target.value)}
                  InputProps={{
                    startAdornment: <Language sx={{ mr: 1, color: 'action.active' }} />,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Twitter URL"
                  name="twitter_url"
                  value={formData.twitter_url || ''}
                  onChange={(e) => handleSocialMediaChange('twitter_url', e.target.value)}
                  InputProps={{
                    startAdornment: <Twitter sx={{ mr: 1, color: 'action.active' }} />,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Facebook URL"
                  name="facebook_url"
                  value={formData.facebook_url || ''}
                  onChange={(e) => handleSocialMediaChange('facebook_url', e.target.value)}
                  InputProps={{
                    startAdornment: <Facebook sx={{ mr: 1, color: 'action.active' }} />,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Instagram URL"
                  name="instagram_url"
                  value={formData.instagram_url || ''}
                  onChange={(e) => handleSocialMediaChange('instagram_url', e.target.value)}
                  InputProps={{
                    startAdornment: <Instagram sx={{ mr: 1, color: 'action.active' }} />,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="LinkedIn URL"
                  name="linkedin_url"
                  value={formData.linkedin_url || ''}
                  onChange={(e) => handleSocialMediaChange('linkedin_url', e.target.value)}
                  InputProps={{
                    startAdornment: <LinkedIn sx={{ mr: 1, color: 'action.active' }} />,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          {party && (
            <Box>
              <Typography variant="caption" color="text.secondary">
                Created by: {creatorEmail}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 3 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};