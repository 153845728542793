import { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField,
  Autocomplete,
  CircularProgress,
  Alert,
  Box,
  FormControlLabel,
  Checkbox,
  Link,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { supabase } from '../../lib/supabaseClient';
import { useAuth } from '../../hooks/useAuth';
import { italianTranslations as t } from '../../i18n/it';
import { placesService } from '../../services/placesService';
import { Place } from '../../types/database.types';
import { debounce } from 'lodash';
import PrivacyPolicy from '../Privacy/PrivacyPolicy';
import { countries, Country } from '../../utils/countries';

interface ActivationDialogProps {
  open: boolean;
  onComplete: () => void;
  onCancel: () => void;
}

export default function ActivationDialog({ open, onComplete, onCancel }: ActivationDialogProps) {
  const [age, setAge] = useState('');
  const [residentPlace, setResidentPlace] = useState<Place | null>(null);
  const [places, setPlaces] = useState<Place[]>([]);
  const [searchingPlaces, setSearchingPlaces] = useState(false);
  const [gender, setGender] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [livingAbroad, setLivingAbroad] = useState(false);
  const [country, setCountry] = useState<Country | null>(null);
  const { session } = useAuth();

  // Debounced function to search places
  const searchPlaces = debounce(async (query: string) => {
    if (!query) {
      setPlaces([]);
      return;
    }
    try {
      setSearchingPlaces(true);
      const results = await placesService.getActivePlaces(query);
      setPlaces(results);
    } catch (error) {
      console.error('Error searching places:', error);
    } finally {
      setSearchingPlaces(false);
    }
  }, 300);

  const handleSubmit = async () => {
    setError('');
    if (!privacyAccepted) {
      setError('Devi accettare la Privacy Policy per continuare');
      return;
    }
    if (!age || !residentPlace || !gender) {
      setError('Per favore compila tutti i campi');
      return;
    }
    
    // Validazione età
    const ageNum = parseInt(age);
    if (isNaN(ageNum) || ageNum < 18 || ageNum > 99) {
      setError('L\'età deve essere un numero compreso tra 18 e 99');
      return;
    }
    
    if (livingAbroad && !country) {
      setError('Se vivi all\'estero, seleziona la tua nazione');
      return;
    }

    try {
      setLoading(true);
      const { error: updateError } = await supabase.auth.updateUser({
        data: {
          age: ageNum,
          resident_place_id: residentPlace?.id,
          gender: gender,
          living_abroad: livingAbroad,
          country_iso3: livingAbroad ? country?.iso3 : null,
          country_name: livingAbroad ? country?.name : null,
          tour_completed: false, // Imposta il flag per il tour a false per mostrarlo dopo l'attivazione
        },
      });

      if (updateError) throw updateError;

      onComplete();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onCancel}
        fullScreen
        PaperProps={{
          elevation: 0,
          sx: {
            boxShadow: 'none',
            border: 'none',
            borderRadius: 0,
            overflow: 'auto',
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%'
          }
        }}
        sx={{
          '& .MuiDialog-paper': {
            m: 0,
            p: 0,
            maxHeight: '100%',
            height: '100%',
            width: '100%',
            maxWidth: '100%',
            boxShadow: 'none',
            border: 'none',
            borderRadius: 0,
            backgroundColor: 'background.paper',
            overflow: 'auto'
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          '& .MuiDialog-container': {
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
          }
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            pt: 4
          }}
        >
          <Box 
            component="img"
            src="/logo.png"
            alt="Polinder Logo"
            sx={{
              width: 'auto',
              height: '97px',
              mb: 3,
              display: 'block',
              opacity: 1,
              transition: 'opacity 0.3s ease-in-out',
              position: 'relative',
              zIndex: 1,
              animation: 'fadeIn 0.5s ease-in-out',
              '@keyframes fadeIn': {
                '0%': {
                  opacity: 0,
                },
                '100%': {
                  opacity: 1,
                },
              },
            }}
          />
          <DialogTitle
            sx={{
              p: { xs: 2, sm: 3 },
              fontSize: '18px',
              fontWeight: 'normal',
              borderBottom: 'none',
              backgroundColor: 'transparent',
              textAlign: 'center',
              width: '100%',
              maxWidth: '500px',
              mx: 'auto',
              color: 'text.secondary'
            }}
          >
            {t.auth.activateAccount}
          </DialogTitle>
          <DialogContent
            sx={{
              p: { xs: 2, sm: 3 },
              '&::-webkit-scrollbar': {
                width: '8px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: '4px'
              },
              border: 'none',
              backgroundColor: 'transparent',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: 'auto',
              overflow: 'auto'
            }}
          >
            <Box sx={{ 
              width: '100%', 
              maxWidth: '500px', 
              mx: 'auto',
              px: { xs: 1, sm: 2 } 
            }}>
              {error && <Alert severity="error" sx={{ mb: 1, fontSize: '14px' }}>{error}</Alert>}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 1, sm: 1.2 }, mt: 1 }}>
                <TextField
                  label={t.auth.age}
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  fullWidth
                  type="text"
                  inputProps={{ 
                    pattern: "[0-9]*",
                    inputMode: "numeric"
                  }}
                  InputProps={{
                    style: { fontSize: '16px' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '16px' }
                  }}
                  required
                  sx={{ mb: 1 }}
                />

                <Autocomplete
                  value={residentPlace}
                  onChange={(_, newValue) => setResidentPlace(newValue)}
                  onInputChange={(_, newInputValue) => searchPlaces(newInputValue)}
                  options={places}
                  getOptionLabel={(option) => `${option.name}, ${option.state}`}
                  loading={searchingPlaces}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t.auth.residentPlace}
                      required
                      InputLabelProps={{
                        style: { fontSize: '16px' }
                      }}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: '16px' }
                      }}
                      sx={{ 
                        mb: 1,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '0.875rem'
                        }
                      }}
                    />
                  )}
                />

                <FormControl fullWidth sx={{ mb: 1 }}>
                  <InputLabel id="gender-label" sx={{ fontSize: '16px' }}>{t.auth.gender}</InputLabel>
                  <Select
                    labelId="gender-label"
                    value={gender}
                    onChange={(e) => setGender(e.target.value as string)}
                    label={t.auth.gender}
                    required
                    sx={{ fontSize: '16px' }}
                  >
                    <MenuItem value="male">{t.auth.male}</MenuItem>
                    <MenuItem value="female">{t.auth.female}</MenuItem>
                    <MenuItem value="other">{t.auth.other}</MenuItem>
                  </Select>
                </FormControl>

                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={livingAbroad} 
                      onChange={(e) => setLivingAbroad(e.target.checked)} 
                    />
                  }
                  label={<Typography sx={{ fontSize: '16px' }}>{t.auth.livingAbroad}</Typography>}
                  sx={{ mb: 1, alignSelf: 'flex-start' }}
                />

                {livingAbroad && (
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <InputLabel id="country-label" sx={{ fontSize: '16px' }}>{t.auth.country}</InputLabel>
                    <Select
                      labelId="country-label"
                      value={country ? country.iso3 : ''}
                      onChange={(e) => {
                        const selectedCountry = countries.find(c => c.iso3 === e.target.value);
                        setCountry(selectedCountry || null);
                      }}
                      label={t.auth.country}
                      required
                      sx={{ fontSize: '16px' }}
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.iso3} value={country.iso3}>{country.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={privacyAccepted}
                      onChange={(e) => setPrivacyAccepted(e.target.checked)}
                      color="primary"
                      required
                    />
                  }
                  label={
                    <span style={{ fontSize: '0.875rem' }}>
                      Ho letto e accetto la{' '}
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => setOpenPrivacy(true)}
                        sx={{
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline'
                          }
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </span>
                  }
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              p: { xs: 0.8, sm: 0.7 },
              pt: 0,
              mt: -1,
              backgroundColor: 'transparent',
              borderTop: 'none',
              gap: 2,
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={onCancel}
              disabled={loading}
              variant="outlined"
              sx={{
                px: { xs: 3, sm: 4 },
                height: '48px',
                width: '150px',
                borderRadius: '24px',
                textTransform: 'none',
                fontWeight: 'normal',
                borderColor: '#CE2B37',
                color: '#CE2B37',
                '&:hover': {
                  borderColor: '#A32D37',
                  backgroundColor: 'rgba(206, 43, 55, 0.1)'
                }
              }}
            >
              {t.common.cancel}
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={loading}
              sx={{ 
                mt: 2, 
                mb: 2,
                height: '48px',
                width: '150px',
                borderRadius: '24px',
                textTransform: 'none',
                fontSize: '18px',
                fontWeight: 'normal',
                backgroundColor: '#ad0e38',
                '&:hover': {
                  backgroundColor: '#8c0b2d'
                }
              }}
            >
              {loading ? <CircularProgress size={24} /> : t.auth.activate}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        open={openPrivacy}
        onClose={() => setOpenPrivacy(false)}
        fullScreen
        scroll="paper"
        PaperProps={{
          elevation: 0,
          sx: {
            boxShadow: 'none',
            border: 'none',
            borderRadius: 0,
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
        sx={{
          '& .MuiDialog-paper': {
            m: 0,
            p: 0,
            maxHeight: '100%',
            height: '100%',
            width: '100%',
            maxWidth: '100%',
            boxShadow: 'none',
            border: 'none',
            borderRadius: 0,
            backgroundColor: 'background.paper',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          '& .MuiDialog-container': {
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
          },
          '& .MuiDialogContent-root': {
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,0.2)',
              borderRadius: '4px'
            }
          }
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}
        >
          <Box 
            component="img"
            src="/logo.png"
            alt="Polinder Logo"
            sx={{
              width: 'auto',
              height: '97px',
              mt: 4,
              mb: 3,
              flexShrink: 0,
              display: 'block',
              opacity: 1,
              transition: 'opacity 0.3s ease-in-out',
              position: 'relative',
              zIndex: 1,
              animation: 'fadeIn 0.5s ease-in-out',
              '@keyframes fadeIn': {
                '0%': {
                  opacity: 0,
                },
                '100%': {
                  opacity: 1,
                },
              },
            }}
          />
          <DialogContent
            sx={{
              p: 2,
              width: '100%',
              maxWidth: '800px',
              flexGrow: 1,
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Box sx={{ width: '100%', maxWidth: '800px' }}>
              <PrivacyPolicy />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              p: { xs: 2, sm: 3 },
              backgroundColor: 'transparent',
              borderTop: 'none',
              justifyContent: 'center',
              flexShrink: 0,
              width: '100%',
              mb: 2
            }}
          >
            <Button
              onClick={() => setOpenPrivacy(false)}
              variant="contained"
              sx={{
                px: { xs: 3, sm: 4 },
                height: '48px',
                width: '150px',
                borderRadius: '24px',
                textTransform: 'none',
                fontWeight: 'normal',
                backgroundColor: '#ad0e38',
                '&:hover': {
                  backgroundColor: '#8c0b2d'
                }
              }}
            >
              Chiudi
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
