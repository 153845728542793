import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Card, 
  CardMedia, 
  Typography, 
  Box, 
  IconButton,
  Divider,
  useTheme,
  CircularProgress,
  Tooltip
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import BrushIcon from '@mui/icons-material/Brush';
import ShareIcon from '@mui/icons-material/Share';
import { keyframes } from '@emotion/react';
import { Politician } from '../../../types/database.types';
import { PoliticianCardDetails } from './PoliticianCardDetails';
import { PoliticianCardStatistic } from './PoliticianCardStatistic';
import { PoliticianCardInfo } from './PoliticianCardInfo';
import { PoliticianCardNews } from './PoliticianCardNews';
import { supabase } from '../../../lib/supabaseClient';
import { caricatureService } from '../../../services/caricatureService';
import { useAuth } from '../../../hooks/useAuth';

const calculateAge = (birthDate: string): number => {
  const today = new Date();
  const birthDateObj = new Date(birthDate);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDifference = today.getMonth() - birthDateObj.getMonth();
  
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  
  return age;
};

interface PoliticianCardProps {
  politician: Politician | null;
  loading: boolean;
  error: string | null;
  role: string;
  colorIndex: number;
  cardColors: string[];
  onCardClick?: () => void;
  customContent?: React.ReactNode;
  replaceImageOnly?: boolean;
  onLoad?: () => void;
  children?: React.ReactNode;
  enableCaricatureGeneration?: boolean;
  onRefresh?: () => void;
  resetView?: number; // Nuova prop per resettare la vista
}

export const PoliticianCard: React.FC<PoliticianCardProps> = ({
  politician,
  loading,
  error,
  role,
  colorIndex,
  cardColors,
  onCardClick,
  customContent,
  replaceImageOnly = false,
  onLoad,
  children,
  enableCaricatureGeneration = false,
  onRefresh,
  resetView,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { session } = useAuth();
  const [showDetails, setShowDetails] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const [showParty, setShowParty] = useState(true);
  const [hasVoted, setHasVoted] = useState(false);
  const [generatingCaricature, setGeneratingCaricature] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [caricatureError, setCaricatureError] = useState<string | null>(null);
  const cardRef = useRef<HTMLDivElement>(null);

  const handleCardClick = () => {
    if (politician) {
      setShowDetails(true);
    }
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
  };

  const handleInfoClick = () => {
    if (politician) {
      setShowInfo(true);
    }
  };

  const handleCloseInfo = () => {
    setShowInfo(false);
  };

  const handleNewsClick = () => {
    if (politician) {
      setShowNews(true);
    }
  };

  const handleCloseNews = () => {
    setShowNews(false);
  };

  const handleStatsClick = () => {
    if (politician) {
      setShowStats(true);
    }
  };

  const handleCloseStats = () => {
    setShowStats(false);
  };

  const handleVoteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowDetails(true);
  };

  const checkLastVote = async () => {
    if (politician?.id) {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        const { data, error } = await supabase
          .from('politician_votes_history')
          .select('voted_at')
          .eq('politician_id', politician.id)
          .eq('user_id', user.id)
          .order('voted_at', { ascending: false })
          .limit(1);

        if (error) {
          console.error('Error checking last vote:', error);
          return;
        }

        setHasVoted(data && data.length > 0);
      } catch (error) {
        console.error('Error checking last vote:', error);
      }
    }
  };

  useEffect(() => {
    checkLastVote();
  }, [politician]);

  useEffect(() => {
    if (resetView !== undefined) {
      setShowDetails(false);
      setShowInfo(false);
      setShowStats(false);
      setShowNews(false);
    }
  }, [resetView]);

  const handleImageLoad = () => {
    console.log('Immagine caricata');
    setImageLoaded(true);
    if (onLoad) {
      onLoad();
    }
  };

  const handleGenerateCaricature = async (e: React.MouseEvent) => {
    e.stopPropagation(); 
    if (!politician || !session?.user?.id) {
      console.log('Politician or session user ID is missing, cannot generate caricature');
      setCaricatureError('Non è possibile generare la caricatura: sessione non valida');
      return;
    }

    try {
      setGeneratingCaricature(true);
      setCaricatureError(null);
      await caricatureService.generatePoliticianCaricature(politician, session.user.id);
      
      const { data, error: refreshError } = await supabase
        .from('politicians')
        .select('*')
        .eq('id', politician.id)
        .single();
      
      if (refreshError) {
        throw refreshError;
      }
      
      if (data && data.image_url) {
        politician.image_url = data.image_url;
        setImageLoaded(false);
        
        if (onRefresh) {
          onRefresh();
        }
      }
    } catch (error) {
      console.error('Error generating caricature:', error);
      if (error instanceof Error) {
        if (error.message === 'Caricatura già in fase di sviluppo') {
          setCaricatureError('Caricatura già in fase di sviluppo da un altro utente');
        } else if (error.message === 'Non è stato possibile trovare un ritratto per questo politico') {
          setCaricatureError('Non è stato possibile trovare un ritratto per questo politico');
        } else if (error.message === 'Non è stato possibile generare la caricatura') {
          setCaricatureError('Non è stato possibile generare la caricatura');
        } else if (error.message === 'Non è stato possibile salvare la caricatura') {
          setCaricatureError('Non è stato possibile salvare la caricatura');
        } else {
          setCaricatureError(`Errore nella generazione della caricatura: ${error.message}`);
        }
      } else {
        setCaricatureError('Errore nella generazione della caricatura');
      }
    } finally {
      setGeneratingCaricature(false);
    }
  };

  const handleShareOnWhatsApp = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!politician) return;
    
    const politicianName = `${politician.first_name} ${politician.last_name}`;
    const encodedName = encodeURIComponent(politicianName);
    const encodedImageUrl = encodeURIComponent(politician.image_url || '');
    
    // Costruisci l'URL relativo alla landing page del politico
    const politicianUrl = `${window.location.origin}/politician/${politician.id}?name=${encodedName}&img=${encodedImageUrl}`;
    
    // Crea il messaggio da condividere
    const message = `Scopri ${politicianName} su Polinder: ${politicianUrl}`;
    
    // Apri WhatsApp Web con il messaggio precompilato
    window.open(`https://wa.me/?text=${encodeURIComponent(message)}`, '_blank');
  };

  const rotate = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  `;
  
  const pulse = keyframes`
    0% { transform: scale(1); opacity: 0.8; }
    50% { transform: scale(1.1); opacity: 1; }
    100% { transform: scale(1); opacity: 0.8; }
  `;
  
  const fade = keyframes`
    0% { opacity: 0.7; }
    50% { opacity: 1; }
    100% { opacity: 0.7; }
  `;
  
  const paintStroke = keyframes`
    0% { clip-path: circle(0% at center); }
    100% { clip-path: circle(100% at center); }
  `;
  
  const bounce = keyframes`
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  `;
  
  const colorShift = keyframes`
    0% { color: #2196f3; }
    25% { color: #f44336; }
    50% { color: #4caf50; }
    75% { color: #ff9800; }
    100% { color: #2196f3; }
  `;

  const generateCaricatureContent = () => {
    if (generatingCaricature) {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          bgcolor: 'rgba(0, 0, 0, 0.05)',
          borderRadius: '8px',
          position: 'relative',
          overflow: 'hidden',
          aspectRatio: '1/1',
        }}>
          {/* Background gradient animation */}
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(45deg, rgba(33, 150, 243, 0.1), rgba(76, 175, 80, 0.1), rgba(244, 67, 54, 0.1))',
            backgroundSize: '300% 300%',
            animation: 'gradientBG 5s ease infinite',
            '@keyframes gradientBG': {
              '0%': { backgroundPosition: '0% 50%' },
              '50%': { backgroundPosition: '100% 50%' },
              '100%': { backgroundPosition: '0% 50%' }
            },
            zIndex: 0,
          }} />
          
          {/* Circular pulse animation */}
          <Box sx={{
            position: 'absolute',
            width: '70%',
            height: '70%',
            borderRadius: '50%',
            background: 'radial-gradient(circle, rgba(33, 150, 243, 0.2) 0%, rgba(33, 150, 243, 0) 70%)',
            animation: `${pulse} 3s infinite ease-in-out`,
            zIndex: 1,
          }} />
          
          {/* Brush icon with animations */}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            zIndex: 3,
            animation: `${bounce} 2s infinite ease-in-out`,
          }}>
            <BrushIcon 
              sx={{ 
                fontSize: 80,
                animation: `${rotate} 6s infinite linear, ${colorShift} 8s infinite linear`,
                filter: 'drop-shadow(0 0 8px rgba(33, 150, 243, 0.5))',
              }} 
            />
          </Box>
          
          {/* Animated paint strokes */}
          <Box sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 2,
            opacity: 0.2,
          }}>
            <Box sx={{
              position: 'absolute',
              top: '20%',
              left: '20%',
              width: '20%',
              height: '5px',
              bgcolor: '#f44336',
              transform: 'rotate(45deg)',
              animation: `${fade} 2.5s infinite ease-in-out 0.2s`,
            }} />
            <Box sx={{
              position: 'absolute',
              top: '30%',
              right: '25%',
              width: '15%',
              height: '5px',
              bgcolor: '#4caf50',
              transform: 'rotate(-30deg)',
              animation: `${fade} 2.5s infinite ease-in-out 0.5s`,
            }} />
            <Box sx={{
              position: 'absolute',
              bottom: '35%',
              left: '30%',
              width: '25%',
              height: '5px',
              bgcolor: '#ff9800',
              transform: 'rotate(15deg)',
              animation: `${fade} 2.5s infinite ease-in-out 0.8s`,
            }} />
          </Box>
          
          {/* Loading text */}
          <Typography
            variant="body1"
            sx={{
              position: 'absolute',
              bottom: '15%',
              color: 'primary.main',
              fontWeight: 'bold',
              textAlign: 'center',
              animation: `${fade} 1.5s infinite ease-in-out`,
              zIndex: 4,
              px: 2,
              textShadow: '0px 0px 5px rgba(255, 255, 255, 0.7)',
            }}
          >
            Generazione caricatura in corso...
          </Typography>
        </Box>
      );
    } else if (politician && !politician.image_url && enableCaricatureGeneration) {
      return (
        <Box
          onClick={handleGenerateCaricature}
          sx={{
            width: '100%',
            overflow: 'hidden',
            margin: 0,
            padding: 0,
            position: 'relative',
            aspectRatio: '16/14',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: theme.palette.background.default,
            cursor: 'pointer',
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <AddPhotoAlternateIcon 
              sx={{ 
                fontSize: 60,
                color: 'primary.main',
                mb: 1
              }} 
            />
            <Typography 
              variant="caption" 
              color="primary"
              sx={{ 
                fontSize: '0.9rem',
                textAlign: 'center',
                fontWeight: 'medium'
              }}
            >
              Genera Caricatura
            </Typography>
          </Box>
        </Box>
      );
    }
    
    return null; 
  };

  const finalCustomContent = enableCaricatureGeneration 
    ? generateCaricatureContent() || customContent 
    : customContent;

  if (loading) {
    return (
      <Card sx={{ 
        bgcolor: theme.palette.background.default, 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        padding: 0,
        borderRadius: '8px',
      }}>
        <Box sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: theme.palette.background.default,
        }}>
          <CircularProgress 
            size={60}
            color="primary" 
          />
        </Box>
      </Card>
    );
  }

  if (error) {
    return (
      <Card sx={{ 
        bgcolor: theme.palette.background.default, 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        padding: 0,
        borderRadius: '8px',
      }}>
        <Box sx={{
          width: '100%',
          height: '100%',
          bgcolor: theme.palette.background.default,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}>
          <Typography 
            color="error" 
            variant="body1" 
            align="center" 
            sx={{ color: theme.palette.error.main }}
          >
            {error}
          </Typography>
        </Box>
      </Card>
    );
  }

  if (!politician && !customContent) {
    return null;
  }

  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%', backgroundColor: theme.palette.background.default }}>
      <Card 
        ref={cardRef}
        sx={{
          width: '100vw',
          height: '100%',
          boxShadow: 'none',
          borderRadius: 0,
          position: 'relative',
          m: 0,
          p: 0,
          border: 'none',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.background.default
        }}
        onClick={onCardClick || handleCardClick}
      >
        {/* Immagine del politico */}
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
            margin: 0,
            padding: 0,
            aspectRatio: '16/14',
            bgcolor: theme.palette.background.default,
          }}
        >
          {/* Bottone di condivisione */}
          <Tooltip title="Condividi su WhatsApp">
            <IconButton
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                bgcolor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 10,
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                },
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              }}
              onClick={handleShareOnWhatsApp}
            >
              <ShareIcon sx={{ color: '#25D366' }} />
            </IconButton>
          </Tooltip>

          {replaceImageOnly && finalCustomContent ? (
            finalCustomContent
          ) : (
            <Box sx={{ position: 'relative', width: '100%' }}>
              <CardMedia
                component="img"
                onLoad={handleImageLoad}
                sx={{
                  width: '100vw',
                  height: 'auto',
                  maxHeight: 'calc(100vh - 300px)',
                  display: 'block',
                  objectFit: 'contain',
                  objectPosition: 'top',
                  aspectRatio: 'auto',
                  transition: 'transform 0.5s ease',
                  padding: 0,
                  margin: 0,
                  border: 'none',
                  borderRadius: 0,
                  '&:hover': {
                    transform: 'none',
                  },
                  backgroundColor: theme.palette.background.default
                }}
                image={politician?.image_url || '/placeholder.png'}
                alt={politician ? `${politician.first_name} ${politician.last_name}` : 'Politico'}
                onClick={handleCardClick}
              />
            </Box>
          )}
          {children}
        </Box>
        <Box sx={{ 
          padding: '15px 10px',
          width: '100%',
          margin: 0,
          marginTop: '10px', 
          border: 'none',
          borderRadius: 0,
          position: 'relative',
          top: 0, 
          left: 0,
          right: 0,
          zIndex: 5,
          flexGrow: 0,
          flexShrink: 0,
          borderTop: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start', 
          textAlign: 'center',
          backgroundColor: theme.palette.background.default
        }}
        onClick={handleCardClick}
        >
          <Typography 
            variant="h5" 
            component="div"
            align="center"
            sx={{
              textShadow: '0px 1px 2px rgba(0,0,0,0.3)',
              fontSize: '1.5rem',
              fontWeight: 'normal',
              color: theme.palette.text.primary,
              marginTop: 0,
              width: '100%',
              textAlign: 'center'
            }}
          >
            {politician?.first_name} {politician?.last_name}
          </Typography>
          <Typography 
            variant="subtitle1" 
            align="center"
            sx={{
              fontWeight: 'normal',
              letterSpacing: 0.5,
              mt: 0.5,
              fontSize: '0.85rem',
              color: theme.palette.text.secondary,
              width: '100%',
              textAlign: 'center',
              px: 2, 
              lineHeight: 1.4, 
              wordBreak: 'break-word', 
              whiteSpace: 'pre-wrap'
            }}
          >
            {role}
          </Typography>
          <Typography 
            variant="body2" 
            align="center"
            sx={{
              color: theme.palette.text.disabled, 
              mt: 0.5,
              fontSize: '0.85rem',
              width: '100%',
              textAlign: 'center'
            }}
          >
            {politician?.birth_date ? 
              `${calculateAge(politician.birth_date)} anni` : 
              "Età non disponibile"}
            {politician?.birth_place ? 
              `, ${politician.birth_place.name}` : 
              ", Luogo di nascita non disponibile"}
          </Typography>
            
          <Box 
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 2,
              mb: 1,
              px: { xs: 2, sm: 4, md: 6 },
              width: '100%',
              justifyContent: 'space-around'
            }}
          >
            {politician?.party?.logo_url && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                  transition: 'transform 0.2s ease',
                  width: { xs: '50px', sm: '65px', md: '75px' },
                  height: { xs: '50px', sm: '65px', md: '75px' },
                  borderRadius: '50%',
                  overflow: 'hidden'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (politician?.party?.id) {
                    navigate(`/app/user/parties?selectedPartyId=${politician.party.id}`);
                  }
                }}
              >
                <img 
                  src={politician.party.logo_url} 
                  alt={politician.party.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '50%'
                  }}
                />
              </Box>
            )}
                
            <Box
              sx={{
                width: { xs: '50px', sm: '65px', md: '75px' },
                height: { xs: '50px', sm: '65px', md: '75px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
                transition: 'transform 0.2s ease'
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleStatsClick();
              }}
            >
              <Box
                component="img"
                src="/icon_statistic.svg"
                alt="Statistics"
                sx={{
                  width: '100%',
                  height: '100%'
                }}
              />
            </Box>

            <Box
              sx={{
                width: { xs: '50px', sm: '65px', md: '75px' },
                height: { xs: '50px', sm: '65px', md: '75px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
                transition: 'transform 0.2s ease'
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleInfoClick();
              }}
            >
              <Box
                component="img"
                src="/icon_Info.svg"
                alt="Info"
                sx={{
                  width: '100%',
                  height: '100%'
                }}
              />
            </Box>
                
            <Box
              sx={{
                width: { xs: '50px', sm: '65px', md: '75px' },
                height: { xs: '50px', sm: '65px', md: '75px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
                transition: 'transform 0.2s ease'
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleNewsClick();
              }}
            >
              <Box
                component="img"
                src="/icon_news.svg"
                alt="News"
                sx={{
                  width: '100%',
                  height: '100%'
                }}
              />
            </Box>
                
            <Box
              sx={{
                width: { xs: '50px', sm: '65px', md: '75px' },
                height: { xs: '50px', sm: '65px', md: '75px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
                transition: 'transform 0.2s ease'
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleVoteClick(e);
              }}
            >
              <Box
                component="img"
                src="/icon_vote.svg"
                alt="Vote"
                sx={{
                  width: '100%',
                  height: '100%'
                }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
      {showDetails && politician && (
        <PoliticianCardDetails 
          politician={politician}
          role={role}
          onClose={handleCloseDetails}
          onVoteSubmitted={() => {
            checkLastVote();
            if (onRefresh) {
              onRefresh();
            }
          }}
        />
      )}
      {showInfo && politician && (
        <PoliticianCardInfo 
          politician={politician}
          onClose={handleCloseInfo}
        />
      )}
      {showNews && politician && (
        <PoliticianCardNews 
          politician={politician}
          role={role}
          onClose={handleCloseNews}
        />
      )}
      {showStats && politician && (
        <PoliticianCardStatistic 
          politician={politician}
          role={role}
          onClose={handleCloseStats}
        />
      )}
    </Box>
  );
};
