import { supabase } from '../lib/supabaseClient';

export interface User {
  id: string;
  email: string;
}

class UserService {
  private userCache: Map<string, User> = new Map();

  async getUserById(id: string): Promise<User | null> {
    // Check cache first
    if (this.userCache.has(id)) {
      return this.userCache.get(id)!;
    }

    try {
      // Use auth_users_view instead of admin API
      const { data: user, error } = await supabase
        .from('auth_users_view')
        .select('id, email')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error fetching user:', error);
        return null;
      }

      if (user) {
        const userData: User = {
          id: user.id,
          email: user.email || ''
        };
        // Cache the user data
        this.userCache.set(id, userData);
        return userData;
      }

      return null;
    } catch (error) {
      console.error('Failed to fetch user:', error);
      return null;
    }
  }

  // Get user email from ID, returns a shortened version if available
  async getUserEmail(id: string): Promise<string> {
    try {
      // First try to get the current user's session
      const { data: { session } } = await supabase.auth.getSession();
      
      // If this is the current user, we can get the email directly
      if (session?.user.id === id) {
        return session.user.email?.split('@')[0] || id.substring(0, 8) + '...';
      }

      // Otherwise, try to get the user from the database
      const user = await this.getUserById(id);
      if (user?.email) {
        // Return just the username part of the email
        return user.email.split('@')[0];
      }

      // If all else fails, return a shortened version of the ID
      return id.substring(0, 8) + '...';
    } catch (error) {
      console.error('Error getting user email:', error);
      return id.substring(0, 8) + '...';
    }
  }
}

export const userService = new UserService();
