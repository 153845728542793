import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { PoliticalOffice } from '../../types/database.types';
import { politicalOfficeService } from '../../services/politicalOfficeService';
import { PoliticalOfficeDialog } from './PoliticalOfficeDialog';
import { userService } from '../../services/userService';
import { supabase } from '../../lib/supabaseClient';

interface Props {}

export const PoliticalOfficesList: React.FC<Props> = () => {
  const [offices, setOffices] = useState<PoliticalOffice[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState<PoliticalOffice | null>(null);
  const [error, setError] = useState<string | null>(null);

  const loadOffices = async () => {
    try {
      const data = await politicalOfficeService.getPoliticalOffices();
      setOffices(data);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to load political offices';
      console.error('Error loading offices:', err);
      setError(errorMessage);
    }
  };

  useEffect(() => {
    loadOffices();
  }, []);

  const handleEdit = (office: PoliticalOffice) => {
    setSelectedOffice(office);
    setOpenDialog(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await politicalOfficeService.deletePoliticalOffice(id);
      await loadOffices();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to delete political office';
      console.error('Error deleting office:', err);
      setError(errorMessage);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedOffice(null);
    setError(null);
  };

  const handleSave = async (office: PoliticalOffice): Promise<boolean> => {
    try {
      await loadOffices();
      handleClose();
      return true;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to save political office';
      console.error('Error saving office:', err);
      setError(errorMessage);
      return false;
    }
  };

  const handleAdd = () => {
    setSelectedOffice(null);
    setOpenDialog(true);
    setError(null);
  };

  return (
    <Box sx={{ p: 3 }}>
      {error && (
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Political Offices
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAdd}
          startIcon={<AddIcon />}
        >
          Add New Office
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="30%">Title</TableCell>
              <TableCell width="55%">Description</TableCell>
              <TableCell width="15%" align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offices.map((office) => (
              <TableRow key={office.id}>
                <TableCell>{office.title}</TableCell>
                <TableCell>{office.description}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleEdit(office)}>
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(office.id)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PoliticalOfficeDialog
        open={openDialog}
        onClose={handleClose}
        onSave={handleSave}
        office={selectedOffice}
      />
    </Box>
  );
};
