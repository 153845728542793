import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { PoliticalOffice } from '../../types/database.types';
import { politicalOfficeService } from '../../services/politicalOfficeService';
import { supabase } from '../../lib/supabaseClient';
import { userService } from '../../services/userService';

interface Props {
  open: boolean;
  office: PoliticalOffice | null;
  onClose: () => void;
  onSave: (office: PoliticalOffice) => Promise<boolean>;
}

export const PoliticalOfficeDialog: React.FC<Props> = ({
  open,
  office,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState<Omit<PoliticalOffice, 'id' | 'created_at' | 'updated_at'>>({
    title: '',
    description: '',
    created_by: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [creatorEmail, setCreatorEmail] = useState<string>('');

  useEffect(() => {
    if (office) {
      setFormData({
        title: office.title,
        description: office.description || '',
        created_by: office.created_by
      });
      // Fetch creator email
      userService.getUserById(office.created_by).then(user => {
        if (user?.email) {
          setCreatorEmail(user.email);
        }
      });
    } else {
      const initializeFormData = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          setError('User not authenticated');
          return;
        }
        setFormData({
          title: '',
          description: '',
          created_by: user.id
        });
        setCreatorEmail('');
      };
      initializeFormData();
    }
  }, [office]);

  const handleInputChange = (field: keyof Omit<PoliticalOffice, 'id' | 'created_at' | 'updated_at'>, value: string) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    if (!formData.title.trim()) {
      setError('Title is required');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      if (office) {
        const updatedOffice = await politicalOfficeService.updatePoliticalOffice(office.id, formData);
        await onSave(updatedOffice);
      } else {
        const newOffice = await politicalOfficeService.createPoliticalOffice(formData);
        await onSave(newOffice);
      }
      onClose();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to save political office';
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: '60vh',
          maxHeight: '90vh'
        }
      }}
    >
      <DialogTitle>
        <Typography variant="h6">
          {office ? 'Edit Political Office' : 'New Political Office'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: 3,
            py: 1,
            '& .MuiTextField-root': { width: '100%' }
          }}
        >
          {error && <Alert severity="error">{error}</Alert>}
          
          <TextField
            autoFocus
            label="Title"
            value={formData.title}
            onChange={(e) => handleInputChange('title', e.target.value)}
            required
            variant="outlined"
          />

          <TextField
            label="Description"
            value={formData.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            multiline
            rows={6}
            variant="outlined"
          />

          {office && (
            <Box sx={{ typography: 'caption', color: 'text.secondary' }}>
              <div>Created by: {creatorEmail || 'Loading...'}</div>
              <div>Created at: {new Date(office.created_at).toLocaleString()}</div>
              <div>Updated at: {new Date(office.updated_at).toLocaleString()}</div>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          color="primary"
          disabled={loading || !formData.title.trim()}
        >
          {loading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
