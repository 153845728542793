import { supabase } from '../lib/supabaseClient';
import { Party } from '../types/database.types';
import { openaiService } from './openaiService';

// Debug log for Supabase configuration
console.log('Supabase URL:', process.env.REACT_APP_SUPABASE_URL);
console.log('Supabase Client:', !!supabase);

export class PartyService {
  private truncateText(text: string, maxLength: number = 2000): string {
    return text ? text.substring(0, maxLength) : '';
  }

  private validatePartyData(party: Partial<Party>): Partial<Party> {
    const validatedData = {
      name: this.truncateText(party.name || '', 255),
      description: this.truncateText(party.description || '', 2000), // Allow longer descriptions
      symbol: this.truncateText(party.symbol || '', 255),
      founded_date: this.truncateText(party.founded_date || '', 255),
      logo_url: this.truncateText(party.logo_url || '', 255),
      website_url: this.truncateText(party.website_url || '', 255),
      twitter_url: this.truncateText(party.twitter_url || '', 255),
      facebook_url: this.truncateText(party.facebook_url || '', 255),
      instagram_url: this.truncateText(party.instagram_url || '', 255),
      linkedin_url: this.truncateText(party.linkedin_url || '', 255),
      headquarters_id: party.headquarters_id,
      political_orientation: party.political_orientation
    };

    // Log the validated data
    console.log('Validated party data:', validatedData);
    return validatedData;
  }

  async getParties(): Promise<Party[]> {
    try {
      console.log('Fetching parties...');
      const { data, error } = await supabase
        .from('parties')
        .select(`
          *,
          headquarters:places(*)
        `)
        .order('name');

      if (error) {
        console.error('Error in getParties:', error);
        throw new Error(error.message);
      }

      console.log('Fetched parties:', data);
      return data || [];
    } catch (error) {
      console.error('Error fetching parties:', error);
      throw error;
    }
  };

  async getPartyById(id: string): Promise<Party | null> {
    try {
      console.log('Fetching party with ID:', id);
      const { data, error } = await supabase
        .from('parties')
        .select(`
          *,
          headquarters:places(*)
        `)
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error in getPartyById:', error);
        throw error;
      }
      console.log('Fetched party:', data);
      return data;
    } catch (error) {
      console.error('Failed to get party by ID:', error);
      throw error;
    }
  };

  async getPartyMemberCounts(partyId: string): Promise<{ deputati: number; senatori: number }> {
    try {
      // Conteggio deputati
      const { data: deputatiData, error: deputatiError } = await supabase
        .from('politicians')
        .select(`
          id,
          politician_offices!inner(
            political_office:political_offices!inner(title)
          )
        `)
        .eq('party_id', partyId)
        .eq('politician_offices.political_office.title', 'Deputato');

      if (deputatiError) {
        console.error('Error counting deputati:', deputatiError);
        throw deputatiError;
      }

      // Conteggio senatori
      const { data: senatoriData, error: senatoriError } = await supabase
        .from('politicians')
        .select(`
          id,
          politician_offices!inner(
            political_office:political_offices!inner(title)
          )
        `)
        .eq('party_id', partyId)
        .eq('politician_offices.political_office.title', 'Senatore');

      if (senatoriError) {
        console.error('Error counting senatori:', senatoriError);
        throw senatoriError;
      }

      return {
        deputati: deputatiData?.length || 0,
        senatori: senatoriData?.length || 0
      };
    } catch (error) {
      console.error('Failed to get party member counts:', error);
      return { deputati: 0, senatori: 0 };
    }
  }

  async getPartiesWithMemberCounts(): Promise<(Party & { memberCounts?: { deputati: number; senatori: number } })[]> {
    try {
      const parties = await this.getParties();
      
      // Per ogni partito, ottieni i conteggi dei membri
      const partiesWithCounts = await Promise.all(
        parties.map(async (party) => {
          const memberCounts = await this.getPartyMemberCounts(party.id);
          return {
            ...party,
            memberCounts
          };
        })
      );
      
      return partiesWithCounts;
    } catch (error) {
      console.error('Failed to get parties with member counts:', error);
      return [];
    }
  }

  async createParty(party: Omit<Party, 'id' | 'created_at' | 'updated_at' | 'created_by'>): Promise<Party> {
    try {
      console.log('Creating party with raw data:', party);
      
      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      console.log('Current user:', user);
      
      if (userError) {
        console.error('User authentication error:', userError);
        throw new Error('User not authenticated');
      }

      if (!user) {
        console.error('No user found');
        throw new Error('No user found');
      }

      // Validate and truncate the party data
      const validatedData = this.validatePartyData(party);
      console.log('Validated data:', validatedData);

      // Insert the party
      const { data, error } = await supabase
        .from('parties')
        .insert({
          ...validatedData,
          created_by: user.id
        })
        .select()
        .single();

      if (error) {
        console.error('Error creating party:', error);
        throw error;
      }

      console.log('Party created successfully:', data);
      return data;
    } catch (error) {
      console.error('Failed to create party:', error);
      throw error;
    }
  };

  async updateParty(id: string, party: Partial<Omit<Party, 'id' | 'created_at' | 'updated_at' | 'created_by'>>): Promise<Party> {
    try {
      console.log('Updating party with ID:', id);
      console.log('Update data:', party);

      // Validate and truncate the party data
      const validatedData = this.validatePartyData(party);
      console.log('Validated update data:', validatedData);

      // Update the party
      const { data, error } = await supabase
        .from('parties')
        .update(validatedData)
        .eq('id', id)
        .select()
        .single();

      if (error) {
        console.error('Error updating party:', error);
        throw error;
      }

      console.log('Party updated successfully:', data);
      return data;
    } catch (error) {
      console.error('Failed to update party:', error);
      throw error;
    }
  };

  async deleteParty(id: string): Promise<void> {
    try {
      console.log('Deleting party with ID:', id);
      const { error } = await supabase
        .from('parties')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Error deleting party:', error);
        throw error;
      }

      console.log('Party deleted successfully');
    } catch (error) {
      console.error('Failed to delete party:', error);
      throw error;
    }
  };

  async getHeadquartersId(partyName: string): Promise<string | undefined> {
    try {
      console.log('Getting headquarters ID for:', partyName);
      const headquartersInfo = await openaiService.getHeadquartersInfo(partyName);
      
      if (!headquartersInfo) {
        console.log('No headquarters info found for:', partyName);
        return undefined;
      }

      // Search for the place with case-insensitive city name and exact state match
      const { data: existingPlaces, error } = await supabase
        .from('places')
        .select('id, name, state')
        .ilike('name', headquartersInfo.city)
        .eq('state', headquartersInfo.state);

      if (error) {
        console.error('Database error:', error);
        return undefined;
      }

      if (existingPlaces && existingPlaces.length > 0) {
        return existingPlaces[0].id;
      }

      return undefined;
    } catch (error) {
      console.error('Error in getHeadquartersId:', error);
      return undefined;
    }
  }

  async searchParties(query: string): Promise<Party[]> {
    try {
      console.log('Searching parties with query:', query);
      
      if (!query.trim()) {
        return this.getParties();
      }

      const { data, error } = await supabase
        .from('parties')
        .select(`
          *,
          headquarters:places(*)
        `)
        .or(`name.ilike.%${query}%,description.ilike.%${query}%,symbol.ilike.%${query}%`)
        .order('name');

      if (error) {
        console.error('Error searching parties:', error);
        throw error;
      }

      console.log('Search results:', data);
      return data || [];
    } catch (error) {
      console.error('Failed to search parties:', error);
      throw error;
    }
  };
}

// Export a singleton instance
export const partyService = new PartyService();
