import { supabase } from '../lib/supabaseClient';
import { Database } from '../types/database.types';
import { PoliticalOffice } from '../types/database.types';
import { Place } from '../types/database.types';

type PoliticianOffice = Database['public']['Tables']['politician_offices']['Row'];
type PoliticalOfficeInsert = Database['public']['Tables']['political_offices']['Insert'];
type PoliticalOfficeUpdate = Database['public']['Tables']['political_offices']['Update'];

type DbResult = {
  political_office_id: string;
  political_offices: PoliticalOffice;
  assigned_place_id?: string;
  assigned_place?: Place;
}

export const politicalOfficeService = {
  getPoliticalOffices: async (): Promise<PoliticalOffice[]> => {
    console.log('Fetching political offices...');
    const { data, error } = await supabase
      .from('political_offices')
      .select('*')
      .order('title');

    if (error) {
      console.error('Error fetching political offices:', error);
      throw error;
    }
    console.log('Fetched political offices:', data);
    return data || [];
  },

  createPoliticalOffice: async (office: PoliticalOfficeInsert): Promise<PoliticalOffice> => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    const { data, error } = await supabase
      .from('political_offices')
      .insert({
        ...office,
        created_by: user.id
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  updatePoliticalOffice: async (id: string, office: PoliticalOfficeUpdate): Promise<PoliticalOffice> => {
    const { data, error } = await supabase
      .from('political_offices')
      .update({
        ...office,
        updated_at: new Date().toISOString()
      })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  deletePoliticalOffice: async (id: string): Promise<void> => {
    const { error } = await supabase
      .from('political_offices')
      .delete()
      .eq('id', id);

    if (error) throw error;
  },

  getPoliticianOffices: async (politicianId: string): Promise<DbResult[]> => {
    const { data, error } = await supabase
      .from('politician_offices')
      .select(`
        political_office_id,
        political_offices (*),
        assigned_place_id,
        assigned_place:places (*)
      `)
      .eq('politician_id', politicianId)
      .returns<DbResult[]>();

    if (error) throw error;
    return data || [];
  },

  updatePoliticianOffices: async (politicianId: string, offices: { political_office_id: string; assigned_place_id?: string }[]) => {
    try {
      console.log('Updating politician offices:', { politicianId, offices });

      // Get current user
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // First, delete all existing offices for this politician
      const { error: deleteError } = await supabase
        .from('politician_offices')
        .delete()
        .eq('politician_id', politicianId);

      if (deleteError) {
        console.error('Error deleting politician offices:', deleteError);
        throw deleteError;
      }

      // Then insert new offices with created_by
      if (offices.length > 0) {
        const { error: insertError } = await supabase
          .from('politician_offices')
          .insert(offices.map(office => ({
            politician_id: politicianId,
            political_office_id: office.political_office_id,
            assigned_place_id: office.assigned_place_id,
            created_by: user.id
          })));

        if (insertError) {
          console.error('Error inserting politician offices:', insertError);
          throw insertError;
        }
      }

      return true;
    } catch (error) {
      console.error('Failed to update politician offices:', error);
      throw error;
    }
  },
};
