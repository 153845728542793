import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Button, 
  Card, 
  CardMedia, 
  Container,
  Paper,
  Divider,
  useTheme
} from '@mui/material';
import Footer from './Footer';
import PrivacyDialog from './PrivacyDialog';

const PoliticianLandingPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [imageError, setImageError] = useState(false);
  
  const politicianName = searchParams.get('name') || 'Politico';
  const imageUrl = searchParams.get('img') || '';
  
  // Estrai il nome e cognome dal parametro name
  const nameParts = politicianName.split(' ');
  const firstName = nameParts[0] || '';
  const lastName = nameParts.slice(1).join(' ') || '';
  
  // Iniziali per l'avatar
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  
  // URL di fallback con avatar generato
  const fallbackImageUrl = `https://api.dicebear.com/7.x/initials/svg?seed=${encodeURIComponent(initials)}&backgroundColor=1976d2&textColor=ffffff`;
  
  const handleOpenPrivacy = () => {
    setOpenPrivacy(true);
  };
  
  const handleRegister = () => {
    navigate('/auth');
  };
  
  // Funzione per gestire l'errore di caricamento dell'immagine
  const handleImageError = () => {
    console.log('Errore caricamento immagine, uso fallback');
    setImageError(true);
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#f5f5f5'
      }}
    >
      {/* Header */}
      <Box
        sx={{
          width: '100%',
          bgcolor: '#1976d2',
          color: 'white',
          py: 2,
          px: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          Polinder
        </Typography>
        <Button 
          variant="contained" 
          color="secondary"
          onClick={handleRegister}
          sx={{ fontWeight: 'bold' }}
        >
          Accedi / Registrati
        </Button>
      </Box>
      
      {/* Main Content */}
      <Container maxWidth="md" sx={{ py: 4, flex: 1 }}>
        <Box>
          <Typography variant="h4" align="center" gutterBottom fontWeight="bold" color="primary">
            Scopri {politicianName} su Polinder
          </Typography>
          
          <Typography variant="subtitle1" align="center" sx={{ mb: 4 }}>
            La piattaforma per conoscere e valutare i politici italiani
          </Typography>
          
          <Card sx={{ mb: 4, overflow: 'hidden', boxShadow: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
              <CardMedia
                component="img"
                image={imageError || !imageUrl ? fallbackImageUrl : imageUrl}
                alt={politicianName}
                onError={handleImageError}
                sx={{ 
                  width: { xs: '100%', md: '40%' },
                  objectFit: 'cover',
                  aspectRatio: '1/1',
                  bgcolor: '#1976d2'  // Aggiungiamo un colore di sfondo per le immagini con trasparenza
                }}
              />
              
              <Box sx={{ p: 3, width: { xs: '100%', md: '60%' } }}>
                <Typography variant="h5" gutterBottom fontWeight="bold">
                  {politicianName}
                </Typography>
                
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Per visualizzare tutte le informazioni su {firstName}, inclusi partito, 
                  ruolo politico, competenze e valutazioni degli utenti, registrati 
                  gratuitamente su Polinder.
                </Typography>
                
                <Divider sx={{ my: 2 }} />
                
                <Typography variant="body1" sx={{ mb: 3 }}>
                  Polinder ti permette di conoscere meglio i politici italiani, 
                  esprimere le tue preferenze e vedere le statistiche complete.
                </Typography>
                
                <Button 
                  variant="contained" 
                  color="primary" 
                  size="large" 
                  fullWidth
                  onClick={handleRegister}
                  sx={{ fontWeight: 'bold' }}
                >
                  Registrati ora per saperne di più
                </Button>
              </Box>
            </Box>
          </Card>
          
          <Paper sx={{ p: 3, mb: 4 }}>
            <Typography variant="h6" gutterBottom fontWeight="bold">
              Cosa puoi fare su Polinder
            </Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ 
                  bgcolor: 'primary.main', 
                  color: 'white', 
                  width: 40, 
                  height: 40, 
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold'
                }}>
                  1
                </Box>
                <Typography>
                  Esprimere le tue preferenze sui politici italiani
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ 
                  bgcolor: 'primary.main', 
                  color: 'white', 
                  width: 40, 
                  height: 40, 
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold'
                }}>
                  2
                </Box>
                <Typography>
                  Vedere statistiche dettagliate sulle competenze dei politici
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ 
                  bgcolor: 'primary.main', 
                  color: 'white', 
                  width: 40, 
                  height: 40, 
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold'
                }}>
                  3
                </Box>
                <Typography>
                  Confrontare politici e partiti in base alle preferenze degli utenti
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ 
                  bgcolor: 'primary.main', 
                  color: 'white', 
                  width: 40, 
                  height: 40, 
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold'
                }}>
                  4
                </Box>
                <Typography>
                  Accedere alle ultime notizie sui politici e partiti
                </Typography>
              </Box>
            </Box>
            
            <Button 
              variant="outlined" 
              color="primary" 
              fullWidth 
              sx={{ mt: 3 }}
              onClick={handleRegister}
            >
              Unisciti a Polinder
            </Button>
          </Paper>
        </Box>
      </Container>
      
      {/* Footer */}
      <Footer onPrivacyClick={handleOpenPrivacy} />
      
      {/* Privacy Policy Dialog */}
      <PrivacyDialog 
        open={openPrivacy} 
        onClose={() => setOpenPrivacy(false)} 
      />
    </Box>
  );
};

export default PoliticianLandingPage;
