import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  CircularProgress,
  Grid,
} from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { supabase } from '../../../lib/supabaseClient';

interface CompetencyData {
  timestamp: string;
  [key: string]: string | number | undefined;
}

interface SkillVoteResponse {
  created_at: string;
  rating: number;
  skills: {
    property: string;
  };
}

const SkillChart: React.FC<{
  title: string;
  data: { timestamp: string; value: number | null }[];
  color: string;
}> = ({ title, data, color }) => (
  <Paper sx={{ p: { xs: 1, sm: 2 }, height: '100%' }}>
    <Typography variant="h6" gutterBottom align="center">
      {title}
    </Typography>
    <Box sx={{ 
      width: '100%', 
      height: 250,
      ml: { xs: -1, sm: 0 },
      mr: { xs: -1, sm: 0 }  
    }}>
      <LineChart
        xAxis={[{
          data: data.map(d => d.timestamp),
          scaleType: 'point',
          valueFormatter: (timestamp: string) => {
            return '';  
          },
          tickLabelStyle: {
            display: 'none'  
          },
          tickSize: 0
        }]}
        yAxis={[{
          min: 1,
          max: 5,
          tickNumber: 5,
          tickLabelStyle: {
            fontSize: 12,
            textAnchor: 'end'
          }
        }]}
        series={[{
          data: data.map(d => d.value),
          color: color,
          showMark: true,
          curve: "linear"
        }]}
        height={220}
        margin={{ 
          left: 35,  
          right: 10, 
          top: 20, 
          bottom: 60 
        }}
        sx={{
          '.MuiLineElement-root': {
            strokeWidth: 2,
          },
          '.MuiChartsAxis-tickLabel': {
            '&.MuiChartsAxis-tickLabel--x': {
              display: 'none'  
            },
            '&.MuiChartsAxis-tickLabel--y': {
              textAlign: 'right',
              fontWeight: 500
            }
          },
          '.MuiChartsAxis-line': {
            stroke: '#ccc',
            strokeWidth: 1
          },
          '.MuiChartsAxis-tick': {
            stroke: '#ccc',
            strokeWidth: 1
          }
        }}
      />
    </Box>
  </Paper>
);

export const PoliticianStatistics: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<CompetencyData[]>([]);
  const [competencyProperties, setCompetencyProperties] = useState<string[]>([]);
  const [politician, setPolitician] = useState<{ first_name: string; last_name: string } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch politician details
        const { data: politicianData, error: politicianError } = await supabase
          .from('politicians')
          .select('first_name, last_name')
          .eq('id', id)
          .single();

        if (politicianError) throw politicianError;
        setPolitician(politicianData);

        // Fetch last 30 days of competency data
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        
        const { data: competencyData, error: competencyError } = await supabase
          .from('politician_skill_votes')
          .select(`
            created_at,
            rating,
            skills!inner (
              property
            )
          `)
          .eq('politician_id', id)
          .gte('created_at', thirtyDaysAgo.toISOString())
          .order('created_at', { ascending: true });

        if (competencyError) throw competencyError;

        // Process the data for the chart
        const votes = competencyData as unknown as SkillVoteResponse[];
        const dailyData = votes.reduce((acc: { [key: string]: { [key: string]: number[] } }, vote) => {
          // Get just the date part of the timestamp
          const date = new Date(vote.created_at).toISOString().split('T')[0];
          const property = vote.skills.property;
          
          if (!acc[date]) {
            acc[date] = {};
          }
          if (!acc[date][property]) {
            acc[date][property] = [];
          }
          acc[date][property].push(vote.rating);
          return acc;
        }, {});

        // Get unique competency properties
        const properties = Array.from(new Set(votes.map(vote => vote.skills.property)));
        setCompetencyProperties(properties);

        // Calculate daily averages
        const processedData = Object.entries(dailyData).map(([date, ratings]) => {
          const dayData: CompetencyData = { timestamp: date };
          properties.forEach(property => {
            if (ratings[property]) {
              const avg = ratings[property].reduce((sum, val) => sum + val, 0) / ratings[property].length;
              dayData[property] = Number(avg.toFixed(1));
            } else {
              dayData[property] = undefined;
            }
          });
          return dayData;
        });

        // Sort by date
        processedData.sort((a, b) => a.timestamp.localeCompare(b.timestamp));
        setData(processedData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err instanceof Error ? err.message : 'Si è verificato un errore durante il recupero dei dati');
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ px: { xs: 1, sm: 2 } }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ px: { xs: 1, sm: 2 } }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <Typography color="error">{error}</Typography>
        </Box>
      </Container>
    );
  }

  if (!data.length || !politician) {
    return (
      <Container maxWidth="lg" sx={{ px: { xs: 1, sm: 2 } }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <Typography>Nessun dato disponibile per questo periodo</Typography>
        </Box>
      </Container>
    );
  }

  // Chart colors
  const colors = ['#2196f3', '#4caf50', '#f44336', '#ff9800', '#9c27b0', '#795548'];

  return (
    <Container maxWidth="lg" sx={{ px: { xs: 1, sm: 2 } }}>
      <Paper sx={{ p: { xs: 1, sm: 3 }, mt: 3 }}>
        <Typography variant="h5" gutterBottom>
          Competenze di {politician.first_name} {politician.last_name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Ultimi 30 giorni
        </Typography>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          {competencyProperties.map((property, index) => (
            <Grid item xs={12} md={6} key={property}>
              <SkillChart
                title={property}
                data={data.map(item => ({
                  timestamp: item.timestamp,
                  value: (item[property] as number | undefined) ?? null
                }))}
                color={colors[index % colors.length]}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};
