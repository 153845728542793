import React from 'react';
import { Container } from '@mui/material';
import PrivacyPolicy from '../components/Privacy/PrivacyPolicy';

const PrivacyPage: React.FC = () => {
  return (
    <Container 
      maxWidth="lg" 
      sx={{ 
        py: { xs: 4, sm: 6 },
        minHeight: '100vh',
        backgroundColor: '#fff'
      }}
    >
      <PrivacyPolicy />
    </Container>
  );
};

export default PrivacyPage;
