import React from 'react';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Avatar,
  Divider,
  Link,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Party } from '../../../types/database.types';

interface PartyCardInfoProps {
  party: Party | null;
  onClose: () => void;
}

export const PartyCardInfo: React.FC<PartyCardInfoProps> = ({
  party,
  onClose
}) => {
  const theme = useTheme();
  
  if (!party) {
    return null;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: theme.palette.background.default,
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        borderRadius: 0
      }}
    >
      {/* Header with logo and close button */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        borderBottom: `1px solid ${theme.palette.divider}`
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={party?.logo_url || '/party-placeholder.png'}
            alt={party?.name}
            sx={{ 
              width: 60, 
              height: 60, 
              mr: 2,
              border: `1px solid ${theme.palette.divider}`,
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
            }}
          />
          <Box>
            <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
              {party?.name}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={onClose} sx={{ color: theme.palette.text.primary }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Party Info */}
      <Box sx={{ p: 2, flex: 1, overflow: 'auto', pt: 3 }}>
        {/* Basic Info */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, mb: 1 }}>
            Dati del partito
          </Typography>
          <Box sx={{ pl: 2, color: theme.palette.text.secondary }}>
            {party?.symbol && (
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                <strong>Sigla:</strong> {party.symbol}
              </Typography>
            )}
            {party?.political_orientation && (
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                <strong>Orientamento politico:</strong> {party.political_orientation}
              </Typography>
            )}
            {party?.government_position && (
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                <strong>Posizione nel governo:</strong> {party.government_position}
              </Typography>
            )}
            {party?.founded_date && (
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                <strong>Fondato il:</strong> {new Date(party.founded_date).toLocaleDateString('it-IT')}
              </Typography>
            )}
            {party?.headquarters && (
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                <strong>Sede:</strong> {party.headquarters.name}, {party.headquarters.region}
              </Typography>
            )}
          </Box>
        </Box>

        {/* Description */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, mb: 1 }}>
            Descrizione
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, pl: 2 }}>
            {party?.description || "Nessuna descrizione disponibile per questo partito."}
          </Typography>
        </Box>

        {/* Social Media Links */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ color: theme.palette.text.primary, mb: 1 }}>
            Social Media e Sito Web
          </Typography>
          <Box sx={{ pl: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            {party?.website_url && (
              <Link
                href={party?.website_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5, 
                  textDecoration: 'none',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: theme.palette.text.primary
                  }
                }}
              >
                <Typography sx={{ fontSize: '1rem' }}>Sito Web Ufficiale</Typography>
              </Link>
            )}
            {party?.twitter_url && (
              <Link
                href={party?.twitter_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5, 
                  textDecoration: 'none',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: '#1DA1F2'
                  }
                }}
              >
                <TwitterIcon sx={{ color: '#1DA1F2', fontSize: '1.5rem' }} />
                <Typography sx={{ fontSize: '1rem' }}>Twitter</Typography>
              </Link>
            )}
            {party?.facebook_url && (
              <Link
                href={party?.facebook_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5, 
                  textDecoration: 'none',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: '#4267B2'
                  }
                }}
              >
                <FacebookIcon sx={{ color: '#4267B2', fontSize: '1.5rem' }} />
                <Typography sx={{ fontSize: '1rem' }}>Facebook</Typography>
              </Link>
            )}
            {party?.instagram_url && (
              <Link
                href={party?.instagram_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5, 
                  textDecoration: 'none',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: '#E4405F'
                  }
                }}
              >
                <InstagramIcon sx={{ color: '#E4405F', fontSize: '1.5rem' }} />
                <Typography sx={{ fontSize: '1rem' }}>Instagram</Typography>
              </Link>
            )}
            {party?.linkedin_url && (
              <Link
                href={party?.linkedin_url}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1.5, 
                  textDecoration: 'none',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    color: '#0A66C2'
                  }
                }}
              >
                <LinkedInIcon sx={{ color: '#0A66C2', fontSize: '1.5rem' }} />
                <Typography sx={{ fontSize: '1rem' }}>LinkedIn</Typography>
              </Link>
            )}
            {!party?.website_url && !party?.twitter_url && !party?.facebook_url && 
             !party?.instagram_url && !party?.linkedin_url && (
              <Typography sx={{ color: theme.palette.text.disabled, fontStyle: 'italic' }}>
                Nessun social media o sito web disponibile
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
